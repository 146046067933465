import { Box, Button, Grid, Snackbar, Stack, Typography } from "@mui/joy";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import useContainer from "../useContainer";
import { getActiveBusiness } from "../../../store/session";
import { useParams, useSearchParams } from "react-router-dom";
import { theme } from "../../../components/Theme";
import SupplierProductInformation from "./SupplierProductInformation";
import {
  MinusIcon,
  PlusIcon,
  ShoppingCartIcon,
} from "@heroicons/react/24/outline";
import { getCountryInfo } from "../../../utils/country";
import { formatDate } from "../../../utils/formatDate";
import { If } from "../../../components/Condition";
import { formatPrice } from "../../../utils/formatPrice";
import { calculatePrice } from "../../../utils/cart";

function SupplierItemDetail() {
  const { t } = useTranslation();
  const isGuestMode = window.location.pathname.startsWith("/catalog");
  const { itemId } = useParams();
  const { catalogId } = useParams();
  const [searchParams] = useSearchParams();
  const orderId = searchParams.get("order");

  const [quantity, setQuantity] = useState(1);
  const [activeUOM, setActiveUOM] = useState<"uom1" | "uom2" | "uom3">("uom1");

  const {
    catalog,
    fetchCatalog,
    fetchCurrentItem,
    currentItem,
    handleUpdateCart,
    handleUpdateOrder,
    showToast,
    setShowToast,
  } = useContainer();

  useEffect(() => {
    const businessId = getActiveBusiness()?.id;
    if (!businessId) {
      return;
    }

    if (catalogId && itemId) {
      fetchCatalog(catalogId, (_res) => fetchCurrentItem({ itemId }));
    }
  }, [catalogId, itemId, getActiveBusiness()?.id]);

  const CatalogInfo = ({
    title,
    value,
    hiddenxs,
  }: {
    title: string;
    value: string;
    hiddenxs: boolean;
  }) => {
    if (hiddenxs) return null;
    return (
      <Grid lg={4} xs={4}>
        <div className="flex justify-between items-center">
          <div className="flex justify-between items-start lg:items-center lg:flex-row flex-col">
            <div className="flex justify-between item-start lg:items-center gap-1">
              <span
                className={`text-[12px] text-[${theme.colorSchemes.light.palette.others.secondary_text}] w-[100px]`}
              >
                {title}
              </span>

              <span
                className={`hidden lg:block text-[12px] [${theme.colorSchemes.light.palette.others.secondary_text}]`}
              >
                {":"}
              </span>
            </div>
            <span className="text-[11px] font-semibold">&nbsp;{value}</span>
          </div>
        </div>
      </Grid>
    );
  };

  return currentItem && catalog ? (
    <Stack spacing={2.5}>
      <Stack
        direction={{ xs: "column", lg: "row" }}
        justifyContent={""}
        alignItems={{ xs: "flex-start", lg: "center" }}
        spacing={1}
        mt={4}
        sx={{
          background: "var(--Neutral-050, #F9FAFB)",
          paddingX: 2,
          paddingY: 3,
          // borderRadius: 8,
        }}
      >
        <div className="flex gap-2 w-[100%] lg:w-[25%] mb-2 lg:mb-0">
          <img
            style={{
              width: "60px",
              height: "60px",
              objectFit: "cover",
            }}
            src="/inventory-form-no-img.png"
            alt=""
          />
          <div>
            <Typography fontSize={16} fontWeight={500} textColor={"#101828"}>
              {catalog?.catalogName}
            </Typography>
            <If
              condition={
                (catalog.business as any).kycPaymentGatewayStatus ===
                "VALIDATED"
              }
            >
              <Typography fontSize={15} fontWeight={700} textColor={"#101828"}>
                {t("catalog.supplier.items.verifiedSupplier")}
              </Typography>
            </If>
          </div>
        </div>
        <Grid
          container
          lg={12}
          columnSpacing={{ md: 1 }}
          width={{ xs: "100%", lg: "75%" }}
        >
          <CatalogInfo
            title={t("catalog.totalItems")}
            value={(catalog.itemsCount || "0").toString() + ` Items`}
            hiddenxs={false}
          />
          <CatalogInfo
            title={t("catalog.sellerEmail")}
            value={catalog.business.companyEmail}
            hiddenxs={true}
          />
          <CatalogInfo
            title={t("catalog.sellerLocation")}
            value={`${catalog.business.companyProvince}, ${
              getCountryInfo(catalog.business.companyCountry)?.name
            }`}
            hiddenxs={false}
          />
          <CatalogInfo
            title={t("catalog.catalogLastUpdated")}
            value={
              catalog.updatedAt ? formatDate(new Date(catalog.updatedAt)) : ""
            }
            hiddenxs={false}
          />
          <CatalogInfo
            title={t("catalog.sellerPhone")}
            value={catalog.business.companyNumber}
            hiddenxs={true}
          />
        </Grid>
      </Stack>
      <SupplierProductInformation
        catalog={catalog}
        setActiveUOM={setActiveUOM}
        activeUOM={activeUOM}
        item={currentItem}
      />
      <div className="border-0 lg:border rounded-md p-0 lg:p-2">
        <Typography
          fontSize={18}
          fontWeight={600}
          sx={{
            borderRadius: "4px",
            backgroundColor: "#F2F4F7",
            padding: "10px 15px",
            width: "100%",
            color: "#000",
          }}
        >
          {t("catalog.supplier.items.productSpecification")}
        </Typography>
        <div className=" my-5 flex flex-col gap-2">
          {currentItem.inventoryItem.flexiColumns?.data.map((col) => (
            <div className="flex justify-start items-center gap-4">
              <span
                className={`text-[${theme.colorSchemes.light.palette.common.white}] text-sm w-[100px]`}
              >
                {col.columnName}
              </span>
              <span
                className={`text-[${theme.colorSchemes.light.palette.common.black}] font-semibold`}
              >
                {col.cellValue}
              </span>
            </div>
          ))}
        </div>
      </div>
      <div className="border-0 lg:border rounded-md p-0 lg:p-2">
        <Typography
          fontSize={18}
          fontWeight={600}
          sx={{
            borderRadius: "4px",
            backgroundColor: "#F2F4F7",
            padding: "10px 15px",
            width: "100%",
            color: "#000",
          }}
        >
          {t("catalog.supplier.items.productDescription")}
        </Typography>
        {/* <Typography
          fontSize={15}
          fontWeight={400}
          sx={{
            color: "#000",
            margin: "30px 0px",
          }}
        >
          This item has been restock again and can be available soon
        </Typography> */}
      </div>

      <div className="lg:hidden block">
        <Stack
          direction={"row"}
          className="border border-gray-400 rounded-lg overflow-hidden mt-4 mb-2"
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <button
            onClick={() => setQuantity(quantity - 1 > 0 ? quantity - 1 : 1)}
            className={`"rounded-none w-[40px] h-[40px] flex justify-center items-center border-r border-gray-700"
                                        }`}
          >
            <MinusIcon className="w-6 h-6" />
          </button>
          <Typography
            level="title-md"
            className="w-[60px] flex justify-center items-center"
          >
            {quantity}
          </Typography>
          <button
            onClick={() => setQuantity(quantity + 1)}
            className={`"px-2 w-[40px] h-[40px] flex justify-center items-center rounded-none  border-l border-gray-700" 
                                        }`}
          >
            <PlusIcon className="w-6 h-6" />
          </button>
        </Stack>
        <Stack
          direction={"row"}
          spacing="10px"
          alignItems={"center"}
          sx={{
            color: "#475467",
            borderRadius: "4px",
            padding: "10px 0px",
            width: "100%",
          }}
        >
          <Typography
            fontSize={14}
            fontWeight={500}
            sx={{
              color: "#475467",
            }}
          >
            {t("catalog.supplier.items.totalPrice")}
          </Typography>
          <Typography
            fontSize={15}
            fontWeight={600}
            sx={{
              color: "#000",
            }}
          >
            {`${currentItem.currency ?? currentItem.inventoryItem.currency} ${
              isGuestMode
                ? catalog.catalogType === "PRIVATE"
                  ? "-"
                  : formatPrice(
                      calculatePrice(currentItem, activeUOM) * quantity,
                      currentItem.currency ?? currentItem.inventoryItem.currency
                    )
                : formatPrice(
                    calculatePrice(currentItem, activeUOM) * quantity,
                    currentItem.currency ?? currentItem.inventoryItem.currency
                  )
            }`}
          </Typography>
        </Stack>
        <Button
          fullWidth
          sx={{
            borderRadius: "0.5rem",
          }}
          onClick={() => {
            if (orderId) {
              handleUpdateOrder(
                orderId,
                catalog,
                currentItem,
                quantity,
                activeUOM
              );
            } else {
              handleUpdateCart(
                catalogId ?? "",
                activeUOM,
                currentItem,
                quantity
              );
            }

            setShowToast(true);
            setTimeout(() => {
              setShowToast(false);
            }, 3000);
          }}
        >
          <ShoppingCartIcon
            style={{
              width: 18,
              height: 18,
              marginRight: "0.5rem",
            }}
          />
          {t(`catalog.addToCartBtn`)}
        </Button>
        {/* <button
          onClick={() => {
            handleUpdateCart(catalogId ?? "", activeUOM, currentItem, quantity);
          }}
          className={`my-4 lg:my-0 py-3 px-4 rounded-lg  border capitalize items-center flex cursor-default 
                             text-white bg-primary-500 justify-center w-full
                            `}
        >
          <ShoppingCartIcon
            style={{
              width: 18,
              height: 18,
              marginRight: "0.5rem",
            }}
          />
          {t(`catalog.addToCartBtn`)}
        </button> */}

        <Snackbar
          variant="solid"
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={showToast}
          key={"asjfkangdlknakjrsh"}
          sx={{
            width: "40%",
            maxWidth: 800,
          }}
        >
          <Box width={"100%"}>
            <Typography
              textAlign={"center"}
              textColor={"common.white"}
              fontSize={14}
            >
              {t(
                `catalog.${
                  orderId ? "itemAddedToOrder" : "itemAddedToCart"
                }`
              )}
            </Typography>
          </Box>
        </Snackbar>
      </div>
    </Stack>
  ) : (
    <>Item not found</>
  );
}

export default SupplierItemDetail;
