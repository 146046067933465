import { privateAxios } from "../lib/api";
import { ApiConstants } from "../utils/constants";

export class AddressService {
  static async getAddressByAccount() {
    const res = await privateAxios.get(ApiConstants.addressURL);
    return res.data as IAddressResponse[];
  }

  static async getDefaultAddressByAccount() {
    const res = await privateAxios.get(ApiConstants.addressURL, {
      params: { defaultAddress: true },
    });
    return res.data as IAddressResponse;
  }

  static async createAddress(payload: any) {
    const response = await privateAxios.post(ApiConstants.addressURL, payload);
    return response.data;
  }

  static async updateAddress(payload: any) {
    const response = await privateAxios.put(
      `${ApiConstants.addressURL}/${payload.id}`,
      payload
    );
    return response.data;
  }

  static async deleteAddress(id: string) {
    const response = await privateAxios.delete(
      `${ApiConstants.addressURL}/${id}`
    );
    return response.data;
  }
}
