import {
  CheckCircleIcon,
  ExclamationCircleIcon,
} from "@heroicons/react/24/solid";
import { Box, Button, Modal, Stack, Typography } from "@mui/joy";
import { useEffect, useState } from "react";
// import { useTranslation } from "react-i18next";

interface Props {
  show?: boolean;
  onClose: () => void;
  onConfirm: () => void;
  isCompleted: boolean;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "#fff",
  boxShadow: 24,
  // px: 3,
  // py: 3,
  borderRadius: "32px",
  borderColor: "transparent",
  outline: "none",
  height: "23.75rem",
  width: { xs: "90dvw", md: "31.25rem" },
};

const PaymentSuccessModal = ({
  show = false,
  onClose,
  onConfirm,
  isCompleted = true,
}: Props) => {
  //   const { t } = useTranslation();

  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    setShowModal(show);
  }, [show]);

  const handleClose = () => {
    onClose();
  };

  const handleConfirm = () => {
    onConfirm();
  };

  return (
    <Modal
      onClose={handleClose}
      open={showModal}
      style={{
        backgroundColor: "#32383E99",
        backdropFilter: "unset",
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style} justifyContent={"center"}>
        <Stack
          direction={"column"}
          sx={{
            height: "100%",
            gap: "1.5rem",
            px: { xs: "1rem", lg: "1.5rem" },
            py: "40px",
            overflow: "auto",
          }}
          className="hide-scrollbar"
        >
          {isCompleted ? (
            <CheckCircleIcon
              width={120}
              height={120}
              color="#499873"
              className="mx-auto"
            />
          ) : (
            <ExclamationCircleIcon
              width={120}
              height={120}
              color="#499873"
              className="mx-auto"
            />
          )}

          <Stack direction={"column"} gap={"12px"}>
            <Typography
              sx={{
                textAlign: "center",
                fontWeight: 700,
                fontSize: 24,
                color: "#171A1C",
              }}
            >
              {isCompleted
                ? "Payment Successful"
                : "Please Continue Your Purchase"}
            </Typography>

            <Typography
              sx={{
                textAlign: "center",
                fontWeight: 500,
                fontSize: 16,
                color: "#555E68",
              }}
            >
              {isCompleted
                ? "Your order has been placed successfully. "
                : "Your order is on hold until the payment made."}
            </Typography>

            <Typography
              sx={{
                textAlign: "left",
                fontWeight: 400,
                fontSize: 14,
                color: "#555E68",
              }}
            >
              📦
              <span className="font-semibold">Next Step:</span>
              {isCompleted
                ? "The seller is processing your order."
                : "Continue your payment."}
            </Typography>
            <Typography
              sx={{
                textAlign: "left",
                fontWeight: 400,
                fontSize: 14,
                color: "#555E68",
              }}
            >
              🔔 <span className="font-semibold">Stay Updated:</span>{" "}
              {isCompleted
                ? "Check your order status anytime in My Orders."
                : "Check your order detail to continue make the payment."}
            </Typography>
          </Stack>

          <Stack
            direction={"row"}
            sx={{
              alignItems: "center",
              justifyContent: "space-between",
              // pb: "1rem",
              gap: "12px",
            }}
          >
            <Button
              onClick={handleClose}
              variant="outlined"
              className="my-auto rounded-md w-1/2"
            >
              <Typography
                sx={{
                  color: "#499873",
                  fontSize: 14,
                }}
              >
                {"Back to Catalog"}
              </Typography>
            </Button>
            <Button
              onClick={handleConfirm}
              className="my-auto rounded-md w-1/2"
            >
              <Typography
                sx={{
                  color: "white",
                  fontSize: 14,
                }}
              >
                {"View Order"}
              </Typography>
            </Button>
          </Stack>
        </Stack>
      </Box>
    </Modal>
  );
};

export default PaymentSuccessModal;
