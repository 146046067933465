import { CatalogType } from "../../types/catalog";
import { PaginationType } from "../../types/item";
import { useCallback, useState } from "react";
import { InventoryService } from "../../services/inventory.service";

const useContainer = () => {
  //#region ----- local states
  const [catalogs, setCatalogs] = useState<CatalogType[]>([]);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [pagination, setPagination] = useState<PaginationType>({
    current: 1,
    limit: 10,
    max: 1,
  });
  const [isLoading, setIsLoading] = useState(true);
  const [hasMore, setHasMore] = useState(true);

  //#region ----- misc
  const fetchAllCatalogs = useCallback(
    ({
      businessId,
      catalogType,
      paging = true,
      limit = 50,
      offset = 0,
      searchTerm,
      isReset = true,
      publicCatalogPeriodType = "ALL",
    }: {
      businessId: string;
      catalogType: "PUBLIC" | "PRIVATE";
      paging?: boolean;
      limit?: number;
      offset?: number;
      searchTerm?: string | null;
      isReset?: boolean;
      publicCatalogPeriodType?: string;
    }) => {
      console.log("FETCHING CATALOGs");
      InventoryService.getAllCatalogsInventory({
        businessId,
        catalogType,
        paging,
        limit,
        offset,
        searchTerm,
        publicCatalogPeriodType,
      })
        .then((res) => {
          console.log("CATALOGS RESPONSE", res);
          if (isReset) {
            setCatalogs(res.data);
            setHasMore(true);
          } else {
            setCatalogs((prev) => [...prev, ...res.data]);
          }

          if (res.pagination) {
            setPagination(res.pagination);
          }
          if (res.data.length === 0) {
            setHasMore(false);
          }

          console.log(res);
        })
        .catch((err) => console.error(err))
        .finally(() => {
          setIsLoading(false);
        });
    },
    [catalogs, hasMore, pagination]
  );

  //#region ----- handler functions
  //   const handlePaging = (action: string | number) => {
  //     const pagingConfig = {
  //       limit: 50,
  //       offset: 0,
  //     };

  //     if (typeof action === "string") {
  //       switch (action) {
  //         case "first":
  //           pagingConfig.offset = 0;
  //           break;

  //         case "previous":
  //           pagingConfig.offset = pagination.limit * (pagination.current - 2);
  //           break;

  //         case "next":
  //           pagingConfig.offset = pagination.limit * pagination.current;
  //           break;

  //         case "last":
  //           pagingConfig.offset =
  //             pagination.max * pagination.limit - pagination.limit;
  //           break;
  //       }
  //     } else {
  //       pagingConfig.offset = (action - 1) * pagination.limit;
  //     }

  //     if (catalog) {
  //       try {
  //         fetchAllCatalogs({
  //           catalogId: catalog?.id,
  //           paging: true,
  //           ...pagingConfig,
  //         });
  //       } catch (error) {
  //         console.log(error);
  //       }
  //     }
  //   };

  return {
    fetchAllCatalogs,
    pagination,
    setPagination,
    isLoading,
    setIsLoading,
    hasMore,
    setHasMore,
    catalogs,
    setCatalogs,
    confirmDelete,
    setConfirmDelete,
    showAlert,
    setShowAlert,
    alertMessage,
    setAlertMessage,
  };
};

export default useContainer;
