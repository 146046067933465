import {
  Autocomplete,
  AutocompleteOption,
  createFilterOptions,
  Dropdown,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  Stack,
  Typography,
} from "@mui/joy";
import { PartialToggler } from "../utils";
import { BuildingOffice2Icon } from "@heroicons/react/24/outline";
import { useTranslation } from "react-i18next";
import { Controller, useFormContext } from "react-hook-form";
import { MoreVert, Search } from "@mui/icons-material";
import { OrderStatusEnum } from "../../types/order";
import { Fragment } from "react/jsx-runtime";
import SectionToggleBar from "./SectionToggleBar";

export default function SecondPartyInfo({
  externalBusinessList,
  isReadOnly,
  isAdjustmentOrder,
  handleOnChangeExternalBusiness,
  handleEditNewBusiness,
  handleRemoveNewBusiness,
  handleUpdateCompanyInfo,
  isCreator,
  currentOrderStatus,
  orderType,
}: any) {
  const { t } = useTranslation();
  const { control, getValues, register } = useFormContext();
  const selectedExternalBusiness = getValues("selectedExternalBusiness");

  let infoDetailToLoop = [
    {
      value: `+ ${selectedExternalBusiness?.role?.[0]?.account?.profile?.countryCode} ${selectedExternalBusiness?.role?.[0]?.account?.profile?.localNumber}`,
      label: t("order.createOrder.form.phone"),
    },
    {
      value: selectedExternalBusiness?.role?.[0]?.account?.email,
      label: t("order.createOrder.form.email"),
    },
    {
      value: selectedExternalBusiness?.companyAddress,
      label: t("order.createOrder.form.companyAddress"),
    },
    {
      value: selectedExternalBusiness?.companyPostalCode,
      label: t("order.createOrder.form.companyPostalCode"),
    },
  ];

  let requiredMessage = "";
  let placeholder = "";
  let registerField = "";
  let toggleBarTitle = "";
  if (orderType === "sales") {
    requiredMessage = "Buyer is required";
    placeholder = t("order.createOrder.form.searchBuyer");
    registerField = "buyerIds";
    toggleBarTitle = t("order.createOrder.buyerInfo");
  } else if (orderType === "purchase") {
    requiredMessage = "Seller is required";
    placeholder = t("order.createOrder.form.searchSeller");
    registerField = "sellerIds";
    toggleBarTitle = t("order.createOrder.sellerInfo");
    const buyerInfoDetail = [
      {
        value: selectedExternalBusiness?.bankInfo?.[0]?.bankName,
        label: t("order.createOrder.form.bankName"),
      },
      {
        value: selectedExternalBusiness?.bankInfo?.[0]?.accountNo,
        label: t("order.createOrder.form.accountNo"),
      },
      {
        value: selectedExternalBusiness?.bankInfo?.[0]?.accountHolderName,
        label: t("order.createOrder.form.accountHolderName"),
      },
    ];

    infoDetailToLoop = [...infoDetailToLoop, ...buyerInfoDetail];
  }

  return (
    <PartialToggler
      defaultExpanded={true}
      renderToggle={({ open, setOpen, sxClasses }) => (
        <>
          <SectionToggleBar
            {...{ open, setOpen, sxClasses }}
            icon={<BuildingOffice2Icon width={24} height={24} />}
            title={toggleBarTitle}
            isRequired={true}
          />
        </>
      )}
    >
      {/* External Business Search  */}
      {!isReadOnly && !isAdjustmentOrder && (
        <PartialToggler.TogglyShowUpper>
          <Controller
            name="selectedExternalBusiness"
            control={control}
            defaultValue={null}
            rules={{ required: requiredMessage }}
            render={({
              field: { name, ref, onChange, value },
              fieldState: { error },
            }) => (
              <FormControl error={!!error} ref={ref}>
                <Autocomplete
                  name={name}
                  variant="linkz-autocomplete-rounded"
                  startDecorator={<Search />}
                  placeholder={placeholder}
                  options={externalBusinessList || null}
                  getOptionLabel={(_option) => ""}
                  isOptionEqualToValue={(option, value) => {
                    return option.id === value.id;
                  }}
                  filterOptions={(options, params) => {
                    const filter = createFilterOptions({
                      // matchFrom: "start",
                      stringify: (option: any) =>
                        `${option?.companyName} ${option?.role?.[0]?.account?.profile?.fullName}`,
                    });

                    const filtered = filter(options, params);

                    if (params.inputValue !== "") {
                      filtered.push({
                        newBusinessInput: params.inputValue,
                        newBusinessOptionText: `Add "${params.inputValue}"`,
                      });
                    }

                    return filtered;
                  }}
                  value={value}
                  disabled={isReadOnly || isAdjustmentOrder}
                  renderOption={(props, option: any) => (
                    <AutocompleteOption
                      key={option?.id}
                      {...props}
                      sx={{
                        py: 1,
                        "&:hover": {
                          backgroundColor: "var(--joy-palette-neutral-100)",
                        },
                      }}
                    >
                      <Typography>
                        {option?.newBusinessInput
                          ? option?.newBusinessOptionText
                          : `${option?.companyName} (${option?.role?.[0]?.account?.profile?.fullName})`}
                      </Typography>
                    </AutocompleteOption>
                  )}
                  onChange={(_event, newValue) => {
                    onChange(newValue);
                    handleOnChangeExternalBusiness(orderType, newValue);
                  }}
                  sx={{ width: { xs: "100%", lg: "50%" } }}
                />
                <Input
                  type="hidden"
                  {...register(registerField)}
                  sx={{ display: "none" }}
                />
                <FormHelperText>{error?.message}</FormHelperText>
              </FormControl>
            )}
          />
        </PartialToggler.TogglyShowUpper>
      )}

      <PartialToggler.AlwaysShow>
        {/* Company Name and Contact Info */}
        {selectedExternalBusiness &&
          Object.keys(selectedExternalBusiness).length > 0 && (
            <Stack mt={2} position={"relative"}>
              <Typography level="text-md-semibold">
                {selectedExternalBusiness?.companyName}
              </Typography>
              <Grid
                container
                spacing={1}
                m={0}
                mt={1}
                width={{ xs: "100%", lg: "75%" }}
              >
                <Grid xs={5}>
                  <Typography level="text-sm-medium">
                    {t("order.createOrder.form.contact")} :{" "}
                  </Typography>
                </Grid>
                <Grid xs={6}>
                  <Typography level="text-sm-medium">
                    {
                      selectedExternalBusiness?.role?.[0]?.account?.profile
                        ?.fullName
                    }
                  </Typography>
                </Grid>
              </Grid>

              {/* Business Edit/Remove Setting */}
              {handleEditNewBusiness &&
                handleRemoveNewBusiness &&
                isCreator && (
                  <Stack position={"absolute"} top={0} right={0}>
                    {currentOrderStatus !== OrderStatusEnum.COMPLETE &&
                      !isAdjustmentOrder && (
                        <Dropdown>
                          <MenuButton
                            slots={{ root: IconButton }}
                            slotProps={{
                              root: {
                                variant: "plain",
                                color: "neutral",
                                size: "sm",
                              },
                            }}
                          >
                            <MoreVert color="primary" />
                          </MenuButton>
                          <Menu size="sm" sx={{ minWidth: 140 }}>
                            <MenuItem onClick={handleUpdateCompanyInfo}>
                              {t("commons.editBtn")}
                            </MenuItem>
                            <MenuItem
                              onClick={() => {
                                handleOnChangeExternalBusiness("purchase", {});
                              }}
                            >
                              {t("commons.removeBtn")}
                            </MenuItem>
                          </Menu>
                        </Dropdown>
                      )}
                  </Stack>
                )}
            </Stack>
          )}
      </PartialToggler.AlwaysShow>
      <PartialToggler.TogglyShowLower>
        <Stack spacing={4}>
          {selectedExternalBusiness &&
            Object.keys(selectedExternalBusiness).length > 0 && (
              <>
                <Grid container spacing={1} width={{ xs: "100%", lg: "75%" }}>
                  {infoDetailToLoop?.length > 0 &&
                    infoDetailToLoop.map((info, index) => (
                      <Fragment key={index}>
                        <Grid xs={5}>
                          <Typography level="text-sm-medium">
                            {info.label} :
                          </Typography>
                        </Grid>
                        <Grid xs={6}>
                          <Typography level="text-sm-medium">
                            {info.value}
                          </Typography>
                        </Grid>
                      </Fragment>
                    ))}
                </Grid>
              </>
            )}
        </Stack>
      </PartialToggler.TogglyShowLower>
    </PartialToggler>
  );
}
