import { Box, Stack, Typography } from "@mui/joy";
import { theme } from "../../../components/Theme";

import EmblaCarousel from "../../../components/carousel-thumbs/EmblaCarousel";
import { EmblaOptionsType } from "embla-carousel";
import { t } from "i18next";
import { ItemType } from "../../../types/item";

function ProductInformation({ item }: { item: ItemType | undefined }) {
  const OPTIONS: EmblaOptionsType = {};
  return (
    <Box
      sx={{
        borderWidth: "1px",
        borderRadius: "6px",
        p: "0.5rem",
      }}
    >
      <Stack
        direction={{ sx: "column", lg: "row" }}
        spacing={3}
        justifyContent={{ sx: "start" }}
        // alignItems={"center"}
        m={1}
      >
        <Stack
          sx={{
            flex: { sx: 1, lg: 0.5 },
          }}
        >
          {item && item.images != null ? (
            <EmblaCarousel
              slides={[{ src: item.images || "/inventory-form-no-img.png" }]}
              options={OPTIONS}
            />
          ) : (
            <img
              className="w-full  object-cover rounded-md"
              src={
                item && item.thumbnail
                  ? item.thumbnail
                  : "/inventory-form-no-img.png"
              }
              alt={item?.productName}
            />
          )}
        </Stack>
        <Stack sx={{ flex: 1 }}>
          <Typography
            fontSize={20}
            fontWeight={500}
            sx={{ color: `${theme.colorSchemes.light.palette.common.black}` }}
          >
            <span>{t("catalog.items.sku")}</span>
            <span>{item?.sku}</span>
          </Typography>
          <Typography
            fontSize={24}
            fontWeight={700}
            sx={{ color: `${theme.colorSchemes.light.palette.common.black}` }}
          >
            {item?.productName}
          </Typography>
          <Stack direction={"row"} spacing={0.5}>
            {item?.tags &&
              (() => {
                let tagsData;
                try {
                  tagsData =
                    typeof item.tags === "string"
                      ? JSON.parse(item.tags)
                      : item.tags;
                } catch (error) {
                  console.error("Invalid JSON in flexiColumns", error);
                  return null;
                }

                return (
                  Array.isArray(tagsData?.tags) &&
                  tagsData.tags.length > 0 &&
                  tagsData.tags.map((fc: any, _index: number) => (
                    <Typography
                      fontSize={12}
                      fontWeight={500}
                      sx={{
                        color: "#475467",
                        borderRadius: "4px",
                        backgroundColor: "#F2F4F7",
                        padding: "6px 10px",
                      }}
                    >
                      {fc}
                    </Typography>
                  ))
                );
              })()}
          </Stack>
          <Stack
            spacing={0.5}
            sx={{
              padding: "16px",
            }}
          >
            {item?.flexiColumns &&
              (() => {
                let flexiData;
                try {
                  flexiData =
                    typeof item.flexiColumns === "string"
                      ? JSON.parse(item.flexiColumns)
                      : item.flexiColumns;
                } catch (error) {
                  console.error("Invalid JSON in flexiColumns", error);
                  return null;
                }

                return (
                  Array.isArray(flexiData?.data) &&
                  flexiData.data.length > 0 &&
                  flexiData.data.map((fc: any, index: number) => (
                    <Stack
                      key={index}
                      direction={"row"}
                      sx={{
                        justifyContent: "flex-start",
                        alignItems: "center",
                        gap: "1rem",
                      }}
                    >
                      <span
                        className={`text-[${theme.colorSchemes.light.palette.common.white}] w-20`}
                      >
                        {fc.columnName}
                      </span>
                      <span
                        className={`text-[${theme.colorSchemes.light.palette.common.black}] font-semibold`}
                      >
                        {fc.cellValue}
                      </span>
                    </Stack>
                  ))
                );
              })()}
          </Stack>

          <Typography
            sx={{ color: `${theme.colorSchemes.light.palette.common.black}` }}
            mb={1}
          >
            {t("catalog.items.description")}
          </Typography>
          <Typography
            sx={{
              display: "flex",
              width: "100%",
              padding: "16px",
              alignItems: "flex-start",
              gap: "16px",
              borderRadius: "8px",
              border: "1px solid var(--Neutral-400, #98A2B3)",
            }}
          >
            {item?.productDescription}
          </Typography>
        </Stack>
      </Stack>
    </Box>
  );
}

export default ProductInformation;
