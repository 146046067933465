import { useTranslation } from "react-i18next";
import useContainer from "./useContainer";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  Input,
  Stack,
  Typography,
} from "@mui/joy";
import { VisibilityOffOutlined, VisibilityOutlined } from "@mui/icons-material";
import Password from "../../../../components/PasswordPolicy/PasswordPolicy";

const EmailSignupForm = () => {
  const { t } = useTranslation();

  const {
    agreed,
    isValid,
    handleSubmit,
    onSubmit,
    register,
    errors,
    showPassword,
    toggleShowPassword,
    password,
    isLoading,
    token,
  } = useContainer();

  return (
    <Box component="section" data-testid="register" id="register">
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-col w-full gap-y-4"
      >
        <Grid
          container
          spacing={2}
          sx={{
            alignItems: "start",
            justifyContent: "center",
            rowGap: "1rem",
            width: "100%",
            px: "1rem",
          }}
          alignItems={"start"}
          justifyContent={"center"}
          rowGap={"1rem"}
        >
          {/* <Grid xs={12}>
              <FormControl error={!!errors.fullName}>
                <FormLabel>
                  <Typography
                    className={`transition-opacity duration-300 ${
                      fullName ? "opacity-100 h-auto" : "opacity-0 h-0"
                    }`}
                    fontSize={"sm"}
                    fontWeight={500}
                    textColor={"text.secondary"}
                  >
                    {t("landing.signup.fullName")}
                  </Typography>
                </FormLabel>
                <Input
                  placeholder={t("landing.signup.fullName")}
                  size="lg"
                  {...register("fullName")}
                />
                {errors.fullName?.message ? (
                  <FormHelperText>{errors.fullName?.message}</FormHelperText>
                ) : null}
              </FormControl>
            </Grid>
            <If
              condition={(!!token && !alrdyHasBusiness) || !!!token}
              children={
                <Grid xs={12}>
                  <FormControl error={!!errors.companyName}>
                    <FormLabel>
                      <Typography
                        className={`transition-opacity duration-300 ${
                          companyName ? "opacity-100 h-auto" : "opacity-0 h-0"
                        }`}
                        fontSize={"sm"}
                        fontWeight={500}
                        textColor={"text.secondary"}
                      >
                        {t("landing.signup.companyName")}
                      </Typography>
                    </FormLabel>
                    <Input
                      placeholder={t("landing.signup.companyName")}
                      size="lg"
                      {...register("companyName")}
                    />
                    {errors.companyName?.message ? (
                      <FormHelperText>
                        {errors.companyName?.message}
                      </FormHelperText>
                    ) : null}
                  </FormControl>
                </Grid>
              }
            />
            <If
              condition={(!!token && !alrdyHasBusiness) || !!!token}
              children={
                <Grid xs={12}>
                  <FormControl error={!!errors.countryName}>
                    <FormLabel>
                      <Typography
                        className={`transition-opacity duration-300 ${
                          countryName ? "opacity-100 h-auto" : "opacity-0 h-0"
                        }`}
                        fontSize={"sm"}
                        fontWeight={500}
                        textColor={"text.secondary"}
                      >
                        {t("landing.signup.country")}
                      </Typography>
                    </FormLabel>
                    <ReactFlagsSelect
                      id={"select-flag"}
                      placeholder={t("landing.signup.country")}
                      selected={countryName ?? ""}
                      onSelect={(code) => setValue("countryName", code)}
                      countries={WhiteListedCountries}
                    />

                    {errors.countryName?.message ? (
                      <FormHelperText>
                        {errors.countryName?.message}
                      </FormHelperText>
                    ) : null}
                  </FormControl>
                </Grid>
              }
            />
            <Grid xs={12} className="hidden">
              <FormControl error={errors.mobile ? true : false}>
                <FormLabel>
                  <Typography
                    fontSize={"sm"}
                    fontWeight={500}
                    className={`transition-opacity duration-300 ${
                      mobile ? "opacity-100 h-auto" : "opacity-0 h-0"
                    }`}
                    textColor={"text.secondary"}
                  >
                    {t("landing.signup.phoneNumber")}
                  </Typography>
                </FormLabel>
                <PhoneInput
                  inputProps={{
                    name: "input-phone",
                  }}
                  value={phoneNo}
                  onlyCountries={PhNoWhiteListedCountries}
                  masks={{
                    my: "..-....-....",
                    sg: "....-....",
                    th: "... ... ...",
                    id: "... ... ... ...",
                  }}
                  enableTerritories={false}
                  isValid={(inputNumber, meta: any, countries) => {
                    if (inputNumber === "") {
                      return true;
                    }
                    const result = (countries || []).some((country: any) => {
                      if (
                        startsWith(inputNumber, country.dialCode) ||
                        startsWith(country.dialCode, inputNumber)
                      ) {
                        const iso2: CountryCode = meta.iso2 as CountryCode;
                        const format = PhNoFormat[iso2];
                        if (!format) return false;
                        const isMatch = matchesFormat(
                          inputNumber,
                          format,
                          country.countryCode
                        );
                        return isMatch;
                      }
                      return false;
                    });

                    if (!result && !!!errors.mobile?.message) {
                      setError("mobile", {
                        message: "invalid mobile number.",
                      });
                    } else if (result && !!errors.mobile?.message) {
                      setError("mobile", {
                        message: undefined,
                      });
                    }
                    return result;
                  }}
                  inputStyle={{
                    height: "42px",
                    width: "100%",
                    fontSize: "16px",
                  }}
                  onChange={(phone, meta: any) => {
                    console.log({ phone, meta });
                    setValue("country.code", meta.dialCode);
                    setValue("country.label", meta.name);
                    setValue(
                      "country.dial",
                      phone.replace(new RegExp(meta.dialCode), "")
                    );
                    setValue(
                      "mobile",
                      phone.replace(new RegExp(meta.dialCode), "")
                    );
                  }}
                />

                {errors.mobile?.message ? (
                  <FormHelperText>{errors.mobile?.message}</FormHelperText>
                ) : null}
              </FormControl>
            </Grid> */}
          <Grid xs={12} px={0}>
            <FormControl error={errors.email ? true : false}>
              {/* <FormLabel>
                  <Typography
                    className={`transition-opacity duration-300 h-auto ${
                      email ? "opacity-100 h-auto" : "opacity-0 h-0"
                    }`}
                    fontSize={"sm"}
                    fontWeight={400}
                    textColor={"text.secondary"}
                  >
                    {t("landing.signup.email")}
                  </Typography>
                </FormLabel> */}
              <Input
                sx={{
                  fontSize: 14,
                  fontWeight: 600,
                  borderRadius: "8px",
                }}
                disabled={!!token}
                placeholder={t("landing.signup.email", {
                  optional: "",
                })}
                required
                size="lg"
                type="email"
                {...register("email")}
              />
              {errors.email?.message && (
                <FormHelperText>{errors.email?.message}</FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid xs={12} p={0}>
            <FormControl error={!!errors.password}>
              {/* <FormLabel>
                  <Typography
                    className={`transition-opacity duration-300 ${
                      password ? "opacity-100 h-auto" : "opacity-0 h-0"
                    }`}
                    fontSize={"sm"}
                    fontWeight={400}
                    textColor={"text.secondary"}
                  >
                    {t("landing.signup.password")}
                  </Typography>
                </FormLabel> */}
              <Input
                sx={{
                  fontSize: 14,
                  fontWeight: 600,
                  borderRadius: "8px",
                }}
                endDecorator={
                  <IconButton onClick={toggleShowPassword}>
                    {showPassword ? (
                      <VisibilityOffOutlined />
                    ) : (
                      <VisibilityOutlined />
                    )}
                  </IconButton>
                }
                placeholder={t("landing.signup.password")}
                required
                size="lg"
                type={showPassword ? "text" : "password"}
                {...register("password")}
              />
              {errors.password?.message && (
                <FormHelperText>{errors.password?.message}</FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid xs={12} px={0}>
            <Password value={password} />
          </Grid>
        </Grid>

        <FormControl size="md">
          <Stack
            direction={"row"}
            sx={{
              alignItems: "center",
              width: "100%",
              gap: "12px",
              py: "8px",
              px: "1rem",
            }}
          >
            <Checkbox
              {...register("agreed")}
              required
              sx={{
                alignSelf: "start",
              }}
            />
            <Typography fontSize={14} fontWeight={400}>
              {t("landing.signup.acceptTerms")}
              <span
                // onClick={() => setShowTermsModal(true)}
                style={{
                  margin: "0 3px",
                  textDecoration: "none",
                }}
              >
                <a
                  href={`${import.meta.env.VITE_TC_URL ?? "#"}`}
                  target="_blank"
                >
                  <span className={"text-[#499873] cursor-pointer font-bold"}>
                    {t("landing.signup.terms")}
                  </span>
                </a>
              </span>
              &
              <span
                // onClick={() => setPrivacyPolicy(true)}
                style={{
                  marginLeft: "3px",
                  textDecoration: "none",
                }}
              >
                <a
                  href={`${import.meta.env.VITE_PP_URL ?? "#"}`}
                  target="_blank"
                >
                  <span className={"text-[#499873] cursor-pointer font-bold"}>
                    {t("landing.signup.privacy")}
                  </span>
                </a>
              </span>
              .
            </Typography>
          </Stack>
        </FormControl>

        <Button
          id="btn-create"
          className="mx-auto"
          sx={{
            width: "100%",
            maxWidth: { xs: "100%", lg: "400px" },
            mt: "1rem",
          }}
          fullWidth
          size="lg"
          type="submit"
          disabled={isLoading || !isValid || !agreed}
        >
          {t("commons.createBtn")}
        </Button>
      </form>
    </Box>
  );
};

export default EmailSignupForm;
