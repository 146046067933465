import { Button, Grid, Stack, Typography } from "@mui/joy";
import { useTranslation } from "react-i18next";
import ProductInformation from "./ProductInformation";
import { FormProvider, useForm } from "react-hook-form";
import {
  CreateCatalogInventoryItemType,
  CreateItemType,
  UOMType,
} from "../../../types/item";
import ItemQuantityManager from "../../../components/Inventory/Form/ItemQuantityManager";
import { useEffect, useState } from "react";
import useContainer from "../useContainer";
import { getActiveBusiness } from "../../../store/session";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import AdditionalUOMInput from "../../../components/Inventory/Form/AdditionalUOMInput";
import { DeleteOutlined } from "@mui/icons-material";
import { InventoryService } from "../../../services/inventory.service";
import { ItemService } from "../../../services/item.service";
import ItemsRemovedModal from "../../../components/Cart/ItemsRemovedModal";

function ListCatalogueItemDetail() {
  const { t } = useTranslation();
  const { itemId, catalogId } = useParams();
  const [searchParams, _setSearchParams] = useSearchParams();
  const inventoryItemId = searchParams.get("inventoryId");

  const {
    currentItem,
    setIsLoading,
    setShowAlert,
    confirmDelete,
    setConfirmDelete,
    allUOMs,
    fetchCurrentItem,
  } = useContainer();

  useEffect(() => {
    const businessId = getActiveBusiness()?.id;
    if (!businessId) {
      return;
    }

    // fetchInventoryFormData(businessId, itemId);
  }, [itemId, getActiveBusiness()?.id]);

  const methods = useForm<CreateItemType>({
    defaultValues: {
      ...currentItem?.inventoryItem,
    },
  });
  const { handleSubmit, unregister, reset } = methods;
  const [additionalUOMs, setAdditionalUOMs] = useState<any[]>([]);
  const [_availableUOMs, setAvailableUOMs] = useState<any[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (currentItem) {
      const rawUOMs = [
        {
          uom: currentItem.inventoryItem.uom2,
          conversion: currentItem.inventoryItem.conversion2,
          price: currentItem.inventoryItem.price2,
          field: {
            uom: "uom2",
            conversion: "conversion2",
            price: "price2",
          },
        },
        {
          uom: currentItem.inventoryItem.uom3,
          conversion: currentItem.inventoryItem.conversion3,
          price: currentItem.inventoryItem.price3,
          field: {
            uom: "uom3",
            conversion: "conversion3",
            price: "price3",
          },
        },
      ];

      // TODO: fix this logic somehow, it's diffiult to read
      const currentUOMs = rawUOMs.filter((uom) => uom.uom !== null);
      const leftOverUOMs = rawUOMs.filter((uom) => uom.uom === null);

      setAdditionalUOMs(currentUOMs);
      setAvailableUOMs(leftOverUOMs);
      reset(currentItem.inventoryItem);
    }
  }, [currentItem]);

  // const handleAddUOM = () => {
  //   setAdditionalUOMs((prev) => [
  //     ...prev,
  //     {
  //       ...availableUOMs[0],
  //       uom: currentItem?.inventoryItem.uom1,
  //       conversion: null,
  //       price: 0,
  //     },
  //   ]);

  //   setAvailableUOMs(availableUOMs.slice(1));
  // };

  useEffect(() => {
    if (inventoryItemId) {
      fetchCurrentItem({ itemId: inventoryItemId });
    }
  }, [inventoryItemId]);

  const handleRemoveUOM = (index: number) => {
    // TODO: in case need to retain uom order, need to update the field names based on index
    setAvailableUOMs((prev) => {
      if (!Array.isArray(prev)) {
        return [
          {
            field: { ...additionalUOMs[index].field },
            uom: currentItem?.inventoryItem.uom1,
            conversion: 1,
            price: 0,
          },
        ];
      }
      return [
        ...prev,
        {
          field: { ...additionalUOMs[index].field },
          uom: currentItem?.inventoryItem.uom1,
          conversion: 1,
          price: 0,
        },
      ];
    });

    unregister(additionalUOMs[index].field.uom);
    unregister(additionalUOMs[index].field.conversion);
    unregister(additionalUOMs[index].field.price);

    const newAdditionalUOMs = additionalUOMs.filter((_uom, i) => i !== index);
    setAdditionalUOMs(newAdditionalUOMs);
  };
  const onSubmit = (data: CreateItemType) => {
    setIsLoading(true);

    const payload: CreateCatalogInventoryItemType = {
      price: data.price,
      price2: data.price2,
      price3: data.price3,
      currency: data.currency,
      catalog: catalogId as string,
      inventoryItem: itemId as string,
      isDeleted: false,
    };

    InventoryService.patchCatalogItems({
      itemId: inventoryItemId as string,
      updateCatalogInventoryItemDto: payload,
    })
      .then((res) => {
        if (res) {
          setShowAlert(true);
        }

        if (currentItem) {
          ItemService.patchInventoryItem({
            id: currentItem.inventoryItem.id,
            updateInventoryItemDto: {
              ...currentItem.inventoryItem,
              uom1: data.uom1,
              uom2: data.uom2 ?? null,
              uom3: data.uom3 ?? null,
              price: data.price,
              price2: data.price2,
              price3: data.price3,
            },
          }).catch((error) => console.error(error));
        }
      })
      .catch((error) => console.error(error))
      .finally(() => {
        setIsLoading(false);
        navigate(-1);
      });
  };

  const handleDelete = async () => {
    setIsLoading(true);

    if (!inventoryItemId) return;

    InventoryService.removeCatalogItem({
      catalogInventoryItemId: inventoryItemId,
    })
      .then(() => {
        navigate(-1);
      })
      .catch((e) => console.log(e));
  };

  return (
    <Stack spacing={2.5}>
      <span>{currentItem?.catalog.catalogName}</span>
      <Typography fontSize={24} fontWeight={700} sx={{ color: "#101828" }}>
        {t("catalog.items.detailCatalogItem")}
      </Typography>
      <ProductInformation item={currentItem?.inventoryItem} />
      <FormProvider {...methods}>
        <form
          onSubmit={handleSubmit(onSubmit)}
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              if (!(event.target instanceof HTMLTextAreaElement)) {
                event.preventDefault();
              }
            }
          }}
        >
          <Stack direction={"column"} gap={"32px"}>
            <section className="w-full border-2 border-gray-200 rounded-lg p-4 lg:p-8 flex flex-col gap-6">
              {currentItem && (
                <>
                  <ItemQuantityManager
                    item={currentItem.inventoryItem}
                    allUOMs={allUOMs}
                    isReadOnly
                    isDetail
                  />

                  <Grid container spacing={2} mb={2}>
                    {additionalUOMs.map((uom, index) => {
                      return (
                        <AdditionalUOMInput
                          key={uom.uom + index}
                          allUOMs={allUOMs}
                          uom={uom as UOMType}
                          index={index + 1}
                          onRemove={handleRemoveUOM}
                          basePrice={currentItem.inventoryItem.price}
                          isDetail
                          isReadOnly
                        />
                      );
                    })}
                  </Grid>
                </>
              )}
            </section>

            <hr className="w-full h-0.5 bg-gray-200" />

            <div className="w-full flex flex-col-reverse lg:flex-row lg:justify-between gap-3 ">
              <Button
                type="button"
                variant="plain"
                className=" w-full lg:w-[13rem] text-nowrap rounded-md text-[#B41F1C] hover:bg-[#B41F1C] hover:text-white"
                onClick={() => {
                  setConfirmDelete(true);
                }}
                startDecorator={
                  <DeleteOutlined
                    style={{
                      width: 18,
                      height: 18,
                    }}
                  />
                }
              >
                {t("catalog.items.deleteFromCatalogBtn")}
              </Button>
              <Stack direction={"row"} spacing={1}>
                <Button
                  type="button"
                  variant="plain-gray"
                  className="ml-auto rounded-md w-full lg:w-[8rem] text-nowrap"
                  onClick={() => navigate(-1)}
                >
                  {t("commons.cancelBtn")}
                </Button>
                <Button className="w-full rounded-md lg:w-[8rem]" type="submit">
                  {t("commons.saveBtn")}
                </Button>
              </Stack>
            </div>
          </Stack>
        </form>
      </FormProvider>
      <ItemsRemovedModal
        show={confirmDelete}
        onClick={handleDelete}
        onClose={() => setConfirmDelete(false)}
      />
    </Stack>
  );
}

export default ListCatalogueItemDetail;
