import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Stack,
  Typography,
} from "@mui/joy";
import { t } from "i18next";
import startsWith from "lodash.startswith";
import PhoneInput from "react-phone-input-2";
import { SuccessModal } from "../../components/SuccessModal";
import Shared from "../../layouts/Shared";
import {
  CountryCode,
  PhNoFormat,
  PhNoWhiteListedCountries,
} from "../../utils/constants";
import { matchesFormat } from "../../utils/misc";
import useContainer from "./useContainer";

export default function PhoneInputPage() {
  const {
    showModal,
    // mobile,
    errors,
    phoneNo,
    setError,
    setValue,
    isLoading,
    isValid,
    clearErrors,
    onSubmit,
    handleSubmit,
    trigger,
  } = useContainer();

  return (
    <Shared
      minWidth={"329px"}
      width={{ md: "640px" }}
      showNavArrow={false}
      sxProps={{ backgroundColor: "transparent" }}
    >
      <Stack
        direction={"column"}
        flex={1}
        width={{ xs: "100%", lg: "480px" }}
        mx={"auto"}
      >
        <Box
          component="section"
          data-testid="register"
          id="register"
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
            py: "1rem",
          }}
        >
          <Typography
            textColor={"primary.solidActiveBg"}
            textAlign={"center"}
            fontSize={"18"}
            fontWeight={600}
          >
            {t("landing.signup.enterPhoneNumberPrompt")}
          </Typography>

          <form
            onSubmit={handleSubmit(onSubmit)}
            className="flex flex-col w-full gap-y-10 py-10 px-4"
          >
            <Typography
              textColor={"#101828"}
              fontSize={16}
              textAlign={"center"}
              fontWeight={600}
            >
              {t("landing.signup.enterPhoneNumberPrompt2")}
            </Typography>

            <Stack direction={"row"} gap={2} width={"100%"}>
              <FormControl
                error={errors.mobile ? true : false}
                className="w-full"
              >
                {/* <FormLabel>
                  <Typography
                    fontSize={"sm"}
                    fontWeight={500}
                    className={`transition-opacity duration-300 ${
                      mobile ? "opacity-100 h-auto" : "opacity-0 h-0"
                    }`}
                    textColor={"text.secondary"}
                  >
                    {t("landing.signup.phoneNumber")}
                  </Typography>
                </FormLabel> */}
                <PhoneInput
                  inputProps={{
                    name: "input-phone",
                  }}
                  value={phoneNo}
                  onlyCountries={PhNoWhiteListedCountries}
                  masks={{
                    my: "..-....-....",
                    sg: "....-....",
                    th: "... ... ...",
                    id: "... ... ... ...",
                  }}
                  enableTerritories={false}
                  country={"id"}
                  isValid={(inputNumber, meta: any, countries) => {
                    if (inputNumber === "" || inputNumber === meta.dialCode) {
                      return true;
                    }
                    const result = (countries || []).some((country: any) => {
                      if (
                        startsWith(inputNumber, country.dialCode) ||
                        startsWith(country.dialCode, inputNumber)
                      ) {
                        const iso2: CountryCode = meta.iso2 as CountryCode;
                        const format = PhNoFormat[iso2];
                        if (!format) return false;
                        const isMatch = matchesFormat(
                          inputNumber,
                          format,
                          country.countryCode
                        );

                        return isMatch;
                      }
                      return false;
                    });

                    if (!result && !errors.mobile?.message) {
                      setError("mobile", {
                        message: t("commons.error.form.mobileInvalid"),
                      });
                    } else if (result && !!errors.mobile?.message) {
                      if (errors.mobile.type === "manual") {
                        return false;
                      }
                      clearErrors("mobile");
                      trigger("mobile");
                    }
                    return result;
                  }}
                  inputStyle={{
                    height: "56px",
                    width: "100%",
                    fontSize: "14px",
                    fontWeight: 500,
                    borderRadius: "8px",
                    paddingLeft: "5.5rem",
                  }}
                  buttonStyle={{
                    padding: "0 1rem",
                    borderRadius: "8px 0 0 8px",
                  }}
                  onChange={(phone, meta: any) => {
                    setValue("country.code", meta.dialCode);
                    setValue("country.label", meta.name);
                    setValue(
                      "country.dial",
                      phone.replace(new RegExp(meta.dialCode), "")
                    );
                    setValue(
                      "mobile",
                      phone.replace(new RegExp(meta.dialCode), "")
                    );
                  }}
                />

                {errors.mobile?.message && (
                  <FormHelperText>{errors.mobile?.message}</FormHelperText>
                )}
              </FormControl>
            </Stack>

            <Typography
              textColor={"#101828"}
              fontSize={16}
              textAlign={"center"}
              fontWeight={400}
            >
              {t("landing.signup.enterPhoneNumberPrompt3")}
            </Typography>

            <Button
              id="btn-create"
              className="mx-auto"
              sx={{ maxWidth: "352px" }}
              fullWidth
              size="lg"
              type="submit"
              disabled={isLoading || !isValid}
            >
              {t("commons.createBtn")}
            </Button>
          </form>
        </Box>
      </Stack>

      <SuccessModal
        title={t("commons.successModalTitle")}
        content={t("landing.signup.accountCreated")}
        open={showModal}
      />
    </Shared>
  );
}
