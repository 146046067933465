import { DeleteOutlined } from "@mui/icons-material";
import {
  Autocomplete,
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
  Input,
} from "@mui/joy";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { UOMType } from "../../../types/item";
import React, { useCallback, useEffect, useState } from "react";
import { NumericFormat, NumericFormatProps } from "react-number-format";
import { AVAILABLE_UOMS } from "../../../utils/constants";

interface Props {
  allUOMs: string[];
  uom: UOMType;
  isReadOnly?: boolean;
  index: number;
  onRemove?: (index: number) => void;
  basePrice: number;
  isDetail?: boolean;
}

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

const AdditionalUOMInput = ({
  allUOMs,
  uom,
  isReadOnly = false,
  index,
  onRemove,
  basePrice,
  isDetail = false,
}: Props) => {
  const { t } = useTranslation();
  const {
    control,
    register,
    setValue,
    getValues,
    formState: { errors },
  } = useFormContext();

  const currentuom1 = useWatch({
    control,
    name: "uom1",
  });

  const currentPrice = useWatch({
    control,
    name: "price",
  });

  const currentConversion = useWatch({
    control,
    name: uom.field.conversion,
  });

  const updateUOMPrice = useCallback(
    (uom: UOMType, currentPrice: number, currentConversion: number) => {
      const newPrice = currentPrice * currentConversion;
      setValue(uom.field.price, !Number.isNaN(newPrice) ? newPrice : 0);
      setUnitValue(!Number.isNaN(newPrice) ? newPrice : 0);
    },
    [currentConversion, currentPrice]
  );

  const [unitValue, setUnitValue] = useState(getValues(uom.field.price));

  useEffect(() => {
    if (!uom.price || currentPrice !== basePrice) {
      updateUOMPrice(uom, currentPrice, currentConversion);
    }
  }, [currentConversion, currentPrice]);
  useEffect(() => {
    setUnitValue(uom.price || 0);
  }, [uom]);

  const NumericFormatAdapter = React.forwardRef<
    NumericFormatProps,
    CustomProps
  >(function NumericFormatAdapter(props, ref) {
    const { onChange, ...other } = props;

    return (
      <NumericFormat
        {...other}
        getInputRef={ref}
        {...register(props.name, {
          required: t("commons.error.form.required", {
            label: t("catalog.inventory.editForm.price"),
          }),
          valueAsNumber: true,
        })}
        onValueChange={(values) => {
          setValue(props.name, values.value);
        }}
        thousandSeparator=","
        valueIsNumericString
      />
    );
  });

  return (
    <>
      <Grid xs={9} lg={2}>
        <FormControl sx={{ flex: "1" }} error={!!errors[uom.field.conversion]}>
          <FormLabel>
            {t("catalog.inventory.editForm.conversionRate")}
          </FormLabel>
          <Input
            disabled={isReadOnly}
            type="number"
            placeholder={t("catalog.inventory.editForm.conversionRate")}
            defaultValue={uom.conversion ?? 1}
            {...register(uom.field.conversion, {
              required: t("commons.error.form.required", {
                label: t("catalog.inventory.editForm.conversionRate"),
              }),
              valueAsNumber: true,
            })}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              if (e.target.value) {
                updateUOMPrice(uom, currentPrice, Number(e.target.value));
              }
            }}
            className={`min-h-[3.5rem] ${isReadOnly && "bg-[#EAECF0]"}`}
          />
          <FormHelperText>
            {(errors[uom.field.conversion] as any)?.message}
          </FormHelperText>
        </FormControl>
      </Grid>

      <Grid
        xs={3}
        lg={2}
        sx={{
          display: "flex",
          alignItems: "end",
        }}
      >
        <p className="text-base font-semibold">{currentuom1}</p>
      </Grid>

      <Grid xs={isDetail ? 12 : 6} lg={4}>
        <Controller
          // name={uom.field.uom}
          control={control}
          defaultValue={uom.uom}
          {...register(uom.field.uom, {
            required: t("commons.error.form.required", {
              label: t("catalog.inventory.editForm.uom"),
            }),
          })}
          render={({ field: { value } }) => (
            <FormControl sx={{ flex: "1" }} error={!!errors[uom.field.uom]}>
              <FormLabel>{t("catalog.inventory.editForm.uom")}</FormLabel>
              <Autocomplete
                disabled={isReadOnly}
                options={[...allUOMs, ...AVAILABLE_UOMS]}
                getOptionLabel={(option) => option}
                onInputChange={(_e, newValue) => {
                  setValue(uom.field.uom, newValue);
                }}
                value={value}
                className={` ${isReadOnly && "bg-[#EAECF0]"}`}
              />
              <FormHelperText>
                {(errors[uom.field.uom] as any)?.message}
              </FormHelperText>
            </FormControl>
          )}
        />
      </Grid>

      <Grid xs={isDetail ? 12 : 6} lg={4}>
        <FormControl sx={{ flex: "1" }} error={!!errors[uom.field.price]}>
          <FormLabel>{t("catalog.inventory.editForm.pricePerUnit")}</FormLabel>
          <Input
            // disabled={isReadOnly}
            placeholder={t("catalog.inventory.editForm.pricePerUnit")}
            defaultValue={unitValue}
            name={uom.field.price}
            // {...register(uom.field.price, {
            //   required: t("commons.error.form.required", {
            //     label: t("catalog.inventory.editForm.price"),
            //   }),
            //   valueAsNumber: true,
            // })}
            className="min-h-[3.5rem]"
            slotProps={{
              input: {
                component: NumericFormatAdapter,
              },
            }}
          />
          <FormHelperText>
            {(errors[uom.field.price] as any)?.message}
          </FormHelperText>
        </FormControl>
      </Grid>

      {onRemove && (
        <Grid
          xs={12}
          sx={{
            display: "flex",
          }}
        >
          <Button
            type="button"
            variant="plain"
            startDecorator={
              <DeleteOutlined
                style={{
                  width: 18,
                  height: 18,
                }}
              />
            }
            className="text-sm ml-auto text-nowrap border-[#475467] text-[#B41F1C] hover:text-white hover:bg-[#B41F1C] hover:border-[##B41F1C]"
            onClick={() => {
              onRemove(index - 1);
            }}
          >
            {t("catalog.inventory.editForm.uom")}
          </Button>
        </Grid>
      )}
    </>
  );
};

export default AdditionalUOMInput;
