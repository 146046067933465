import { Button, Grid, Sheet, Stack } from "@mui/joy";
import { useEffect, useState } from "react";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import useContainer from "./useContainer";
import { useTranslation } from "react-i18next";
import { FormProvider, useForm } from "react-hook-form";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { ItemService } from "../../services/item.service";
import { getActiveBusiness, useAuthStore } from "../../store/session";
import LoadingModal from "../../components/LoadingModal/LoadingModal";
import { SuccessModal } from "../../components/SuccessModal";
import { s3Client } from "../../lib/spaces";
import { DeleteObjectCommand } from "@aws-sdk/client-s3";
import { ConfirmationModal } from "../../components/ConfirmationModal";
import ItemQuantityManager from "../../components/Inventory/Form/ItemQuantityManager";
import AdditionalUOMInput from "../../components/Inventory/Form/AdditionalUOMInput";
import { CreateItemType, UOMType } from "../../types/item";
import NotFound from "../NotFound/NotFound";
import { If } from "../../components/Condition";
import ActiveItemToggle from "../../components/Inventory/Form/ActiveItemToggle";
import ProductDetailsSection from "../../components/Inventory/Form/ProductDetailsSection";
import PageTitleBox from "./components/PageTitleBox";

const EditInventory = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const { inventoryItemId } = useParams();
  const [searchParams] = useSearchParams();
  const isCreate = searchParams.get("mode") === "create";
  const navigate = useNavigate();
  const currentPath = location.pathname;
  const isFullView = currentPath.includes("full-view");

  const [additionalUOMs, setAdditionalUOMs] = useState<any[]>([]);
  const [availableUOMs, setAvailableUOMs] = useState<any[]>([]);
  const [showConfirm, setShowConfirm] = useState(false);

  const { session } = useAuthStore.getState();
  if (!session) {
    return <NotFound />;
  }

  const {
    fetchInventoryFormData,
    currentItem,
    setCurrentItem,
    isLoading,
    setIsLoading,
    showAlert,
    setShowAlert,
    alertMessage,
    setAlertMessage,
    allTags,
    allSku,
    allUOMs,
  } = useContainer();

  useEffect(() => {
    const businessId = getActiveBusiness()?.id;
    if (!businessId) {
      return;
    }

    fetchInventoryFormData(businessId, inventoryItemId);
  }, [inventoryItemId, getActiveBusiness()?.id]);
  useEffect(() => {
    if (currentItem) {
      const rawUOMs = [
        {
          uom: currentItem.uom2,
          conversion: currentItem.conversion2,
          price: currentItem.price2,
          field: {
            uom: "uom2",
            conversion: "conversion2",
            price: "price2",
          },
        },
        {
          uom: currentItem.uom3,
          conversion: currentItem.conversion3,
          price: currentItem.price3,
          field: {
            uom: "uom3",
            conversion: "conversion3",
            price: "price3",
          },
        },
      ];

      // TODO: fix this logic somehow, it's diffiult to read
      const currentUOMs = rawUOMs.filter((uom) => uom.uom !== null);
      const leftOverUOMs = rawUOMs.filter((uom) => uom.uom === null);

      setAdditionalUOMs(currentUOMs);
      setAvailableUOMs(leftOverUOMs);
      reset(currentItem);
    }
  }, [currentItem]);

  const methods = useForm<CreateItemType>({
    defaultValues: {
      ...currentItem,
    },
  });
  const { handleSubmit, unregister, reset } = methods;

  const onSubmit = (data: CreateItemType) => {
    setIsLoading(true);
    const tagsPayload = (data: CreateItemType) => {
      if (data.tags && data.tags.tags) {
        return data.tags;
      } else {
        return undefined;
      }
    };

    if (currentItem) {
      const payload = {
        sku: data.sku,
        currency: data.currency,
        price: data.price,
        quantity: data.quantity,
        lowStockQuantity: data.lowStockQuantity,
        minimumQuantity: data.minimumQuantity,
        uom1: data.uom1,
        uom2: data.uom2 ?? null,
        price2: data.price2 ?? null,
        conversion2: data.conversion2 ?? null,
        uom3: data.uom3 ?? null,
        price3: data.price3 ?? null,
        conversion3: data.conversion3 ?? null,
        productName: data.productName,
        productDescription: data.productDescription,
        inventoryType: data.inventoryType,
        business: currentItem.business,
        tags: tagsPayload(data),
        flexiColumns: data.flexiColumns,
        createdBy: currentItem.createdBy,
        isActive: currentItem.isActive,
        isDeleted: currentItem.isDeleted,
      };

      ItemService.patchInventoryItem({
        id: currentItem.id,
        updateInventoryItemDto: payload,
      })
        .then((res) => {
          if (res) {
            setShowAlert(true);
            setAlertMessage(
              t("catalog.inventory.editForm.inventoryItemUpdated")
            );

            setTimeout(() => {
              navigate(`/${isFullView ? "full-view" : "client"}/inventory`);
            }, 1500);
          }
        })
        .catch((error) => console.error(error))
        .finally(() => setIsLoading(false));
    }
  };

  const handleAddUOM = () => {
    setAdditionalUOMs((prev) => [
      ...prev,
      {
        ...availableUOMs[0],
        uom: currentItem?.uom1,
        conversion: null,
        price: 0,
      },
    ]);

    setAvailableUOMs(availableUOMs.slice(1));
  };

  const handleRemoveUOM = (index: number) => {
    // TODO: in case need to retain uom order, need to update the field names based on index
    setAvailableUOMs((prev) => {
      if (!Array.isArray(prev)) {
        return [
          {
            field: { ...additionalUOMs[index].field },
            uom: currentItem?.uom1,
            conversion: 1,
            price: 0,
          },
        ];
      }
      return [
        ...prev,
        {
          field: { ...additionalUOMs[index].field },
          uom: currentItem?.uom1,
          conversion: 1,
          price: 0,
        },
      ];
    });

    unregister(additionalUOMs[index].field.uom);
    unregister(additionalUOMs[index].field.conversion);
    unregister(additionalUOMs[index].field.price);

    const newAdditionalUOMs = additionalUOMs.filter((_uom, i) => i !== index);
    setAdditionalUOMs(newAdditionalUOMs);
  };

  const handleDeleteImage = async () => {
    setIsLoading(true);

    if (currentItem?.thumbnail) {
      const objectKey = currentItem?.thumbnail?.split("/")[3];

      const params = {
        Bucket: import.meta.env.VITE_SPACES_BUCKET_NAME,
        Key: objectKey,
      };

      try {
        const data = await s3Client.send(new DeleteObjectCommand(params));

        const newImagesData =
          currentItem.images?.data.filter((image) => image.url !== objectKey) ??
          [];

        if (data) {
          ItemService.patchInventoryItemThumbnail({
            id: currentItem.id,
            thumbnail: null,
            images: {
              data: newImagesData,
            },
          })
            .then((data) => {
              setCurrentItem(data);
              setAlertMessage(t("catalog.inventory.imageDeleteuccess"));
              setShowAlert(true);
              setTimeout(() => {
                setShowAlert(false);
              }, 3000);
            })
            .catch((error) => {
              window.alert("Error updating database");
              throw new Error(error);
            })
            .finally(() => {
              setIsLoading(false);
              window.location.reload();
            });
        }
      } catch (err) {
        console.log("Error", err);
      }
    }
  };

  const handleDeleteItem = () => {
    setIsLoading(true);
    if (currentItem) {
      ItemService.deleteInventoryItem({ id: currentItem?.id })
        .then((res) => {
          if (res) {
            setShowConfirm(false);
            setAlertMessage(
              t("catalog.inventory.editForm.inventoryItemRemoved")
            );
            setShowAlert(true);

            setTimeout(() => {
              window.location.href = `/${
                isFullView ? "full-view" : "client"
              }/inventory`;
            }, 1500);
          }
        })
        .catch((error) => console.error(error))
        .finally(() => setIsLoading(false));
    }
  };

  return (
    <Sheet
      sx={{
        backgroundColor: "transparent",
        padding: isFullView ? { xs: "1rem", lg: "4rem" } : 0,
      }}
    >
      {currentItem && (
        <div className="w-[92.4vw] lg:w-full flex flex-col gap-8">
          {/* page header */}
          <div className="hidden lg:block">
            <PageTitleBox
              title={
                isCreate
                  ? t("catalog.inventory.create.pageTitle")
                  : t("catalog.inventory.editForm.pageTitle")
              }
            />
          </div>

          <FormProvider {...methods}>
            <form
              onSubmit={handleSubmit(onSubmit)}
              onKeyDown={(event) => {
                // event.key === "Enter" && event.preventDefault();
                if (event.key === "Enter") {
                  if (!(event.target instanceof HTMLTextAreaElement)) {
                    event.preventDefault();
                  }
                }
              }}
            >
              <If condition={currentItem ? true : false}>
                <Stack
                  sx={{
                    paddingTop: "16px",
                    marginBottom: 2,
                    borderTop: "1px solid var(--Neutral-200, #EAECF0)",
                    borderBottom: "1px solid var(--Neutral-200, #EAECF0)",
                  }}
                >
                  <ActiveItemToggle currentItem={currentItem} />
                </Stack>
              </If>

              <Stack direction={"column"} gap={"32px"}>
                {/* product details */}
                <ProductDetailsSection
                  currentItem={currentItem}
                  handleDeleteImage={handleDeleteImage}
                  setIsLoading={setIsLoading}
                  setShowAlert={setShowAlert}
                  setAlertMessage={setAlertMessage}
                  allSku={allSku}
                  allTags={allTags}
                />
                <hr className="w-full h-0.5 bg-gray-200 lg:hidden" />
                <section className="w-full lg:border-2 border-gray-200 rounded-lg p-4 lg:p-8 flex flex-col gap-6">
                  {currentItem && (
                    <>
                      <ItemQuantityManager
                        item={currentItem}
                        allUOMs={allUOMs}
                      />

                      {!isCreate && (
                        <hr className="w-full bg-gray-200 h-0.5 my-[20px]" />
                      )}

                      <Grid container spacing={2} mb={2}>
                        {additionalUOMs.map((uom, index) => {
                          return (
                            <AdditionalUOMInput
                              key={uom.uom + index}
                              allUOMs={allUOMs}
                              uom={uom as UOMType}
                              index={index + 1}
                              onRemove={handleRemoveUOM}
                              basePrice={currentItem.price}
                            />
                          );
                        })}

                        {availableUOMs.length > 0 && (
                          <Button
                            variant="outlined"
                            startDecorator={<AddOutlinedIcon />}
                            className="ml-auto whitespace-nowrap mt-4"
                            onClick={handleAddUOM}
                          >
                            {t("catalog.inventory.editForm.addUOMBtn")}
                          </Button>
                        )}
                      </Grid>
                    </>
                  )}
                </section>
                <hr className="w-full h-0.5 bg-gray-200 lg:hidden" />

                <div className="w-full flex flex-col-reverse lg:flex-row gap-3">
                  <Button
                    type="button"
                    variant="plain-gray"
                    className={`ml-auto w-full lg:w-[8rem] text-nowrap ${
                      isCreate && "text-[#C31D1D]"
                    }`}
                    onClick={() => {
                      setShowConfirm(true);
                    }}
                  >
                    {t(
                      isCreate
                        ? "commons.cancelBtn"
                        : "catalog.inventory.editForm.deleteItemBtn"
                    )}
                  </Button>
                  <Button className="w-full lg:w-[8rem]" type="submit">
                    {t(isCreate ? "commons.addItemBtn" : "commons.saveBtn")}
                  </Button>
                </div>
              </Stack>
            </form>
          </FormProvider>
        </div>
      )}
      {/* modal */}
      <SuccessModal
        title={t("commons.successModalTitle")}
        content={alertMessage}
        open={showAlert}
      />
      <LoadingModal isLoading={isLoading} />
      <ConfirmationModal
        title={t("catalog.inventory.editForm.deleteItemModalTitle")}
        content={t("catalog.inventory.editForm.deleteItemModalPrompt")}
        open={showConfirm}
        onCancel={() => setShowConfirm(false)}
        onConfirm={handleDeleteItem}
      />
    </Sheet>
  );
};

export default EditInventory;
