import { Box, Button, Modal, Stack, Typography } from "@mui/joy";
import { useTranslation } from "react-i18next";

interface Props {
  show?: boolean;
  onClick: () => void;
  onClose: () => void;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "#fff",
  boxShadow: 24,
  width: { xs: "85%", lg: "94%" },
  maxWidth: 600,
  px: 5,
  py: 5,
  borderRadius: "32px",
  borderColor: "transparent",
  outline: "none",
};

const ChangeCurrencyModal = ({ show = false, onClick, onClose }: Props) => {
  const { t } = useTranslation();

  const handleClick = () => {
    onClick();
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <Modal
      open={show}
      style={{
        backgroundColor: "#32383E99",
        backdropFilter: "unset",
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style} justifyContent={"center"}>
        <img src="/subtract.svg" className="m-auto" />
        <Typography
          mt={3}
          textAlign={"center"}
          fontWeight={700}
          fontSize={24}
          id="modal-modal-title"
          sx={{
            color: "#171A1C",
          }}
        >
          {t("catalog.createCatalog.changeCurrencyModalLabel")}
        </Typography>
        {/* <div className="w-full flex flex-col"> */}
        {/* copy link  */}
        <Stack justifyContent={"center"} mt={2}>
          <Typography
            textAlign={"center"}
            fontWeight={500}
            fontSize={16}
            // lineHeight={2}
            sx={{ color: "#555E68" }}
          >
            {t("catalog.createCatalog.changeCurrencyModalPrompt")}
          </Typography>
          <Typography
            textAlign={"center"}
            fontWeight={500}
            fontSize={16}
            mt={2}
            sx={{ color: "#555E68" }}
          >
            {t("catalog.createCatalog.changeCurrencyModalConfirmLabel")}
          </Typography>
        </Stack>

        {/* buttons */}
        <Stack
          direction={{ xs: "column-reverse", lg: "row" }}
          justifyContent={"space-between"}
          mt={3}
          sx={{ width: "100%" }}
          spacing={2}
        >
          <Button
            type="button"
            variant="outlined"
            //   className="ml-auto"
            onClick={handleClick}
            className="w-full lg:w-[49%] rounded-md color-[#499873] border-[#499873]"
          >
            {t("catalog.createCatalog.saveChangeBtn")}
          </Button>
          <Button
            type="button"
            onClick={handleClose}
            className="w-full lg:w-[49%] rounded-md"
          >
            {t("catalog.createCatalog.cancelChangeBtn")}
          </Button>
        </Stack>
        {/* </div> */}
      </Box>
    </Modal>
  );
};

export default ChangeCurrencyModal;
