import { Grid, Stack } from "@mui/joy";
import Typography from "@mui/joy/Typography";
import { ValdiationUtils } from "../../utils/validation";
import { IfElse } from "../Condition";
import { PolicyBullet } from "./PolicyBullet";
import { useTranslation } from "react-i18next";

interface IProps {
  value?: string;
}

export default function PasswordPolicy({ value = "" }: IProps) {
  const { t } = useTranslation();

  return (
    <Stack rowGap={2}>
      <Typography fontSize="md" fontWeight={"600"}>
        {t("landing.signup.passwordRequirementPrompt")}
      </Typography>
      <Grid
        container
        fontSize={"sm"}
        fontWeight={"400"}
        marginTop={"0px !important"}
        gap={0}
      >
        <Grid xs={12} md={6}>
          <Stack alignItems={"center"} direction={"row"} gap={1}>
            <IfElse
              condition={value.length > 7}
              ifBlock={<PolicyBullet isActive={true} />}
              elseBlock={<PolicyBullet />}
            />
            <Typography
              textColor={value.length > 7 ? "#499873" : "text.secondary"}
              fontSize="md"
            >
              {t("landing.signup.passwordRequirement")}
            </Typography>
          </Stack>
        </Grid>
        <Grid xs={12} md={6}>
          <Stack alignItems={"center"} direction={"row"} gap={1}>
            <IfElse
              condition={ValdiationUtils.containsUppercase(value)}
              ifBlock={<PolicyBullet isActive={true} />}
              elseBlock={<PolicyBullet />}
            />
            <Typography
              textColor={
                ValdiationUtils.containsUppercase(value)
                  ? "#499873"
                  : "text.secondary"
              }
              fontSize="md"
            >
              {t("landing.signup.passwordRequirement2")}
            </Typography>
          </Stack>
        </Grid>
        <Grid xs={12} md={6}>
          <Stack alignItems={"center"} direction={"row"} gap={1}>
            <IfElse
              condition={ValdiationUtils.containsLowercase(value)}
              ifBlock={<PolicyBullet isActive={true} />}
              elseBlock={<PolicyBullet />}
            />
            <Typography
              textColor={
                ValdiationUtils.containsLowercase(value)
                  ? "#499873"
                  : "text.secondary"
              }
              fontSize="md"
            >
              {t("landing.signup.passwordRequirement3")}
            </Typography>
          </Stack>
        </Grid>
        <Grid xs={12} md={6}>
          <Stack alignItems={"center"} direction={"row"} gap={1}>
            <IfElse
              condition={ValdiationUtils.containsNumber(value)}
              ifBlock={<PolicyBullet isActive={true} />}
              elseBlock={<PolicyBullet />}
            />
            <Typography
              textColor={
                ValdiationUtils.containsNumber(value)
                  ? "#499873"
                  : "text.secondary"
              }
              fontSize="md"
            >
              {t("landing.signup.passwordRequirement4")}
            </Typography>
          </Stack>
        </Grid>
        <Grid xs={12} md={6}>
          <Stack alignItems={"center"} direction={"row"} gap={1}>
            <IfElse
              condition={ValdiationUtils.containsSpecialChar(value)}
              ifBlock={<PolicyBullet isActive={true} />}
              elseBlock={<PolicyBullet />}
            />
            <Typography
              textColor={
                ValdiationUtils.containsSpecialChar(value)
                  ? "#499873"
                  : "text.secondary"
              }
              fontSize="md"
            >
              {t("landing.signup.passwordRequirement5")}
            </Typography>
          </Stack>
        </Grid>
      </Grid>
    </Stack>
  );
}
