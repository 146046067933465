import { Add, Search } from "@mui/icons-material";
import {
  Autocomplete,
  AutocompleteOption,
  Button,
  Card,
  // createFilterOptions,
  Divider,
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
  Input,
  Option,
  Select,
  Stack,
  Textarea,
  Typography,
} from "@mui/joy";
import { useEffect, useState } from "react";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { NumericFormat } from "react-number-format";
import { DISCOUNT_TYPE, TaxAmountTypes } from "../../utils/constants";
import { formatPrice } from "../../utils/formatPrice";
import {
  calcAmountByTax,
  calcDiscountAmountByType,
  formEditableOrderStatuses,
} from "../../utils/order";
import { validateOnChange, validateOnKeyDown } from "../../utils/cart";
import { useSearchParams } from "react-router-dom";
import { normalizeSearchString } from "../../utils/misc";
import { TaxAmountTypeEnum, TaxTypeEnum } from "../../types/order";

interface OrderItemProps {
  index: number;
  orderItemField: any;
  handleRemoveOrderItem: any;
  handleUpdateOrderItem: any;
  isReadOnly: any;
  nationalTaxes: any;
  productSearchList: any;
  sellerCountry: string;
  orderType: any;
  isAdjustmentOrder: boolean;
  mainOrderContent: any[];
  listViewMode: boolean;
  orderStatus: any;
}

export default ({
  index,
  orderItemField,
  handleRemoveOrderItem,
  handleUpdateOrderItem,
  isReadOnly,
  nationalTaxes,
  productSearchList,
  sellerCountry,
  orderType,
  isAdjustmentOrder,
  mainOrderContent,
  listViewMode,
  orderStatus,
}: OrderItemProps) => {
  const { t } = useTranslation();
  const {
    register,
    setValue,
    getValues,
    control,
    formState: { errors },
  } = useFormContext();
  const [itemCalPrice, setItemCalPrice] = useState(0);
  const [qtyInputValue, setQtyInputValue] = useState(
    (orderItemField.quantity || 0).toString()
  );
  const [searchParam] = useSearchParams();
  const revisionId = searchParam.get("revision");
  // const uomList = useRef(orderItemField.uomList);
  const allSku = productSearchList.map((product: any) => product.sku); // put all sku in an id
  const mainOrderQty = mainOrderContent
    ? mainOrderContent.find(
        (mainOrderItem) =>
          mainOrderItem.productId === getValues(`orderItems.${index}.productId`)
      ).quantity
    : 0;

  // const [discountType, setDiscountType] = useState(DISCOUNT_TYPE.percentage.value);

  const watchedNestedProduct = useWatch({ name: `orderItems.${index}` });

  const handleCalculatePrice = () => {
    const wprod = getValues(`orderItems.${index}`);
    console.log("Tax Item in handleCaculatePrice :::", wprod.taxItem);
    let itemPrice = (wprod.price || 0) * (wprod.quantity || 0);
    const discount = calcDiscountAmountByType(
      wprod.discount,
      wprod.discountType,
      itemPrice
    );

    let itemPriceWithDiscount = itemPrice - discount;

    const itemTotalAmount = calcAmountByTax(
      wprod.taxItem?.taxAmount,
      wprod.taxItem?.taxType,
      itemPriceWithDiscount,
      wprod.taxItem?.taxAmountType
    );

    /** Set the price with discount amount also to the orderItem state (wprod) by mutating */
    if (wprod.itemPriceWithDiscount !== itemPriceWithDiscount) {
      setValue(
        `orderItems.${index}.itemPriceWithDiscount`,
        itemPriceWithDiscount
      );
    }

    /** Set the total amount also to the orderItem state (wprod) by mutating */
    if (wprod.itemTotalAmount !== itemTotalAmount) {
      setValue(`orderItems.${index}.itemTotalAmount`, itemTotalAmount);
    }
    console.log("Item Total Amount >>>", typeof itemTotalAmount);
    setItemCalPrice(Number(itemTotalAmount.toFixed(2)));
  };

  const validateOrderSku = (value: string) => {
    const productId = getValues(`orderItems.${index}.productId`);
    const createdSku: { [key: string]: any }[] =
      getValues(`orderItems`).map((orderItem: any) => {
        return { sku: orderItem.sku, productId: orderItem.productId };
      }) ?? [];
    const numberOfSku = createdSku.filter(
      (sku: any) => sku.sku === value && sku.productId === ""
    ).length;

    if (allSku.includes(value)) {
      if (productId === "") {
        return t("commons.error.form.duplicateSKU");
      }
      return true;
    } else {
      if (productId === "" && numberOfSku > 1) {
        return t("commons.error.form.duplicateSKU");
      }
      return true;
    }
  };

  const validateLowStock = (value: number) => {
    if (orderType !== "sales") {
      return true;
    }

    const uom = getValues(`orderItems.${index}.uomItem`);
    const inventoryQuantity = watchedNestedProduct.inventoryItem
      ? watchedNestedProduct.inventoryItem.quantity
      : watchedNestedProduct.selectedItem?.quantity || 0;

    const orderItem = getValues("orderItems")[index];
    const businessId = orderItem.inventoryItem
      ? orderItem.inventoryItem.business
      : orderItem.selectedItem?.business;

    if (
      businessId === "" ||
      !businessId ||
      !formEditableOrderStatuses.includes(orderStatus)
    ) {
      return true;
    }

    console.log("BANANA INPUT VALIDATION RESULS", {
      result: value * (uom?.conversion || 1) <= inventoryQuantity,
      conversion: value * (uom?.conversion || 1),
      inventoryQuantity,
    });
    return value * (uom?.conversion || 1) <= inventoryQuantity;
  };

  useEffect(() => {
    handleCalculatePrice();
  }, [watchedNestedProduct]);

  // useEffect(() => {
  //   if (validateLowStock(Number(watchedNestedProduct.quantity)) === "true") {
  //     setError(
  //       `orderItems.${index}.lowQuantity`,
  //       {
  //         type: "validate",
  //         message:
  //           "Quantity should be less than or equal to inventory quantity",
  //       },
  //       { shouldFocus: true }
  //     );
  //   }
  // }, []);

  console.log("In Selected Products:", orderItemField, index);

  // // New national taxes by adding "Others" option
  // const newNationalTaxes = nationalTaxes.concat([
  //   {
  //     taxLabel: "Others",
  //     taxAmount: 0,
  //     taxType: "MANUAL",
  //   },
  // ]);

  return !listViewMode ? (
    <Stack
      spacing={4}
      width={{ xs: "90vw", md: "100%" }}
      sx={{
        // backgroundColor: "var(--joy-palette-neutral-100)",
        // padding: 4,
        borderRadius: 8,
      }}
    >
      <Stack direction={"row"} gap={2} alignItems={"center"}>
        {index + 1}.
        <Typography fontWeight={600}>
          {t("order.createOrder.productForm.totalAmount")}:
        </Typography>
        <Typography>
          {getValues("currency")}{" "}
          {formatPrice(itemCalPrice || 0, getValues("currency"))}
        </Typography>
        {!isReadOnly && !revisionId && (
          <Button
            variant="plain"
            onClick={() => handleRemoveOrderItem(index)}
            disabled={isAdjustmentOrder && getValues("orderItems").length === 1}
            sx={{
              marginLeft: "auto",
              color: "var(--joy-palette-danger-500)",
              "&:hover": {
                backgroundColor: "var(--joy-palette-danger-100)",
              },
            }}
          >
            {t("order.createOrder.productForm.removeBtn")}
          </Button>
        )}
      </Stack>

      <Grid container spacing={2}>
        <Grid xs={12} lg={6}>
          <Controller
            name={`orderItems.${index}.selectedItem`}
            control={control}
            render={({
              field: { onBlur, onChange, name, value, ref },
              fieldState: { error },
            }) => {
              // const selectedValue = productSearchList.find(
              //   (p: any) => p.sku === value
              // );

              return (
                <FormControl error={!!error} ref={ref}>
                  <FormLabel>
                    {/* {t("order.createOrder.productForm.skuNo")} */}
                  </FormLabel>
                  <Autocomplete
                    id="autocomplete_product_service"
                    name={name}
                    variant="linkz-autocomplete-rounded"
                    startDecorator={<Search />}
                    freeSolo={true}
                    placeholder={t(
                      "order.createOrder.productForm.searchProduct"
                    )}
                    // options={[...products, { isAddBtn: true }]}
                    options={[...productSearchList]}
                    getOptionLabel={(option) =>
                      typeof option === "string" ? option : option.sku
                    }
                    // getOptionLabel={(_option) => ""}
                    // filterOptions={createFilterOptions(
                    //   {
                    //     // matchFrom: "start",ta
                    //     stringify: (option) =>
                    //       `${option.sku} ${option.productName}`,
                    //   }
                    // )}
                    filterOptions={(options, { inputValue }) => {
                      // Remove any special characters or spaces from the input value
                      const normalizedInput = normalizeSearchString(
                        inputValue
                      ) as string;

                      // Filter options based on normalized SKU and productName
                      return options.filter((option) => {
                        const [normalizedSku, normalizedProductName] =
                          normalizeSearchString([
                            option.sku,
                            option.productName,
                          ]);

                        return (
                          normalizedSku.includes(normalizedInput) ||
                          normalizedProductName.includes(normalizedInput)
                        );
                      });
                    }}
                    isOptionEqualToValue={(option, value) => {
                      console.log(
                        "Comparing option:",
                        option,
                        "with value:",
                        value
                      );
                      if (typeof value === "string")
                        return option.sku === value;
                      return option.sku === value.sku;
                    }}
                    // onInputChange={(_event, newInputValue) => {
                    //   console.log(
                    //     "sku autocomplete onInputChanged ?:",
                    //     newInputValue
                    //   );
                    //   setValue(`orderItems.${index}.sku`, newInputValue);
                    //   setValue(`orderItems.${index}.productId`, "");
                    // // clearErrors(`orderItems.${index}.selectedItem`);
                    // // validateOrderSku(newInputValue); had to comment due to onBlur issue
                    // }}
                    onChange={(_event, newValue) => {
                      console.log("sku autocomplete onChanged ?:", newValue);
                      onChange(newValue);
                      if (index === 0) setValue("currency", newValue.currency);
                      if (newValue) {
                        handleUpdateOrderItem(index, newValue);
                        setValue(`orderItems.${index}.sku`, newValue.sku);
                      } else {
                        // handleUpdateOrderItem(index, newValue);
                        // setValue(`orderItems.${index}.productId`, "");
                        // setValue(`orderItems.${index}.businessId`, "");
                        // setValue(`orderItems.${index}.catalogId`, "");
                        // setValue(`orderItems.${index}.createdById`, "");
                      }
                    }}
                    // value={selectedValue}
                    value={value}
                    onBlur={onBlur}
                    disabled={isReadOnly || isAdjustmentOrder}
                    renderOption={(props, option) => (
                      <AutocompleteOption
                        {...props}
                        key={option.id}
                        sx={{
                          "&:hover": {
                            backgroundColor: "var(--joy-palette-neutral-100)",
                          },
                        }}
                      >
                        <Stack
                          direction={"row"}
                          justifyContent={"space-between"}
                          alignItems={"center"}
                          flex={1}
                        >
                          <Stack>
                            {option.sku}
                            <Typography>
                              {t("order.createOrder.productForm.product")}:{" "}
                              {option.productName}
                            </Typography>
                          </Stack>
                          <Add />
                        </Stack>
                      </AutocompleteOption>
                    )}
                  />
                  <FormHelperText>{error?.message}</FormHelperText>
                  <input
                    type="hidden"
                    {...register(`orderItems.${index}.sku`, {
                      // validate: (value) => {
                      //   return validateOrderSku(value);
                      // },
                    })}
                  />
                </FormControl>
              );
            }}
          />
        </Grid>
        <Grid xs={0} lgOffset={6} />
        {/* SKU no */}
        <Grid xs={12} lg={6}>
          <FormControl error={!!(errors?.orderItems as any)?.[index]?.sku}>
            <FormLabel>{t("order.createOrder.productForm.skuNo")}</FormLabel>
            <Input
              placeholder={t("order.createOrder.productForm.skuNo")}
              {...register(`orderItems.${index}.sku`, {
                validate: (value) => {
                  return validateOrderSku(value);
                },
              })}
              disabled={isReadOnly || isAdjustmentOrder}
            />
            <FormHelperText>
              {(errors?.orderItems as any)?.[index]?.sku?.message}
            </FormHelperText>
          </FormControl>
        </Grid>
        {/* Product name */}
        <Grid xs={12} lg={6}>
          <FormControl>
            <FormLabel>
              {t("order.createOrder.productForm.productName")}
            </FormLabel>
            <Input
              placeholder={t("order.createOrder.productForm.productName")}
              defaultValue={orderItemField.productName}
              {...register(`orderItems.${index}.productName`)}
              disabled={isReadOnly || isAdjustmentOrder}
            />
          </FormControl>
        </Grid>
        {/* Description */}
        <Grid xs={12}>
          <FormControl>
            <FormLabel>
              {t("order.createOrder.productForm.description")}
            </FormLabel>
            <Textarea
              placeholder={t("order.createOrder.productForm.description")}
              minRows={4}
              {...register(`orderItems.${index}.productDescription`)}
              disabled={isReadOnly || isAdjustmentOrder}
            >
              {orderItemField.productDescription}
            </Textarea>
          </FormControl>
        </Grid>
        {/* uom */}
        <Grid xs={12} lg={6}>
          <Controller
            name={`orderItems.${index}.uomItem`}
            control={control}
            defaultValue={orderItemField.uomList?.[0] || ""}
            render={({
              field: { onChange, onBlur, name, value, ref },
              fieldState: { error },
            }) => (
              <FormControl sx={{ flex: "1" }} error={!!error} ref={ref}>
                <FormLabel>{t("order.createOrder.productForm.uom")}</FormLabel>
                <Autocomplete
                  name={name}
                  freeSolo={true}
                  placeholder={t("order.createOrder.productForm.uom")}
                  options={orderItemField.uomList || []}
                  getOptionLabel={
                    (option) =>
                      typeof option === "string" ? option : option?.uom || ""
                    // `${option?.uom} (${option?.conversion} ${orderItemField.uomList?.[0]?.uom})` || ""
                  }
                  onInputChange={(_event, newInputValue) => {
                    setValue(`orderItems.${index}.uom`, newInputValue);
                    setValue(`orderItems.${index}.conversion`, 1);
                  }}
                  onChange={(_event, newValue: any) => {
                    if (typeof newValue === "string") {
                      onChange({
                        uom: newValue,
                        conversion: 1,
                        price: orderItemField.price,
                      });
                      setValue(`orderItems.${index}.uom`, newValue);
                      setValue(`orderItems.${index}.conversion`, 1);
                      setValue(
                        `orderItems.${index}.price`,
                        orderItemField.price
                      );
                    } else {
                      onChange(newValue);
                      setValue(`orderItems.${index}.uom`, newValue.uom);
                      setValue(
                        `orderItems.${index}.conversion`,
                        newValue.conversion
                      );
                      setValue(`orderItems.${index}.price`, newValue.price);
                    }
                    // handleCalculatePrice();
                  }}
                  onBlur={onBlur}
                  value={value}
                  disabled={isReadOnly || isAdjustmentOrder}
                />
                <FormHelperText>{error?.message}</FormHelperText>
              </FormControl>
            )}
          />
          <Input
            type="hidden"
            {...register(`orderItems.${index}.uom`)}
            // defaultValue={
            //   orderItemField.uom ?? getValues(`orderItems.${index}.uomItem`)
            // }
            sx={{ display: "none" }}
          />
          <Input
            type="hidden"
            {...register(`orderItems.${index}.conversion`)}
            defaultValue={orderItemField.conversion ?? 1}
            sx={{ display: "none" }}
          />
        </Grid>
        {/* qty */}
        <Grid xs={12} lg={6}>
          <FormControl
            sx={{ flex: "1" }}
            error={!!(errors?.orderItems as any)?.[index]?.quantity}
          >
            <FormLabel>{t("order.createOrder.productForm.quantity")}</FormLabel>
            <Input
              // type="number"
              placeholder={t("order.createOrder.productForm.quantity")}
              value={qtyInputValue}
              {...register(`orderItems.${index}.quantity`, {
                // onChange: () => handleCalculatePrice(),
                valueAsNumber: true,
                min: { value: 1, message: t("commons.error.form.quantity") },
                ...(mainOrderQty > 0 && {
                  max: {
                    value: mainOrderQty,
                    message: t("commons.error.form.quantityExceed", {
                      threshold: mainOrderQty,
                    }),
                  },
                }),
                validate: (value) => {
                  if (value <= 0) {
                    return t("commons.error.form.quantity");
                  }

                  return (
                    validateLowStock(value) ||
                    t("catalog.inventory.updateQty.errorOrder")
                  );
                },
              })}
              onChange={(e) => {
                validateOnChange(e.target.value, (sanitizedValue) => {
                  setQtyInputValue(sanitizedValue);
                  setValue(`orderItems.${index}.quantity`, sanitizedValue);
                });
              }}
              onKeyDown={(e) => {
                if (validateOnKeyDown(e.key)) {
                  e.preventDefault();
                  return;
                }
              }}
              disabled={isReadOnly || (revisionId && true)}
            />
            {/* <input
              type="hidden"
              defaultValue={validateLowStock(
                Number(watchedNestedProduct.quantity)
              )}
              {...register(`orderItems.${index}.lowQuantity`, {
                validate: (value) => {
                  if (orderType === "sales") {
                    return value !== "true"
                      ? true
                      : t("catalog.inventory.updateQty.errorOrder", {
                          lowStockQuantity: watchedNestedProduct.quantity,
                        });
                  }

                  return true;
                },
              })}
            /> */}
            <FormHelperText>
              {(errors?.orderItems as any)?.[index]?.quantity?.message}
            </FormHelperText>
          </FormControl>
        </Grid>
        {/* price */}
        <Grid xs={12} lg={6}>
          <Controller
            name={`orderItems.${index}.price`}
            defaultValue={orderItemField.price || 0}
            control={control}
            render={({ field: { name, onChange, ref, value } }) => (
              <FormControl sx={{ flex: "1" }} ref={ref}>
                <FormLabel>
                  {t("order.createOrder.productForm.pricePer")}{" "}
                  {getValues(`orderItems.${index}`).uomItem?.uom || "Unit"}
                </FormLabel>
                <NumericFormat
                  name={name}
                  customInput={Input}
                  startDecorator={<>{getValues("currency")}</>}
                  placeholder={`${t(
                    "order.createOrder.productForm.pricePer"
                  )} ${orderItemField.uom || "Unit"}`}
                  disabled={isReadOnly || isAdjustmentOrder}
                  value={value}
                  onValueChange={(v) => {
                    onChange(Number(v.value));
                    // handleCalculatePrice();
                  }}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  // valueIsNumericString={true}
                  thousandSeparator={
                    getValues("currency") === "IDR" ? "." : ","
                  }
                  decimalSeparator={getValues("currency") === "IDR" ? "," : "."}
                />
              </FormControl>
            )}
          />
        </Grid>
        {/* discount */}
        <Grid xs={12} lg={6}>
          <FormControl sx={{ flex: "1" }}>
            <FormLabel>{t("order.createOrder.productForm.discount")}</FormLabel>
            <Controller
              name={`orderItems.${index}.discount`}
              defaultValue={orderItemField.discount || 0}
              control={control}
              render={({ field: { name, onChange, value } }) => (
                <NumericFormat
                  name={name}
                  value={value}
                  customInput={Input}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  thousandSeparator={
                    getValues("currency") === "IDR" ? "." : ","
                  }
                  decimalSeparator={getValues("currency") === "IDR" ? "," : "."}
                  {...(getValues(`orderItems.${index}.discountType`) ===
                    DISCOUNT_TYPE.percentage.value && {
                    isAllowed: (values) => {
                      const { floatValue } = values;
                      return floatValue === undefined || floatValue <= 100; // Limit input to 100%
                    },
                  })}
                  placeholder={t("order.createOrder.productForm.discount")}
                  defaultValue={watchedNestedProduct.discount || 0}
                  // {...register(`orderItems.${index}.discount`, {
                  //   valueAsNumber: true,
                  // })}
                  onValueChange={(v) => {
                    onChange(Number(v.value) || 0);
                    // setValue(`orderItems.${index}.discount`, v.value);
                    // handleCalculatePrice();
                  }}
                  disabled={isReadOnly || isAdjustmentOrder}
                  endDecorator={
                    <>
                      <Divider orientation="vertical" />
                      <Controller
                        name={`orderItems.${index}.discountType`}
                        defaultValue={DISCOUNT_TYPE.percentage.value}
                        control={control}
                        render={({ field: { name, onChange, value, ref } }) => (
                          <Select
                            variant="plain"
                            name={name}
                            value={value}
                            ref={ref}
                            onChange={(_, value) => {
                              onChange(value);
                              setValue(`orderItems.${index}.discount`, 0);
                              // handleCalculatePrice();
                            }}
                            sx={{
                              "&:hover": { bgcolor: "transparent" },
                              mr: -1.5,
                              pt: 0,
                              pb: 0,
                              minHeight: "auto",
                            }}
                          >
                            <Option value={DISCOUNT_TYPE.percentage.value}>
                              {DISCOUNT_TYPE.percentage.label}
                            </Option>
                            <Option value={DISCOUNT_TYPE.amount.value}>
                              {DISCOUNT_TYPE.amount.label}
                            </Option>
                          </Select>
                        )}
                      />
                    </>
                  }
                />
              )}
            />
          </FormControl>
        </Grid>
        {/* tax */}
        {sellerCountry === "ID" &&
          getValues("additionalTaxItem.taxType") === null &&
          getValues("additionalTaxItem.taxAmount") === 0 && (
            <>
              <Grid xs={12} lg={6}>
                <Controller
                  name={`orderItems.${index}.taxItem`}
                  defaultValue={{
                    taxLabel: "NONE",
                    taxAmount: 0,
                    taxType: null,
                  }}
                  control={control}
                  render={({
                    field: { onChange, onBlur, name, value, ref },
                    fieldState: { error },
                  }) => (
                    <FormControl sx={{ flex: "1" }} error={!!error} ref={ref}>
                      <FormLabel>
                        {t("order.createOrder.productForm.taxAdditional")}
                      </FormLabel>
                      <Autocomplete
                        name={name}
                        placeholder={t(
                          "order.createOrder.productForm.taxAdditional"
                        )}
                        options={nationalTaxes || []}
                        getOptionLabel={(option) => option?.taxLabel || ""}
                        onChange={(_event, newValue: any) => {
                          onChange(newValue);
                          console.log(
                            "National Tax Item changed? ::",
                            newValue
                          );
                          if (newValue) {
                            /** Reset additional tax */
                            setValue("additionalTaxItem", {
                              taxLabel: "NONE",
                              taxAmount: 0,
                              taxType: null,
                            });
                          } else {
                            /** If clicked the cross 'x' button */
                            setValue(`orderItems.${index}.taxItem`, {
                              taxLabel: "NONE",
                              taxAmount: 0,
                              taxType: null,
                            });
                          }
                          // handleCalculatePrice();
                        }}
                        onBlur={onBlur}
                        value={value}
                        disabled={isReadOnly || isAdjustmentOrder}
                      />
                      <FormHelperText>{error?.message}</FormHelperText>
                    </FormControl>
                  )}
                />
              </Grid>

              {/* Manual Tax Amount */}
              {getValues(`orderItems.${index}.taxItem.taxType`) ===
                TaxTypeEnum.MANUAL && (
                <Grid xs={12} lg={6}>
                  <FormControl sx={{ flex: "1" }}>
                    <FormLabel>
                      {t("order.createOrder.productForm.otherTaxAmount")}
                    </FormLabel>
                    <Controller
                      name={`orderItems.${index}.taxItem.taxAmount`}
                      defaultValue={orderItemField.taxItem.taxAmount || 0}
                      control={control}
                      render={({ field: { name, onChange, value } }) => (
                        <NumericFormat
                          name={name}
                          value={value}
                          customInput={Input}
                          decimalScale={2}
                          fixedDecimalScale={true}
                          thousandSeparator={
                            getValues("currency") === "IDR" ? "." : ","
                          }
                          decimalSeparator={
                            getValues("currency") === "IDR" ? "," : "."
                          }
                          {...(getValues(
                            `orderItems.${index}.taxItem.taxAmountType`
                          ) === TaxAmountTypeEnum.PERCENTAGE && {
                            isAllowed: (values) => {
                              const { floatValue } = values;
                              return (
                                floatValue === undefined || floatValue <= 100
                              ); // Limit input no more than 100%
                            },
                          })}
                          placeholder={t(
                            "order.createOrder.productForm.otherTaxAmount"
                          )}
                          // defaultValue={orderItemField.taxItem.tax || 0}
                          // {...register(`orderItems.${index}.otherTaxAmount`, {
                          //   valueAsNumber: true,
                          // })}
                          onValueChange={(v) => {
                            onChange(Number(v.value) || 0);
                            // setValue(
                            //   `orderItems.${index}.otherTaxAmount`,
                            //   v.value
                            // );
                            // setValue(
                            //   `orderItems.${index}.taxItem.taxAmount`,
                            //   v.value
                            // );
                            // handleCalculatePrice();
                          }}
                          disabled={isReadOnly || isAdjustmentOrder}
                          endDecorator={
                            <>
                              <Divider orientation="vertical" />
                              <Controller
                                name={`orderItems.${index}.taxItem.taxAmountType`}
                                defaultValue={
                                  orderItemField.taxItem.taxAmountType ||
                                  TaxAmountTypeEnum.PERCENTAGE
                                }
                                control={control}
                                render={({
                                  field: { name, onChange, value, ref },
                                }) => (
                                  <Select
                                    variant="plain"
                                    name={name}
                                    value={value}
                                    ref={ref}
                                    onChange={(_, value) => {
                                      onChange(value);
                                      console.log(
                                        "Tax Amount Type changed? ::",
                                        value
                                      );

                                      // reset taxAmount to 0 when taxAmountType is changed
                                      setValue(
                                        `orderItems.${index}.taxItem.taxAmount`,
                                        0
                                      );
                                      // handleCalculatePrice();
                                    }}
                                    sx={{
                                      "&:hover": { bgcolor: "transparent" },
                                      mr: -1.5,
                                      pt: 0,
                                      pb: 0,
                                      minHeight: "auto",
                                    }}
                                  >
                                    <Option
                                      value={TaxAmountTypes.percentage.value}
                                    >
                                      {TaxAmountTypes.percentage.label}
                                    </Option>
                                    <Option value={TaxAmountTypes.fixed.value}>
                                      {TaxAmountTypes.fixed.label}
                                    </Option>
                                  </Select>
                                )}
                              />
                            </>
                          }
                        />
                      )}
                    />
                  </FormControl>
                </Grid>
              )}
            </>
          )}
        {/* total amount */}
        <Grid xs={12} lg={6}>
          <FormControl sx={{ flex: "1" }}>
            <FormLabel>
              {t("order.createOrder.productForm.totalAmount")}
            </FormLabel>
            <Input
              placeholder={`${t("order.createOrder.productForm.totalAmount")}`}
              startDecorator={<>{getValues("currency")}</>}
              value={formatPrice(itemCalPrice || 0, getValues("currency"))}
              disabled={true}
            />
          </FormControl>
        </Grid>
      </Grid>

      {/* <UpdateQuantityModal
        show={showUpdateQuantityModal}
        onClose={() => {
          setShowUpdateQuantityModal(false);
        }}
        item={
          orderItemField.inventoryItem
            ? orderItemField.inventoryItem
            : orderItemField.selectedItem
        }
      /> */}

      {/* {orderItemField.uomList && orderItemField.uomList.length > 0 && (
        <Stack direction={"row"} gap={3}>
          <Controller
            name={`orderItems.${index}.uomItem`}
            control={control}
            defaultValue={orderItemField.uomList?.[0] || ""}
            render={({
              field: { onChange, onBlur, name, value, ref },
              fieldState: { error },
            }) => (
              <FormControl sx={{ flex: "1" }} error={!!error} ref={ref}>
                <FormLabel>{t("order.createOrder.productForm.uom")}</FormLabel>
                <Autocomplete
                  name={name}
                  placeholder={t("order.createOrder.productForm.uom")}
                  options={orderItemField.uomList || []}
                  getOptionLabel={
                    (option) => option?.uom || ""
                    // `${option?.uom} (${option?.conversion} ${orderItemField.uomList?.[0]?.uom})` || ""
                  }
                  onChange={(_event, newValue: any) => {
                    onChange(newValue);
                    setValue(`orderItems.${index}.uom`, newValue.uom);
                    setValue(
                      `orderItems.${index}.conversion`,
                      newValue.conversion
                    );
                    setValue(`orderItems.${index}.price`, newValue.price);
                  }}
                  onBlur={onBlur}
                  value={value}
                  disabled={isReadOnly}
                />
                <FormHelperText>{error?.message}</FormHelperText>
              </FormControl>
            )}
          />
          <Input
            type="hidden"
            {...register(`orderItems.${index}.uom`)}
            defaultValue={orderItemField.uom}
            sx={{ display: "none" }}
          />
          <Input
            type="hidden"
            {...register(`orderItems.${index}.conversion`)}
            defaultValue={orderItemField.conversion}
            sx={{ display: "none" }}
          />
        </Stack>
      )} */}
    </Stack>
  ) : (
    // ListVIew Mode here
    <>
      <Card sx={{ backgroundColor: "#FCFCFD" }}>
        <Stack>
          <Grid container spacing={2} flexGrow={1} padding={1} gap={3}>
            {/* SKU */}
            <Controller
              name={`orderItems.${index}.selectedItem`}
              control={control}
              render={({
                field: { onBlur, onChange, name, value, ref },
                fieldState: { error },
              }) => {
                // const selectedValue = productSearchList.find(
                //   (p: any) => p.sku === value
                // );

                return (
                  <FormControl sx={{ flex: "1" }} error={!!error} ref={ref}>
                    <FormLabel>
                      {t("order.createOrder.productForm.skuNo")}
                    </FormLabel>
                    <Autocomplete
                      id="autocomplete_product_service"
                      name={name}
                      // variant="linkz-autocomplete-rounded"
                      startDecorator={<Search />}
                      freeSolo={true}
                      placeholder={t(
                        "order.createOrder.productForm.searchProduct"
                      )}
                      // options={[...products, { isAddBtn: true }]}
                      options={[...productSearchList]}
                      getOptionLabel={(option) =>
                        typeof option === "string" ? option : option.sku
                      }
                      // getOptionLabel={(_option) => ""}
                      // filterOptions={createFilterOptions(
                      //   {
                      //     // matchFrom: "start",ta
                      //     stringify: (option) =>
                      //       `${option.sku} ${option.productName}`,
                      //   }
                      // )}
                      filterOptions={(options, { inputValue }) => {
                        // Remove any special characters or spaces from the input value
                        const normalizedInput = normalizeSearchString(
                          inputValue
                        ) as string;

                        // Filter options based on normalized SKU and productName
                        return options.filter((option) => {
                          const [normalizedSku, normalizedProductName] =
                            normalizeSearchString([
                              option.sku,
                              option.productName,
                            ]);

                          return (
                            normalizedSku.includes(normalizedInput) ||
                            normalizedProductName.includes(normalizedInput)
                          );
                        });
                      }}
                      isOptionEqualToValue={(option, value) => {
                        console.log(
                          "Comparing option:",
                          option,
                          "with value:",
                          value
                        );
                        if (typeof value === "string")
                          return option.sku === value;
                        return option.sku === value.sku;
                      }}
                      onInputChange={(_event, newInputValue) => {
                        setValue(`orderItems.${index}.sku`, newInputValue);
                      }}
                      // onInputChange={(_event, newInputValue) => {
                      //   console.log(
                      //     "sku autocomplete onInputChanged ?:",
                      //     newInputValue
                      //   );
                      //   setValue(`orderItems.${index}.sku`, newInputValue);
                      //   setValue(`orderItems.${index}.productId`, "");
                      // // clearErrors(`orderItems.${index}.selectedItem`);
                      // // validateOrderSku(newInputValue); had to comment due to onBlur issue
                      // }}
                      onChange={(_event, newValue) => {
                        console.log("sku autocomplete onChanged ?:", newValue);
                        onChange(newValue);
                        if (index === 0)
                          setValue("currency", newValue.currency);
                        if (newValue) {
                          handleUpdateOrderItem(index, newValue);
                          setValue(`orderItems.${index}.sku`, newValue.sku);
                        } else {
                          // handleUpdateOrderItem(index, newValue);
                          // setValue(`orderItems.${index}.productId`, "");
                          // setValue(`orderItems.${index}.businessId`, "");
                          // setValue(`orderItems.${index}.catalogId`, "");
                          // setValue(`orderItems.${index}.createdById`, "");
                        }
                      }}
                      // value={selectedValue}
                      value={value}
                      onBlur={onBlur}
                      disabled={isReadOnly || isAdjustmentOrder}
                      renderOption={(props, option) => (
                        <AutocompleteOption
                          {...props}
                          key={option.id}
                          sx={{
                            "&:hover": {
                              backgroundColor: "var(--joy-palette-neutral-100)",
                            },
                          }}
                        >
                          <Stack
                            direction={"row"}
                            justifyContent={"space-between"}
                            alignItems={"center"}
                            flex={1}
                          >
                            <Stack>
                              {option.sku}
                              <Typography>
                                {t("order.createOrder.productForm.product")}:{" "}
                                {option.productName}
                              </Typography>
                            </Stack>
                            <Add />
                          </Stack>
                        </AutocompleteOption>
                      )}
                    />
                    <FormHelperText>{error?.message}</FormHelperText>
                    <input
                      type="hidden"
                      {...register(`orderItems.${index}.sku`, {
                        // validate: (value) => {
                        //   return validateOrderSku(value);
                        // },
                      })}
                      value={getValues(`orderItems.${index}.sku`)}
                    />
                  </FormControl>
                );
              }}
            />
            {/* UOM */}
            <div>
              <Controller
                name={`orderItems.${index}.uomItem`}
                control={control}
                defaultValue={orderItemField.uomList?.[0] || ""}
                render={({
                  field: { onChange, onBlur, name, value, ref },
                  fieldState: { error },
                }) => (
                  <FormControl sx={{ flex: "1" }} error={!!error} ref={ref}>
                    <FormLabel>
                      {t("order.createOrder.productForm.uom")}
                    </FormLabel>
                    <Autocomplete
                      name={name}
                      freeSolo={true}
                      placeholder={t("order.createOrder.productForm.uom")}
                      options={orderItemField.uomList || []}
                      getOptionLabel={
                        (option) =>
                          typeof option === "string"
                            ? option
                            : option?.uom || ""
                        // `${option?.uom} (${option?.conversion} ${orderItemField.uomList?.[0]?.uom})` || ""
                      }
                      onInputChange={(_event, newInputValue) => {
                        setValue(`orderItems.${index}.uom`, newInputValue);
                        setValue(`orderItems.${index}.conversion`, 1);
                      }}
                      onChange={(_event, newValue: any) => {
                        if (typeof newValue === "string") {
                          onChange({
                            uom: newValue,
                            conversion: 1,
                            price: orderItemField.price,
                          });
                          setValue(`orderItems.${index}.uom`, newValue);
                          setValue(`orderItems.${index}.conversion`, 1);
                          setValue(
                            `orderItems.${index}.price`,
                            orderItemField.price
                          );
                        } else {
                          onChange(newValue);
                          setValue(`orderItems.${index}.uom`, newValue.uom);
                          setValue(
                            `orderItems.${index}.conversion`,
                            newValue.conversion
                          );
                          setValue(`orderItems.${index}.price`, newValue.price);
                        }
                        // handleCalculatePrice();
                      }}
                      onBlur={onBlur}
                      value={value}
                      disabled={isReadOnly || isAdjustmentOrder}
                    />
                    <FormHelperText>{error?.message}</FormHelperText>
                  </FormControl>
                )}
              />
              <Input
                type="hidden"
                {...register(`orderItems.${index}.uom`)}
                // defaultValue={
                //   orderItemField.uom ?? getValues(`orderItems.${index}.uomItem`)
                // }
                sx={{ display: "none" }}
              />
              <Input
                type="hidden"
                {...register(`orderItems.${index}.conversion`)}
                defaultValue={orderItemField.conversion ?? 1}
                sx={{ display: "none" }}
              />
            </div>
            {/* price */}
            <Controller
              name={`orderItems.${index}.price`}
              defaultValue={orderItemField.price || 0}
              control={control}
              render={({ field: { name, onChange, ref, value } }) => (
                <FormControl sx={{ flex: "1" }} ref={ref}>
                  <FormLabel>
                    {t("order.createOrder.productForm.pricePer")}{" "}
                    {getValues(`orderItems.${index}`).uomItem?.uom || "Unit"}
                  </FormLabel>
                  <NumericFormat
                    sx={{ minWidth: "200px" }}
                    name={name}
                    customInput={Input}
                    startDecorator={<>{getValues("currency")}</>}
                    placeholder={`${t(
                      "order.createOrder.productForm.pricePer"
                    )} ${orderItemField.uom || "Unit"}`}
                    disabled={isReadOnly || isAdjustmentOrder}
                    value={value}
                    onValueChange={(v) => {
                      onChange(Number(v.value));
                      // handleCalculatePrice();
                    }}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    // valueIsNumericString={true}
                    thousandSeparator={
                      getValues("currency") === "IDR" ? "." : ","
                    }
                    decimalSeparator={
                      getValues("currency") === "IDR" ? "," : "."
                    }
                  />
                </FormControl>
              )}
            />
            {/* tax */}
            {sellerCountry === "ID" &&
              getValues("additionalTaxItem.taxType") === null &&
              getValues("additionalTaxItem.taxAmount") === 0 && (
                <>
                  <div>
                    <Controller
                      name={`orderItems.${index}.taxItem`}
                      defaultValue={{
                        taxLabel: "NONE",
                        taxAmount: 0,
                        taxType: null,
                      }}
                      control={control}
                      render={({
                        field: { onChange, onBlur, name, value, ref },
                        fieldState: { error },
                      }) => (
                        <FormControl
                          sx={{ flex: "1" }}
                          error={!!error}
                          ref={ref}
                        >
                          <FormLabel>
                            {t("order.createOrder.productForm.taxAdditional")}
                          </FormLabel>
                          <Autocomplete
                            name={name}
                            placeholder={t(
                              "order.createOrder.productForm.taxAdditional"
                            )}
                            options={nationalTaxes || []}
                            getOptionLabel={(option) => option?.taxLabel || ""}
                            onChange={(_event, newValue: any) => {
                              onChange(newValue);
                              console.log(
                                "National Tax Item changed? ::",
                                newValue
                              );
                              if (newValue) {
                                /** Reset additional tax */
                                setValue("additionalTaxItem", {
                                  taxLabel: "NONE",
                                  taxAmount: 0,
                                  taxType: null,
                                });
                              } else {
                                /** If clicked the cross 'x' button */
                                setValue(`orderItems.${index}.taxItem`, {
                                  taxLabel: "NONE",
                                  taxAmount: 0,
                                  taxType: null,
                                });
                              }
                              // handleCalculatePrice();
                            }}
                            onBlur={onBlur}
                            value={value}
                            disabled={isReadOnly || isAdjustmentOrder}
                          />
                          <FormHelperText>{error?.message}</FormHelperText>
                        </FormControl>
                      )}
                    />
                  </div>

                  {/* Manual Tax Amount */}
                  {getValues(`orderItems.${index}.taxItem.taxType`) ===
                    TaxTypeEnum.MANUAL && (
                    <div>
                      <FormControl sx={{ flex: "1" }}>
                        <FormLabel>
                          {t("order.createOrder.productForm.otherTaxAmount")}
                        </FormLabel>
                        <Controller
                          name={`orderItems.${index}.taxItem.taxAmount`}
                          defaultValue={orderItemField.taxItem.taxAmount || 0}
                          control={control}
                          render={({ field: { name, onChange, value } }) => (
                            <NumericFormat
                              name={name}
                              value={value}
                              customInput={Input}
                              decimalScale={2}
                              fixedDecimalScale={true}
                              thousandSeparator={
                                getValues("currency") === "IDR" ? "." : ","
                              }
                              decimalSeparator={
                                getValues("currency") === "IDR" ? "," : "."
                              }
                              {...(getValues(
                                `orderItems.${index}.taxItem.taxAmountType`
                              ) === TaxAmountTypeEnum.PERCENTAGE && {
                                isAllowed: (values) => {
                                  const { floatValue } = values;
                                  return (
                                    floatValue === undefined ||
                                    floatValue <= 100
                                  ); // Limit input no more than 100%
                                },
                              })}
                              placeholder={t(
                                "order.createOrder.productForm.otherTaxAmount"
                              )}
                              // defaultValue={orderItemField.taxItem.tax || 0}
                              // {...register(`orderItems.${index}.otherTaxAmount`, {
                              //   valueAsNumber: true,
                              // })}
                              onValueChange={(v) => {
                                onChange(Number(v.value) || 0);
                                // setValue(
                                //   `orderItems.${index}.otherTaxAmount`,
                                //   v.value
                                // );
                                // setValue(
                                //   `orderItems.${index}.taxItem.taxAmount`,
                                //   v.value
                                // );
                                // handleCalculatePrice();
                              }}
                              disabled={isReadOnly || isAdjustmentOrder}
                              endDecorator={
                                <>
                                  <Divider orientation="vertical" />
                                  <Controller
                                    name={`orderItems.${index}.taxItem.taxAmountType`}
                                    defaultValue={
                                      orderItemField.taxItem.taxAmountType ||
                                      TaxAmountTypeEnum.PERCENTAGE
                                    }
                                    control={control}
                                    render={({
                                      field: { name, onChange, value, ref },
                                    }) => (
                                      <Select
                                        variant="plain"
                                        name={name}
                                        value={value}
                                        ref={ref}
                                        onChange={(_, value) => {
                                          onChange(value);
                                          console.log(
                                            "Tax Amount Type changed? ::",
                                            value
                                          );

                                          // reset taxAmount to 0 when taxAmountType is changed
                                          setValue(
                                            `orderItems.${index}.taxItem.taxAmount`,
                                            0
                                          );
                                          // handleCalculatePrice();
                                        }}
                                        sx={{
                                          "&:hover": { bgcolor: "transparent" },
                                          mr: -1.5,
                                          pt: 0,
                                          pb: 0,
                                          minHeight: "auto",
                                        }}
                                      >
                                        <Option
                                          value={
                                            TaxAmountTypes.percentage.value
                                          }
                                        >
                                          {TaxAmountTypes.percentage.label}
                                        </Option>
                                        <Option
                                          value={TaxAmountTypes.fixed.value}
                                        >
                                          {TaxAmountTypes.fixed.label}
                                        </Option>
                                      </Select>
                                    )}
                                  />
                                </>
                              }
                            />
                          )}
                        />
                      </FormControl>
                    </div>
                  )}
                </>
              )}
            {/* <Stack
            direction={{
              sm: "column",
              md: "column",
              lg: "row",
            }}
            gap={3}
          > */}
            {/* Product Name */}
            <FormControl sx={{ flex: "1" }}>
              <FormLabel>
                {t("order.createOrder.productForm.productName")}
              </FormLabel>
              <Input
                placeholder={t("order.createOrder.productForm.productName")}
                defaultValue={orderItemField.productName}
                {...register(`orderItems.${index}.productName`)}
                disabled={isReadOnly || isAdjustmentOrder}
              />
            </FormControl>
            {/* Quantity */}
            <FormControl
              sx={{ flex: "1" }}
              error={!!(errors?.orderItems as any)?.[index]?.quantity}
            >
              <FormLabel>
                {t("order.createOrder.productForm.quantity")}
              </FormLabel>
              <Input
                // type="number"
                placeholder={t("order.createOrder.productForm.quantity")}
                value={qtyInputValue}
                {...register(`orderItems.${index}.quantity`, {
                  // onChange: () => handleCalculatePrice(),
                  valueAsNumber: true,
                  min: { value: 1, message: t("commons.error.form.quantity") },
                  ...(mainOrderQty > 0 && {
                    max: {
                      value: mainOrderQty,
                      message: t("commons.error.form.quantityExceed", {
                        threshold: mainOrderQty,
                      }),
                    },
                  }),
                  validate: (value) => {
                    if (value <= 0) {
                      return t("commons.error.form.quantity");
                    }

                    return (
                      validateLowStock(value) ||
                      t("catalog.inventory.updateQty.errorOrder")
                    );
                  },
                })}
                onChange={(e) => {
                  validateOnChange(e.target.value, (sanitizedValue) => {
                    setQtyInputValue(sanitizedValue);
                    setValue(`orderItems.${index}.quantity`, sanitizedValue);
                  });
                }}
                onKeyDown={(e) => {
                  if (validateOnKeyDown(e.key)) {
                    e.preventDefault();
                    return;
                  }
                }}
                disabled={isReadOnly || (revisionId && true)}
              />
              {/* <input
              type="hidden"
              defaultValue={validateLowStock(
                Number(watchedNestedProduct.quantity)
              )}
              {...register(`orderItems.${index}.lowQuantity`, {
                validate: (value) => {
                  if (orderType === "sales") {
                    return value !== "true"
                      ? true
                      : t("catalog.inventory.updateQty.errorOrder", {
                          lowStockQuantity: watchedNestedProduct.quantity,
                        });
                  }

                  return true;
                },
              })}
            /> */}
              <FormHelperText>
                {(errors?.orderItems as any)?.[index]?.quantity?.message}
              </FormHelperText>
            </FormControl>
            {/* Discount */}
            <FormControl sx={{ flex: "1" }}>
              <FormLabel>
                {t("order.createOrder.productForm.discount")}
              </FormLabel>
              <Controller
                name={`orderItems.${index}.discount`}
                defaultValue={orderItemField.discount || 0}
                control={control}
                render={({ field: { name, onChange, value } }) => (
                  <NumericFormat
                    name={name}
                    value={value}
                    customInput={Input}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    thousandSeparator={
                      getValues("currency") === "IDR" ? "." : ","
                    }
                    decimalSeparator={
                      getValues("currency") === "IDR" ? "," : "."
                    }
                    {...(getValues(`orderItems.${index}.discountType`) ===
                      DISCOUNT_TYPE.percentage.value && {
                      isAllowed: (values) => {
                        const { floatValue } = values;
                        return floatValue === undefined || floatValue <= 100; // Limit input to 100%
                      },
                    })}
                    placeholder={t("order.createOrder.productForm.discount")}
                    defaultValue={watchedNestedProduct.discount || 0}
                    // {...register(`orderItems.${index}.discount`, {
                    //   valueAsNumber: true,
                    // })}
                    onValueChange={(v) => {
                      onChange(Number(v.value) || 0);
                      // setValue(`orderItems.${index}.discount`, v.value);
                      // handleCalculatePrice();
                    }}
                    disabled={isReadOnly || isAdjustmentOrder}
                    endDecorator={
                      <>
                        <Divider orientation="vertical" />
                        <Controller
                          name={`orderItems.${index}.discountType`}
                          defaultValue={DISCOUNT_TYPE.percentage.value}
                          control={control}
                          render={({
                            field: { name, onChange, value, ref },
                          }) => (
                            <Select
                              variant="plain"
                              name={name}
                              value={value}
                              ref={ref}
                              onChange={(_, value) => {
                                onChange(value);
                                setValue(`orderItems.${index}.discount`, 0);
                                // handleCalculatePrice();
                              }}
                              sx={{
                                "&:hover": { bgcolor: "transparent" },
                                mr: -1.5,
                                pt: 0,
                                pb: 0,
                                minHeight: "auto",
                              }}
                            >
                              <Option value={DISCOUNT_TYPE.percentage.value}>
                                {DISCOUNT_TYPE.percentage.label}
                              </Option>
                              <Option value={DISCOUNT_TYPE.amount.value}>
                                {DISCOUNT_TYPE.amount.label}
                              </Option>
                            </Select>
                          )}
                        />
                      </>
                    }
                  />
                )}
              />
            </FormControl>
          </Grid>
          <br />
          {/* Description */}
          <FormControl>
            <FormLabel>
              {t("order.createOrder.productForm.description")}
            </FormLabel>
            <Input
              placeholder={t("order.createOrder.productForm.description")}
              {...register(`orderItems.${index}.productDescription`)}
              disabled={isReadOnly || isAdjustmentOrder}
            >
              {orderItemField.productDescription}
            </Input>
          </FormControl>
          <Stack
            direction={{
              sm: "column",
              md: "column",
              lg: "row",
            }}
            justifyContent={"flex-end"}
            mt={2}
          >
            {!isReadOnly && !revisionId && (
              <Button
                variant="plain"
                onClick={() => handleRemoveOrderItem(index)}
                disabled={
                  isAdjustmentOrder && getValues("orderItems").length === 1
                }
                sx={{
                  marginLeft: "auto",
                  color: "var(--joy-palette-danger-500)",
                  "&:hover": {
                    backgroundColor: "var(--joy-palette-danger-100)",
                  },
                }}
              >
                {t("order.createOrder.productForm.removeBtn")}
              </Button>
            )}
          </Stack>
        </Stack>
      </Card>
    </>
  );
};
