import { Button, ButtonProps } from "@mui/joy";

export const BtnFilledPrimary = ({
  label,
  ...btnProps
}: { label: string } & ButtonProps) => {
  return (
    <Button variant="linkz-filled-primary" {...btnProps}>
      {label}
    </Button>
  );
};

export const BtnOutlinedPrimary = ({
  label,
  ...btnProps
}: { label: string } & ButtonProps) => {
  return (
    <Button variant="linkz-outlined-primary" {...btnProps}>
      {label}
    </Button>
  );
};

export const BtnFilledPrimarySm = ({
  label,
  ...btnProps
}: { label: string } & ButtonProps): JSX.Element => {
  return (
    <Button variant="linkz-filled-primary" size="sm" {...btnProps}>
      {label}
    </Button>
  );
};

export const BtnFilledPrimaryRoundedSm = ({
  label,
  ...btnProps
}: { label: string } & ButtonProps): JSX.Element => {
  return (
    <Button variant="linkz-filled-primary-rounded" size="sm" {...btnProps}>
      {label}
    </Button>
  );
};

export const BtnOutlinedPrimarySm = ({
  label,
  ...btnProps
}: { label: string } & ButtonProps): JSX.Element => {
  return (
    <Button variant="linkz-outlined-primary" size="sm" {...btnProps}>
      {label}
    </Button>
  );
};

export const BtnOutlinedPrimaryRoundedSm = ({
  label,
  ...btnProps
}: { label: string } & ButtonProps): JSX.Element => {
  return (
    <Button variant="linkz-outlined-primary-rounded" size="sm" {...btnProps}>
      {label}
    </Button>
  );
};

export const BtnTonalPrimarySm = ({
  label,
  ...btnProps
}: { label: string } & ButtonProps) => {
  return (
    <Button variant="linkz-tonal-primary" size="sm" {...btnProps}>
      {label}
    </Button>
  );
};

export const BtnTonalPrimaryRoundedSm = ({
  label,
  ...btnProps
}: { label: string } & ButtonProps): JSX.Element => {
  return (
    <Button
      variant="linkz-tonal-primary-rounded"
      size="sm"
      {...btnProps}
    >
      {label}
    </Button>
  );
};

export const BtnTonalDangerSm = ({
  label,
  ...btnProps
}: { label: string } & ButtonProps): JSX.Element => {
  return (
    <Button
      variant="linkz-tonal-danger"
      size="sm"
      {...btnProps}
    >
      {label}
    </Button>
  );
};

export const BtnTonalDangerRoundedSm = ({
  label,
  ...btnProps
}: { label: string } & ButtonProps): JSX.Element => {
  return (
    <Button
      variant="linkz-tonal-danger-rounded"
      size="sm"
      {...btnProps}
    >
      {label}
    </Button>
  );
};
