export interface BreadcrumbItem {
  name: string;
  path: string;
}

class URLPattern {
  static CreateOrder =
    /\/client\/orders\/[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/;

  static UpdateBusiness =
    /\/client\/profile\/business\/[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/;

  static UpdateEmployee =
    /\/client\/employees\/update\/[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/;

  static UpdateContact =
    /\/client\/business-contact\/update\/[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}\/contact\/[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/;
}

type NavbarBreadcrumbs = {
  [key: string]: BreadcrumbItem[];
};

const catalogue = {
  name: "warehouse",
  path: "/client/",
};

// NOTE: please change the name to use the one in translation.json
export const NAVBAR_BREADCUMBS: NavbarBreadcrumbs = {
  "/client/inventory": [
    catalogue,
    {
      name: "inventory",
      path: "/client/inventory",
    },
  ],
  "/client/orders": [
    {
      name: "order",
      path: "/client/orders",
    },
  ],
  "/client/finance": [
    {
      name: "finance",
      path: "/client/finance",
    },
  ],
  "/client/suppliers": [
    catalogue,
    {
      name: "supplier",
      path: "/client/suppliers",
    },
  ],
  "/client/catalogues/list": [
    catalogue,
    {
      name: "catalogueslist",
      path: "/client/catalogues/list",
    },
  ],
  "/client/catalogues": [
    catalogue,
    {
      name: "catalogues",
      path: "/client/catalogues",
    },
  ],
  "/client/catalogues/create": [
    catalogue,
    {
      name: "create",
      path: "/client/catalogues/create",
    },
  ],
  "/client/cart": [
    catalogue,
    {
      name: "supplier",
      path: "/client/suppliers",
    },
  ],
  "/client/cart/checkout": [
    catalogue,
    {
      name: "supplier",
      path: "/client/suppliers",
    },
  ],
  "/client/profile": [
    {
      name: "profile",
      path: "/client/profile",
    },
  ],
  "/client/profile/fullname": [
    {
      name: "profile",
      path: "/client/profile",
    },
    {
      name: "changeName",
      path: "/client/profile/fullname",
    },
  ],
  "/client/profile/mobile": [
    {
      name: "profile",
      path: "/client/profile",
    },
    {
      name: "changePhone",
      path: "/client/profile/mobile",
    },
  ],
  "/client/profile/mobile/verification": [
    {
      name: "profile",
      path: "/client/profile",
    },
    {
      name: "changePhone",
      path: "/client/profile/mobile",
    },
    {
      name: "otpVerification",
      path: "/client/profile/mobile/verification",
    },
  ],
  "/client/profile/password": [
    {
      name: "profile",
      path: "/client/profile",
    },
    {
      name: "changePassword",
      path: "/client/profile/password",
    },
  ],
  "/client/profile/business": [
    {
      name: "profile",
      path: "/client/profile",
    },
    {
      name: "addCompany",
      path: "/client/profile/business",
    },
  ],
  "/client/profile/address": [
    {
      name: "profile",
      path: "/client/profile",
    },
  ],
  "/client/employees": [
    {
      name: "employee",
      path: "/client/employees",
    },
  ],
  "/client/employees/add": [
    {
      name: "employee",
      path: "/client/employees",
    },
    {
      name: "addEmployee",
      path: "/client/employees/add",
    },
  ],
  "/client/business-contact": [
    {
      name: "businessContact",
      path: "/client/business-contact",
    },
  ],
  "/client/business-contact/add": [
    {
      name: "businessContact",
      path: "/client/business-contact",
    },
    {
      name: "addContact",
      path: "/client/business-contact/add",
    },
  ],
  "/client/credit": [
    {
      name: "home",
      path: "/client/",
    },
    {
      name: "buyCredits",
      path: "/client/credit",
    },
  ],
  "/client/": [
    {
      name: "home",
      path: "/client/",
    },
    {
      name: "dashboard",
      path: "/client",
    },
  ],
  "/client/profile/kyc": [
    {
      name: "profile",
      path: "/client/profile",
    },
    {
      name: "profileKYCPG",
      path: "/client/profile/kyc",
    },
  ],
};

export function getBreadcumb(
  path: string,
  mode?: string | null
): BreadcrumbItem[] {
  if (path === "/client") {
    return NAVBAR_BREADCUMBS["/client/"];
  }
  if (path.startsWith("/client/orders")) {
    if (URLPattern.CreateOrder.test(path)) {
      return NAVBAR_BREADCUMBS["/client/orders"].concat({
        name: "orderSingular",
        path,
      });
    }
  }

  if (path.startsWith("/client/finance")) {
    if (URLPattern.CreateOrder.test(path)) {
      return NAVBAR_BREADCUMBS["/client/finance"].concat({
        name: "finance",
        path,
      });
    }
  }

  if (path.startsWith("/client/profile/business")) {
    if (URLPattern.UpdateBusiness.test(path)) {
      return NAVBAR_BREADCUMBS["/client/profile/business"].concat({
        name: "updateBusiness",
        path,
      });
    }
  }

  if (path.endsWith("/address")) {
    if (path.startsWith("/client/profile")) {
      return NAVBAR_BREADCUMBS["/client/profile/address"].concat({
        name: "address",
        path,
      });
    }
  }

  if (path.startsWith("/client/employees/update")) {
    if (URLPattern.UpdateEmployee.test(path)) {
      return NAVBAR_BREADCUMBS["/client/employees"].concat({
        name: "updateEmployee",
        path,
      });
    }
  }

  if (path.endsWith("/items")) {
    if (path.startsWith("/client/inventory")) {
      return NAVBAR_BREADCUMBS["/client/inventory"].concat({
        name: "viewInventory",
        path,
      });
    } else if (path.startsWith("/client/cart/checkout")) {
      return NAVBAR_BREADCUMBS["/client/cart/checkout"].concat([
        {
          name: "viewCatalog",
          path: `/client/suppliers${path.split("/cart")[1]}`,
        },
        {
          name: "cart",
          path: `/client/cart${path.split("/checkout")[1]}`,
        },
        {
          name: "checkout",
          path,
        },
      ]);
    } else if (path.startsWith("/client/cart")) {
      return NAVBAR_BREADCUMBS["/client/cart"].concat([
        {
          name: "viewCatalog",
          path: `/client/suppliers${path.split("/cart")[1]}`,
        },
        {
          name: "cart",
          path,
        },
      ]);
    } else if (path.startsWith("/client/suppliers")) {
      return NAVBAR_BREADCUMBS["/client/suppliers"].concat({
        name: "viewCatalog",
        path,
      });
    } else {
      return NAVBAR_BREADCUMBS["/client/catalogues/list"].concat({
        name: "viewCatalog",
        path,
      });
    }
  }

  if (path.endsWith("/manage")) {
    if (path.startsWith("/client/catalogues")) {
      return NAVBAR_BREADCUMBS["/client/catalogues/list"].concat([
        {
          name: "viewCatalog",
          path: path.replace("manage", "items"),
        },
        {
          name: "manageCatalogItems",
          path,
        },
      ]);
    }
  }

  if (path.includes("/items/detail")) {
    if (path.startsWith("/client/catalogues/inventory")) {
      return NAVBAR_BREADCUMBS["/client/catalogues/list"].concat([
        {
          name: "viewCatalog",
          path: path.replace("manage", "items"),
        },
      ]);
    }
    if (path.startsWith("/client/suppliers")) {
      return NAVBAR_BREADCUMBS["/client/suppliers"].concat([
        {
          name: "viewCatalog",
          path: path.replace("manage", "items"),
        },
      ]);
    }
  }

  if (path.includes("/add-items")) {
    if (path.startsWith("/client/catalogues")) {
      return NAVBAR_BREADCUMBS["/client/catalogues/create"].concat([
        { name: "addItems", path: "/client/catalogues/add-items" },
      ]);
    }
  }
  if (path.endsWith("/edit")) {
    if (path.startsWith("/client/inventory")) {
      return NAVBAR_BREADCUMBS["/client/inventory"].concat({
        name: mode == "create" ? "addInventory" : "editInventory",
        path,
      });
    }

    if (path.startsWith("/client/catalogues")) {
      return NAVBAR_BREADCUMBS["/client/catalogues"].concat({
        name: "editCatalogue",
        path,
      });
    }
  }

  if (path.endsWith("/batch-edit")) {
    if (path.startsWith("/client/inventory")) {
      return NAVBAR_BREADCUMBS["/client/inventory"].concat({
        name: "editItems",
        path,
      });
    }
  }

  // console.log("path", path, path.startsWith("/client/business-contact/update"));
  if (path.startsWith("/client/business-contact/update")) {
    return NAVBAR_BREADCUMBS["/client/business-contact"].concat({
      name: "updateContact",
      path,
    });
  }
  return NAVBAR_BREADCUMBS[path] || [];
}
