import {
  Button,
  Checkbox,
  List,
  ListItem,
  Sheet,
  Stack,
  Typography,
} from "@mui/joy";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { theme } from "../../components/Theme";
import { useParams, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { InventoryService } from "../../services/inventory.service";
import { CatalogType } from "../../types/catalog";
import { ItemService } from "../../services/item.service";
import { formatPrice } from "../../utils/formatPrice";
import { getActiveBusiness, useAuthStore } from "../../store/session";
import { CartDataType, CartItemDataType } from "../../types/cart";
import { CatalogInventoryItemType } from "../../types/item";
import ConfirmDeleteModal from "../../components/Cart/ConfirmDeleteModal";
import { OrderService } from "../../services/order.service";
import { OrderStatusEnum } from "../../types/order";
import LoadingModal from "../../components/LoadingModal/LoadingModal";
import { formatDate } from "../../utils/formatDate";
import { BusinessService } from "../../services/business.service";
import toast from "react-hot-toast";
import { ErrorToastConfig } from "../../components/Error";
import { IExternalBusiness } from "../../types/business";
import {
  calculatePrice,
  deleteCart,
  getCartItems,
  getConversionRate,
  getDeliveryFee,
  getQuantityFromCart,
  getSubtotal,
  getTaxAmount,
  getUOMFromCart,
  updateCartInfo,
  updateCartQty,
  updateCartUOM,
  validateCartItems,
  verifyCartItems,
} from "../../utils/cart";
import CartQuantityManager from "../../components/Catalogue/Item/CartQuantityManager";
import ItemsRemovedModal from "../../components/Cart/ItemsRemovedModal";
import ItemsUpdatedModal from "../../components/Cart/ItemsUpdatedModal";
import { TrackerService } from "../../services/tracker.service";
import { TRACKER_CONSTANTS } from "../../constants/tracker.events";
import UOMSelector from "../../components/Catalogue/Item/UOMSelector";
import TagsCollection from "../../components/Catalogue/Item/TagsCollection";
import { If } from "../../components/Condition";
// import ProductDetailsModal from "../Items/components/ProductDetailsModal";
import { SuccessModal } from "../../components/SuccessModal";
import { Delete } from "@mui/icons-material";

const Carts = () => {
  const { catalogId } = useParams();
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();
  const { session } = useAuthStore.getState();

  const [isLoading, setIsLoading] = useState(false);
  const [catalog, setCatalog] = useState<CatalogType>();
  const [subtotal, setSubtotal] = useState(0);
  const [cartItems, setCartItems] = useState<CartDataType>();
  const [items, setItems] = useState<CatalogInventoryItemType[]>([]);
  const [selected, setSelected] = useState<string[]>([]);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [canOrder, setCanOrder] = useState(false);
  const [hasTracked, setHasTracked] = useState(false);
  const [isMobile, setIsMobile] = useState(
    window.innerWidth < theme.breakpoints.values.sm
  );
  // TODO: simplify
  const [removedItems, setRemovedItems] = useState<CatalogInventoryItemType[]>(
    []
  );
  const [updatedItems, setUpdatedItems] = useState<
    {
      cartItem: CartItemDataType;
      item: CatalogInventoryItemType;
    }[]
  >([]);
  // const [selectedItem, setSelectedItem] = useState<{
  //   show: boolean;
  //   item: CatalogInventoryItemType;
  // }>();
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");

  const trackCart = (
    cartItems: CartDataType,
    catalog: CatalogType,
    timing: "pre" | "post"
  ) => {
    const itemsObject = cartItems.items
      .map((i, index) => {
        const item = items.find((item) => item.id === i.cid);
        if (item) {
          if (timing === "pre") {
            return {
              [`Product ${index + 1} ID`]: item.inventoryItem.id,
              [`Product ${index + 1} Name`]: item.inventoryItem.productName,
              [`Quantity ${index + 1}`]: i.quantity,
            };
          } else {
            return {
              [`Product ${index + 1} ID`]: item.inventoryItem.id,
              [`Product ${index + 1} Name`]: item.inventoryItem.productName,
              [`Quantity ${index + 1}`]: i.quantity,
              [`Total Product Value Amount ${index + 1}`]:
                calculatePrice(item, i.uom) * i.quantity,
            };
          }
        }
      })
      ?.reduce((acc, obj) => {
        return { ...acc, ...obj };
      }, {});

    TrackerService.track(
      timing === "pre"
        ? TRACKER_CONSTANTS.CATALOG_SUPPLIER.events.CartPageVisited
        : TRACKER_CONSTANTS.CATALOG_SUPPLIER.events.CreateOrderBtnClick,
      {
        "Catalog ID": catalog.id,
        "Catalog Name": catalog.catalogName,
        "Catalog Owner Company Name": catalog.business.companyName,
        "Product Count": cartItems.items.length,
        ...itemsObject,
      }
    );
  };

  useEffect(() => {
    if (window.innerWidth < theme.breakpoints.values.sm) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [window.innerWidth]);

  useEffect(() => {
    if (catalogId) {
      setIsLoading(true);
      InventoryService.getOneCatalog({
        catalogId: catalogId,
      })
        .then((res) => {
          setCatalog(res);
          const accountId =
            searchParams.get("cart") === "wildcart"
              ? "wildcart"
              : session?.account.id || "wildcart";

          const tempCartItems = getCartItems(catalogId, accountId, "local");
          const tempWildcartItems =
            accountId !== "wildcart"
              ? getCartItems(catalogId, "wildcart", "local")
              : null;
          const finalCartItems = tempCartItems
            ? tempCartItems
            : // ? tempWildcartItems
              //   ? mergeWildcartToOwnedCart(tempWildcartItems, tempCartItems)
              //   : tempCartItems
              tempWildcartItems;

          // when user logs in without checkout
          const nonCheckoutItems = getCartItems(
            catalogId,
            "wildcart",
            "session"
          );

          if (searchParams.get("checkout") === "false" && nonCheckoutItems) {
            nonCheckoutItems.direct = false;
            nonCheckoutItems.accountId = session?.account.id || "wildcart";
            updateCartInfo({
              catalogId,
              accountId: session?.account.id || null,
              direct: false,
              storageType: "local",
            });

            setCartItems(nonCheckoutItems || undefined);
          } else {
            setCartItems(finalCartItems || undefined);
          }
        })
        .catch((err) => console.error(err))
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [catalogId]);

  useEffect(() => {
    if (cartItems && catalogId) {
      const ids = cartItems?.items.map((i) => i.cid) || [];
      if (cartItems.items.length > 0) {
        ItemService.getCatalogInventoryItemsByIds({ ids })
          .then((res) => {
            validateCartItems(res, cartItems, (result) => {
              setRemovedItems(result.removedItems);
              setUpdatedItems(result.updatedItems);
            });
            setItems(res);
            // if (getWildCart(catalogId)) {
            //   setCartOwner(session?.account.id, catalogId, res);
            // }

            if (cartItems.direct && session?.account.id) {
              if (catalog?.catalogType === "PRIVATE") {
                setIsLoading(true);
                handleCreateOrder(
                  res.map((i) => i.inventoryItem.id),
                  res
                );
              }
            }
          })
          .catch((err) => console.error(err))
          .finally(() => {
            if (catalog?.catalogType === "PRIVATE") {
              setIsLoading(cartItems.direct && !!session?.account.id);
            } else {
              setIsLoading(false);
            }
          });
      } else {
        setItems([]);
        setIsLoading(false);
      }
    }
  }, [cartItems]);

  useEffect(() => {
    if (cartItems) {
      getSubtotal({
        selected,
        items,
        cartItems,
        callback: (subtotal) => setSubtotal(subtotal),
      });

      if (verifyCartItems(cartItems, items)) {
        setCanOrder(true);
      } else {
        setCanOrder(false);
      }
    }
  }, [selected, cartItems]);

  useEffect(() => {
    if (!hasTracked && catalog && cartItems && items.length > 0) {
      trackCart(cartItems, catalog, "pre");
      setHasTracked(true);
    }
  }, [catalog, items, cartItems, hasTracked]);

  useEffect(() => {
    if (showAlert) {
      setTimeout(() => {
        setShowAlert(false);
      }, 2000);
    }
  }, [showAlert]);

  const handleDeleteCart = () => {
    if (catalogId) {
      deleteCart(catalogId, session?.account.id || "wildcart", "local");
      const newCartItems = getCartItems(
        catalogId,
        session?.account.id || "wildcart",
        "local"
      );
      setCartItems(newCartItems ?? undefined);
      setSelected([]);
      setShowAlert(true);
      setAlertMessage(t("catalog.cartDeleteMessage"));

      // window.location.reload();
    }
  };

  const handleDeleteSelectedItem = () => {
    if (!catalog || !cartItems) {
      return;
    }

    const selectednventoryItems = items.filter((i) =>
      selected.includes(i.inventoryItem.id)
    );

    selectednventoryItems.forEach((i) => {
      updateCartQty({
        catalogId: catalog.id,
        accountId: session?.account.id || "wildcart",
        override: true,
        quantityChange: 0,
        inventoryItem: i,
        direct: session?.account.id ? false : true,
        activeUOM: getUOMFromCart(i.id, cartItems),
        storageType: session?.account.id ? "local" : "session",
        callback: (newCartItems) => {
          setCartItems(newCartItems);
        },
      });
    });

    setSelected([]);
    setShowAlert(true);
    setAlertMessage(
      t("catalog.cartDeleteItemMessage", {
        selected: selectednventoryItems.length,
      })
    );
  };

  const handleCreateOrder = (
    selectedIds: string[],
    items: CatalogInventoryItemType[]
  ) => {
    setIsLoading(true);
    if (catalog) {
      BusinessService.getMyBusinessRoles(getActiveBusiness()?.id || "").then(
        (_res) => {
          // had to cast as any
          // TODO: update this flow (wether or not need business relation)
          // const sellerBusiness: any = res.find(
          //   (b) => b.id === catalog?.business.id
          // );
          const sellerBusiness: IExternalBusiness = catalog.business;

          if (sellerBusiness) {
            // const sellerAccount = sellerBusiness?.role?.[0]?.account?.id ?? "";
            const roles = sellerBusiness.role as any[];
            const sellerAccount: any =
              sellerBusiness.role.find((r: any) => r.isOwner) ?? roles[0];

            if (sellerAccount) {
              OrderService.initiateDraft({
                grandTotalAmount: 0,
                paymentTerm: "",
                sellerId: catalog?.business.id || "",
                buyerId: getActiveBusiness()?.id || "",
                invoiceNumber: "",
                createdById: session?.account.id || null,
                status: OrderStatusEnum.DRAFT,
                isFromCatalog: true,
              })
                .then((res) => {
                  if (res.data.id) {
                    const selectedItems = items.filter((i) =>
                      selectedIds.includes(i.inventoryItem.id)
                    );
                    const itemsToBeOrdered = selectedItems.map((i) => {
                      const cartItem = cartItems?.items.find(
                        (item) => item.cid === i.id
                      );

                      return {
                        productId: i.inventoryItem.id,
                        sku: i.inventoryItem.sku,
                        productName: i.inventoryItem.productName,
                        quantity: cartItem?.quantity ?? 1,
                        uom: i.inventoryItem[cartItem?.uom ?? "uom1"],
                        conversion: getConversionRate(
                          cartItem?.uom ?? "uom1",
                          i.inventoryItem
                        ),
                        price: calculatePrice(i, cartItem?.uom ?? "uom1"),
                        discount: 0,
                        totalPrice:
                          calculatePrice(i, cartItem?.uom ?? "uom1") *
                          (cartItem?.quantity ?? 1),
                        businessId: i.inventoryItem.business,
                        inventoryType: i.inventoryItem.inventoryType,
                        currency: i.currency,
                        productDescription: i.inventoryItem.productDescription,
                        createdById: i.createdBy || "",
                      };
                    });

                    const selectedCartItems: CartDataType = {
                      catalogId: catalog.id,
                      accountId: session?.account.id || "wildcart",
                      direct: false,
                      items:
                        cartItems?.items.filter((ci) => {
                          const selectedItem = selectedItems.find(
                            (si) => si.id === ci.cid
                          );

                          if (selectedItem) return ci;
                        }) || [],
                    };
                    /**
                     * REMIND - Abiyyu: sellerId/buyerId and sellerUserId/buyerId are not using anymore because there are already sellerIds/buyerIds objects
                     * So, I commented out for now.
                     * */
                    OrderService.update(res.data.id, {
                      currency:
                        items[0].currency ?? items[0].inventoryItem.currency,
                      orderItems: itemsToBeOrdered,
                      grandTotalAmount: subtotal,
                      status: OrderStatusEnum.DRAFT,
                      createdById: session?.account.id || "",
                      // sellerId: catalog?.business.id,
                      // sellerUserId: "",
                      sellerIds: {
                        businessId: catalog?.business.id,
                        accountId: sellerAccount.account,
                      },
                      // buyerId: getActiveBusiness()?.id || "",
                      // buyerUserId: session?.account.id || "",
                      buyerIds: {
                        businessId: getActiveBusiness()?.id || "",
                        accountId: session?.account.id || "",
                      },
                      deliveryFee: getDeliveryFee(
                        catalog,
                        selectedCartItems,
                        subtotal
                      ),
                      totalTax: getTaxAmount(
                        selectedCartItems.items,
                        selectedItems,
                        catalog
                      ),
                      additionalDiscountType: "PERCENTAGE",
                    })
                      .then((res) => {
                        if (res.id) {
                          if (cartItems) {
                            trackCart(cartItems, catalog, "post");
                          }

                          deleteCart(
                            catalog.id,
                            cartItems?.accountId || "wildcart",
                            "local"
                          );

                          const newCartItems = getCartItems(
                            catalog.id,
                            session?.account.id || "wildcart",
                            "local"
                          );
                          setCartItems(newCartItems ?? undefined);

                          window.location.href = `/client/orders/${res.id}`;
                        }
                      })
                      .catch((error) => {
                        throw new Error(error);
                      });
                  }
                })
                .catch((error) => console.error(error));
            }
          } else {
            setIsLoading(false);
            toast(t("commons.error.Error: Seller Not Found", ErrorToastConfig));
          }
        }
      );
    }
  };

  const handleCheckOut = () => {
    const verifiedItems = items.filter((i) => {
      const stillInCart = cartItems?.items.find((ci) => ci.cid === i.id);
      if (stillInCart) {
        return i;
      }
    });

    if (verifiedItems.length === 0) {
      setCanOrder(false);
      return;
    }

    if (catalog?.catalogType === "PUBLIC") {
      const selectedItems = items.filter((i) =>
        selected.includes(i.inventoryItem.id)
      );
      const checkOutItems = {
        ...cartItems,
        items: cartItems?.items.filter((ci) => {
          const selectedItem = selectedItems.find((si) => si.id === ci.cid);

          if (selectedItem) {
            return ci;
          }
        }),
      };
      sessionStorage.setItem("checkOutItems", JSON.stringify(checkOutItems));
      window.location.href = `/client/cart/checkout/${catalogId}/items`;
    } else {
      handleCreateOrder(selected, verifiedItems);
    }
  };

  return (
    <Sheet
      sx={{
        width: { xs: "90vw", lg: "100%" },
        px: { lg: "24px" },
        display: "flex",
        flexDirection: "column",
        gap: "32px",
      }}
    >
      <hr
        className={`w-full hidden lg:block border-b border-solid border-[${theme.colorSchemes.light.palette.others.disabled}]`}
      />

      <Stack
        direction={{ xs: "column", lg: "row" }}
        justifyContent={"space-between"}
        gap={{ xs: "12px", lg: 0 }}
      >
        <Stack direction={"column"} gap={"8px"}>
          <span className="hidden lg:block">
            <Typography fontSize={16} fontWeight={600} textColor={"#171A1C"}>
              {t("catalog.cart")}{" "}
              {cartItems
                ? cartItems.items.length > 0 && `(${cartItems?.items.length})`
                : ""}
            </Typography>
          </span>
          <Typography fontSize={14} textColor={"#171A1C"}>
            <span className="text-sm lg:text-base font-semibold lg:font-normal">
              {catalog?.business.companyName}
            </span>
          </Typography>
          <Typography
            fontSize={14}
            sx={{
              color: theme.colorSchemes.dark.palette.text.secondary,
            }}
          >
            <span className="text-xs lg:text-sm lg:hidden">
              {t("commons.lastUpdated")}:{" "}
              <span className="ml-2">
                {formatDate(new Date(catalog?.updatedAt ?? ""))}
              </span>
            </span>
          </Typography>
        </Stack>

        <If condition={selected.length > 0}>
          <Stack direction={"row"} gap={"12px"}>
            <Button
              onClick={handleDeleteSelectedItem}
              variant="outlined"
              className="my-auto border-[#475467] rounded-md text-[#475467]"
            >
              {t("catalog.cartDeleteItem", {
                selected: selected.length,
              })}
            </Button>
            <Button
              onClick={() => {
                setIsDeleteModalOpen(true);
              }}
              variant="outlined"
              startDecorator={<Delete color="error" />}
              className="my-auto border-[#B41F1C] rounded-md text-[#B41F1C]"
            >
              {t("catalog.deleteCartBtn")}
            </Button>
          </Stack>
        </If>
      </Stack>

      <hr
        className={`w-full lg:hidden border-b border-solid border-[${theme.colorSchemes.light.palette.others.disabled}]`}
      />

      <List sx={{ mb: { xs: "150px", lg: "100px" } }}>
        {cartItems &&
          items.map((i) => {
            if (!catalog) {
              return;
            }

            // const currency = i.currency ?? i.inventoryItem.currency;
            return (
              <CartItem
                catalog={catalog}
                i={i}
                cartItems={cartItems}
                setCartItems={setCartItems}
                selected={selected}
                setSelected={setSelected}
                isMobile={isMobile}
                setCanOrder={setCanOrder}
                onClick={() => {
                  // setSelectedItem({
                  //   show: true,
                  //   item: i,
                  // });
                }}
              />
            );
          })}
      </List>

      {/* create order toolbar */}
      {/* <If condition={!catalog?.hidePrice}> */}
      <div className="bg-[#FCFCFD] fixed flex w-full max-w-[inherit] bottom-0 left-0">
        <Stack
          direction={{ xs: "column", md: "row" }}
          justifyContent={{ md: "space-between" }}
          alignItems={"center"}
          width={"100%"}
          ml={{ lg: "240px" }}
          py={{ xs: "16px", lg: "24px" }}
          px={{ xs: "16px", lg: "32px" }}
          gap={{ xs: "8px", md: "32px" }}
        >
          <hr
            className={`w-screen h-px absolute hidden lg:block left-[240px] top-0 bg-[#636B744D]`}
          />
          {/* <div className="flex align-middle lg:block">
              <Typography fontSize={18}>
                <span className="text-sm lg:text-lg">
                  {t("catalog.total")}:{" "}
                  <span className="text-black font-[600]">
                    {`${
                      items[0]?.currency ??
                      items[0]?.inventoryItem.currency ??
                      ""
                    } ${
                      catalog?.hidePrice
                        ? "-"
                        : formatPrice(
                            subtotal,
                            items[0]?.currency ??
                              items[0]?.inventoryItem.currency
                          )
                    }`}
                  </span>
                </span>
              </Typography>
            </div> */}
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            width={{ xs: "100%", lg: "fit-content" }}
          >
            <Checkbox
              label={t("catalog.cartSelectAll")}
              checked={selected.length === cartItems?.items.length}
              onChange={(e) => {
                window.dispatchEvent(new Event("storage"));
                if (selected.length > 0 && !e.target.checked) {
                  setSelected([]);
                } else if (e.target.checked) {
                  const newSelected = items.map((i) => i.inventoryItem.id);
                  setSelected(newSelected);
                }
              }}
            />

            <If condition={isMobile}>
              <span className="lg:text-right">
                <Typography fontSize={14}>
                  {t("catalog.cartSelectedDesc", {
                    selected: selected.length,
                    total: items.length,
                  })}
                </Typography>
              </span>
            </If>
          </Stack>

          <div className="flex flex-col lg:flex-row gap-[8px] lg:gap-[10px] w-full justify-center items-center lg:w-fit">
            <Stack direction={"column"} alignItems={"center"} gap={"4px"}>
              <span className="flex gap-[10px] text-left w-full">
                <Typography
                  fontSize={18}
                  fontWeight={500}
                  textColor={"#171A1C"}
                >
                  {t("catalog.total")}:{" "}
                </Typography>

                <Typography
                  fontSize={20}
                  fontWeight={600}
                  textColor={"#171A1C"}
                >
                  {`${
                    items[0]?.currency ?? items[0]?.inventoryItem.currency ?? ""
                  } ${formatPrice(
                    subtotal,
                    items[0]?.currency ?? items[0]?.inventoryItem.currency
                  )}`}
                </Typography>
              </span>

              <If condition={!isMobile}>
                <span className="lg:text-right w-full">
                  <Typography fontSize={14}>
                    {t("catalog.cartSelectedDesc", {
                      selected: selected.length,
                      total: items.length,
                    })}
                  </Typography>
                </span>
              </If>
            </Stack>

            {catalog && (
              <Button
                onClick={handleCheckOut}
                fullWidth={isMobile}
                className="my-auto rounded-md"
                disabled={selected.length === 0 || !canOrder}
              >
                {t(
                  catalog.catalogType === "PUBLIC"
                    ? "catalog.checkoutBtn"
                    : "catalog.createOrderBtn"
                )}
              </Button>
            )}
          </div>
        </Stack>
      </div>
      {/* </If> */}

      {/* <div className="w-full lg:flex align-middle py-6">
        <div className="flex align-middle lg:block">
          <Typography fontSize={18}>
            <span className="text-sm lg:text-lg">
              {t("catalog.total")}:{" "}
              <span className="text-black font-[600]">
                {`${
                  items[0]?.currency ?? items[0]?.inventoryItem.currency ?? ""
                } ${
                  catalog?.hidePrice
                    ? "-"
                    : formatPrice(
                        subtotal,
                        items[0]?.currency ?? items[0]?.inventoryItem.currency
                      )
                }`}
              </span>
            </span>
          </Typography>
          <Typography fontSize={14}>
            <span className="hidden lg:block lg:mt-3">
              {t("catalog.cartSelectedDesc", {
                selected: selected.length,
                total: items.length,
              })}
            </span>
          </Typography>
        </div>

        <div className="mt-3 lg:mt-0 flex flex-col lg:flex-row align-middle lg:ml-auto gap-3">
          <Button
            onClick={() => {
              setIsDeleteModalOpen(true);
            }}
            variant="outlined"
            className="my-auto"
          >
            {t("catalog.deleteCartBtn")}
          </Button>
        </div>
      </div> */}

      {/* modal */}
      <SuccessModal
        title={t("commons.successModalTitle")}
        content={alertMessage}
        open={showAlert}
      />
      <ConfirmDeleteModal
        show={isDeleteModalOpen}
        onClick={handleDeleteCart}
        onClose={() => {
          setIsDeleteModalOpen(false);
        }}
      />
      <LoadingModal isLoading={isLoading} />
      <ItemsRemovedModal
        show={removedItems.length > 0}
        onClick={() => {}}
        onClose={() => {
          setRemovedItems([]);

          if (catalogId && updatedItems.length === 0) {
            const newCartItems = getCartItems(
              catalogId,
              session?.account.id || "wildcart",
              "local"
            );
            setCartItems(newCartItems ?? undefined);
          }
        }}
        removedItems={removedItems}
      />
      <ItemsUpdatedModal
        show={removedItems.length === 0 && updatedItems.length > 0}
        onClick={() => {}}
        onClose={() => {
          setUpdatedItems([]);

          if (catalogId) {
            const newCartItems = getCartItems(
              catalogId,
              session?.account.id || "wildcart",
              "local"
            );
            setCartItems(newCartItems ?? undefined);
          }
        }}
        updatedItems={updatedItems}
      />
      {/* {catalog && selectedItem && (
        <ProductDetailsModal
          show={selectedItem.show}
          item={selectedItem.item}
          catalog={catalog}
          isDisabled
          onClose={(item: CatalogInventoryItemType) => {
            setSelectedItem({
              show: false,
              item: item,
            });
          }}
          withAddToCart={false}
          items={items}
        />
      )} */}
    </Sheet>
  );
};

const CartItem = ({
  catalog,
  i,
  cartItems,
  setCartItems,
  selected,
  setSelected,
  isMobile,
}: // onClick,
{
  catalog: CatalogType;
  i: CatalogInventoryItemType;
  cartItems: CartDataType;
  setCartItems: Dispatch<SetStateAction<CartDataType | undefined>>;
  selected: string[];
  setSelected: Dispatch<SetStateAction<string[]>>;
  isMobile: boolean;
  setCanOrder: Dispatch<SetStateAction<boolean>>;
  onClick: () => void;
}) => {
  const { session } = useAuthStore.getState();
  const [activeUOM, setActiveUOM] = useState<"uom1" | "uom2" | "uom3">(
    getUOMFromCart(i.id, cartItems)
  );

  return (
    <ListItem
      key={i.id}
      sx={{
        display: "block",
        borderBottom: "1px solid #F2F4F7",
        pb: "16px",
      }}
    >
      <div className={`pt-4 flex align-middle w-full gap-4`}>
        <Checkbox
          className="mt-[6%] lg:my-auto"
          checked={selected.includes(i.inventoryItem.id)}
          onChange={(e) => {
            e.stopPropagation();
            const isExist = selected.find((s) => s === i.inventoryItem.id);
            window.dispatchEvent(new Event("storage"));

            if (isExist && !e.target.checked) {
              const newSelected = selected.filter(
                (s) => s !== i.inventoryItem.id
              );
              setSelected(newSelected);
            } else if (!isExist && e.target.checked) {
              setSelected([...selected, i.inventoryItem.id]);
            }
          }}
        />

        <div
          // onClick={onClick}
          className={`flex flex-col lg:flex-row lg:align-middle w-full gap-4`}
        >
          <Stack className="gap-4 w-full lg:w-[37%]" direction={"row"}>
            <img
              src={`${
                i.inventoryItem.thumbnail ?? "/inventory-img-placeholder.png"
              }`}
              alt="item_image"
              // width={100}
              // height={100}
              className="lg:border border-gray-300 rounded-t-lg lg:rounded-lg w-[64px] h-[64px] lg:w-[100px] lg:h-[100px] object-contain flex-shrink-0"
            />
            <Stack gap={"8px"}>
              <Typography fontSize={16} fontWeight={500} textColor={"#475467"}>
                {i.inventoryItem.sku ?? "-"}
              </Typography>
              <Typography fontSize={16} fontWeight={700} textColor={"#101828"}>
                {i.inventoryItem.productName}
              </Typography>
              <TagsCollection tags={i.inventoryItem.tags} />
            </Stack>
          </Stack>

          {!isMobile && catalog && (
            <div className="flex w-[63%] justify-start items-start gap-4">
              <UOMSelector
                item={i}
                hidePrice={false}
                catalogType={catalog.catalogType}
                activeUOM={activeUOM}
                setActiveUOM={(uom) => {
                  setActiveUOM(uom);
                  updateCartUOM({
                    catalogId: catalog.id,
                    accountId: session?.account.id || "wildcart",
                    inventoryItem: i,
                    uom: uom,
                    storageType: session?.account.id ? "local" : "session",
                    callback: (newCartItems) => {
                      setCartItems(newCartItems);
                    },
                  });
                }}
                size="lg"
                isDisabled={!selected.includes(i.inventoryItem.id)}
              />
              <CartQuantityManager
                item={i}
                hidePrice={false}
                // withAddToCart
                updateCart
                updateQtyCallback={(newCartItems: CartDataType) => {
                  setCartItems(newCartItems);

                  const currentItem = newCartItems.items.find(
                    (c) => c.cid === i.id
                  );

                  if (!currentItem) {
                    setSelected((prev) =>
                      prev.filter((p) => p !== i.inventoryItem.id)
                    );
                  }
                }}
                quantity={getQuantityFromCart(i.id, cartItems)}
                catalog={catalog}
                defaultUOM={activeUOM}
                isDisabled={!selected.includes(i.inventoryItem.id)}
                caller="cart"
              />
            </div>
          )}

          {isMobile && catalog && (
            <UOMSelector
              item={i}
              hidePrice={false}
              catalogType={catalog.catalogType}
              activeUOM={activeUOM}
              setActiveUOM={(uom) => {
                setActiveUOM(uom);
                updateCartUOM({
                  catalogId: catalog.id,
                  accountId: session?.account.id || "wildcart",
                  inventoryItem: i,
                  uom: uom,
                  storageType: session?.account.id ? "local" : "session",
                  callback: (newCartItems) => {
                    setCartItems(newCartItems);
                  },
                });
              }}
              size="sm"
              isDisabled={!selected.includes(i.inventoryItem.id)}
            />
          )}
        </div>
      </div>

      {isMobile && catalog && (
        <div className="flex w-full justify-center items-center mt-6">
          <CartQuantityManager
            item={i}
            hidePrice={false}
            // withAddToCart
            updateCart
            updateQtyCallback={(newCartItems: CartDataType) => {
              setCartItems(newCartItems);

              const currentItem = newCartItems.items.find(
                (c) => c.cid === i.id
              );

              if (!currentItem) {
                setSelected((prev) =>
                  prev.filter((p) => p !== i.inventoryItem.id)
                );
              }
            }}
            quantity={getQuantityFromCart(i.id, cartItems)}
            catalog={catalog}
            defaultUOM={activeUOM}
            isDisabled={!selected.includes(i.inventoryItem.id)}
            caller="cart"
          />
        </div>
      )}
    </ListItem>
  );
};

export default Carts;
