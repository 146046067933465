import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import PageTitleBox from "./components/PageTitleBox";
import { useTranslation } from "react-i18next";
import {
  Autocomplete,
  AutocompleteOption,
  Box,
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
  IconButton,
  Input,
  Option,
  Select,
  Table,
} from "@mui/joy";
import {
  Controller,
  FormProvider,
  useFieldArray,
  useForm,
} from "react-hook-form";
import { CatalogType, CreateCatalogType } from "../../types/catalog";
import { Stack, Typography } from "@mui/material";
import { ItemType, PaginationType } from "../../types/item";
import UploadImageBtn from "../../components/Inventory/Items/UploadImageBtn";
import { IfElse } from "../../components/Condition";
import { useEffect, useState } from "react";
import TablePagination from "./components/TablePagination";
import TitleCount from "./components/TitleCount";
import { PlusIcon, TrashIcon } from "@heroicons/react/24/outline";
import {
  DELIVERY_FEES_OPTIONS,
  DELIVERY_FEES_OPTIONS_ENUM,
  PaymentFlowTypeEnum,
  paymentFlowTypes,
} from "../../types/order";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { TaxService } from "../../services/tax.service";
import { KeyboardArrowDown } from "@mui/icons-material";
import { currencyList } from "../Order/PublicViewOrder";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import utc from "dayjs/plugin/utc";
import dayjs from "dayjs";
import ChangeCurrencyModal from "./components/ChangeCurrencyModal";
import { CatalogService } from "../../services/catalog.service";
import { ItemService } from "../../services/item.service";
import toast from "react-hot-toast";
import { ErrorToastConfig } from "../../components/Error";
import { useAuthStore } from "../../store/session";
import { NumericFormat } from "react-number-format";
dayjs.extend(utc);

function CreateCatalogue() {
  const { t } = useTranslation();
  const location = useLocation();

  const [catalog, _setCatalog] = useState<CatalogType>(
    JSON.parse(localStorage.getItem("new-catalog") || "{}")
  );
  const [items, setItems] = useState<string[]>(
    JSON.parse(localStorage.getItem("new-catalog") || "{items:[]}").items || []
  );
  const [itemInfos, setItemInfos] = useState<ItemType[]>(
    JSON.parse(localStorage.getItem("new-catalog") || "{itemInfos:[]}")
      .itemInfos || []
  );

  const [searchParams, _setSearchParams] = useSearchParams();
  const currentPath = location.pathname;

  const query = searchParams.get("type");

  const isFullView = currentPath.includes("full-view");
  const [oldCurrency, setOldCurrency] = useState<string | null>("");
  const [updateCurrency, setUpdateCurrency] = useState<string | null>("");

  const [showCurrency, setShowCurrency] = useState(false);

  const [isShowCurrencyModal, setIsShowCurrencyModal] = useState(false);
  const [_isLoading, setIsLoading] = useState(true);
  const [_alertMessage, setAlertMessage] = useState("");
  const [_showToast, setShowToast] = useState(false);
  const [pagination, setPagination] = useState<PaginationType>({
    current: 1,
    limit: 50,
    max: 1,
  });

  const methods = useForm<CreateCatalogType>({
    defaultValues: {
      feeValues: (catalog &&
        catalog.deliveryFeeConfig &&
        catalog.deliveryFeeConfig.feeValues.map((v) => ({
          upToCount: v.upToCount,
          value: v.value,
        }))) || [{ upToCount: 0, value: 0 }],
      feeType:
        (catalog &&
          catalog.deliveryFeeConfig &&
          catalog.deliveryFeeConfig.feeType) ||
        DELIVERY_FEES_OPTIONS_ENUM.FIXED_AMOUNT,
      catalogName: catalog?.catalogName ?? "",
      taxId:
        catalog.taxId && typeof catalog.taxId == "string"
          ? catalog.taxId
          : null,
      periodStartDate: catalog?.periodStartDate || null,
      periodEndDate: catalog?.periodEndDate || null,
      paymentFlowType:
        query == "PUBLIC"
          ? PaymentFlowTypeEnum.FULL_BEFORE_DELIVERY
          : catalog?.paymentFlowType,
      currency: catalog.currency || null,
    },
  });

  const {
    handleSubmit,
    register,
    control,
    watch,
    getValues,
    setValue,
    formState: { errors },
  } = methods;

  const { fields, append, remove, replace } = useFieldArray({
    control,
    name: "feeValues",
  });

  const watchDeliveryFeesType = watch("feeType");

  const handleItemFlexiCols = (items: ItemType[], flexiCols?: string[]) => {
    const allFlexiColumns: string[] = [];
    for (const item of items) {
      const flexiData = item.flexiColumns?.data || [];
      for (const column of flexiData) {
        if (!allFlexiColumns.includes(column.columnName)) {
          allFlexiColumns.push(column.columnName);
        }
      }
    }

    const tableData: Array<string | number>[] = [];

    // if (flexiCols) {
    for (const item of items) {
      // defined the fixed values
      const fixedValues = [
        item.sku,
        item.productName,
        // item.quantity ?? 0,
        item.currency,
        // item.price,
        // item.uom1 ?? "unit",
        item.inventoryType,
      ];

      // get the flexi values
      const flexiValues = [];
      if (flexiCols) {
        for (const column of flexiCols) {
          const flexiData = item.flexiColumns?.data || [];
          const value =
            flexiData.find((d) => d.columnName === column)?.cellValue || "n/a";
          flexiValues.push(value);
        }
      }

      tableData.push([...fixedValues, ...flexiValues]);
    }
    // }

    return {
      allFlexiColumns: allFlexiColumns,
      tableData: tableData,
    };
  };

  const handlePaging = (action: string | number) => {
    const pagingConfig = {
      limit: pagination.limit,
      offset: 0,
    };

    if (typeof action === "string") {
      switch (action) {
        case "first":
          pagingConfig.offset = 0;
          break;

        case "previous":
          pagingConfig.offset = pagination.limit * (pagination.current - 2);
          break;

        case "next":
          pagingConfig.offset = pagination.limit * pagination.current;
          break;

        case "last":
          pagingConfig.offset =
            pagination.max * pagination.limit - pagination.limit;
          break;
      }
    } else {
      pagingConfig.offset = (action - 1) * pagination.limit;
    }
  };
  const { session } = useAuthStore();

  const [nationalTaxes, setNationalTaxes] = useState<any[]>([]);

  async function getAndSetNationalTaxes() {
    try {
      // const res = await TaxService.getBusinessTaxes(
      //   session?.activeBusiness.id as string
      // );
      const res = await TaxService.getTaxesOption(
        session?.activeBusiness.companyCountry as string
      );

      setNationalTaxes(res.map((res: any) => res));
      setValue(
        "taxId",
        nationalTaxes.find((t) => t.taxLabel === "NONE")?.id || null
      );
    } catch (error) {
      console.error(error);
    }
  }

  const selectedTax = nationalTaxes?.find((tax) => tax.id === watch("taxId"));

  useEffect(() => {
    getAndSetNationalTaxes();
  }, [session?.activeBusiness.id]);

  const { tableData } = handleItemFlexiCols(itemInfos);

  const navigate = useNavigate();

  const addItemsToCatalog = async () => {
    const deliveryFeeConfig = {
      feeType: getValues("feeType"),
      feeValues: getValues("feeValues"),
    };

    const payload = {
      business: { id: session?.activeBusiness.id },
      catalogType: catalog?.catalogType,
      itemInfos,
      items,
    };

    const combinedData = {
      ...getValues(),
      deliveryFeeConfig,
      currency: getValues("currency"),
      ...payload,
    };

    localStorage.setItem("new-catalog", JSON.stringify(combinedData));
    navigate("/client/catalogues/add-items?isCreate=true");
  };

  const handleChange = () => {
    setIsLoading(true);
    setValue("currency", updateCurrency ?? "");
    setOldCurrency(updateCurrency ?? "");
    setIsShowCurrencyModal(false);
    setShowCurrency(true);
  };

  const removeItem = (item: ItemType) => {
    const updatedItems = items.filter((i) => i !== item.id);
    setItems(updatedItems);
    const updatedItemInfos = itemInfos.filter((i) => i.id !== item.id);
    setItemInfos(updatedItemInfos);
  };

  const isValidDate = () => {
    const startDate = getValues("periodStartDate");
    const endDate = getValues("periodEndDate");

    if (!startDate && !endDate) {
      return true;
    }

    return (
      (dayjs().isBefore(startDate, "days") ||
        dayjs().isSame(startDate, "days")) &&
      dayjs(startDate).isBefore(endDate)
    );
  };

  const onSubmit = (data: CreateCatalogType) => {
    const dayValidation = isValidDate();

    if (data.feeValues.length < 1) {
      toast("Add at least one delivery fees input row", ErrorToastConfig);
      return;
    }

    if (!dayValidation) {
      toast("Start month should be less than end month", ErrorToastConfig);
      return;
    }

    const deliveryFeeConfig = {
      feeType: data.feeType,
      feeValues: data.feeValues,
    };
    data.catalogType = query as string;
    data.taxId = data.taxId;
    data.deliveryFeeConfig = deliveryFeeConfig;
    data.tags = { tags: [] };
    data.hidePrice = query === "PUBLIC" ? true : false;
    data.business = catalog?.business.id as string;

    createAndUpdateCatalog(data);
  };

  async function createAndUpdateCatalog(data: CreateCatalogType) {
    try {
      const res = await CatalogService.createCatalogue({ data });

      if (res?.id) {
        await ItemService.updateCatalogItems({
          itemIds: items,
          catalogId: res.id,
        });

        localStorage.removeItem("new-catalog");
        window.location.href = `/client/catalogues/inventory/${res.id}/items?show_currency=${showCurrency}`;
      }
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    const config = getValues("feeType");
    if (config === DELIVERY_FEES_OPTIONS_ENUM.FIXED_AMOUNT) {
      replace({
        upToCount: 0,
        value: 0,
      });
    }
  }, [watch("feeType")]);

  return (
    <Stack
      spacing={2}
      sx={{
        backgroundColor: "transparent",
      }}
    >
      <PageTitleBox title={t("catalog.createCatalog.pageTitle")} />
      <FormProvider {...methods}>
        <form
          onSubmit={handleSubmit(onSubmit)}
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              if (!(event.target instanceof HTMLTextAreaElement)) {
                event.preventDefault();
              }
            }
          }}
        >
          <Stack direction={"column"} spacing={3}>
            {/* <CatalogueInformationSection businessId={businessId} /> */}
            <section className="w-full lg:border-2 border-gray-200 rounded-lg flex flex-col lg:p-6 gap-1 m-0">
              <Stack direction={"row"} alignItems={"center"} gap={2}>
                <div className="w-9 h-9 bg-primary-400 rounded-full text-white font-semibold justify-center items-center flex">
                  1
                </div>
                <Typography
                  fontSize={18}
                  fontWeight={600}
                  sx={{ color: "#101828" }}
                >
                  {t("catalog.createCatalog.catalogInformation")}
                </Typography>
              </Stack>

              <Grid container spacing={2} mt={4} mb={2}>
                <Grid xs={12} lg={6}>
                  <FormControl sx={{ flex: "1" }} error={!!errors.catalogName}>
                    <FormLabel>
                      {t("catalog.createCatalog.catalogueName")}{" "}
                      <span className="text-danger-400">*</span>
                    </FormLabel>
                    <Input
                      type="text"
                      placeholder={t("catalog.createCatalog.catalogueName")}
                      // defaultValue={currentItem?.sku}
                      aria-label="catalogName"
                      {...register("catalogName", {
                        required: t("commons.error.form.required", {
                          label: t("catalog.createCatalog.catalogueName"),
                        }),
                      })}
                      error={!!errors.catalogName}
                      className="min-h-[3.5rem]"
                    />
                    <FormHelperText>
                      {(errors.catalogName as any)?.message}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid xs={12} lg={6}>
                  <Controller
                    name="paymentFlowType"
                    control={control}
                    render={({
                      field: { onChange, onBlur, name, value, ref },
                    }) => {
                      return (
                        <FormControl sx={{ flex: "1" }} ref={ref}>
                          <FormLabel>
                            {t("order.createOrder.form.paymentFlowType")}
                          </FormLabel>
                          <Autocomplete
                            name={name}
                            freeSolo={true}
                            placeholder={t(
                              "order.createOrder.form.paymentFlowType"
                            )}
                            options={paymentFlowTypes.filter(
                              (pt) =>
                                pt.value !== PaymentFlowTypeEnum.SPLIT_PAYMENT
                            )}
                            disabled={query == "PUBLIC"}
                            getOptionLabel={(option) =>
                              typeof option !== "string" ? option?.label : ""
                            }
                            onChange={(_event, newValue) => {
                              if (typeof newValue !== "string") {
                                onChange(
                                  newValue?.value as
                                    | keyof typeof PaymentFlowTypeEnum
                                    | null
                                );
                              }
                            }}
                            value={
                              paymentFlowTypes.find(
                                (pft) => pft.value === value
                              ) || null
                            }
                            onBlur={onBlur}
                            // disabled={isReadOnly || isAdjustmentOrder}
                            sx={{ width: { xs: "100%", lg: "100%" } }}
                            renderOption={(props, option) => (
                              <AutocompleteOption
                                {...props}
                                key={option.value}
                                sx={{
                                  "&:hover": {
                                    backgroundColor:
                                      "var(--joy-palette-neutral-100)",
                                  },
                                }}
                              >
                                <Stack
                                  direction={"row"}
                                  justifyContent={"space-between"}
                                  alignItems={"center"}
                                  flex={1}
                                >
                                  <Stack>
                                    {option.label}
                                    <Typography>
                                      {option.description}
                                    </Typography>
                                  </Stack>
                                </Stack>
                              </AutocompleteOption>
                            )}
                          />
                        </FormControl>
                      );
                    }}
                  />
                  <FormHelperText>
                    {(errors.paymentFlowType as any)?.message}
                  </FormHelperText>
                  {/* </FormControl> */}
                </Grid>
                <Grid xs={12} lg={6}>
                  <Controller
                    name="currency"
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { value, ref } }) => (
                      <FormControl
                        sx={{ flex: "1", height: "100%", flexGrow: 1 }}
                      >
                        <FormLabel>
                          {t("catalog.inventory.editForm.currency")}
                          <span className="text-danger-400">*</span>
                        </FormLabel>

                        <Select
                          ref={ref}
                          color={errors.currency ? "danger" : "neutral"}
                          indicator={<KeyboardArrowDown />}
                          placeholder={t("catalog.inventory.editForm.currency")}
                          slotProps={{
                            button: {
                              sx: { whiteSpace: "nowrap" },
                            },
                          }}
                          onChange={(_event, newValue: string | null) => {
                            if (
                              oldCurrency &&
                              oldCurrency !== newValue &&
                              items.length > 0
                            ) {
                              setIsShowCurrencyModal(true);
                              setUpdateCurrency(newValue ?? "");
                            } else {
                              setValue("currency", newValue ?? "");
                              setOldCurrency(newValue ?? "");
                            }
                          }}
                          value={value}
                          className="mt-auto"
                        >
                          {currencyList.map((item) => (
                            <Option key={item} value={item}>
                              {item}
                            </Option>
                          ))}
                        </Select>
                        <FormHelperText>
                          {t("catalog.createCatalog.currencyNote")}
                        </FormHelperText>
                      </FormControl>
                    )}
                  />
                </Grid>
                <Grid xs={12} lg={6}>
                  <Controller
                    name={`taxId`}
                    control={control}
                    render={({
                      field: { onChange, onBlur, name, ref },
                      fieldState: { error },
                    }) => (
                      <FormControl sx={{ flex: "1" }} error={!!error} ref={ref}>
                        <FormLabel>
                          {t("order.createOrder.productForm.taxAdditional")}
                        </FormLabel>
                        <Autocomplete
                          name={name}
                          placeholder={t(
                            "order.createOrder.productForm.taxAdditional"
                          )}
                          options={nationalTaxes || []}
                          getOptionLabel={(option) => option?.taxLabel || ""}
                          onChange={(_event, newValue: any) => {
                            onChange(newValue);
                            console.log(
                              "National Tax Item changed? ::",
                              newValue
                            );
                            if (newValue) {
                              setValue("taxId", newValue.id);
                            }
                          }}
                          onBlur={onBlur}
                          value={
                            selectedTax ||
                            nationalTaxes.find((t) => t.taxLabel === "NONE") ||
                            null
                          }
                        />
                        <FormHelperText>{error?.message}</FormHelperText>
                      </FormControl>
                    )}
                  />
                </Grid>
                <Grid xs={12} lg={6}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <FormControl sx={{ flexGrow: 1 }}>
                      <FormLabel>
                        {t("catalog.createCatalog.catalogStartPeriod")}
                      </FormLabel>

                      <Controller
                        name="periodStartDate"
                        control={control}
                        render={({ field: { name, value, ref, onChange } }) => (
                          <DesktopDatePicker
                            views={["year", "month", "day"]}
                            format="YYYY/MM/DD"
                            minDate={dayjs(new Date()).utc(true)}
                            slotProps={{
                              popper: {
                                sx: {
                                  "& .MuiDateCalendar-root": {
                                    height: "auto !important",
                                  },
                                },
                              },
                              yearButton: {
                                sx: {
                                  width: "100%",
                                  height: "100%",
                                  borderRadius: 0,
                                  border:
                                    "1px solid var(--joy-palette-neutral-200)",
                                  "&:hover, &:focus, &.Mui-selected": {
                                    backgroundColor:
                                      "var(--joy-palette-primary-500) !important",
                                    color: "var(--joy-palette-common-white)",
                                  },
                                  "&.Mui-disabled": {
                                    background:
                                      "var(--joy-palette-neutral-100)",
                                    color: "var(--joy-palette-neutral-400)",
                                  },
                                },
                              },
                              monthButton: {
                                sx: {
                                  width: "100%",
                                  height: "100%",
                                  borderRadius: 0,
                                  border:
                                    "1px solid var(--joy-palette-neutral-200)",
                                  "&:hover, &:focus, &.Mui-selected": {
                                    backgroundColor:
                                      "var(--joy-palette-primary-500) !important",
                                    color: "var(--joy-palette-common-white)",
                                  },
                                  "&.Mui-disabled": {
                                    background:
                                      "var(--joy-palette-neutral-100)",
                                    color: "var(--joy-palette-neutral-400)",
                                  },
                                },
                              },
                            }}
                            name={name}
                            value={value ? dayjs(value, "YYYY-MM-DD") : null}
                            onChange={(date) => {
                              const d = dayjs(date).format("YYYY-MM-DD");
                              onChange(dayjs(d).isValid() ? d : null);
                            }}
                            inputRef={ref}
                          />
                        )}
                      />
                    </FormControl>
                  </LocalizationProvider>
                </Grid>
                <Grid xs={12} lg={6}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <FormControl sx={{ flexGrow: 1 }}>
                      <FormLabel>
                        {t("catalog.createCatalog.catalogEndPeriod")}
                      </FormLabel>
                      <Controller
                        name="periodEndDate"
                        control={control}
                        render={({ field: { name, value, ref, onChange } }) => (
                          <DesktopDatePicker
                            views={["year", "month", "day"]}
                            format="YYYY/MM/DD"
                            slots={
                              {
                                // calendarHeader: CustomDatePickerHeader,
                              }
                            }
                            // openTo=
                            minDate={dayjs(new Date()).utc(true)}
                            slotProps={{
                              popper: {
                                sx: {
                                  "& .MuiDateCalendar-root": {
                                    height: "auto !important",
                                  },
                                },
                              },
                              yearButton: {
                                sx: {
                                  width: "100%",
                                  height: "100%",
                                  borderRadius: 0,
                                  border:
                                    "1px solid var(--joy-palette-neutral-200)",
                                  "&:hover, &:focus, &.Mui-selected": {
                                    backgroundColor:
                                      "var(--joy-palette-primary-500) !important",
                                    color: "var(--joy-palette-common-white)",
                                  },
                                  "&.Mui-disabled": {
                                    background:
                                      "var(--joy-palette-neutral-100)",
                                    color: "var(--joy-palette-neutral-400)",
                                  },
                                },
                              },
                              monthButton: {
                                sx: {
                                  width: "100%",
                                  height: "100%",
                                  borderRadius: 0,
                                  border:
                                    "1px solid var(--joy-palette-neutral-200)",
                                  "&:hover, &:focus, &.Mui-selected": {
                                    backgroundColor:
                                      "var(--joy-palette-primary-500) !important",
                                    color: "var(--joy-palette-common-white)",
                                  },
                                  "&.Mui-disabled": {
                                    background:
                                      "var(--joy-palette-neutral-100)",
                                    color: "var(--joy-palette-neutral-400)",
                                  },
                                },
                              },
                            }}
                            //   disabled={isReadOnly}
                            name={name}
                            value={value ? dayjs(value, "YYYY-MM-DD") : null}
                            onChange={(date) => {
                              const d = dayjs(date).format("YYYY-MM-DD");
                              onChange(dayjs(d).isValid() ? d : null);
                            }}
                            inputRef={ref}
                          />
                        )}
                      />
                    </FormControl>
                  </LocalizationProvider>
                </Grid>
              </Grid>
            </section>
            <hr className="w-full h-0.5 bg-gray-200 lg:hidden" />
            <Box
              sx={{
                width: { xs: "90vw", lg: isFullView ? "90vw" : "100%" },
                height: "100%",
                maxHeight: { lg: "27.5rem" },
                overflow: "auto",
                borderWidth: { xs: "0px", md: "1px" },
                position: "relative",
                padding: { xs: "0px", md: "24px 32px" },
              }}
            >
              <Stack
                direction={"row"}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <TitleCount
                  count={2}
                  titleText={t("catalog.createCatalog.previewTitle")}
                  subText={t("catalog.createCatalog.previewSubTitle")}
                  classes="mb-8"
                  isShowSubText={tableData.length > 0}
                />
                <button
                  className={`text-primary-400 font-bold border border-primary-400 rounded-xl p-2 text-sm flex justify-center items-center gap-2 ${
                    tableData.length <= 0 && "hidden"
                  }`}
                  onClick={addItemsToCatalog}
                  type="button"
                >
                  <PlusIcon className="w-4 h4" />
                  <span>{t("catalog.createCatalog.additemtothiscatalog")}</span>
                </button>
              </Stack>

              {tableData.length > 0 ? (
                <>
                  <Table
                    borderAxis="x"
                    sx={{
                      "& th": { textAlign: "center" },
                      minWidth: "100%",
                      border: "1px solid #cecece",
                      marginBottom: "12px",
                    }}
                  >
                    <thead>
                      <tr key={"header"}>
                        <th key={"index"} className="w-12">
                          {t("catalog.items.no")}
                        </th>
                        <th key={"picture"} className="md:w-24 w-20">
                          {t("catalog.items.picture")}
                        </th>
                        <th key={"sku"} className="md:w-36 w-20">
                          {t("catalog.items.sku")}
                        </th>
                        <th
                          key={"productName"}
                          className="w-48 hidden md:table-cell"
                        >
                          {t("catalog.items.productName")}
                        </th>
                        <th
                          key={"currency"}
                          className="w-24 hidden md:table-cell"
                        >
                          {t("catalog.items.currency")}
                        </th>
                        <th
                          key={"inventoryType"}
                          className="w-36 hidden md:table-cell"
                        >
                          {t("catalog.items.category")}
                        </th>

                        <th key={"remove"} className="w-16 border-l">
                          {t("catalog.items.remove")}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {tableData.map((d, index) => {
                        return (
                          <tr key={index}>
                            <td className="text-center">
                              {(pagination.current - 1) * pagination.limit +
                                (index + 1)}
                              .
                            </td>
                            <td className="text-center">
                              <UploadImageBtn
                                item={itemInfos[index]}
                                setIsLoading={setIsLoading}
                                setShowToast={setShowToast}
                                setAlertMessage={setAlertMessage}
                              />
                            </td>

                            {d.map((i, idx) => (
                              <td
                                key={
                                  itemInfos[index].id +
                                  i.toString() +
                                  "_col" +
                                  index +
                                  Math.random() * 695622
                                }
                                className={`text-center break-all  ${
                                  idx != 0 ? "hidden md:table-cell" : ""
                                }`}
                              >
                                <IfElse
                                  condition={idx === 2}
                                  ifBlock={watch("currency")}
                                  elseBlock={<>{i}</>}
                                />
                              </td>
                            ))}
                            <td className="text-center border-l">
                              <div className="flex justify-center w-full">
                                <IconButton
                                  color="danger"
                                  onClick={() => removeItem(itemInfos[index])}
                                >
                                  <TrashIcon className="w-6" />
                                </IconButton>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                  <TablePagination
                    justifyContent="flex-end"
                    handlePaging={handlePaging}
                    pagination={pagination}
                    setPagination={setPagination}
                  />
                </>
              ) : (
                <Stack
                  direction={{ xs: "column", lg: "row" }}
                  justifyContent={"center"}
                  alignContent={"center"}
                  alignItems={"center"}
                  spacing={2}
                  m={"auto"}
                >
                  <div className="">
                    <img src={"/item-empty.svg"} />
                  </div>
                  <Stack
                    spacing={1}
                    m={"auto"}
                    justifyContent={{ xs: "center", lg: "flex-start" }}
                    alignContent={{ xs: "center", lg: "flex-start" }}
                    alignItems={{ xs: "center", lg: "flex-start" }}
                  >
                    <Typography
                      fontSize={16}
                      fontWeight={700}
                      sx={{ color: "#101828" }}
                    >
                      {t("catalog.createCatalog.emptyProductTitle")}
                    </Typography>
                    <Typography
                      fontSize={12}
                      fontWeight={500}
                      sx={{ color: "#101828" }}
                    >
                      {t("catalog.createCatalog.emptyProductSubTitle")}
                    </Typography>
                    <button
                      className={`w-60 text-primary-400 font-bold border border-primary-400 rounded-xl p-2 text-sm flex justify-center items-center gap-2 
                      }`}
                      onClick={addItemsToCatalog}
                      type="button"
                    >
                      <PlusIcon className="w-4 h4" />
                      <span>
                        {t("catalog.createCatalog.additemtothiscatalog")}
                      </span>
                    </button>
                    <Typography
                      fontSize={12}
                      fontWeight={400}
                      sx={{
                        color: "#101828",
                        display: "flex",
                        flexDirection: "row",
                        // alignContent: "center",
                        alignItems: "center",
                        gap: "4px",
                      }}
                    >
                      <img src="/light-bulb.svg" />
                      <span>{t("catalog.createCatalog.emptyProductTip")}</span>
                    </Typography>
                  </Stack>
                </Stack>
              )}
            </Box>

            <Box
              sx={{
                width: { xs: "90vw", lg: isFullView ? "90vw" : "100%" },
                height: "100%",
                maxHeight: { lg: "27.5rem" },
                overflow: "auto",
                borderWidth: { xs: "0px", md: "1px" },
                borderTopWidth: { xs: "1px", md: "0px" },
                position: "relative",
                padding: { xs: "20px 0px", md: "24px 32px" },
              }}
            >
              <TitleCount
                count={3}
                titleText={t("catalog.createCatalog.devliveryFeesTitle")}
                classes="mb-8"
              />
              <Controller
                name="feeType"
                control={control}
                render={({ field: { onChange, onBlur, name, value, ref } }) => {
                  return (
                    <FormControl sx={{ flex: "1" }} ref={ref}>
                      <FormLabel>
                        {t("catalog.createCatalog.ChooseFeeTypes")}
                      </FormLabel>
                      <Autocomplete
                        name={name}
                        freeSolo={true}
                        placeholder={t("catalog.createCatalog.ChooseFeeTypes")}
                        options={DELIVERY_FEES_OPTIONS}
                        getOptionLabel={(option) =>
                          typeof option !== "string" ? option?.label : ""
                        }
                        onChange={(_event, newValue) => {
                          if (typeof newValue !== "string") {
                            onChange(newValue?.value);
                          }
                        }}
                        value={
                          DELIVERY_FEES_OPTIONS.find(
                            (pft) => pft.value === value
                          ) || null
                        }
                        onBlur={onBlur}
                        sx={{ width: { xs: "100%", lg: "100%" } }}
                        renderOption={(props, option) => (
                          <AutocompleteOption
                            {...props}
                            key={option.value}
                            sx={{
                              "&:hover": {
                                backgroundColor:
                                  "var(--joy-palette-neutral-100)",
                              },
                            }}
                          >
                            <Stack
                              direction={"row"}
                              justifyContent={"space-between"}
                              alignItems={"center"}
                              flex={1}
                            >
                              <Stack>
                                {option.label}
                                <Typography>{option.description}</Typography>
                              </Stack>
                            </Stack>
                          </AutocompleteOption>
                        )}
                      />
                    </FormControl>
                  );
                }}
              />
              <FormHelperText>
                {(errors.feeType as any)?.message}
              </FormHelperText>
              <FormControl sx={{ flex: "1", margin: "16px auto" }}>
                <Typography
                  fontSize={15}
                  fontWeight={600}
                  sx={{ color: "#101828" }}
                >
                  {watchDeliveryFeesType ===
                  DELIVERY_FEES_OPTIONS_ENUM.FIXED_AMOUNT
                    ? t("catalog.createCatalog.FixedDeliveryFee")
                    : watchDeliveryFeesType ===
                      DELIVERY_FEES_OPTIONS_ENUM.QUANTITY_BASE
                    ? t("commons.QuantityDeliveryFee")
                    : watchDeliveryFeesType ===
                      DELIVERY_FEES_OPTIONS_ENUM.PRICE_BASE
                    ? t("commons.TotalPriceDeliveryFee")
                    : t("catalog.inventory.deliveryFees.distanceTypeLable")}
                </Typography>
                <span className="text-xs mb-2">
                  {watchDeliveryFeesType ===
                  DELIVERY_FEES_OPTIONS_ENUM.FIXED_AMOUNT
                    ? t("catalog.createCatalog.FixedDeliveryFeeDescription")
                    : watchDeliveryFeesType ===
                      DELIVERY_FEES_OPTIONS_ENUM.QUANTITY_BASE
                    ? t("commons.QuantityDeliveryFeeSubTitle")
                    : watchDeliveryFeesType ===
                      DELIVERY_FEES_OPTIONS_ENUM.PRICE_BASE
                    ? t("commons.TotalPriceDeliveryFeeSubTitle")
                    : t(
                        "catalog.inventory.deliveryFees.distanceTypeDescription"
                      )}
                </span>

                {fields.map((item, index) => (
                  <Stack
                    key={item.id}
                    direction={{ xs: "column", md: "row" }}
                    spacing={3}
                    alignItems={"flex-end"}
                    className="py-3 border p-5 md:border-0 md:p-0 rounded-xl md:my-0 my-3"
                  >
                    {watchDeliveryFeesType !=
                      DELIVERY_FEES_OPTIONS_ENUM.FIXED_AMOUNT && (
                      <Controller
                        name={`feeValues.${index}.upToCount`}
                        defaultValue={
                          catalog?.deliveryFeeConfig?.feeValues[index].upToCount
                        }
                        control={control}
                        render={({ field: { name, onChange, ref, value } }) => (
                          <FormControl
                            className="md:w-[45%] w-[100%]"
                            ref={ref}
                          >
                            <FormLabel>
                              {t("commons.startFrom")}{" "}
                              <span className="text-danger-400">*</span>
                            </FormLabel>

                            <IfElse
                              condition={
                                watchDeliveryFeesType !=
                                DELIVERY_FEES_OPTIONS_ENUM.PRICE_BASE
                              }
                              ifBlock={
                                <Input
                                  type="number"
                                  placeholder={t("commons.startFrom")}
                                  endDecorator={
                                    watchDeliveryFeesType !=
                                    DELIVERY_FEES_OPTIONS_ENUM.PRICE_BASE ? (
                                      <span className="text-black bold">
                                        {t("commons.Items")}
                                      </span>
                                    ) : null
                                  }
                                  startDecorator={
                                    watchDeliveryFeesType ==
                                    DELIVERY_FEES_OPTIONS_ENUM.PRICE_BASE ? (
                                      <span className="text-black bold">
                                        {watch("currency")}
                                      </span>
                                    ) : null
                                  }
                                  aria-label="catalogName"
                                  // {...register(`feeValues.${index}.upToCount`, {
                                  //   required: t("commons.error.form.required", {
                                  //     label: t("commons.UpTo"),
                                  //   }),
                                  // })}
                                  className="min-h-[3.5rem]"
                                />
                              }
                              elseBlock={
                                <NumericFormat
                                  name={name}
                                  customInput={Input}
                                  startDecorator={<>{watch("currency")}</>}
                                  placeholder={t("commons.Fee")}
                                  // disabled={isReadOnly || isAdjustmentOrder}
                                  value={value}
                                  onValueChange={(v) => {
                                    onChange(Number(v.value));
                                    // handleCalculatePrice();
                                  }}
                                  decimalScale={2}
                                  fixedDecimalScale={true}
                                  // valueIsNumericString={true}
                                  thousandSeparator={
                                    watch("currency") === "IDR" ? "." : ","
                                  }
                                  decimalSeparator={
                                    watch("currency") === "IDR" ? "," : "."
                                  }
                                />
                              }
                            />
                          </FormControl>
                        )}
                      />
                    )}

                    <Controller
                      name={`feeValues.${index}.value`}
                      defaultValue={
                        catalog?.deliveryFeeConfig?.feeValues[index].value
                      }
                      control={control}
                      render={({ field: { name, onChange, ref, value } }) => (
                        <FormControl className="w-[100%]" ref={ref}>
                          <FormLabel>
                            {t("commons.Fee")}{" "}
                            <span className="text-danger-400">*</span>
                          </FormLabel>
                          {/* <Input
                            type="number"
                            placeholder={t("commons.Fee")}
                            startDecorator={<div>{watch("currency")}</div>}
                            aria-label="catalogName"
                            {...register(`feeValues.${index}.value`, {
                              required: t("commons.error.form.required", {
                                label: t("commons.Fee"),
                              }),
                            })}
                            className="min-h-[3.5rem]"
                          /> */}

                          <NumericFormat
                            name={name}
                            customInput={Input}
                            startDecorator={<>{watch("currency")}</>}
                            placeholder={t("commons.Fee")}
                            // disabled={isReadOnly || isAdjustmentOrder}
                            value={value}
                            onValueChange={(v) => {
                              onChange(Number(v.value));
                              // handleCalculatePrice();
                            }}
                            decimalScale={2}
                            fixedDecimalScale={true}
                            // valueIsNumericString={true}
                            thousandSeparator={
                              watch("currency") === "IDR" ? "." : ","
                            }
                            decimalSeparator={
                              watch("currency") === "IDR" ? "," : "."
                            }
                          />
                        </FormControl>
                      )}
                    />

                    {watchDeliveryFeesType !==
                      DELIVERY_FEES_OPTIONS_ENUM.FIXED_AMOUNT && (
                      <button
                        className="flex justify-between items-center gap-3 border-danger-500 md:border border-0 md:px-3 px-0 rounded md:min-h-[3.5rem] text-danger-500"
                        type="button"
                        onClick={() => remove(index)}
                      >
                        <TrashIcon className="w-6" />
                        <span className="md:hidden block">remove Fee</span>
                      </button>
                    )}
                  </Stack>
                ))}
                <div className="py-3  text-primary-500 bold">
                  {watchDeliveryFeesType !==
                    DELIVERY_FEES_OPTIONS_ENUM.FIXED_AMOUNT && (
                    <button
                      type="button"
                      onClick={() => append({ upToCount: 0, value: 0 })}
                    >
                      + {t("commons.AddFee")}
                    </button>
                  )}
                </div>
              </FormControl>
            </Box>
            <Stack
              direction={{ xs: "column-reverse", md: "row" }}
              justifyContent={{ xs: "center", md: "flex-end" }}
              spacing={"10px"}
            >
              <Button
                variant="outlined"
                className="min-w-[9.2rem] whitespace-nowrap rounded-md"
                onClick={() => {
                  // localStorage.removeItem("new-catalog");
                  navigate("/client/catalogues/list");
                }}
              >
                {t("catalog.inventory.cancelBtn")}
              </Button>
              <Button
                type="submit"
                className="min-w-[9.2rem] whitespace-nowrap rounded-md"
              >
                {t("catalog.inventory.createCatalogBtn")}
              </Button>
            </Stack>
          </Stack>
          <ChangeCurrencyModal
            show={isShowCurrencyModal}
            onClick={handleChange}
            onClose={() => {
              // setValue("currency", oldCurrency ?? "");
              setIsShowCurrencyModal(false);
            }}
          />
        </form>
      </FormProvider>
    </Stack>
  );
}

export default CreateCatalogue;
