import * as HeroIcons from "@heroicons/react/24/outline";
export const HeroIcon = ({
  name,
  ...props
}: {
  name: keyof typeof HeroIcons;
} & React.SVGProps<SVGSVGElement>) => {
  const HeroIconComponent = HeroIcons[name];

  if (!HeroIconComponent) {
    console.error(`Icon "${name}" not found in HeroIcons`);
    return null;
  }
  return (
    <HeroIconComponent width={24} height={24} strokeWidth={2} {...props} />
  );
};
