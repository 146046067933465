import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Modal,
  Option,
  Select,
  Stack,
  Switch,
  Typography,
} from "@mui/joy";
import {
  PhNoWhiteListedCountries,
  WhiteListedCountries,
} from "../../../utils/constants";
import { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { KeyboardArrowDown } from "@mui/icons-material";
import { animateSelectStyle } from "../../../styles/select";
import { countries } from "../../../components/Countries";
import { matchesFormat } from "../../../utils/misc";
import { useMutation } from "@tanstack/react-query";
import { AddressService } from "../../../services/address.service";
import { z } from "zod";
import { useTranslation } from "react-i18next";
import showErrorToast, { ErrorToastConfig } from "../../../components/Error";
import FlagById from "../../../components/FlagById";
import PhoneInput from "react-phone-input-2";
import startsWith from "lodash.startswith";
import LoadingModal from "../../../components/LoadingModal/LoadingModal";
import toast from "react-hot-toast";

interface AddressModalProps {
  show?: boolean;
  onClose: () => void;
  onSuccess: () => void;
  selectedAddress: IAddressResponse | null;
}

export default function AddressModal({
  show = false,
  onClose,
  onSuccess,
  selectedAddress,
}: AddressModalProps) {
  const addressSchema = z.object({
    title: z.string().min(5),
    fullName: z.string().min(5),
    address: z.string().min(10),
    countryCode: z.string().min(2),
    postalCode: z.string().optional(),
    province: z.string().min(5),
    phoneNumber: z.string().optional(),
    phoneNumberCountryCode: z.coerce
      .number()
      .transform((num) => num.toString())
      .refine((str) => str.length >= 1, {
        message: "Phone number country code invalid",
      })
      .optional(),
    defaultAddress: z.boolean().default(true),
  });
  const { register, watch, setError, formState, handleSubmit, setValue } =
    useForm<z.infer<typeof addressSchema>>({
      resolver: zodResolver(addressSchema),
    });

  const { errors } = formState;
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const handleClose = () => {
    setShowModal(false);
    onClose();
  };

  const handleEdit = (selectedAddress: IAddressResponse) => {
    setValue("address", selectedAddress.address);
    setValue("countryCode", selectedAddress.countryCode);
    setValue("defaultAddress", selectedAddress.defaultAddress);
    setValue("fullName", selectedAddress.fullName);
    setValue("phoneNumber", selectedAddress.phoneNumber);
    setValue("phoneNumberCountryCode", selectedAddress.phoneNumberCountryCode);
    setValue("postalCode", selectedAddress.postalCode);
    setValue("province", selectedAddress.province);
    setValue("title", selectedAddress.title);
  };

  const createMutation = useMutation({
    mutationFn: AddressService.createAddress,
    onSuccess: () => {
      handleClose();
      onSuccess();
      setLoading(false);
    },
    onError: (error: any) => {
      setLoading(false);
      toast(showErrorToast(error), ErrorToastConfig);
    },
  });

  const upadateMutation = useMutation({
    mutationFn: AddressService.updateAddress,
    onSuccess: (_) => {
      handleClose();
      onSuccess();
      setLoading(false);
    },
    onError: (error: any) => {
      setLoading(false);
      toast(showErrorToast(error), ErrorToastConfig);
    },
  });

  const onSubmit: SubmitHandler<z.infer<typeof addressSchema>> = (data) => {
    setLoading(true);
    if (selectedAddress) {
      upadateMutation.mutate({ ...data, id: selectedAddress.id });
    } else {
      createMutation.mutate(data);
    }
  };

  useEffect(() => {
    setShowModal(show);
    if (selectedAddress) handleEdit(selectedAddress);
  }, [show]);

  const { t } = useTranslation();

  return (
    <Modal
      onClose={handleClose}
      open={showModal}
      sx={{
        backgroundColor: "#32383E99",
        backdropFilter: "unset",
        display: "flex",
        alignItems: { xs: "flex-end", sm: "center" }, // Bottom sheet behavior on mobile
        justifyContent: "center",
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "#fff",
          boxShadow: 24,
          borderRadius: { xs: "16px", sm: "32px" },
          outline: "none",
          width: "80%",
          maxWidth: { xs: "100vw", sm: "640px" },
          height: "auto",
          maxHeight: "90vh",
          overflowY: "auto",
          padding: { xs: "16px", sm: "32px" },
        }}
        className={"hide-scrollbar"}
        justifyContent={"center"}
      >
        <Box
          display={"flex"}
          flexDirection={"column"}
          alignItems={"center"}
        >
          <Typography level="text-xl-semibold" textColor={"text.primary"}>
            {t("profile.address.newAddress")}
          </Typography>
        </Box>

        <form onSubmit={handleSubmit(onSubmit)} className="mt-4">
          <Stack direction={"column"} spacing={{ xs: 1, sm: 2 }}>
            <FormControl error={!!errors.title}>
              <FormLabel>{t("profile.address.addressTitle")}*</FormLabel>
              <Input
                placeholder={t("profile.address.typeAddressTitle")}
                sx={{ backgroundColor: "white" }}
                {...register("title")}
              />
              {errors.title?.message && (
                <FormHelperText>{errors.title?.message}</FormHelperText>
              )}
              <FormHelperText>
                {"You can give title like ‘Home’, ‘Office’ or ‘Warehouse’"}
              </FormHelperText>
            </FormControl>

            <FormControl error={!!errors.fullName}>
              <FormLabel>{t("profile.address.fullName")}*</FormLabel>
              <Input
                placeholder={t("profile.address.yourFullName")}
                sx={{ backgroundColor: "white" }}
                {...register("fullName")}
              />
              {errors.fullName?.message && (
                <FormHelperText>{errors.fullName?.message}</FormHelperText>
              )}
            </FormControl>

            <div className="flex sm:flex-row sm:space-x-2 sm:space-y-0 flex-col space-y-2">
              <FormControl className="w-full" error={!!errors.countryCode}>
                <FormLabel>{t("profile.address.country")}</FormLabel>
                <Select
                  placeholder={t("profile.address.country")}
                  indicator={<KeyboardArrowDown />}
                  onChange={(_, value) => {
                    if (value) setValue("countryCode", value);
                  }}
                  renderValue={(option) => (
                    <Typography
                      startDecorator={
                        <FlagById id={`${option?.value ?? ""}`} />
                      }
                    >
                      {option?.label}
                    </Typography>
                  )}
                  value={watch("countryCode")}
                  sx={{ ...animateSelectStyle, backgroundColor: "white" }}
                >
                  {countries
                    .filter((country) =>
                      WhiteListedCountries.includes(country.code)
                    )
                    .map((country) => (
                      <Option
                        key={country.code}
                        value={country.code}
                        label={country.label}
                      >
                        <Typography
                          startDecorator={<FlagById id={country.code} />}
                        >
                          {country.label}
                        </Typography>
                      </Option>
                    ))}
                </Select>
                {errors.countryCode?.message && (
                  <FormHelperText>
                    {errors.countryCode?.message}
                  </FormHelperText>
                )}
              </FormControl>

              <FormControl className="w-full" error={!!errors.phoneNumber}>
                <FormLabel>{t("profile.address.phoneNumber")}*</FormLabel>
                <PhoneInput
                  value={
                    (watch("phoneNumber") &&
                      `+${watch("phoneNumberCountryCode")}${watch(
                        "phoneNumber"
                      )}`) ||
                    ""
                  }
                  onlyCountries={PhNoWhiteListedCountries}
                  enableTerritories={false}
                  country={"id"}
                  masks={{
                    my: "..-....-....",
                    sg: "....-....",
                    th: "... ... ...",
                    id: "... ... ... ...",
                  }}
                  isValid={(inputNumber, _: any, countries: any) => {
                    if (inputNumber === "") {
                      return true;
                    }
                    const result = (countries || []).some((country: any) => {
                      return (
                        (startsWith(inputNumber, country.dialCode) ||
                          startsWith(country.dialCode, inputNumber)) &&
                        matchesFormat(
                          inputNumber,
                          country.format,
                          country.countryCode
                        )
                      );
                    });
                    if (!result && errors.phoneNumber?.message === null) {
                      setError("phoneNumber", {
                        message: "invalid mobile number.",
                      });
                    }
                    return result;
                  }}
                  inputStyle={{
                    width: "100%",
                    fontSize: "16px",
                    minHeight: "2.8rem",
                    height: "auto",
                    borderRadius: "8px",
                    fontWeight: 500,
                  }}
                  buttonStyle={{
                    borderRadius: "8px 0 0 8px",
                  }}
                  onChange={(phone, meta: any) => {
                    setValue("phoneNumberCountryCode", meta.dialCode);
                    setValue(
                      "phoneNumber",
                      phone.replace(new RegExp(meta.dialCode), "")
                    );
                  }}
                />
                {errors.phoneNumber?.message && (
                  <FormHelperText>
                    {errors.phoneNumber?.message}
                  </FormHelperText>
                )}
              </FormControl>
            </div>

            <FormControl error={!!errors.address}>
              <FormLabel>{t("profile.address.address")}*</FormLabel>
              <Input
                placeholder={t("profile.address.typeAddress")}
                sx={{ backgroundColor: "white" }}
                {...register("address")}
              />
              {errors.address?.message && (
                <FormHelperText>{errors.address?.message}</FormHelperText>
              )}
            </FormControl>

            <div className="flex sm:flex-row sm:space-x-2 sm:space-y-0 flex-col space-y-2">
              <FormControl className="w-full" error={!!errors.province}>
                <FormLabel>{t("profile.address.provinceState")}*</FormLabel>
                <Input
                  placeholder={t("profile.address.typeProvinceState")}
                  sx={{ backgroundColor: "white", width: "100%" }}
                  {...register("province")}
                />
                {errors.province?.message && (
                  <FormHelperText>{errors.province?.message}</FormHelperText>
                )}
              </FormControl>

              <FormControl className="w-full" error={!!errors.postalCode}>
                <FormLabel>{t("profile.address.postalCode")}</FormLabel>
                <Input
                  placeholder={t("profile.address.typePostalCode")}
                  sx={{ backgroundColor: "white", width: "100%" }}
                  {...register("postalCode")}
                />
                {errors.postalCode?.message && (
                  <FormHelperText>
                    {errors.postalCode?.message}
                  </FormHelperText>
                )}
              </FormControl>
            </div>

            <FormControl
              className="flex flex-row justify-between items-center"
              error={!!errors.defaultAddress}
            >
              <FormLabel className="center">
                {t("profile.address.default")}
              </FormLabel>
              <Switch
                {...register("defaultAddress")}
                defaultChecked={true}
                checked={watch("defaultAddress")}
                onChange={(e) => setValue("defaultAddress", e.target.checked)}
              />
            </FormControl>

            <div className="flex flex-row space-x-2 mt-4">
              <Button
                onClick={() => {
                  handleClose();
                }}
                size="sm"
                variant="linkz-outlined-primary"
                color="primary"
                className="h-10 w-full"
              >
                {t("profile.address.cancel")}
              </Button>
              <Button
                type="submit"
                size="sm"
                variant="linkz-filled-primary"
                color="primary"
                className="h-10 w-full"
              >
                {t("profile.address.confirm")}
              </Button>
            </div>
          </Stack>
        </form>

        <LoadingModal isLoading={loading} />
      </Box>
    </Modal>
  );
}
