import dayjs from "dayjs";
import i18next from "../i18n/config";

export const orderStatuses = [
  {
    value: "DRAFT",
    label: i18next.t("order.filters.status.DRAFT"),
    backendStatuses: ["DRAFT"],
  },
  {
    value: "AWAITING_RESPONSE",
    label: i18next.t("order.filters.status.AWAITING_RESPONSE"),
    backendStatuses: ["AWAITING_RESPONSE", "CONFIRMED"],
  },
  /** PENDING_PAYMENT is currently not using anymore */
  // {
  //   value: "PENDING_PAYMENT",
  //   label: i18next.t("order.filters.status.PENDING_PAYMENT"),
  //   backendStatuses: ["PENDING_PAYMENT"],
  // },
  {
    value: "PROCESSING",
    label: i18next.t("order.filters.status.PROCESSING"),
    backendStatuses: ["PROCESSING"],
  },
  {
    value: "PENDING_FINAL_PAYMENT",
    label: i18next.t("order.filters.status.PENDING_PAYMENT"),
    backendStatuses: ["PENDING_FINAL_PAYMENT"],
  },
  {
    value: "COMPLETE",
    label: i18next.t("order.filters.status.COMPLETE"),
    backendStatuses: ["COMPLETE"],
  },
  {
    value: "CANCELLED",
    label: i18next.t("order.filters.status.CANCELLED"),
    backendStatuses: ["CANCELLED"],
  },
];

export const orderReportTypes = {
  TOTAL_RECEIVABLE: "TOTAL_RECEIVABLE",
  TOTAL_PAYABLE: "TOTAL_PAYABLE",
  PAYMENT_HISTORY: "PAYMENT_HISTORY",
};

export const paymentFlowTypes = [
  {
    value: "RECURRING_PAYMENT",
    label: i18next.t("order.paymentFlowType.RECURRING_PAYMENT.label"),
    description: i18next.t(
      "order.paymentFlowType.RECURRING_PAYMENT.description"
    ),
  },
  {
    value: "FULL_AFTER_DELIVERY",
    label: i18next.t("order.paymentFlowType.FULL_AFTER_DELIVERY.label"),
    description: i18next.t(
      "order.paymentFlowType.FULL_AFTER_DELIVERY.description"
    ),
  },
  {
    value: "FULL_BEFORE_DELIVERY",
    label: i18next.t("order.paymentFlowType.FULL_BEFORE_DELIVERY.label"),
    description: i18next.t(
      "order.paymentFlowType.FULL_BEFORE_DELIVERY.description"
    ),
  },
  {
    value: "SPLIT_PAYMENT",
    label: i18next.t("order.paymentFlowType.SPLIT_PAYMENT.label"),
    description: i18next.t("order.paymentFlowType.SPLIT_PAYMENT.description"),
  },
  {
    value: "MULTIPLE_PAYMENTS",
    label: i18next.t("order.paymentFlowType.MULTIPLE_PAYMENTS.label"),
    description: i18next.t(
      "order.paymentFlowType.MULTIPLE_PAYMENTS.description"
    ),
  },
];

export enum OrderStatusEnum {
  DRAFT = "DRAFT",
  AWAITING_RESPONSE = "AWAITING_RESPONSE",
  CONFIRMED = "CONFIRMED",
  PENDING_PAYMENT = "PENDING_PAYMENT",
  PROCESSING = "PROCESSING",
  PENDING_FINAL_PAYMENT = "PENDING_FINAL_PAYMENT",
  COMPLETE = "COMPLETE",
  CANCELLED = "CANCELLED",
}

export enum TaxTypeEnum {
  PPH_GROSS_UP = "PPH_GROSS_UP",
  PPH_NPWP = "PPH_NPWP",
  PPN_INCLUSIVE = "PPN_INCLUSIVE",
  PPN_EXCLUSIVE = "PPN_EXCLUSIVE",
  GST = "GST",
  MANUAL = "MANUAL",
}

export enum TaxAmountTypeEnum {
  PERCENTAGE = "PERCENTAGE",
  FIXED = "FIXED",
}

export enum PaymentFlowTypeEnum {
  FULL_AFTER_DELIVERY = "FULL_AFTER_DELIVERY",
  FULL_BEFORE_DELIVERY = "FULL_BEFORE_DELIVERY",
  SPLIT_PAYMENT = "SPLIT_PAYMENT",
  RECURRING_PAYMENT = "RECURRING_PAYMENT",
  MULTIPLE_PAYMENTS = "MULTIPLE_PAYMENTS",
}

export interface IOrderInitiatePayload {
  grandTotalAmount: number;
  paymentTerm: string | null;
  sellerId: string | null;
  buyerId: string | null;
  invoiceNumber: string | null;
  createdById: string | null;
  // createdByBusiness: string | null; // Delete later cos this one no need to pass, and it can be saved from currentBusiness.id in the backend itself
  status: string;
  sellerIds?: {
    businessId?: string | null;
    accountId?: string | null;
  } | null;
  buyerIds?: {
    businessId?: string | null;
    accountId?: string | null;
  } | null;
  isFromCatalog?: boolean;
  isUnPaidCatalogueOrder?: boolean;
}

export interface IOrderSaveBase {
  createdById?: string | null;
  createdByBusiness?: string | null;
  selectedExternalBusiness?: { [key: string]: any } | null;
  sellerIds?:
  | {
    businessId?: string | null;
    accountId?: string | null;
  }
  | {
    newBusinessInput?: string | null;
    newBusinessOptionText?: string | null;
  }
  | null;
  buyerIds?:
  | {
    businessId?: string | null;
    accountId?: string | null;
  }
  | {
    newBusinessInput?: string | null;
    newBusinessOptionText?: string | null;
  }
  | null;
  newBusiness?: {
    businessName?: string | null;
    accountName?: string | null;
    email?: string | null;
  } | null;
  paymentFlowType?: keyof typeof PaymentFlowTypeEnum | null;
  paymentTerm?: string | null;
  currency?: string | null;
  deliveryDate?: String | null;
  shippingMethod?: string | null;
  vehicleNumber?: string | null;
  trackingNumber?: string | null;
  driverName?: string | null;
  orderItems?:
  | {
    orderItemId?: string | null; // If products are used to save once in order, there must be orderItemId
    productId?: string | null;
    sku?: string | null;
    productName?: string | null;
    quantity?: number | null;
    price?: number | null;
    discount?: number | null;
    discountType?: string;
    taxLabel?: string | null;
    tax?: number | null;
    taxType?: keyof typeof TaxTypeEnum | null;
    totalPrice?: number | null;
    businessId?: string | null;
    catalogId?: string | null;
    inventoryType?: string | null;
    currency?: string | null;
    productDescription?: string | null;
    createdById?: string | null;
  }[]
  | null;
  status?: string;
  totalTax?: number | null;
  additionalDiscount?: number | null;
  additionalDiscountType?: string;
  additionalTaxItem?: {
    taxLabel?: string | null;
    taxAmount?: number | null;
    taxType?: keyof typeof TaxTypeEnum | null;
    taxAmountType?: keyof typeof TaxAmountTypeEnum | null;
  } | null;
  additionalTax?: number | null;
  additionalTaxLabel?: string;
  additionalTaxType?: keyof typeof TaxTypeEnum | null;
  additionalTaxAmountType?: keyof typeof TaxAmountTypeEnum | null;
  defaultTaxItem?: {
    taxLabel?: string | null;
    taxAmount?: number | null;
    taxType?: keyof typeof TaxTypeEnum | null;
    taxAmountType?: keyof typeof TaxAmountTypeEnum | null;
  } | null;
  deliveryFee?: number | null;
  grandTotalAmount?: number;
  invoiceNumber?: string | null;
  remarks?: string | null;
  paymentMethod?: string | null;
  deductedCredit?: number | null;
  downPayment?: number | null;
  startMonth?: dayjs.Dayjs | null;
  endMonth?: dayjs.Dayjs | null;
  recurringDay?: number | null;
  recurringParentOrderId?: string | null;
  isFromCatalog?: boolean;
  orderShippingAddress?: IAddressResponse;
  submittedAt?: string | null;
  confirmedAt?: string | null;
  processedAt?: string | null;
  completedAt?: string | null;
}

export interface IOrderSavePayload extends IOrderSaveBase { }

export interface IOrderSaveResponse extends IOrderSaveBase { }

// export interface IOrderResponse {
//   sellerId: string | null;
//   sellerUserId: string | null;
//   buyerId: string | null;
//   buyerUserId: string | null;
//   orderType: string;
//   paymentTerm: string | null;
//   currency: string | null;
//   deliveryDate: string | null;
//   shippingMethod: string | null;
//   vehicleNumber: string | null;
//   trackingNumber: string | null;
//   driverName: string | null;
//   orderItems?: {}[] | null;
//   additionalDiscount: number | null;
//   deliveryFee: number | null;
//   grandTotalAmount: number | null;
// }

export interface IOrderList {
  id: string;
  orderType: string;
  invoiceNumber: string | null;
  buyer?: any;
  buyerUser?: any;
  seller?: any;
  sellerUser?: any;
  status: keyof typeof OrderStatusEnum;
  updatedAt: string;
  recurringParentOrderId?: string | null;
  paymentFlowType?: string | null;
  orderRevisions: IOrderRevision[];
  currency?: string | null;
  remainingAmount?: number | null;
  grandTotalAmount?: number | null;
  paidAmount?: number | null;
  settlementDate?: string | null;
  paymentMode?: string | null;
}

export interface IOrderRevision {
  id: string;
  order: any;
  content: IOrderSavePayload;
  version: number;
  isMain: boolean;
  createdAt: string;
}

export interface CancelOrderWithReasonDto {
  reason?: string;
}

// Delivery fees type

export interface IDELIVERY_FEES_OPTIONS {
  value: DELIVERY_FEES_OPTIONS_ENUM;
  label: string;
  description: string;
}

export enum DELIVERY_FEES_OPTIONS_ENUM {
  FIXED_AMOUNT = "FIXED_AMOUNT",
  QUANTITY_BASE = "QUANTITY_BASE",
  PRICE_BASE = "PRICE_BASE",
  // DISTANCE_BASE = 'DISTANCE_BASE'
}

export const DELIVERY_FEES_OPTIONS: IDELIVERY_FEES_OPTIONS[] = [
  {
    value: DELIVERY_FEES_OPTIONS_ENUM.FIXED_AMOUNT,
    label: i18next.t("catalog.inventory.deliveryFees.fixedTypeLable"),
    description: i18next.t(
      "catalog.inventory.deliveryFees.fixedTypeDescription"
    ),
  },
  {
    value: DELIVERY_FEES_OPTIONS_ENUM.QUANTITY_BASE,
    label: i18next.t("catalog.inventory.deliveryFees.qtyTypeLable"),
    description: i18next.t("catalog.inventory.deliveryFees.qtyTypeDescription"),
  },
  {
    value: DELIVERY_FEES_OPTIONS_ENUM.PRICE_BASE,
    label: i18next.t("catalog.inventory.deliveryFees.priceTypeLable"),
    description: i18next.t("catalog.inventory.deliveryFees.priceTypeDescription")
  },
  // {
  //   value: DELIVERY_FEES_OPTIONS_ENUM.DISTANCE_BASE,
  //   label: i18next.t("catalog.inventory.deliveryFees.distanceTypeLable"),
  //   description: i18next.t("catalog.inventory.deliveryFees.distanceTypeDescription")
  // }
]
