import { Box, Button, Modal, Stack, Table, Typography } from "@mui/joy";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CatalogInventoryItemType } from "../../types/item";

interface Props {
  show?: boolean;
  onClick: () => void;
  onClose: () => void;
  removedItems?: CatalogInventoryItemType[];
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "#fff",
  boxShadow: 24,
  width: "94%",
  maxWidth: 600,
  px: 4,
  py: 4,
  borderRadius: "32px",
  borderColor: "transparent",
  outline: "none",
};

const ItemsRemovedModal = ({
  show = false,
  onClick,
  onClose,
  removedItems,
}: Props) => {
  const { t } = useTranslation();

  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    setShowModal(show);
  }, [show]);

  const handleClick = () => {
    onClick();
    setShowModal(false);
    onClose();
  };

  return (
    <Modal
      open={showModal}
      style={{
        backgroundColor: "#32383E99",
        backdropFilter: "unset",
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style} justifyContent={"center"}>
        <Typography
          mt={4}
          textAlign={"left"}
          fontWeight={600}
          fontSize={24}
          id="modal-modal-title"
          sx={{
            color: "#265641",
          }}
        >
          {t("catalog.removedCatalogModalTitle")}
        </Typography>
        {removedItems?.length && (
          <div className="w-full flex flex-col">
            {/* copy link  */}
            <Stack direction={"row"} justifyContent={"left"} mt={4}>
              <Table
                borderAxis="both"
                sx={{ "& th": { textAlign: "center" }, minWidth: "100%" }}
              >
                <thead>
                  <tr key={"header"}>
                    <th key={"sku"} className="w-36">
                      {t("catalog.items.sku")}
                    </th>
                    <th key={"productName"} className="w-48">
                      {t("catalog.items.productName")}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {removedItems.map((d) => {
                    return (
                      <tr key={d.id}>
                        <td>{d.inventoryItem.sku}</td>
                        <td>{d.inventoryItem.productName}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </Stack>
          </div>
        )}
        {/* buttons */}
        <Stack direction={"row"} justifyContent={"space-between"} mt={4}>
          <Button
            type="button"
            onClick={handleClick}
            sx={{
              padding: 1.5,
            }}
          >
            {t("commons.confirmBtn")}
          </Button>

          <Button
            type="button"
            onClick={onClose}
            color="danger"
            sx={{
              padding: 1.5,
            }}
          >
            {t("commons.cancelBtn")}
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
};

export default ItemsRemovedModal;
