import { privateAxios } from "../lib/api";
import {
  CatalogType,
  CreateCatalogType,
  ICatalogInventoryItemResponse,
  ICatalogInviteCreatePayload,
  ICatalogInviteCreateResponse,
  ICatalogResponse,
  ICatalogUpdatePayload,
} from "../types/catalog";
import {
  CatalogInventoryItemType,
  CreateCatalogInventoryItemType,
} from "../types/item";
import { ApiConstants } from "../utils/constants";

export class InventoryService {
  static async createCatalog({
    catalog,
  }: {
    catalog: CreateCatalogType;
  }): Promise<CatalogType> {
    const response = await privateAxios.post(
      `${ApiConstants.catalogURL}`,
      catalog
    );

    return response.data as CatalogType;
  }

  static async duplicateCatalog({
    catalogId,
  }: {
    catalogId: string;
  }): Promise<CatalogType> {
    const response = await privateAxios.post(
      `${ApiConstants.catalogURL}/duplicate/${catalogId}`
    );

    return response.data as CatalogType;
  }

  static async createCatalogInvite({
    catalogId,
    catalogInvites,
  }: {
    catalogId: string;
    catalogInvites: ICatalogInviteCreatePayload[];
  }): Promise<boolean> {
    const response = await privateAxios.post(
      `${ApiConstants.catalogURL}/invites?catalogId=${catalogId}`,
      catalogInvites
    );

    return response.data as boolean;
  }

  static async createCatalogItems({
    catalogId,
    inventoryItems,
  }: {
    catalogId: string;
    inventoryItems: CreateCatalogInventoryItemType[];
  }) {
    const response = await privateAxios.post(
      `${ApiConstants.itemsURL}/catalog/${catalogId}`,
      inventoryItems
    );

    return response.data as CatalogInventoryItemType[];
  }

  static async manageCatalogItems({
    catalogId,
    inventoryItemIds,
  }: {
    catalogId: string;
    inventoryItemIds: string[];
  }) {
    const response = await privateAxios.post(
      `${ApiConstants.itemsURL}/catalog/${catalogId}/batch`,
      inventoryItemIds
    );

    return response.data as CatalogInventoryItemType[];
  }

  static async getAllCatalogs({
    businessId,
  }: {
    businessId?: string;
  }): Promise<CatalogType[]> {
    const response = await privateAxios.get(
      `${ApiConstants.catalogURL}/inventory`,
      {
        params: {
          businessId: businessId,
        },
      }
    );

    return response.data as CatalogType[];
  }

  static async getAllCatalogsInventory({
    businessId,
    paging = false,
    limit = 10,
    offset = 0,
    catalogType,
    sortBy = "id",
    sortDirection = "desc",
    searchTerm,
    publicCatalogPeriodType
  }: {
    businessId?: string;
    paging?: boolean;
    limit?: number;
    offset?: number;
    catalogType: "PUBLIC" | "PRIVATE";
    sortBy?: string;
    sortDirection?: "asc" | "desc";
    searchTerm?: string | null;
    publicCatalogPeriodType?: string
  }): Promise<ICatalogResponse> {
    const response = await privateAxios.get(
      `${ApiConstants.catalogURL}/inventory`,
      {
        params: {
          businessId,
          paging,
          limit,
          offset,
          catalogType,
          sortBy,
          sortDirection,
          searchTerm,
          publicCatalogPeriodType
        },
      }
    );

    return response.data as ICatalogResponse;
  }

  static async getOneCatalog({
    catalogId,
    withItems = false,
  }: {
    catalogId: string;
    withItems?: boolean;
  }): Promise<CatalogType> {
    const response = await privateAxios.get(
      `${ApiConstants.catalogURL}/${catalogId}${withItems ? "?withItems=true" : ""
      }`
    );

    return response.data as CatalogType;
  }

  static async getCatalogItems({
    catalogId,
    paging = false,
    limit = 50,
    offset = 0,
    inventoryType,
    sortBy = "id",
    sortDirection = "desc",
    searchTerm,
  }: {
    catalogId: string;
    paging?: boolean;
    limit?: number;
    offset?: number;
    inventoryType?: string | null;
    sortBy?: string;
    sortDirection?: "asc" | "desc";
    searchTerm?: string | null;
  }) {
    const response = await privateAxios.get(
      `${ApiConstants.catalogURL}/items/${catalogId}`,
      {
        params: {
          paging,
          limit,
          offset,
          inventoryType,
          sortBy,
          sortDirection,
          searchTerm,
        },
      }
    );

    return response.data as ICatalogInventoryItemResponse;
  }

  static async patchCatalog({
    catalog,
  }: {
    catalog: ICatalogUpdatePayload;
  }): Promise<CatalogType> {
    const response = await privateAxios.patch(
      `${ApiConstants.catalogURL}/${catalog.id}`,
      catalog
    );

    return response.data as CatalogType;
  }

  static async patchCatalogItems({
    itemId,
    updateCatalogInventoryItemDto,
  }: {
    itemId: string;
    updateCatalogInventoryItemDto: CreateCatalogInventoryItemType;
  }) {
    const response = await privateAxios.patch(
      `${ApiConstants.itemsURL}/catalog/${itemId}`,
      updateCatalogInventoryItemDto
    );

    return response.data as CatalogInventoryItemType[];
  }

  static async removeCatalog({
    catalogId,
  }: {
    catalogId: string;
  }): Promise<boolean> {
    const response = await privateAxios.delete(
      `${ApiConstants.catalogURL}/${catalogId}`
    );

    return response.data as boolean;
  }

  static async removeCatalogItem({
    catalogInventoryItemId,
  }: {
    catalogInventoryItemId: string;
  }): Promise<boolean> {
    const response = await privateAxios.delete(
      `${ApiConstants.itemsURL}/catalog/inventory/${catalogInventoryItemId}`
    );


    return response.data as boolean;
  }

  static async findCatalogInviteByBusinessAndCatalog({
    businessId,
    catalogId,
  }: {
    businessId: string;
    catalogId: string;
  }): Promise<ICatalogInviteCreateResponse[]> {
    const response = await privateAxios.get(
      `${ApiConstants.catalogURL}/invites/${businessId}/catalog/${catalogId}`
    );

    return response.data as ICatalogInviteCreateResponse[];
  }
}
