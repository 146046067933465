import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/outline";
import { Stack, Typography } from "@mui/joy";

export default function SectionToggleBar({
  open,
  setOpen,
  sxClasses,
  icon,
  title,
  isRequired,
}: any) {
  return (
    <Stack
      direction={"row"}
      justifyContent={"space-between"}
      onClick={() => setOpen(!open)}
      sx={sxClasses}
    >
      <Typography startDecorator={icon} fontWeight={600}>
        {title}
        {isRequired && <span className={`text-danger-500`}>*</span>}
      </Typography>
      {open ? (
        <ChevronUpIcon
          width={20}
          height={20}
          strokeWidth={2}
          color="var(--joy-palette-primary-500)"
        />
      ) : (
        <ChevronDownIcon
          width={20}
          height={20}
          strokeWidth={2}
          color="var(--joy-palette-primary-500)"
        />
      )}
    </Stack>
  );
}
