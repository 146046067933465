import React from "react";
import { EmblaOptionsType } from "embla-carousel";
import useEmblaCarousel from "embla-carousel-react";
import { DotButton, useDotButton } from "./EmblaCarouselDotButton";
import styles from "./Carousel.module.css";

type PropType = {
  slides: any[];
  options?: EmblaOptionsType;
};

const EmblaCarousel: React.FC<PropType> = (props) => {
  const { slides, options } = props;
  const [emblaRef, emblaApi] = useEmblaCarousel(options);

  const { selectedIndex, scrollSnaps, onDotButtonClick } =
    useDotButton(emblaApi);

  return (
    <section className={styles.embla}>
      <div className={styles.embla__viewport} ref={emblaRef}>
        <div className={styles.embla__container}>
          {slides.map(({ src }, index: any) => (
            <div className={styles.embla__slide} key={index}>
              <div className={styles.embla__slide__number}>
                <img
                  src={src || "/inventory-img-placeholder.png"}
                  width={360}
                  height={360}
                  className="w-[100%] h-[100%] object-cover rounded-md"
                />
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="embla__controls my-4">
        <div className="embla__dots flex gap-2">
          {scrollSnaps.map((_, index) => (
            <DotButton
              key={index}
              onClick={() => onDotButtonClick(index)}
              className={"embla__dot".concat(
                index === selectedIndex
                  ? " embla__dot--selected border border-gray-900 rounded p-1"
                  : "p-1"
              )}
            >
              <img
                src={slides[index].src}
                width={72}
                height={72}
                className="w-[72px] h-[72px] object-cover rounded-md"
              />
            </DotButton>
          ))}
        </div>
      </div>
    </section>
  );
};

export default EmblaCarousel;
