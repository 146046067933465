import {
  Select,
  Option,
  Box,
  Typography,
  Input,
  FormHelperText,
} from "@mui/joy";
import { KeyboardArrowDown } from "@mui/icons-material";
import { Chip } from "@mui/joy";
import { formatCurrencyByCode } from "../../../utils/formatPrice";
import { ConfirmationModal } from "../../ConfirmationModal";
import RequestFullPaymentModal from "./RequestFullPaymentModal";
import { ThreeColumnGridRow } from "../../stateless/layouts";
import { Stack } from "@mui/material";
import { Controller} from "react-hook-form";
import { NumericFormat } from "react-number-format";

export default function MultiplePaymentPlan({ container }: { container: any }) {
  const {
    orderId,
    grandTotal,
    control: mpcControl,
    setValue: setMPCValue,
    getValues: getMPCValues,
    watch: mpcWatch,
    errors: mpcErrors,
    currency,
    isReadOnly,
    selectedPaymentPlan,
    setSelectedPaymentPlan,
    onConfirmChangePaymentType,
    handleChangePaymentPlan,
    handleCancelChangePaymentPlan,
    onRequestFullPayment,
    setOnRequestFullPayment,
    getPaidPaymentAmount,
    isFullPaymentRequested,
    getFullPaymentAmount,
  } = container;

  const paymentStatusLabel = (status: string | any) => {
    switch (status) {
      case "PENDING":
        return "Pending";
      case "SUCCESS":
        return "Paid";
      case "FAILED":
        return "Failed";
      case "CANCELED":
        return "Canceled";
      default:
        return "Pending";
    }
  };

  const paymentStatusColor = (status: string | any) => {
    switch (status) {
      case "PENDING":
        return "warning";
      case "SUCCESS":
        return "primary";
      case "FAILED":
        return "danger";
      case "CANCELED":
        return "danger";
      default:
        return "warning";
    }
  };

  // if (errors.paymentHistory && Object.keys(errors.paymentHistory).length > 0) {
  //   let outputOnce = false;
  //   Object.values(errors.paymentHistory).map((payHistError: any) => {
  //     console.log("payHistError", payHistError);
  //     if (
  //       (getValues("paymentTypes") ?? selectedPaymentPlan) === "AMOUNT" &&
  //       !outputOnce
  //     ) {
  //       outputOnce = payHistError.amount?.type === "min";
  //       typeMinError(payHistError.amount);
  //     } else if (
  //       (getValues("paymentTypes") ?? selectedPaymentPlan) === "PERCENTAGE" &&
  //       !outputOnce
  //     ) {
  //       outputOnce = payHistError.percentage?.type === "min";
  //       typeMinError(payHistError.percentage);
  //     }
  //   });
  // }

  // const typeMinError = (vError: any) => {
  //   console.log("vError", vError);
  //   return (
  //     <FormHelperText color="danger" style={{ marginTop: "4px" }}>
  //       {vError.message}
  //     </FormHelperText>
  //   );
  // };

  return (
    <Box
      sx={{
        p: 4,
        backgroundColor: "var(--joy-palette-primary-50)",
        borderRadius: "xl",
        boxShadow: "var(--joy-shadow-sm)",
      }}
    >
      <Typography level="text-lg-semibold">
        Payment Plan
        <Typography color="danger">*</Typography>
      </Typography>

      <Stack spacing={2} my={4}>
        <ThreeColumnGridRow
          label={"Payment value type"}
          inputElement={
            <Select
              disabled={!!isReadOnly}
              value={selectedPaymentPlan}
              defaultValue={"AMOUNT"}
              onChange={(_, value) => setSelectedPaymentPlan(value)}
              indicator={<KeyboardArrowDown />}
              sx={{ maxWidth: 260 }}
            >
              <Option value="AMOUNT">Amount</Option>
              <Option value="PERCENTAGE">Percentage</Option>
            </Select>
          }
          outputValue={""}
        />

        {/* Payment Times Loop */}
        {(getMPCValues("paymentHistory") ?? []).map((payment: any) => (
          <ThreeColumnGridRow
            key={payment.time}
            label={`Payment ${payment.time}`}
            inputElement={
              <Stack direction={"row"} gap={1} alignItems={"center"}>
                {(getMPCValues("paymentTypes") ?? selectedPaymentPlan) ===
                  "AMOUNT" && (
                  <Controller
                    name={`paymentHistory.${payment.time - 1}.amount`}
                    control={mpcControl}
                    render={({ field: { onChange, name, value } }: any) => (
                      <Stack>
                        <NumericFormat
                          name={name}
                          value={value}
                          customInput={Input}
                          decimalScale={2}
                          fixedDecimalScale={true}
                          thousandSeparator={
                            getMPCValues("currency") === "IDR" ? "." : ","
                          }
                          decimalSeparator={
                            getMPCValues("currency") === "IDR" ? "," : "."
                          }
                          sx={{ maxWidth: 260 }}
                          placeholder={""}
                          startDecorator={
                            (getMPCValues("paymentTypes") ??
                              selectedPaymentPlan) === "AMOUNT" && currency
                          }
                          onValueChange={(v) => {
                            const value = v.value;
                            onChange(Number(value) || 0);
                            if (`${parseFloat(value)}` !== "NaN") {
                              setMPCValue(
                                `paymentHistory.${payment.time - 1}.amount`,
                                parseFloat(value)
                              );
                              setMPCValue(
                                `paymentHistory.${payment.time - 1}.percentage`,
                                Math.round(
                                  (parseFloat(value) / grandTotal) * 100
                                )
                              );
                            }
                          }}
                          disabled={isReadOnly}
                        />
                        {mpcErrors?.paymentHistory?.[payment.time - 1]?.amount
                          ?.message && (
                          <FormHelperText
                            color="danger"
                            style={{ marginBottom: "2px" }}
                          >
                            {
                              mpcErrors?.paymentHistory?.[payment.time - 1]
                                ?.amount?.message
                            }
                          </FormHelperText>
                        )}
                      </Stack>
                    )}
                  />
                )}
                {(getMPCValues("paymentTypes") ?? selectedPaymentPlan) ===
                  "PERCENTAGE" && (
                  <Controller
                    name={`paymentHistory.${payment.time - 1}.percentage`}
                    control={mpcControl}
                    render={({ field: { onChange, name, value } }: any) => (
                      <Stack>
                        <NumericFormat
                          name={name}
                          value={value}
                          customInput={Input}
                          decimalScale={2}
                          fixedDecimalScale={true}
                          thousandSeparator={
                            getMPCValues("currency") === "IDR" ? "." : ","
                          }
                          decimalSeparator={
                            getMPCValues("currency") === "IDR" ? "," : "."
                          }
                          sx={{ maxWidth: 260 }}
                          placeholder={""}
                          endDecorator={"%"}
                          {...{
                            isAllowed: (values) => {
                              const { floatValue } = values;
                              return (
                                floatValue === undefined || floatValue <= 100
                              ); // Limit input to 100%
                            },
                          }}
                          onValueChange={(v) => {
                            const value = v.value;
                            onChange(Number(value) || 0);
                            if (`${parseFloat(value)}` !== "NaN") {
                              setMPCValue(
                                `paymentHistory.${payment.time - 1}.percentage`,
                                parseFloat(value)
                              );
                              setMPCValue(
                                `paymentHistory.${payment.time - 1}.amount`,
                                (parseFloat(value) / 100) * grandTotal
                              );
                            }
                          }}
                          disabled={isReadOnly}
                        />
                        {mpcErrors?.paymentHistory?.[payment.time - 1]
                          ?.percentage?.message && (
                          <FormHelperText
                            color="danger"
                            style={{ marginBottom: "2px" }}
                          >
                            {
                              mpcErrors?.paymentHistory?.[payment.time - 1]
                                ?.percentage?.message
                            }
                          </FormHelperText>
                        )}
                      </Stack>
                    )}
                  />
                )}

                {isReadOnly && (
                  <Stack position={"relative"} justifyContent={"center"}>
                    <Chip
                      variant="outlined"
                      size="sm"
                      color={
                        paymentStatusColor(
                          getMPCValues(
                            `paymentHistory.${payment.time - 1}.status`
                          )
                        ) as any
                      }
                      sx={{
                        position: "absolute",
                        left: "100%",
                        marginLeft: "4px",
                      }}
                    >
                      {
                        paymentStatusLabel(
                          getMPCValues(
                            `paymentHistory.${payment.time - 1}.status`
                          )
                        ) as any
                      }
                    </Chip>
                  </Stack>
                )}
              </Stack>
            }
            outputValue={formatCurrencyByCode(
              getMPCValues(`paymentHistory.${payment.time - 1}.amount`),
              currency,
              currency
            )}
          />
        ))}
      </Stack>

      {/* Payment History Errors - displaying errors all at once */}
      {/* {mpcErrors.paymentHistory &&
        Object.values(mpcErrors.paymentHistory).map((payHistError: any) => {
          console.log("payHistError", payHistError);
          return (
            <FormHelperText color="danger" style={{ margin: "8px 0" }}>
              {(getMPCValues("paymentTypes") ?? selectedPaymentPlan) ===
                "AMOUNT" && `payHistError?.amount?.time ${payHistError?.amount?.message}`}
              {(getMPCValues("paymentTypes") ?? selectedPaymentPlan) ===
                "PERCENTAGE" && payHistError?.percentage?.message}
            </FormHelperText>
          );
        })} */}

      {(mpcWatch("paymentHistory") as any[])?.reduce(
        (acc, curr) => acc + curr.amount,
        0
      ) !== grandTotal && (
        <Typography color="danger" fontSize={"sm"}>
          Total Payment{" "}
          {formatCurrencyByCode(
            (getMPCValues("paymentHistory") ?? [])?.reduce(
              (acc: number, curr: any) => acc + curr.amount,
              0
            ),
            currency,
            currency
          )}{" "}
          must be equal to{" "}
          {formatCurrencyByCode(grandTotal, currency, currency)}
        </Typography>
      )}

      {isFullPaymentRequested() && (
        <Typography
          mb={"1rem"}
          color="warning"
          fontWeight={"bold"}
          fontSize={"sm"}
        >
          Full payment requested by seller with amount{" "}
          {formatCurrencyByCode(getFullPaymentAmount(), currency, currency)}
        </Typography>
      )}
      <Box>
        {/* {orderStatus !== OrderStatusEnum.COMPLETE &&
          allowRequestFullPayment() &&
          isSeller &&
          !isFullPaymentRequested() && (
            <Button onClick={() => setOnRequestFullPayment(true)}>
              Request Full Payment
            </Button>
          )} 
        {orderStatus !== OrderStatusEnum.COMPLETE &&
          isFullPaymentRequested() &&
          isSeller && (
            <Button
              sx={{ bgcolor: "white" }}
              variant="outlined"
              onClick={() => cancelRequestFullPayment()}
            >
              Cancel Full Payment
            </Button>
          )} */}
      </Box>

      {!isReadOnly && (
        <ConfirmationModal
          open={onConfirmChangePaymentType}
          title="Change Payment Plan"
          content="Changing Payment plan will erase all filled-in information. Are you sure you want to continue?"
          onCancel={() => handleCancelChangePaymentPlan()}
          onConfirm={() => {
            handleChangePaymentPlan();
          }}
          btnConfirmText="Proceed"
        />
      )}
      <RequestFullPaymentModal
        orderId={orderId}
        open={onRequestFullPayment}
        grandTotal={grandTotal}
        paidAmount={getPaidPaymentAmount()}
        currency={currency}
        onClose={() => setOnRequestFullPayment(false)}
      />
    </Box>
  );
}
