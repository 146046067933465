import { DocumentDuplicateIcon } from "@heroicons/react/24/outline";
import {
  CloudDoneOutlined,
  CloudUploadOutlined,
  CopyAllOutlined,
  EventRepeatOutlined,
  ShareOutlined,
} from "@mui/icons-material";
import { Chip, Stack, Typography } from "@mui/joy";
import { PaymentFlowTypeEnum, OrderStatusEnum } from "../../types/order";
import { useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";
import dayjs from "dayjs";
import toast from "react-hot-toast";
import { showSuccessToast, SuccessToastConfig } from "../Error";
import { MouseEventHandler, useState } from "react";
import { BtnTonalPrimarySm } from "../stateless/buttons";

interface OrderHeaderProps {
  orderType: string;
  currentOrderStatus: string;
  revisions: Array<{ [key: string]: any }>;
  invoiceNumber: string | null;
  autoSaveLoading: boolean;
  paymentLink: { [key: string]: any } | null;
  toPDF: any;
  handleGeneratePdf?: MouseEventHandler<HTMLAnchorElement>;
  orderHeaderActionButtons?: JSX.Element;
}

export default function OrderHeader({
  orderType,
  currentOrderStatus,
  revisions,
  invoiceNumber,
  autoSaveLoading,
  toPDF,
  // handleGeneratePdf,
  paymentLink,
  orderHeaderActionButtons,
}: OrderHeaderProps) {
  const { t } = useTranslation();
  const { getValues } = useFormContext();
  const [isGeneratingPDF, setIsGeneratingPDF] = useState<boolean>(false);

  const recurringDay = getValues("recurringDay");
  const startMonthYear = dayjs(getValues("startMonth"));
  const today = dayjs();
  const definedRecurringDate =
    recurringDay && startMonthYear.date(recurringDay);

  const isFromCatalog = getValues("isFromCatalog");

  // TODO: adjust order data based on revision
  const handleGeneratePdf = async () => {
    /** TODO:: This loading state doesn't work yet */
    setIsGeneratingPDF(true);
    console.log("Clicked Generate PDF");
    try {
      toPDF({});
    } catch (error) {
      console.error("Error downloading PDF: ", error);
    } finally {
      console.log("DONE! Clicked Generate PDF");
      setIsGeneratingPDF(false);
    }
  };

  return (
    <Stack>
      <Stack
        direction={"row"}
        gap={"8px"}
        alignItems={"center"}
        flexWrap={"wrap"}
      >
        {/* Order Type Chip */}
        <Chip
          variant="soft"
          sx={{
            "--Chip-radius": "8px",
            color: isFromCatalog
              ? "var(--joy-palette-others-order_catalogue_text)"
              : `var(--joy-palette-others-order_${orderType}_text)`,
            background: isFromCatalog
              ? "var(--joy-palette-others-order_catalogue)"
              : `var(--joy-palette-others-order_${orderType})`,
          }}
        >
          {isFromCatalog ? "Catalogue Order" : `${orderType} Order`}
        </Chip>

        {/* Order Number */}
        <Stack
          direction={"row"}
          gap={"8px"}
          alignItems={"center"}
          flexWrap={"wrap"}
        >
          <Typography
            level={"text-xl-semibold"}
            startDecorator={
              getValues("paymentFlowType") ===
              PaymentFlowTypeEnum.RECURRING_PAYMENT ? (
                <Stack
                  borderRadius={"8px"}
                  bgcolor={"var(--joy-palette-primary-100)"}
                  width={28}
                  height={28}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <EventRepeatOutlined color="primary" />
                </Stack>
              ) : revisions?.length > 0 ? (
                <Stack
                  borderRadius={"8px"}
                  bgcolor={"#F7D5AC"}
                  width={28}
                  height={28}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <DocumentDuplicateIcon
                    width={20}
                    height={20}
                    color="#D48434"
                  />
                </Stack>
              ) : (
                ""
              )
            }
          >
            {t("order.createOrder.summary.orderNumber")}:
          </Typography>

          {/* Generate Invoice Number Once Awaiting Response occurred */}
          <Typography
            level={"text-xl-semibold"}
            textColor={!invoiceNumber ? "#D0D5DD" : ""}
          >
            {invoiceNumber || "000000-0000000"}
          </Typography>
        </Stack>
      </Stack>

      {/* Header Buttons Row */}
      <Stack
        flex={1}
        direction={"row"}
        gap={"8px"}
        alignItems={"center"}
        flexWrap={"wrap"}
        marginTop={"16px"}
      >
        {/** Share PDF */}
        {handleGeneratePdf && currentOrderStatus !== OrderStatusEnum.DRAFT && (
          <BtnTonalPrimarySm
            startDecorator={<ShareOutlined />}
            onClick={handleGeneratePdf}
            label={
              isGeneratingPDF
                ? "Generating ..."
                : t("order.createOrder.summary.sharePdfBtn")
            }
          />
        )}

        {/** Payment Link */}
        {paymentLink &&
          (getValues("paymentFlowType") !==
            PaymentFlowTypeEnum.RECURRING_PAYMENT ||
            today.isAfter(definedRecurringDate)) &&
          getValues("paymentFlowType") !==
            PaymentFlowTypeEnum.MULTIPLE_PAYMENTS && (
            <BtnTonalPrimarySm
              startDecorator={<CopyAllOutlined />}
              onClick={() => {
                navigator.clipboard.writeText(paymentLink?.url);
                toast(
                  showSuccessToast(
                    t("order.createOrder.summary.paymentLinkCopied")
                  ),
                  SuccessToastConfig
                );
              }}
              label={t("order.createOrder.summary.copyPaymentLinkBtn")}
            />
          )}

        {/** Order Header Action Buttons - JSX Element */}
        {orderHeaderActionButtons}

        {/** Auto Draft Save Loading */}
        {autoSaveLoading &&
          (autoSaveLoading ? (
            <Typography
              startDecorator={<CloudUploadOutlined />}
              fontWeight={600}
              pl={0.6}
            >
              {t("order.createOrder.summary.draftSaving")}
            </Typography>
          ) : (
            <Typography
              color="primary"
              startDecorator={<CloudDoneOutlined />}
              fontWeight={600}
              pl={0.6}
            >
              {t("order.createOrder.summary.draftSaved")}
            </Typography>
          ))}
      </Stack>
    </Stack>
  );
}
