import { listItemButtonClasses } from "@mui/joy";
import { extendTheme } from "@mui/joy/styles";

declare module "@mui/joy/styles" {
  // No custom tokens found, you can skip the theme augmentation.
}

export const theme = extendTheme({
  colorSchemes: {
    light: {
      palette: {
        common: {
          black: "#101828",
          white: "#FFF",
        },
        background: {
          surface: "#FFF",
        },
        primary: {
          50: "#F1F8F4",
          100: "#DDEEE3",
          200: "#BDDDCA",
          300: "#91C4A9",
          400: "#62A583",
          500: "#499873",
          600: "#2F6C51",
          700: "#265641",
          800: "#204536",
          900: "#1B392D",
        },
        success: {
          50: "#f0fdf4",
          100: "#dcfce7",
          200: "#bbf7d0",
          300: "#86efac",
          400: "#4ade80",
          500: "#22c55e",
          600: "#16a34a",
          700: "#15803d",
          800: "#166534",
          900: "#14532d",
        },
        warning: {
          50: "#FEFAF6",
          100: "#FDF0E1",
          200: "#FCE1C2",
          300: "#F3C896",
          400: "#EA9A3E",
          500: "#9A5B13",
          600: "#72430D",
          700: "#492B08",
          800: "#2E1B05",
          900: "#1D1002",
        },
        danger: {
          50: "#FEF6F6",
          100: "#FCE4E4",
          200: "#F7C5C5",
          300: "#F09898",
          400: "#E47474",
          500: "#C41C1C",
          600: "#A51818",
          700: "#7D1212",
          800: "#430A0A",
          900: "#240505",
        },
        neutral: {
          50: "#F9FAFB",
          100: "#F0F4F8",
          200: "#DDE7EE",
          300: "#CDD7E1",
          400: "#9FA6AD",
          500: "#636B74",
          600: "#555E68",
          700: "#32383E",
          800: "#171A1C",
          900: "#0B0D0E",
        },
        others: {
          // This is for light theme.
          order_sales: "#B9EEEB",
          order_sales_text: "#208A84",
          order_purchase: "#FCE1C2",
          order_purchase_text: "#9A5B13",
          order_catalogue: "#EBE1F7",
          order_catalogue_text: "#8E30E2",
          order_draft: "#F0F4F8",
          disabled: "#636B744D",
          success: "#E3FBE3",
          neutral_dark: "#475467",
          neutral_light: "#F9FAFB",
          secondary_text: "#667085",
        },
        text: {
          secondary: "#555E68",
        },
      },
    },
    dark: {
      palette: {
        common: {
          black: "#000",
          white: "#FFF",
        },
        primary: {
          50: "#F1F8F4",
          100: "#DDEEE3",
          200: "#BDDDCA",
          300: "#91C4A9",
          400: "#62A583",
          500: "#499873",
          600: "#2F6C51",
          700: "#265641",
          800: "#204536",
          900: "#1B392D",
        },
        success: {
          50: "#f0fdf4",
          100: "#dcfce7",
          200: "#bbf7d0",
          300: "#86efac",
          400: "#4ade80",
          500: "#22c55e",
          600: "#16a34a",
          700: "#15803d",
          800: "#166534",
          900: "#14532d",
        },
        warning: {
          50: "#fff7ed",
          100: "#ffedd5",
          200: "#fed7aa",
          300: "#fdba74",
          400: "#fb923c",
          500: "#f97316",
          600: "#ea580c",
          700: "#c2410c",
          800: "#9a3412",
          900: "#7c2d12",
        },
        /** Removed palette 'others' from here cos we don't have dark theme yet. */
        text: {
          secondary: "#555E68",
        },
      },
    },
  },
  focus: {},
  fontFamily: {
    display: "Plus Jakarta Sans", // applies to `h1`–`h4`
    body: "Plus Jakarta Sans", // applies to `title-*` and `body-*`
  },
  components: {
    JoyInput: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          padding: "10px 16px",
          border: `1px solid ${theme.vars.palette.neutral.outlinedBorder}`,
          borderRadius: "8px",
          // minHeight: "56px",
          ...theme.typography["text-sm-medium"],
          ...(ownerState.size === "sm" && {
            ...theme.typography["text-sm-medium"],
          }),
          ...(ownerState.variant === "linkz-input-search" && {
            borderRadius: "999px",
          }),
          ...(ownerState.color === "danger" && {
            border: `1px solid ${theme.vars.palette.danger[500]}`,
            "&:hover, &:not([data-skip-inverted-colors])": {
              "--Input-focusedHighlight": `${theme.vars.palette.danger[500]}`,
              "--Input-focusedThickness": `1px`,
            },
          }),
        }),
      },
    },
    JoyAutocomplete: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          padding: "10px 16px",
          border: `1px solid ${theme.vars.palette.neutral.outlinedBorder}`,
          borderRadius: "8px",
          // minHeight: "56px",
          ...theme.typography["text-sm-medium"],
          ...(ownerState.size === "sm" && {
            padding: "10px 16px",
          }),
          ...(ownerState.variant === "linkz-autocomplete-rounded" && {
            borderRadius: "999px",
            // padding: "16px 24px",
          }),
          ...(ownerState.color === "danger" && {
            border: `1px solid ${theme.vars.palette.danger[500]}`,
            "&:hover, &:not([data-skip-inverted-colors])": {
              "--Input-focusedHighlight": `${theme.vars.palette.danger[500]}`,
              "--Input-focusedThickness": `1px`,
            },
          }),
        }),
        clearIndicator: () => ({
          minWidth: "auto",
          minHeight: "auto",
        }),
        popupIndicator: () => ({
          minWidth: "auto",
          minHeight: "auto",
        }),
      },
    },
    JoyButton: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          // borderRadius: "999px",
          border: "1.5px solid",
          borderColor: "transparent",
          borderRadius: "8px",
          padding: "10px 16px",
          minWidth: "101px",
          // minHeight: "40px",
          ...theme.typography["text-sm-medium"],
          transition: "all 0.1s ease-in-out",
          ...(ownerState.size === "sm" && {
            padding: "9px 12px",
            minWidth: "auto",
            ...theme.typography["text-sm-medium"],
          }),
          ...(ownerState.size === "lg" && {
            padding: "10px 16px",
            ...theme.typography["text-lg-semibold"],
          }),

          ...(ownerState.variant === "linkz-filled-primary" && {
            background: theme.vars.palette.primary[500],
            borderColor: theme.vars.palette.primary[500],
            color: theme.vars.palette.common.white,
            "&:hover": {
              background: theme.vars.palette.primary[600],
              borderColor: theme.vars.palette.primary[600],
            },
          }),

          ...(ownerState.variant === "linkz-filled-primary-rounded" && {
            background: theme.vars.palette.primary[500],
            borderColor: theme.vars.palette.primary[500],
            color: theme.vars.palette.common.white,
            borderRadius: "999px",
            "&:hover": {
              background: theme.vars.palette.primary[600],
              borderColor: theme.vars.palette.primary[600],
            },
          }),

          ...(ownerState.variant === "linkz-outlined-primary" && {
            borderColor: theme.vars.palette.primary[500],
            color: theme.vars.palette.primary[500],
            "&:hover": {
              backgroundColor: theme.vars.palette.primary[500],
              color: "white",
            },
          }),

          ...(ownerState.variant === "linkz-outlined-danger" && {
            borderColor: theme.vars.palette.danger[500],
            color: theme.vars.palette.danger[500],
            "&:hover": {
              backgroundColor: theme.vars.palette.danger[500],
              color: "white",
            },
          }),

          ...(ownerState.variant === "linkz-outlined-primary-rounded" && {
            borderColor: theme.vars.palette.primary[500],
            color: theme.vars.palette.primary[500],
            borderRadius: "999px",
            "&:hover": {
              backgroundColor: theme.vars.palette.primary[500],
              color: "white",
            },
          }),

          ...(ownerState.variant === "linkz-tonal-primary" && {
            background: theme.vars.palette.neutral[100],
            borderColor: theme.vars.palette.neutral[100],
            "&:hover": {
              background: theme.vars.palette.primary[100],
              borderColor: theme.vars.palette.primary[100],
              color: theme.vars.palette.primary[500],
            },
          }),

          ...(ownerState.variant === "linkz-tonal-primary-rounded" && {
            background: theme.vars.palette.neutral[100],
            borderColor: theme.vars.palette.neutral[100],
            borderRadius: "999px",
            "&:hover": {
              background: theme.vars.palette.primary[100],
              borderColor: theme.vars.palette.primary[100],
              color: theme.vars.palette.primary[500],
            },
          }),

          ...(ownerState.variant === "linkz-tonal-danger" && {
            background: theme.vars.palette.neutral[100],
            borderColor: theme.vars.palette.neutral[100],
            "&:hover": {
              background: theme.vars.palette.danger[100],
              borderColor: theme.vars.palette.danger[100],
              color: theme.vars.palette.danger[500],
            },
          }),

          ...(ownerState.variant === "linkz-tonal-danger-rounded" && {
            background: theme.vars.palette.neutral[100],
            borderColor: theme.vars.palette.neutral[100],
            borderRadius: "999px",
            "&:hover": {
              background: theme.vars.palette.danger[100],
              borderColor: theme.vars.palette.danger[100],
              color: theme.vars.palette.danger[500],
            },
          }),

          ...(ownerState.variant === "outlined" && {
            borderWidth: "2px",
            "&:hover": {
              backgroundColor: theme.vars.palette.primary[500],
              borderColor: theme.vars.palette.primary[500],
              color: "white",
            },
          }),
          ...(ownerState.variant === "plain-gray" && {
            borderWidth: "2px",
            "&:disabled": {
              backgroundColor: theme.vars.palette.neutral[200],
              borderColor: theme.vars.palette.neutral[200],
              color: theme.vars.palette.neutral[400],
              "&:hover": {
                backgroundColor: theme.vars.palette.neutral[200],
                borderColor: theme.vars.palette.neutral[200],
                color: theme.vars.palette.neutral[400],
              },
            },
            "&:hover": {
              backgroundColor: theme.vars.palette.neutral[200],
              borderColor: theme.vars.palette.neutral[200],
              color: theme.vars.palette.neutral[700],
            },
          }),
          ...(ownerState.variant === "outlined-gray" && {
            borderColor: theme.vars.palette.neutral[700],
            border: "2px solid",
            "&:disabled": {
              backgroundColor: theme.vars.palette.neutral[200],
              borderColor: theme.vars.palette.neutral[200],
              color: theme.vars.palette.neutral[400],
              "&:hover": {
                backgroundColor: theme.vars.palette.neutral[200],
                borderColor: theme.vars.palette.neutral[200],
                color: theme.vars.palette.neutral[400],
              },
            },
            "&:hover": {
              backgroundColor: theme.vars.palette.neutral[200],
              borderColor: theme.vars.palette.neutral[200],
              color: theme.vars.palette.neutral[700],
            },
          }),
          ...(ownerState.variant === "primary-light" && {
            backgroundColor: theme.vars.palette.primary[200],
            borderColor: theme.vars.palette.primary[200],
            color: theme.vars.palette.common.black,
            "&:disabled": {
              backgroundColor: theme.vars.palette.neutral[200],
              borderColor: theme.vars.palette.neutral[200],
              color: theme.vars.palette.neutral[400],
              "&:hover": {
                backgroundColor: theme.vars.palette.neutral[200],
                borderColor: theme.vars.palette.neutral[200],
                color: theme.vars.palette.neutral[400],
              },
            },
            "&:hover": {
              backgroundColor: theme.vars.palette.primary[300],
              color: theme.vars.palette.common.black,
            },
          }),
          ...(ownerState.variant === "filter-outlined" && {
            color: theme.vars.palette.others.neutral_dark,
            borderColor: `${theme.vars.palette.others.neutral_dark} !important`,
            border: "1px solid",
            borderRadius: 8,
            maxHeight: 40,
            gap: "8px",
            "&:disabled": {
              backgroundColor: theme.vars.palette.neutral[200],
              borderColor: theme.vars.palette.neutral[200],
              color: theme.vars.palette.neutral[400],
              "&:hover": {
                backgroundColor: theme.vars.palette.neutral[200],
                borderColor: theme.vars.palette.neutral[200],
                color: theme.vars.palette.neutral[400],
              },
            },
            "&:hover": {
              backgroundColor: theme.vars.palette.neutral[200],
              borderColor: theme.vars.palette.neutral[200],
              color: theme.vars.palette.neutral[700],
            },
          }),
        }),
      },
    },
    JoyIconButton: {
      styleOverrides: {
        root: () => ({
          color: "var(--joy-palette-common-black)",
        }),
      },
    },
    JoySelect: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          padding: "10px 16px",
          borderRadius: "8px",
          ...(ownerState.size === "sm" && theme.typography["text-sm-medium"]),
          ...(ownerState.variant === "linkz-select-rounded" && {
            borderRadius: "999px",
            padding: "16px 24px",
          }),
        }),
      },
    },
    JoySheet: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          ...(ownerState.variant === "linkz-sidebar" && {
            "&": {
              backgroundColor: theme.vars.palette.primary[50],
            },
            [`& .${listItemButtonClasses.root}:hover`]: {
              backgroundColor: `${theme.vars.palette.primary[100]} !important`,
            },
            [`& .${listItemButtonClasses.root}.${listItemButtonClasses.selected}`]:
              {
                backgroundColor: `${theme.vars.palette.primary[400]} !important`,
                color: "white",
              },
            [`& .${listItemButtonClasses.root}.${listItemButtonClasses.selected} p, 
          & .${listItemButtonClasses.root}.${listItemButtonClasses.selected} svg`]:
              {
                // color: theme.vars.palette.primary[500],
                color: "white",
              },
          }),
        }),
      },
    },
    JoyDrawer: {
      styleOverrides: {
        content: ({ ownerState }) => ({
          ...(ownerState.size === "lg" && {
            height: "100%",
            position: "fixed",
          }),
        }),
      },
    },
    JoyFormHelperText: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          ...(ownerState.color === "danger" && {
            color: theme.vars.palette.danger[500],
          }),
        }),
      },
    },
    JoyStepIndicator: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          padding: "4px",
          width: "24px",
          height: "24px",

          ...(ownerState.variant === "linkz-step-finished" && {
            background: theme.vars.palette.neutral[400],
            color: theme.vars.palette.common.white,
          }),

          ...(ownerState.variant === "linkz-step-ongoing" && {
            background: theme.vars.palette.primary[500],
            color: theme.vars.palette.common.white,
          }),

          ...(ownerState.variant === "linkz-step-unfinished" && {
            border: "2px solid " + theme.vars.palette.neutral[400],
            background: "transparent",
            color: theme.vars.palette.neutral[400],
          }),
        }),
      },
    },
    JoyChip: {
      styleOverrides: {
        root: () => ({
          padding: "4px 8px",
          textTransform: "capitalize",
        }),
      },
    },
  },
  typography: {
    "text-xl2-bold": {
      fontSize: "24px",
      lineHeight: "32px",
      fontWeight: 700,
    },
    "text-xl2-semibold": {
      fontSize: "24px",
      lineHeight: "32px",
      fontWeight: 600,
    },
    "text-xl-bold": {
      fontSize: "20px",
      lineHeight: "28px",
      fontWeight: 700,
    },
    "text-xl-semibold": {
      fontSize: "20px",
      lineHeight: "28px",
      fontWeight: 600,
    },
    "text-xl-regular": {
      fontSize: "20px",
      lineHeight: "28px",
      fontWeight: 400,
    },
    "text-lg-bold": {
      fontSize: "18px",
      lineHeight: "28px",
      fontWeight: 700,
    },

    "text-lg-semibold": {
      fontSize: "18px",
      lineHeight: "28px",
      fontWeight: 600,
    },

    "text-lg-regular": {
      fontSize: "18px",
      lineHeight: "28px",
      fontWeight: 400,
    },
    "text-md-bold": {
      fontSize: "16px",
      lineHeight: "24px",
      fontWeight: 700,
    },
    "text-md-semibold": {
      fontSize: "16px",
      lineHeight: "24px",
      fontWeight: 600,
    },
    "text-md-regular": {
      fontSize: "16px",
      lineHeight: "24px",
      fontWeight: 400,
    },
    "text-sm-medium": {
      fontSize: "15px",
      lineHeight: "20px",
      fontWeight: 500,
    },
    "text-sm-regular": {
      fontSize: "15px",
      lineHeight: "20px",
      fontWeight: 400,
    },
    "text-xs-medium": {
      fontSize: "13px",
      lineHeight: "16px",
      fontWeight: 500,
    },
  },
});

/* Variant Declarations */
declare module "@mui/joy/Sheet" {
  interface SheetPropsVariantOverrides {
    "linkz-sidebar": true;
  }
}

declare module "@mui/joy/Input" {
  interface InputPropsVariantOverrides {
    "linkz-input-search": true;
  }
}

declare module "@mui/joy/Select" {
  interface SelectPropsVariantOverrides {
    "linkz-select-rounded": true;
  }
}

declare module "@mui/joy/Autocomplete" {
  interface AutocompletePropsVariantOverrides {
    "linkz-autocomplete-rounded": true;
  }
}

declare module "@mui/joy/Button" {
  interface ButtonPropsVariantOverrides {
    "plain-gray": true;
    "outlined-gray": true;
    "primary-light": true;
    "filter-outlined": true;
    "linkz-filled-primary": true;
    "linkz-filled-primary-rounded": true;
    "linkz-outlined-primary": true;
    "linkz-outlined-danger": true;
    "linkz-outlined-primary-rounded": true;
    "linkz-tonal-primary": true;
    "linkz-tonal-primary-rounded": true;
    "linkz-tonal-danger": true;
    "linkz-tonal-danger-rounded": true;
  }
}

declare module "@mui/joy/StepIndicator" {
  interface StepIndicatorPropsVariantOverrides {
    "linkz-step-finished": true;
    "linkz-step-ongoing": true;
    "linkz-step-unfinished": true;
  }
}

declare module "@mui/joy/styles" {
  interface Palette {
    others: {
      order_sales: string;
      order_sales_text: string;
      order_purchase: string;
      order_purchase_text: string;
      order_catalogue: string;
      order_catalogue_text: string;
      order_draft: string;
      disabled: string;
      success: string;
      neutral_dark: string;
      neutral_light: string;
      secondary_text: string;
    };
  }
  interface TypographySystemOverrides {
    "text-xl2-bold": true;
    "text-xl2-semibold": true;
    "text-xl-bold": true;
    "text-xl-semibold": true;
    "text-xl-regular": true;
    "text-lg-bold": true;
    "text-lg-semibold": true;
    "text-lg-regular": true;
    "text-md-bold": true;
    "text-md-semibold": true;
    "text-md-regular": true;
    "text-sm-medium": true;
    "text-sm-regular": true;
    "text-xs-medium": true;
  }
}
