import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormLabel,
  Input,
  Option,
  Select,
  Stack,
  Table,
  Typography,
} from "@mui/joy";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getActiveBusiness, useAuthStore } from "../../store/session";
import { ItemService } from "../../services/item.service";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { CreateItemType, ItemType } from "../../types/item";
import useContainer from "../../pages/Inventory/useContainer";
import NotFound from "../../pages/NotFound/NotFound";
import UploadImageBtn from "./Items/UploadImageBtn";
import { IfElse } from "../Condition";
import { theme } from "../Theme";
import TablePagination from "../../pages/Inventory/components/TablePagination";
import {
  ChevronDownIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/24/outline";
import { validateDuplicateSku } from "../../utils/excel";

const style = {
  borderRadius: "32px",
  borderColor: "transparent",
  outline: "none",
};
export default function AddCatalogueItems() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname;
  const isFullView = currentPath.includes("full-view");

  const [searchParams, _] = useSearchParams();

  const [flexiColumns, setFlexiColumns] = useState<string[]>();
  const [sortDirection, setSortDirection] = useState<"asc" | "desc">("desc");
  const cache = localStorage.getItem(
    searchParams.get("isUpdate") ? "edit-catalog" : "new-catalog"
  ) as string;
  const [selectedItems, setSelectedItems] = useState<string[]>(
    JSON.parse(cache).items
  );
  const [categories, setCategories] = useState<string[]>([]);
  const [selectedFilter, setSelectedFilter] = useState<string>("");
  const [_showDuplicateModal, setShowDuplicateModal] = useState<{
    show: boolean;
    useDatabaseSku: boolean;
    onClick: (data: ItemType[]) => void;
  }>({ show: false, useDatabaseSku: true, onClick: () => {} });
  const [_payload, setPayload] = useState<string[]>([]);
  const [_selectedQuantityItem, setSelectedQuantityItem] = useState<
    { show: boolean; item: ItemType } | undefined
  >();
  const [_databaseSku, setDatabaseSku] = useState<string[]>([]);
  const [_duplicateSKU, setDuplicateSKU] = useState<{
    processedData: CreateItemType[];
    duplicates: string[];
  }>();
  const {
    handlePaging,
    handleUpdateSku,
    handleItemFlexiCols,
    fetchInventoryItems,
    setIsLoading,
    setShowAlert,
    setAlertMessage,
    pagination,
    setPagination,
    inventoryItems,
    setInventoryItems,
    searchTerm,
    setSearchTerm,
    setShowToast,
  } = useContainer();

  const { session } = useAuthStore.getState();

  const initiateInventoryItems = useCallback((businessId: string) => {
    ItemService.getInventoryItems({
      businessId: businessId,
      paging: true,
      limit: 50,
      offset: 0,
      sortBy: "updatedAt",
    })
      .then((itemRes) => {
        setShowAlert(false);
        setInventoryItems(itemRes.data);
        if (itemRes.pagination) {
          setPagination(itemRes.pagination);
        }
        return ItemService.getInventoryItemField({
          fields: ["flexiColumns", "inventoryType"],
          businessId,
        });
      })
      .then((filterRes) => {
        const { allFlexiColumns } = handleItemFlexiCols(filterRes);
        setFlexiColumns(allFlexiColumns);

        const cats: string[] = [];
        for (const element of filterRes) {
          cats.push(element.inventoryType);
        }

        const uniqueValues = cats.filter(
          (item, index) => cats.indexOf(item) === index
        );
        setCategories(uniqueValues);

        return ItemService.getInventoryItemField({
          fields: ["id", "sku", "productName"],
          businessId,
          isDistinct: false,
        });
      })
      .then((skuRes) => {
        const allSku = skuRes.map((item: ItemType) => item.sku);
        setDatabaseSku(allSku);
        const validatedDuplicates = validateDuplicateSku(
          skuRes as CreateItemType[]
        );

        if (validatedDuplicates.length > 0 && verifySKUReminder()) {
          setDuplicateSKU({
            processedData: skuRes as CreateItemType[],
            duplicates: validatedDuplicates,
          });
          setShowDuplicateModal({
            show: true,
            useDatabaseSku: false,
            onClick: (data: ItemType[]) => {
              setIsLoading(true);
              handleUpdateSku(businessId, data);
            },
          });
          localStorage.setItem(
            "duplicateSKUReminder",
            new Date().getTime().toString()
          );
        }
      })
      .catch((err) => console.error(err))
      .finally(() => setIsLoading(false));
  }, []);

  const query = searchParams.get("q");
  const isCreate = searchParams.get("isCreate");

  useEffect(() => {
    const businessId = getActiveBusiness()?.id;
    if (businessId) {
      initiateInventoryItems(businessId);
      if (query && !searchTerm) {
        fetchInventoryItems({
          businessId,
          paging: true,
          limit: pagination.limit,
          offset: 0,
          sortBy: "updatedAt",
          sortDirection: sortDirection,
          searchTerm: query,
          inventoryType: selectedFilter,
        });
        const newPagination = pagination;
        newPagination.limit = 10;
        setPagination(newPagination);
        handlePaging("first");
      }
    }
  }, [getActiveBusiness()?.id]);

  useEffect(() => {
    const businessId = getActiveBusiness()?.id;

    if (businessId) {
      if (query && !searchTerm) {
        return;
      }
      fetchInventoryItems({
        businessId,
        paging: true,
        limit: pagination.limit,
        offset: 0,
        sortBy: "updatedAt",
        sortDirection: sortDirection,
        searchTerm: searchTerm,
        inventoryType: selectedFilter,
      });
      const newPagination = pagination;
      newPagination.limit = 10;
      setPagination(newPagination);
      handlePaging("first");
    }
  }, [searchTerm, selectedFilter, sortDirection]);

  useEffect(() => {
    const category = searchParams.get("category");
    const _sortDirection = searchParams.get("sortDirection");
    if (category) {
      setSelectedFilter(category);
    }
    if (_sortDirection) {
      setSortDirection(_sortDirection as any);
    }
    if (query) {
      setSearchTerm(query);
    }
    return () => {};
  }, []);

  if (!session) {
    return <NotFound />;
  }

  const verifySKUReminder = () => {
    const reminder = localStorage.getItem("duplicateSKUReminder");

    if (reminder) {
      const currentTime = new Date().getTime();
      const hoursDifference = (currentTime - +reminder) / (1000 * 60 * 60);

      return hoursDifference > 24;
    }

    return true;
  };

  const handleSelectAll = () => {
    const businessId = getActiveBusiness()?.id;

    if (businessId) {
      ItemService.getInventoryItemField({
        fields: ["id"],
        businessId,
        inventoryType: selectedFilter,
        searchTerm,
      })
        .then((res) => {
          const ids: string[] = res.map((i: { id: string }) => i.id);
          setSelectedItems(ids);
        })
        .catch((error) => console.error(error));
    }
  };

  const handleChecked = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    if (event.target.checked) {
      setSelectedItems((prev) => [...prev, inventoryItems[index].id]);
    } else {
      setSelectedItems(
        selectedItems.filter((i) => i !== inventoryItems[index].id)
      );
    }
  };

  const handledSearch = () => {
    const businessId = getActiveBusiness()?.id;

    if (businessId) {
      if (query && !searchTerm) {
        return;
      }
      fetchInventoryItems({
        businessId,
        paging: true,
        limit: pagination.limit,
        offset: 0,
        sortBy: "updatedAt",
        sortDirection: sortDirection,
        searchTerm: searchTerm,
        inventoryType: selectedFilter,
      });
    }
  };
  const { tableData } = handleItemFlexiCols(inventoryItems, flexiColumns);
  return (
    <Box sx={style} className="hide-scrollbar">
      <Typography
        // textAlign={"center"}
        fontWeight={600}
        fontSize={24}
        id="modal-modal-title"
        sx={{
          color: "#000",
          marginTop: { xs: 0, md: 0 },
          // marginBottom: 2,
        }}
      >
        {t("catalog.inventory.createCatalog.chooseitemsforcataloguetitle")}
      </Typography>
      <Typography
        fontWeight={400}
        fontSize={14}
        sx={{
          color: "#000",
          marginTop: { xs: 0, md: 0 },
          marginBottom: 4,
        }}
      >
        {t("catalog.inventory.createCatalog.chooseitemsTitleTip")}
      </Typography>
      <Box
        sx={{
          // overflow: "auto",
          borderWidth: "1px",
          margin: "10px 0px",
          padding: "10px 20px",
        }}
      >
        {/* toolbar */}
        <Stack
          direction={{ xs: "column", md: "row" }}
          gap={{ xs: "10px", md: "10px" }}
          justifyContent={"flex-start"}
          // alignItems={"flex-start"}
          width={{ xs: "90vw", md: "100%" }}
          margin="10px 0px"
        >
          <FormControl sx={{ width: { xs: "90%", lg: "45%" } }}>
            <FormLabel>Category</FormLabel>
            <Select
              onChange={(
                _event: React.SyntheticEvent | null,
                newValue: string | null
              ) => {
                if (newValue || newValue === "") {
                  const newParams = new URLSearchParams(searchParams);

                  if (newValue === "") {
                    newParams.delete("category", encodeURIComponent(newValue));
                  } else {
                    newParams.set("category", encodeURIComponent(newValue));
                  }

                  // setSearchParams(newParams);
                  setSelectedFilter(newValue);
                }
              }}
              slotProps={{ button: { sx: { whiteSpace: "nowrap" } } }}
              size="sm"
              defaultValue={""}
              sx={{
                // minWidth: "100%",
                paddingY: "10px",
                paddingX: "16px",
                fontWeight: 600,
                gap: "8px",
                color: theme.colorSchemes.light.palette.others.neutral_dark,
                borderColor:
                  theme.colorSchemes.light.palette.others.neutral_dark,
              }}
              indicator={<ChevronDownIcon width={18} height={18} />}
            >
              <Option value={""}>{t("catalog.inventory.allFilterBtn")}</Option>
              {categories.map((cat) => (
                <Option key={cat} value={cat}>
                  {cat}
                </Option>
              ))}
            </Select>
          </FormControl>
          {/* searchbar */}
          <FormControl sx={{ width: { xs: "90%", lg: "45%" } }}>
            <FormLabel>Product Name</FormLabel>
            <Input
              defaultValue={searchTerm}
              // variant="linkz-input-search"
              size="sm"
              name="searchFormData.search"
              placeholder={t("catalog.inventory.searchPlaceholder")}
              fullWidth
              sx={{
                width: "100%",
              }}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                const newParams = new URLSearchParams(searchParams);
                const query = event.target.value;
                if (query) {
                  newParams.set("q", query);
                } else {
                  newParams.delete("q");
                }
                // setSearchParams(newParams);
                setSearchTerm(event.target.value);
              }}
            />
          </FormControl>

          <FormControl size="sm" sx={{ width: "10%", marginTop: "18px" }}>
            <FormLabel></FormLabel>
            <Button
              onClick={handledSearch}
              size="sm"
              startDecorator={<MagnifyingGlassIcon width={15} height={15} />}
              variant="outlined"
              className="rounded-md"
            >
              {t("catalog.inventory.searchBtn")}
            </Button>
          </FormControl>
        </Stack>
        {/* items table */}
        <Box
          sx={{
            width: { xs: "90vw", lg: isFullView ? "90vw" : "100%" },
            height: "30%",
            maxHeight: { lg: "27.5rem" },
            overflow: "auto",
            borderWidth: "2px",
            position: "relative",
            margin: "10px 0px",
          }}
        >
          <Table
            borderAxis="x"
            sx={{ "& th": { textAlign: "center" }, minWidth: "100%" }}
          >
            <thead>
              <tr key={"header"}>
                <th key={"checkbox"} className="w-12">
                  <Checkbox
                    size="sm"
                    onChange={(event) => {
                      if (event.target.checked) {
                        handleSelectAll();
                      } else {
                        setSelectedItems([]);
                      }
                    }}
                  />
                </th>
                {/* <th key={"index"} className="w-12 ">
                  {t("catalog.items.no")}
                </th> */}
                <th key={"picture"} className="w-24">
                  {t("catalog.items.picture")}
                </th>
                <th key={"sku"} className="w-36">
                  {t("catalog.items.sku")}
                </th>
                <th key={"productName"} className="w-48">
                  {t("catalog.items.productName")}
                </th>
                <th key={"quantity"} className="w-24">
                  {t("catalog.items.quantity")}
                </th>
                <th key={"currency"} className="w-24">
                  {t("catalog.items.currency")}
                </th>
                <th key={"price"} className="w-36">
                  {t("catalog.items.price")}
                </th>
                <th key={"unit_of_measure"} className="w-24">
                  {t("catalog.items.uom")}
                </th>
                <th key={"inventoryType"} className="w-36">
                  {t("catalog.items.category")}
                </th>
                {flexiColumns?.map((col) => (
                  <th key={col} className="w-28">
                    {col}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {tableData.map((d, index) => {
                const isSelected = selectedItems.includes(
                  inventoryItems[index].id
                );
                return (
                  <tr
                    key={inventoryItems[index].id}
                    className={`${isSelected ? "bg-primary-50 " : ""}`}
                  >
                    <td className="text-center">
                      <Checkbox
                        size="sm"
                        checked={isSelected}
                        onChange={(e) => handleChecked(e, index)}
                      />
                    </td>
                    {/* <td className="text-center">
                      {(pagination.current - 1) * pagination.limit +
                        (index + 1)}
                    </td> */}
                    <td className="text-center">
                      <UploadImageBtn
                        item={inventoryItems[index]}
                        setIsLoading={setIsLoading}
                        setShowToast={setShowToast}
                        setAlertMessage={setAlertMessage}
                      />
                    </td>
                    {d.map((i, idx) => (
                      <td
                        key={
                          inventoryItems[index].id +
                          i.toString() +
                          "_col" +
                          index +
                          Math.random() * 695622
                        }
                        className="text-center break-all"
                      >
                        <IfElse
                          condition={idx === 2}
                          ifBlock={
                            <IfElse
                              condition={
                                Number(i) <=
                                (inventoryItems[index].lowStockQuantity || 0)
                              }
                              ifBlock={
                                <button
                                  onClick={() => {
                                    setSelectedQuantityItem({
                                      show: true,
                                      item: inventoryItems[index],
                                    });
                                  }}
                                  className="bg-[#F7D5AC] w-fit h-fit mx-auto rounded-[4px] p-1"
                                >
                                  {i}
                                </button>
                              }
                              elseBlock={<>{i}</>}
                            />
                          }
                          elseBlock={<>{i}</>}
                        />
                      </td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Box>
      </Box>
      {/* pagination */}
      {/* TODO: make this common component reusable */}

      <TablePagination
        handlePaging={handlePaging}
        pagination={pagination}
        setPagination={setPagination}
        withLimit={false}
        justifyContent="flex-end"
      />

      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
        mt={4}
      >
        <Box
          sx={{
            position: "sticky",
            bottom: 0,
            left: 0,
            width: "100%",
            paddingY: "1.25rem",
            paddingX: "1.25rem",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            transition: "all 0.1s ease-in-out",
            overflow: "hidden",
          }}
        >
          <Typography fontWeight={700} fontSize={14}>
            {selectedItems.length}{" "}
            <span className="font-medium">
              {t("catalog.inventory.batchEdit.itemsSelected")}
            </span>
          </Typography>
        </Box>
        <Stack direction={"row"} justifyContent={"right"} spacing={1}>
          <Button
            type="button"
            variant="plain-gray"
            className="ml-auto rounded-md"
            onClick={() => navigate(-1)}
          >
            {t("commons.cancelBtn")}
          </Button>
          <div
            className="hidden md:block"
            // onMouseOver={() =>
            //   setShowTooltip(selectedItems.length == 0)
            // }
            // onMouseLeave={() => setShowTooltip(false)}
          >
            {/* <Tooltip
                placement="right"
                variant="outlined"
                arrow
                open={showTooltip}
                sx={{ maxWidth: 350 }}
                title={
                  <div className="p-4 space-y-2">
                    <Typography level="title-sm">
                      {t("catalog.inventory.tooltipTitle")}
                    </Typography>
                    <div className="flex gap-2 items-center">
                      <WbIncandescentOutlined
                        color="primary"
                        className="rotate-180"
                      />
                      <Typography level="body-sm">
                        {t("catalog.inventory.tooltipBody")}
                      </Typography>
                    </div>
                  </div>
                }
              > */}
            <Button
              disabled={!(selectedItems.length > 0)}
              className="min-w-[9.2rem] whitespace-nowrap rounded-md"
              onClick={() => {
                const items = [];
                const itemInfos: ItemType[] = [];
                for (let i = 0; i < selectedItems.length; i++) {
                  const item = inventoryItems.find(
                    (inventoryItem) => inventoryItem.id === selectedItems[i]
                  );

                  if (item) {
                    items.push(item.id);
                    itemInfos.push(item);
                  }
                }

                setPayload(items);

                const newCatalog = JSON.parse(cache as string);
                const newCache = {
                  ...newCatalog,
                  items,
                  itemInfos,
                };
                const catalogId = newCatalog.catalogId;
                localStorage.setItem(
                  searchParams.get("isUpdate") ? "edit-catalog" : "new-catalog",
                  JSON.stringify(newCache)
                );
                navigate(
                  isCreate == "true"
                    ? `/client/catalogues/create?type=${newCatalog.catalogType}`
                    : `/client/catalogues/${catalogId}/edit`
                );
                // setShowCreateModal(true);
              }}
            >
              {t("catalog.inventory.createCatalog.confirmBtn")}
            </Button>
            {/* </Tooltip> */}
          </div>
        </Stack>
      </Stack>
    </Box>
  );
}
