import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import {
  Button,
  Chip,
  Input,
  List,
  ListItem,
  Sheet,
  Stack,
  Tab,
  TabList,
  TabPanel,
  Tabs,
  Typography,
  tabClasses,
} from "@mui/joy";
import { debounce } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroller";
import { Link, useSearchParams } from "react-router-dom";
import ListUnavailable from "../../components/Catalogue/ListUnavailable";
import { theme } from "../../components/Theme";
import { OrderService } from "../../services/order.service";
// import { SupplierService } from "../../services/supplier.service";
import { getActiveBusiness, useAuthStore } from "../../store/session";
import useContainer from "./useContainer";
import { ShoppingCartIcon } from "@heroicons/react/24/outline";
import dayjs from "dayjs";
import { getCartItems } from "../../utils/cart";

function ListSuppliers() {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const orderId = searchParams.get("order");
  const query = searchParams.get("query");

  const unsavedOrderData = localStorage.getItem(`unsavedOrderData_${orderId}`);
  // const qstatus = searchParams.get("status");
  const { session } = useAuthStore.getState();

  // const [_selectedFilter, setSelectedFilter] = useState<string>(qstatus ?? "");

  const [sellerId, setSellerId] = useState(
    unsavedOrderData ? JSON.parse(unsavedOrderData).sellerIds?.businessId : null
  );
  const [selectedTab, setSelectedTab] = useState("public");
  // const loaderRef = useRef(null);
  // const statusFilter = ["Invited", "Last Seen"];
  const {
    fetchCatalogs,
    catalogs,
    setCatalogs,
    pagination,
    // setPagination,
    setIsLoading,
    hasMore,
    // setHasMore,
  } = useContainer();

  // const initiateAllCatalogs = useCallback(
  //   ({
  //     businessId,
  //     sellerId = null,
  //     catalogType = "PUBLIC",
  //   }: {
  //     businessId: string;
  //     sellerId?: string | null;
  //     catalogType?: string;
  //   }) => {
  //     // console.log(`fetching catalogue scope = ${searchTerm}`);
  //     setIsLoading(true);
  //     SupplierService.getAllCatalogs({
  //       businessId,
  //       sellerId,
  //       paging: true,
  //       limit: 10,
  //       offset: 0,
  //       catalogType,
  //     })
  //       .then((res) => {
  //         console.log("RESPONSE LOADMORE", res);
  //         setCatalogs(res.data);

  //         if (res.pagination) {
  //           setPagination(res.pagination);
  //         }

  //         if (res.data.length === 0) {
  //           setHasMore(false);
  //         }

  //         console.log("LOADMORE CATALOGS", catalogs);
  //       })
  //       .catch((error: any) => console.error(error))
  //       .finally(() => setIsLoading(false));
  //   },
  //   []
  // );

  // useEffect(() => {
  //   const businessId = getActiveBusiness()?.id;
  //   if (!businessId) return;
  //   if (query && !searchTerm) {
  //     // console.log("cancelling");
  //     return;
  //   }

  //   if (searchTerm || searchTerm === "") {
  //     fetchCatalogs({
  //       businessId: getActiveBusiness()?.id ?? "",
  //       sellerId: sellerId,
  //       limit: 10,
  //       offset: 0,
  //       catalogType: selectedTab.toUpperCase(),
  //       searchTerm: searchTerm,
  //       isReset: true,
  //     });
  //   }
  // }, [searchTerm]);

  // useEffect(() => {
  //   const observer = new IntersectionObserver((entries) => {
  //     const target = entries[0];
  //     if (target.isIntersecting && hasMore && catalogs.length > 0) {
  //       fetchCatalogs({
  //         businessId: getActiveBusiness()?.id ?? "",
  //         sellerId: unsavedOrderData
  //           ? JSON.parse(unsavedOrderData).sellerIds.businessId
  //           : null,
  //         limit: 10,
  //         offset: pagination.limit * pagination.current,
  //         catalogType: selectedTab.toUpperCase(),
  //       });
  //     }
  //   });

  //   if (loaderRef.current) {
  //     observer.observe(loaderRef.current);
  //   }

  //   return () => {
  //     if (loaderRef.current) {
  //       observer.unobserve(loaderRef.current);
  //     }
  //   };
  // }, [fetchCatalogs]);

  useEffect(() => {
    const businessId = getActiveBusiness()?.id;
    if (!businessId) return;

    setIsLoading(true);
    console.log("UNSAVED ORDER DATA", unsavedOrderData);

    if (orderId && !unsavedOrderData && !sellerId) {
      OrderService.getOrder(orderId)
        .then((res) => {
          console.log("RES SELLER ID", res);
          setSellerId(res.seller.id ?? null);
          fetchCatalogs({
            businessId: getActiveBusiness()?.id ?? "",
            sellerId: res.seller.id,
            limit: 10,
            offset: 0,
            isReset: true,
            searchTerm: query || "",
            catalogType: selectedTab.toUpperCase(),
          });
          // initiateAllCatalogs({
          //   businessId: getActiveBusiness()?.id ?? "",
          //   sellerId: sellerId,
          //   catalogType: selectedTab.toUpperCase(),
          // });
        })
        .catch((error) => {
          console.error(error);
          throw error;
        })
        .finally(() => setIsLoading(false));
    } else {
      fetchCatalogs({
        businessId: getActiveBusiness()?.id ?? "",
        sellerId: sellerId,
        limit: 10,
        offset: 0,
        isReset: true,
        searchTerm: query || "",
        catalogType: selectedTab.toUpperCase(),
      });
      // initiateAllCatalogs({
      //   businessId: getActiveBusiness()?.id ?? "",
      //   sellerId: sellerId,
      //   catalogType: selectedTab.toUpperCase(),
      // });
    }
  }, [orderId, unsavedOrderData, selectedTab, query]);

  const getCartItemCount = (id: string) => {
    const accountId =
      searchParams.get("cart") === "wildcart"
        ? "wildcart"
        : session?.account.id || "wildcart";

    const cart = getCartItems(id, accountId, "local");

    if (!cart) {
      return "";
    }

    return cart?.items.length > 0 ? `(${cart?.items.length})` : "";
  };

  const handleChange = (
    _event: React.SyntheticEvent | null,
    value: string | number | null
  ) => {
    if (typeof value === "string") {
      setSelectedTab(value);
      setCatalogs([]);
      fetchCatalogs({
        businessId: getActiveBusiness()?.id ?? "",
        sellerId: sellerId,
        limit: 10,
        offset: 0,
        isReset: true,
        catalogType: value.toUpperCase(),
      });
    }
  };

  const handleSearchChange = useCallback(
    debounce((query) => {
      if (query) {
        searchParams.set("query", query);
      } else {
        searchParams.delete("query");
      }
      setSearchParams(searchParams);
      // setSearchTerm(query);
    }, 300),
    []
  );

  const CatalogInfo = ({ title, value }: { title: string; value: string }) => {
    return (
      <div className=" flex justify-between items-center">
        <div className="flex justify-start items-center">
          <div className="flex justify-between items-center gap-5">
            <span
              className={`text-xs text-[${theme.colorSchemes.light.palette.others.secondary_text}] w-32`}
            >
              {title}
            </span>
            <span
              className={`text-xs [${theme.colorSchemes.light.palette.others.secondary_text}]`}
            >
              {":"}
            </span>
          </div>
          <span className="text-xs font-semibold">{value}</span>
        </div>
      </div>
    );
  };

  const SuppliersView = () => {
    return (
      <>
        {/* <Input
          variant="linkz-input-search"
          size="sm"
          name="searchFormData.search"
          placeholder={t("catalog.searchItemPlaceholder")}
          startDecorator={<SearchOutlinedIcon />}
          sx={{ flexGrow: 1, marginTop: 6, width: {xs: "100%", lg: "50%"} }}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setSearchTerm(event.target.value);
          }}
        /> */}
        <List sx={{ marginTop: 3 }}>
          <InfiniteScroll
            pageStart={0}
            // initialLoad
            useWindow={false}
            loadMore={(_page: number) => {
              console.log("LOADMORE TRIGGERED", pagination);
              fetchCatalogs({
                businessId: getActiveBusiness()?.id ?? "",
                sellerId: sellerId,
                limit: 10,
                isReset: false,
                offset: pagination.limit * pagination.current,
                searchTerm: query || "",
                catalogType: selectedTab.toUpperCase(),
              });
            }}
            hasMore={hasMore}
            loader={
              <div className="loader" key={0}>
                Loading ...
              </div>
            }
          >
            {catalogs.map((cat) => {
              return (
                <ListItem
                  key={cat.id}
                  sx={{
                    paddingX: 0,
                  }}
                >
                  <div
                    className={`md:py-2 py-6 px-6 md:px-2.5 lg:py-4 my-1 lg:my-2 lg:flex algin-middle w-full rounded-lg lg:rounded-none border lg:border-t-0 lg:border-l-0 lg:border-r-0 lg:border-b-1 border-[${theme.colorSchemes.light.palette.others.disabled}]`}
                  >
                    <div className=" mb-0 md:mb-2">
                      <div className="flex flex-row justify-between items-center md:flex-col  md:justify-start md:items-start">
                        <div className=" flex flex-col md:flex-row md:justify-start md:items-center gap-1 md:gap-3 mb-2">
                          {cat?.periodStartDate && cat?.periodEndDate ? (
                            dayjs().isAfter(dayjs(cat.periodEndDate)) ? (
                              <Chip
                                color="danger"
                                sx={{
                                  borderRadius: 10,
                                  paddingY: 0.3,
                                  paddingX: 0.7,
                                  border: 1,
                                  backgroundColor: "#F8D7DA",
                                  borderColor: "#D32F2F",
                                }}
                              >
                                Expired
                              </Chip>
                            ) : (
                              <Chip
                                color="success"
                                sx={{
                                  borderRadius: 10,
                                  paddingY: 0.3,
                                  paddingX: 0.7,
                                  border: 1,
                                  backgroundColor: "#CFE5DA",
                                  borderColor: "#408565",
                                }}
                              >
                                Active
                              </Chip>
                            )
                          ) : (
                            <Chip
                              color="success"
                              sx={{
                                borderRadius: 10,
                                paddingY: 0.3,
                                paddingX: 0.7,
                                border: 1,
                                backgroundColor: "#CFE5DA",
                                borderColor: "#408565",
                              }}
                            >
                              Active
                            </Chip>
                          )}
                          <Typography fontSize={18} fontWeight={600}>
                            {cat?.business.companyName}
                          </Typography>
                        </div>
                        {/* <Button
                          variant="outlined"
                          size="sm"
                          className="md:hidden text-sm min-w-8 p-2 border-danger-200  text-nowrap lg:text-base rounded-md"
                          onClick={() => {
                            setSelectedCatalog(cat.id);
                            setConfirmDelete(true);
                          }}
                        >
                          <DeleteOutline color="error" className="w-8 h-8" />
                        </Button> */}
                      </div>
                      <div className="flex flex-col md:flex-row  justify-start  lg:gap-11">
                        <div className="flex flex-col justify-start  items-start">
                          {cat.catalogName && (
                            <CatalogInfo
                              title={t("catalog.createCatalog.catalogueName")}
                              value={cat.catalogName}
                            />
                          )}
                          {cat.currency && (
                            <CatalogInfo
                              title={t("catalog.catalogCurrency")}
                              value={cat.currency}
                            />
                          )}
                          {cat.itemsCount && (
                            <CatalogInfo
                              title={t("catalog.catalogTotalItems")}
                              value={`${cat.itemsCount} Items`}
                            />
                          )}
                        </div>
                        <div className="flex flex-col items-start">
                          {/* {cat?.periodStartDate && cat.periodEndDate && ( */}
                          <CatalogInfo
                            title={t("catalog.catalogPeriod")}
                            value={
                              cat.periodStartDate && cat.periodEndDate
                                ? `${dayjs(cat.periodStartDate).format(
                                    "YYYY-MM-DD"
                                  )} ~ ${dayjs(cat.periodEndDate).format(
                                    "YYYY-MM-DD"
                                  )}`
                                : "-"
                            }
                          />
                          {/* )} */}

                          {cat.updatedAt && (
                            <CatalogInfo
                              title={t("catalog.catalogLastUpdated")}
                              value={dayjs(cat.updatedAt).format("YYYY-MM-DD")}
                            />
                          )}
                        </div>
                      </div>
                    </div>

                    <div className=" flex md:items-center justify-end md:gap-3 md:ml-auto">
                      <Link
                        to={
                          orderId
                            ? `/client/orders/${orderId}`
                            : session?.accessToken
                            ? `/client/cart/${cat.id}/items`
                            : `/public/cart/${cat.id}/items`
                        }
                        className="lg:mx-auto lg:ml-auto lg:mr-0"
                      >
                        <Button
                          variant="outlined"
                          startDecorator={
                            <ShoppingCartIcon
                              style={{
                                width: 18,
                                height: 18,
                                marginRight: "0.5rem",
                              }}
                            />
                          }
                          className=" ml-3 md:ml-0 mt-4 border-[#499873] min-w-32 md:min-w-24 text-sm lg:mt-0 text-nowrap lg:text-base rounded-md"
                        >
                          {t("catalog.cart")}
                          {getCartItemCount(cat.id)}
                        </Button>
                      </Link>
                      <Link
                        to={`/client/suppliers/${cat.id}/items${
                          orderId ? `?order=${orderId}` : ""
                        }`}
                        // to={`/client/catalogues/inventory/${cat.id}/items`}
                        // className="lg:mx-auto lg:ml-auto lg:mr-0"
                      >
                        <Button
                          variant="solid"
                          className=" ml-3 md:ml-0 mt-4 min-w-44 md:min-w-24 text-sm lg:mt-0 text-nowrap lg:text-base rounded-md"
                        >
                          {t("catalog.viewCatalogBtn")}
                        </Button>
                      </Link>
                    </div>
                  </div>
                </ListItem>
              );
            })}
          </InfiniteScroll>
        </List>
        {/* <div ref={loaderRef} className="flex justify-center w-full">
          {isLoading && "Loading..."}
        </div> */}
      </>
    );
  };

  return (
    <Sheet sx={{ backgroundColor: "transparent" }}>
      <Tabs
        aria-label="Basic tabs"
        value={selectedTab}
        onChange={handleChange}
        className="w-[90vw] md:w-[75vw]"
        sx={{
          backgroundColor: "transparent",
          marginTop: 4,
        }}
      >
        <TabList
          sx={{
            [`&& .${tabClasses.root}`]: {
              "&:hover": {
                bgcolor: "transparent",
              },
              [`&.${tabClasses.selected}`]: {
                "&::after": {
                  bgcolor: "primary.500",
                  borderTopLeftRadius: 3,
                  borderTopRightRadius: 3,
                  height: 2,
                },
                color: "primary.plainColor",
              },
              bgcolor: "transparent",
              flex: "initial",
              paddingY: 2,
            },
          }}
        >
          <Tab
            value={"public"}
            key={"public"}
            sx={{
              fontWeight: "600",
              whiteSpace: "nowrap",
              minWidth: 100,
              flex: "none",
              scrollSnapAlign: "start",
              flexDirection: "column",
            }}
          >
            {t("catalog.catalogType.public")}
          </Tab>
          <Tab
            value={"private"}
            key={"private"}
            sx={{
              fontWeight: "600",
              whiteSpace: "nowrap",
              minWidth: 150,
              flex: "none",
              scrollSnapAlign: "start",
              flexDirection: "column",
            }}
          >
            {t("catalog.catalogType.private")}
          </Tab>
        </TabList>

        {/* default public empty tab */}
        <TabPanel value={"public"} key={"public"} className="px-0 py-0 lg:px-6">
          <Stack direction={{ xs: "column", lg: "row" }} spacing={3} mt={4}>
            <Input
              defaultValue={query ?? ""}
              variant="linkz-input-search"
              name="searchFormData.search"
              size="sm"
              placeholder={t("catalog.searchCatalogPlaceholder")}
              startDecorator={<SearchOutlinedIcon />}
              sx={{
                flexGrow: 0,
                marginTop: 6,
                width: { xs: "100%", lg: "41%" },
              }}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                const query = event.target.value;
                handleSearchChange(query);
              }}
            />
            {/* <Select
              value={qstatus ?? ""}
              onChange={(
                _event: React.SyntheticEvent | null,
                newValue: string | null
              ) => {
                if (newValue || newValue === "") {
                  const newParams = new URLSearchParams(searchParams);

                  if (newValue === "") {
                    newParams.delete("status");
                  } else {
                    newParams.set("status", encodeURIComponent(newValue));
                  }
                  setSearchParams(newParams);
                  setSelectedFilter(newValue);
                }
              }}
              slotProps={{ button: { sx: { whiteSpace: "nowrap" } } }}
              size="sm"
              defaultValue={qstatus ?? ""}
              sx={{
                width: { xs: "40%", lg: "15%" },
                display: { sx: "none", md: "flex" },
                paddingY: "10px",
                paddingX: "16px",
                fontWeight: 600,
                gap: "8px",
                color: theme.colorSchemes.light.palette.others.neutral_dark,
                borderColor:
                  theme.colorSchemes.light.palette.others.neutral_dark,
              }}
              indicator={<ChevronDownIcon width={18} height={18} />}
            >
              <Option value={""}>{t("catalog.filter")}</Option>
              {statusFilter.map((s) => (
                <Option key={s} value={s}>
                  {s}
                </Option>
              ))}
            </Select> */}
          </Stack>
          {catalogs.length > 0 ? (
            <SuppliersView />
          ) : (
            <ListUnavailable
              message={t("catalog.emptySupplierCatalogPrompt")}
            />
          )}

          {/* ? (
            ) : (
              <ListUnavailable
                message={t("catalog.emptySupplierCatalogPrompt")}
              />
            )
          ) : (
            <>Loading...</>
          )} */}
        </TabPanel>

        {/* default private empty tab */}
        <TabPanel
          value={"private"}
          key={"private"}
          className="px-0 py-0 lg:px-6"
        >
          <Stack direction={{ xs: "column", lg: "row" }} spacing={3} mt={4}>
            <Input
              defaultValue={query ?? ""}
              variant="linkz-input-search"
              name="searchFormData.search"
              placeholder={t("catalog.searchCatalogPlaceholder")}
              startDecorator={<SearchOutlinedIcon />}
              size="sm"
              sx={{
                flexGrow: 0,
                marginTop: 6,
                width: { xs: "100%", lg: "41%" },
              }}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                const query = event.target.value;
                handleSearchChange(query);
              }}
            />
            {/* <Select
              value={qstatus ?? ""}
              onChange={(
                _event: React.SyntheticEvent | null,
                newValue: string | null
              ) => {
                if (newValue || newValue === "") {
                  const newParams = new URLSearchParams(searchParams);

                  if (newValue === "") {
                    newParams.delete("status");
                  } else {
                    newParams.set("status", encodeURIComponent(newValue));
                  }
                  setSearchParams(newParams);
                  setSelectedFilter(newValue);
                }
              }}
              slotProps={{ button: { sx: { whiteSpace: "nowrap" } } }}
              size="sm"
              defaultValue={qstatus ?? ""}
              sx={{
                width: { xs: "40%", lg: "15%" },
                display: { sx: "none", md: "flex" },
                paddingY: "10px",
                paddingX: "16px",
                fontWeight: 600,
                gap: "8px",
                color: theme.colorSchemes.light.palette.others.neutral_dark,
                borderColor:
                  theme.colorSchemes.light.palette.others.neutral_dark,
              }}
              indicator={<ChevronDownIcon width={18} height={18} />}
            >
              <Option value={""}>{t("catalog.filter")}</Option>
              {statusFilter.map((s) => (
                <Option key={s} value={s}>
                  {s}
                </Option>
              ))}
            </Select> */}
          </Stack>
          {catalogs.length > 0 ? (
            <SuppliersView />
          ) : (
            <ListUnavailable
              message={t("catalog.emptySupplierCatalogPrompt")}
            />
          )}

          {/* {!isLoading ? (
            catalogs.length > 0 ? (
              <SuppliersView />
            ) : (
              <ListUnavailable
                message={t("catalog.emptySupplierCatalogPrompt")}
              />
            )
          ) : (
            <>Loading...</>
          )} */}
        </TabPanel>
      </Tabs>
    </Sheet>
  );
}

export default ListSuppliers;
