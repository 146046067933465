import {
  Box,
  Drawer,
  FormControl,
  FormLabel,
  Grid,
  IconButton,
  Textarea,
  Typography,
} from "@mui/joy";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { CatalogInventoryItemType } from "../../../types/item";
import { theme } from "../../../components/Theme";
import { ChevronLeftOutlined } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { CatalogType } from "../../../types/catalog";
import { formatPrice } from "../../../utils/formatPrice";
import { calculatePrice } from "../../../utils/cart";
import UOMSelector from "../../../components/Catalogue/Item/UOMSelector";
import CartQuantityManager from "../../../components/Catalogue/Item/CartQuantityManager";
import { CartItemDataType } from "../../../types/cart";
import AddToCartButton from "./AddToCartButton";
import { If } from "../../../components/Condition";

type Props = {
  show: boolean;
  catalog: CatalogType;
  item: CatalogInventoryItemType;
  selectedUOM?: "uom1" | "uom2" | "uom3";
  isDisabled: boolean;
  onClose?: (item: CatalogInventoryItemType) => void;
  withAddToCart: boolean;
  items: CatalogInventoryItemType[];
  orderDataCallback?: () => void;
  setCartUpdated?: Dispatch<SetStateAction<boolean>>;
};

const ProductDetailsModal = ({
  show,
  catalog,
  item,
  selectedUOM = "uom1",
  onClose,
  withAddToCart,
  items,
  orderDataCallback,
  setCartUpdated,
}: Props) => {
  const { t } = useTranslation();
  // const isGuestMode = window.location.pathname.startsWith("/catalog");
  const currency = item.currency ?? item.inventoryItem.currency;

  const [isOpen, setIsOpen] = useState(false);
  const [activeUOM, setActiveUOM] = useState(selectedUOM);
  const [toCart, setToCart] = useState<CartItemDataType[]>([]);
  const [resetFlag, setResetFlag] = useState(false);

  useEffect(() => {
    setIsOpen(show);
  }, [show]);

  const handleClose = () => {
    setToCart([]);
    setResetFlag(!resetFlag);
    setIsOpen(false);
    if (onClose) {
      onClose(item);
    }
  };

  return (
    <Drawer open={isOpen} onClose={handleClose} anchor="bottom" size="lg">
      <div className="h-screen">
        <div
          className={`flex align-middle p-3 border-b border-[${theme.colorSchemes.light.palette.others.disabled}]`}
        >
          <IconButton
            variant="plain"
            onClick={handleClose}
            className="absolute top-2"
          >
            <ChevronLeftOutlined />
          </IconButton>
          <span className="mx-auto my-auto">
            <Typography fontSize={16} fontWeight={600}>
              {t("catalog.supplier.items.detailsLabel")}
            </Typography>
          </span>
        </div>

        <div className="w-full flex justify-center mt-4">
          <img
            src={`${item.inventoryItem.thumbnail ?? "/union.png"}`}
            alt="item_image"
            // width={100}
            // height={100}
            className="border-b lg:border border-gray-300 lg:mr-4 p-2 w-full sm:w-screen sm:h-[50vw] md:w-[393px] md:h-[393px] lg:w-[100px] lg:h-[100px] object-contain"
          />
        </div>

        <If condition={withAddToCart}>
          <Box p={"8px"}>
            <UOMSelector
              item={item}
              hidePrice={false}
              catalogType={catalog.catalogType}
              activeUOM={activeUOM}
              setActiveUOM={(uom) => {
                setActiveUOM(uom);
              }}
              size="lg"
            />
          </Box>
        </If>

        {/* <div className="flex w-screen overflow-auto gap-2 my-4 px-4">
          {item.inventoryItem.uom1 && (
            <Button
              variant="outlined"
              className={`rounded-lg min-w-20 ${
                activeUOM === "uom1" ? "bg-primary-500 text-white" : ""
              }`}
              disabled={isDisabled}
              onClick={() => {
                setActiveUOM("uom1");

                // if (updateUOMCallback) {
                //   updateUOMCallback("uom1");
                // }
              }}
            >
              {item.inventoryItem.uom1}
            </Button>
          )}
          {item.inventoryItem.uom2 && (
            <Button
              variant="outlined"
              className={`rounded-lg min-w-20 ${
                activeUOM === "uom2" ? "bg-primary-500 text-white" : ""
              }`}
              disabled={isDisabled}
              onClick={() => {
                setActiveUOM("uom2");

                // if (updateUOMCallback) {
                //   updateUOMCallback("uom1");
                // }
              }}
            >
              {item.inventoryItem.uom2}
            </Button>
          )}
          {item.inventoryItem.uom3 && (
            <Button
              variant="outlined"
              className={`rounded-lg min-w-20 ${
                activeUOM === "uom3" ? "bg-primary-500 text-white" : ""
              }`}
              disabled={isDisabled}
              onClick={() => {
                setActiveUOM("uom3");

                if (updateUOMCallback) {
                  updateUOMCallback("uom1");
                }
              }}
            >
              {item.inventoryItem.uom3}
            </Button>
          )}
        </div> */}

        <Grid xs={12} container sx={{ width: "100%", padding: 2 }}>
          <Grid xs={6}>
            <Typography
              fontSize={14}
              sx={{
                color: theme.colorSchemes.dark.palette.text.secondary,
              }}
            >
              {t("catalog.supplier.items.productName")}:{" "}
            </Typography>
          </Grid>
          <Grid xs={6}>
            <div className="w-full text-right">
              <span className="text-black text-sm">
                {item.inventoryItem.productName}
              </span>
            </div>
          </Grid>

          <Grid xs={6}>
            <Typography
              fontSize={14}
              sx={{
                color: theme.colorSchemes.dark.palette.text.secondary,
              }}
            >
              SKU:
            </Typography>
          </Grid>
          <Grid xs={6}>
            <div className="w-full text-right">
              <span className="text-black text-sm">
                {item.inventoryItem.sku}
              </span>
            </div>
          </Grid>

          <Grid xs={6}>
            <Typography
              fontSize={14}
              sx={{
                color: theme.colorSchemes.dark.palette.text.secondary,
              }}
            >
              {t("catalog.items.category")}:{" "}
            </Typography>
          </Grid>
          <Grid xs={6}>
            <div className="w-full text-right">
              <span className="text-black text-sm">
                {item.inventoryItem.inventoryType}
              </span>
            </div>
          </Grid>

          {item.inventoryItem.flexiColumns?.data.map((col) => (
            <>
              <Grid xs={6}>
                <Typography
                  fontSize={14}
                  sx={{
                    color: theme.colorSchemes.dark.palette.text.secondary,
                  }}
                >
                  {col.columnName}:{" "}
                </Typography>
              </Grid>
              <Grid xs={6}>
                <div className="w-full text-right">
                  <span className="text-black text-sm">{col.cellValue}</span>
                </div>
              </Grid>
            </>
          ))}

          <Grid xs={6}>
            <Typography
              fontSize={14}
              sx={{
                color: theme.colorSchemes.dark.palette.text.secondary,
              }}
            >
              {t("catalog.supplier.items.price")}:{" "}
            </Typography>
          </Grid>

          {catalog && (
            <Grid xs={6}>
              <div className="w-full text-right">
                {item.price && item.inventoryItem.price > item.price && (
                  <p className="text-gray-600 line-through">
                    {`${currency} ${formatPrice(
                      calculatePrice(item, activeUOM, true),
                      currency
                    )}`}
                  </p>
                )}

                <span className="text-black text-sm font-semibold">
                  <Typography
                    className="text-black font-bold"
                    fontSize={16}
                    sx={{
                      wordBreak: "break-all",
                    }}
                  >
                    {`${currency} ${formatPrice(
                      calculatePrice(item, activeUOM),
                      currency
                    )}`}
                    {item.inventoryItem[activeUOM] &&
                      `/${item.inventoryItem[activeUOM]}`}
                  </Typography>
                </span>
              </div>
            </Grid>
          )}

          <Grid xs={12}>
            <FormControl sx={{ flex: "1", marginTop: "1rem" }}>
              <FormLabel>
                {t("catalog.inventory.editForm.productDescription")}
              </FormLabel>
              <Textarea
                disabled
                size="sm"
                placeholder={t("catalog.inventory.editForm.productDescription")}
                minRows={4}
                defaultValue={item.inventoryItem.productDescription}
              />
            </FormControl>
          </Grid>
        </Grid>

        {withAddToCart && (
          <div
            className={`flex flex-col align-middle p-3 border-t border-[${theme.colorSchemes.light.palette.others.disabled}] sticky bottom-0 w-full z-10 bg-white`}
          >
            <CartQuantityManager
              item={item}
              hidePrice={false}
              withAddToCart
              catalog={catalog}
              defaultUOM={activeUOM}
              // unsavedOrderData={unsavedOrderData}
              setToCart={setToCart}
              resetFlag={resetFlag}
            />

            <AddToCartButton
              toCart={toCart}
              catalog={catalog}
              inventoryItems={items}
              setCartUpdated={setCartUpdated}
              cartDataCallback={() => {
                setToCart([]);
                setResetFlag(!resetFlag);
              }}
              orderDataCallback={orderDataCallback}
              isDisabled={toCart.length === 0}
            />
          </div>
        )}
      </div>
    </Drawer>
  );
};

export default ProductDetailsModal;
