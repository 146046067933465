import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
} from "@headlessui/react";
import {
  CheckIcon,
  ChevronRightIcon,
  ChevronUpDownIcon,
} from "@heroicons/react/24/solid";
import { useQuery } from "@tanstack/react-query";
import { t } from "i18next";
import { Link } from "react-router-dom";
import FlagById from "../../components/FlagById";
import LoadingModal from "../../components/LoadingModal/LoadingModal";
import { OrderService } from "../../services/order.service";
import { formatCurrency } from "../../utils/formatPrice";
import { currencies } from "./Dashboard";

export const PayableRecieveableCard = ({
  list,
  isPayable = false,
  totalAmount,
  currency,
  handleCurrencyChange,
}: {
  list: any[];
  isPayable?: boolean;
  totalAmount: number;
  currency: {
    id: string;
    name: string;
  };
  handleCurrencyChange: (val: { id: string; name: string }) => void;
}) => {
  const getAvailableCurrencyQuery = useQuery({
    queryKey: ["available-currency"],
    queryFn: () => OrderService.getAvailableCurrency(),
  });
  const availableCurrencies = getAvailableCurrencyQuery.data?.data ?? [];
  if (getAvailableCurrencyQuery.isLoading) {
    return <LoadingModal isLoading={getAvailableCurrencyQuery.isLoading} />;
  }
  return (
    <div className="flex overflow-hidden flex-col flex-1 h-full bg-white rounded-2xl shadow-lg">
      {availableCurrencies.length > 1 && (
        <div className="p-4">
          <Listbox value={currency} onChange={handleCurrencyChange}>
            <div className="relative mt-2">
              <ListboxButton className="relative w-fit cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-[#499873] sm:text-sm/6">
                <span className="flex items-center">
                  <FlagById id={currency.id.toUpperCase()} />
                  <span className="block ml-3 truncate">{currency.name}</span>
                </span>
                <span className="flex absolute inset-y-0 right-0 items-center pr-2 ml-3 pointer-events-none">
                  <ChevronUpDownIcon
                    aria-hidden="true"
                    className="text-gray-400 size-5"
                  />
                </span>
              </ListboxButton>

              <ListboxOptions
                transition
                className="absolute z-10 mt-1 max-h-56 w-fit overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none data-[closed]:data-[leave]:opacity-0 data-[leave]:transition data-[leave]:duration-100 data-[leave]:ease-in sm:text-sm"
              >
                {currencies
                  .filter((cur) => availableCurrencies.includes(cur.name))
                  .map((currency) => (
                    <ListboxOption
                      key={currency.id}
                      value={currency}
                      className="group relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900 data-[focus]:bg-[#499873] data-[focus]:text-white"
                    >
                      <div className="flex items-center">
                        <FlagById id={currency.id.toUpperCase()} />
                        <span className="ml-3 block truncate font-normal group-data-[selected]:font-semibold">
                          {currency.name}
                        </span>
                      </div>

                      <span className="absolute inset-y-0 right-0 flex items-center pr-4 text-[#499873] group-data-[focus]:text-white [.group:not([data-selected])_&]:hidden">
                        <CheckIcon aria-hidden="true" className="size-5" />
                      </span>
                    </ListboxOption>
                  ))}
              </ListboxOptions>
            </div>
          </Listbox>
        </div>
      )}
      <div className="flex flex-col flex-1 gap-4 p-4">
        <div className="flex flex-col gap-2 justify-between items-start lg:flex-row">
          <div className="flex flex-col gap-1">
            <p className="text-[#101828] font-semibold text-xl">
              {isPayable
                ? t("dashboard.totalPayable")
                : t("dashboard.totalRecieveable")}
            </p>
            <p className="text-[#475467] text-xs font-normal">
              {isPayable
                ? t("dashboard.payableDesc")
                : t("dashboard.recieveableDesc")}
            </p>
          </div>
          <p className="text-lg font-bold text-[#101828]">
            {formatCurrency(totalAmount, (currency.id ?? "").toUpperCase()!)}
          </p>
        </div>
        <div className="hidden flex-col gap-3 lg:flex">
          {list.length > 0 && (
            <p className="text-[#475467] text-xs font-normal">
              {!isPayable
                ? t("dashboard.top3Buyers")
                : t("dashboard.top3Sellers")}
            </p>
          )}
          <div className="flex flex-col gap-3">
            {}
            {list.map((item: any, index: number) => (
              <div
                key={index}
                className={`flex items-center gap-2 pb-3 ${
                  index < list.length - 1 ? "border-b" : ""
                } border-[#EAECF0] `}
              >
                <p className="text-[#475467] text-xs font-normal w-5 h-5">
                  {index + 1}.
                </p>
                <p className="flex-1 text-[#101828] text-sm font-semibold">
                  {item.company_name}
                </p>
                <p className="text-[#475467] text-sm font-normal">
                  {formatCurrency(
                    item.totalAmount,
                    (currency.id ?? "").toUpperCase()!
                  )}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
      <Link
        to={`/client/orders/reports?reportType=${
          isPayable ? "TOTAL_PAYABLE" : "TOTAL_RECEIVABLE"
        }`}
        className="flex items-center justify-between text-[#499873] py-2 px-4 bg-[#F9FAFB] cursor-pointer"
      >
        <p className="py-[10px] text-sm font-semibold">
          {t("dashboard.viewDetails")}
        </p>
        <ChevronRightIcon className="w-6 h-6" />
      </Link>
    </div>
  );
};
