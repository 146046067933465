import { Stack } from "@mui/joy";

export default function OrderFooter({footerActionButtons}: any) {
  return (
    <Stack
      direction={"row"}
      justifyContent={"end"}
      alignItems={"center"}
      gap={"8px"}
      flexWrap={"wrap"}
      my={2}
      sx={{
        [`& > *`]: {
          flexBasis: { xs: "100%", md: "auto" },
          textAlign: { xs: "center", md: "" },
        },
      }}
    >
      {footerActionButtons}
    </Stack>
  );
}
