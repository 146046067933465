import { ChevronRightIcon } from "@heroicons/react/24/solid";
import { Stack } from "@mui/joy";
import { useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { BusinessSessionHelper } from "../../../helpers/business-session.helper";
import { BusinessService } from "../../../services/business.service";
import { BrowserRouterConstants } from "../../../utils/constants";

export default function CreditLimitCard() {
  const { t, i18n } = useTranslation();

  const creditData = useQuery({
    queryKey: ["business-credits", BusinessSessionHelper.Business()?.id],
    queryFn: () => BusinessService.getBusinessCredit(),
  });

  return (
    <div className="w-full lg:max-w-[570px] flex flex-col bg-white rounded-2xl overflow-hidden shadow-lg ">
      <div className="flex flex-col gap-2 p-4">
        <Stack direction={"row"} alignItems={"center"} gap={1}>
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="8" cy="8" r="8" fill="#499873" />
            <circle
              cx="8"
              cy="8"
              r="8"
              fill="url(#paint0_linear_2830_795)"
              fill-opacity="0.3"
            />
            <path
              d="M5.33596 7.83348C5.33596 6.90507 5.33024 5.97666 5.33825 5.04711C5.34398 4.39814 5.7595 3.99324 6.39022 4.00009C6.97058 4.00693 7.36321 4.42437 7.36664 5.06308C7.37236 6.37129 7.36664 7.67836 7.37007 8.98657C7.37236 9.74846 7.28995 9.64011 8.04544 9.64581C8.7975 9.65037 9.54956 9.64125 10.3028 9.64923C10.8648 9.65494 11.2529 9.94806 11.3822 10.4419C11.5379 11.0339 11.1029 11.6612 10.4871 11.668C9.0871 11.6851 7.68715 11.6828 6.28834 11.668C5.76179 11.6623 5.34398 11.197 5.34054 10.6484C5.33368 9.71082 5.33825 8.77215 5.3394 7.83348H5.33596Z"
              fill="#DDEEE3"
            />
            <defs>
              <linearGradient
                id="paint0_linear_2830_795"
                x1="12.6667"
                y1="1.33333"
                x2="5"
                y2="15.3333"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#2C8F60" />
                <stop offset="0.47464" stop-color="white" />
                <stop offset="1" stop-color="#2C8F60" />
              </linearGradient>
            </defs>
          </svg>
          <p className="text-[#101828] text-base ">{t("dashboard.credits")}</p>
        </Stack>
        <div className="flex items-center">
          <div className="flex flex-col flex-1 gap-2">
            <p className="text-2xl font-bold text-[#171A1C]">
              {creditData.data?.credits}
            </p>
            <p className="text-xs font-normal text-[#475467]">
              {t("commons.dateUpdated")}{" "}
              {dayjs(creditData.data?.data?.updatedAt)
                .locale(i18n.resolvedLanguage ?? "en")
                .format("ddd D MMM hh:mm A")}
            </p>
          </div>
          <Link
            to={BrowserRouterConstants.Credit}
            className="bg-[#499873] px-4 py-[10px] rounded-lg text-white text-sm font-semibold"
          >
            {t("dashboard.topUpBtn")}
          </Link>
        </div>
      </div>
      <Link
        to={BrowserRouterConstants.CreditTransactionTab}
        className="flex items-center justify-between text-[#499873] py-2 px-4 bg-[#F9FAFB] cursor-pointer"
      >
        <p className="py-[10px] text-sm font-semibold">
          {t("dashboard.creditHistoryBtn")}
        </p>
        <ChevronRightIcon className="w-6 h-6" />
      </Link>
    </div>
  );
}
