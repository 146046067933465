import {
  LockOpenOutlined,
  LockOutlined,
  ShoppingCartOutlined,
} from "@mui/icons-material";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Input,
  List,
  Option,
  Select,
  Sheet,
  Snackbar,
  Stack,
  Typography,
} from "@mui/joy";
// import { AxiosError } from "axios";
import debounce from "lodash.debounce";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroller";
import { Link, useParams, useSearchParams } from "react-router-dom";
import LogoImg from "../../assets/linkz-logo-small.png";
import ListUnavailable from "../../components/Catalogue/ListUnavailable";
import { If, IfElse } from "../../components/Condition";
import { theme } from "../../components/Theme";
// import { TRACKER_CONSTANTS } from "../../constants/tracker.events";
import { ItemService } from "../../services/item.service";
import { SupplierService } from "../../services/supplier.service";
// import { TrackerService } from "../../services/tracker.service";
import { getActiveBusiness, useAuthStore } from "../../store/session";
import { CartItemDataType } from "../../types/cart";
import { CatalogInventoryItemType } from "../../types/item";
import { getCartItems } from "../../utils/cart";
import { SupplierProductCard } from "./components/SupplierProductCard";
import ProductDetailsModal from "./components/ProductDetailsModal";
// import ProductListItem from "./components/ProductListItem";
import useContainer from "./useContainer";
import {
  ChevronDownIcon,
  ListBulletIcon,
  Squares2X2Icon,
} from "@heroicons/react/24/outline";
import { formatDate } from "../../utils/formatDate";
import { getCountryInfo } from "../../utils/country";
import dayjs from "dayjs";
import LanguageSelector from "../../components/LanguageSelector";

const sortOptions = [
  { value: "", label: "Sort By" },
  { value: "id_asc", label: "Newest first" },
  { value: "id_desc", label: "Latest first" },
  { value: "productName_asc", label: "A to Z (ascending)" },
  { value: "productName_desc", label: "Z to A (descending)" },
  // { value: "price_desc", label: "Highest Price" },
  // { value: "price_asc", label: "Lowest Price" },
];

const ListSupplierItems = () => {
  const { t } = useTranslation();

  const { catalogId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const orderId = searchParams.get("order");
  const query = searchParams.get("query");
  const sortBy = searchParams.get("sort_by");
  const sortDirection = searchParams.get("sort_direction") ?? "";
  const layoutType = searchParams.get("layout") ?? "list";
  const inventoryType = searchParams.get("inventory_type");

  // TODO: replace with /public
  const isGuestMode = window.location.pathname.startsWith("/catalog");
  const { session } = useAuthStore.getState();

  const [categories, setCategories] = useState<string[]>([]);
  // const [viewMode, _setViewMode] = useState<string>("grid");
  const [toCart, _setToCart] = useState<CartItemDataType[]>([]);
  const [isMobile, setIsMobile] = useState(
    window.innerWidth < theme.breakpoints.values.sm
  );
  // const [resetFlag, _setResetFlag] = useState(false);
  const [_subtotal, setSubtotal] = useState(0);
  const [selectedItem, setSelectedItem] = useState<{
    show: boolean;
    item: CatalogInventoryItemType;
  }>();

  // const loaderRef = useRef(null);

  const {
    fetchAllItems,
    fetchCatalog,
    items,
    catalog,
    pagination,
    isLoading,
    setIsLoading,
    unauthorized,
    setUnauthorized,
    hasMore,
    cartItems,
    setCartItems,
    cartUpdated,
    setCartUpdated,
    showToast,
    setShowToast,
    error,
    imgExistanceCheckQuery,
  } = useContainer();

  // const initiateInventoryItems = useCallback(
  //   (catalogId: string, query?: string) => {
  //     InventoryService.getCatalogItems({
  //       catalogId,
  //       paging: true,
  //       limit: 10,
  //       offset: 0,
  //       searchTerm: query,
  //       sortDirection: (["asc", "desc"].includes(sortDirection)
  //         ? sortDirection
  //         : "desc") as "asc" | "desc",
  //       sortBy: sortBy ? sortBy : undefined,
  //     })
  //       .then((res) => {
  //         setItems(res.data);

  //         if (res.pagination) {
  //           setPagination(res.pagination);
  //         }

  //         if (res.data.length === 0) {
  //           setHasMore(false);
  //         }

  //         // getting all inventoryType for categories
  //         const businessId = res.data[0].inventoryItem.business;
  //         if (businessId) {
  //           ItemService.getCatalogInventoryTypes({
  //             catalogId,
  //           }).then((res) => {
  //             // const cats = res.map(
  //             //   (type: { inventoryType: string }) => type.inventoryType
  //             // );
  //             setCategories(res);
  //           });

  //           // ItemService.getInventoryItemField({
  //           //   fields: ["inventoryType"],
  //           //   businessId,
  //           // }).then((res) => {
  //           //   const cats = res.map(
  //           //     (type: { inventoryType: string }) => type.inventoryType
  //           //   );
  //           //   setCategories(cats);
  //           // });
  //         }
  //       })
  //       .catch((err) => console.error(err));
  //   },
  //   []
  // );

  useEffect(() => {
    if (window.innerWidth < theme.breakpoints.values.sm) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [window.innerWidth]);

  useEffect(() => {
    if (catalogId) {
      console.log("FILTERS UPDATED", {
        session,
        query,
        inventoryType,
        sortDirection,
        sortBy,
      });
      setIsLoading(true);
      // clear storedItemDetails
      if (session?.accessToken && isGuestMode) {
        window.location.href = `/client/suppliers/${catalogId}/items`;
        return;
      }

      fetchCatalog(catalogId, (res) => {
        if (res.catalogType !== "PUBLIC") {
          SupplierService.verifyAccess({
            catalog: catalogId,
            business: getActiveBusiness()?.id ?? null,
            accountId: session?.account.id ?? null,
          })
            .then((res) => {
              if (!res) {
                setUnauthorized(true);

                if (isGuestMode) {
                  window.location.href = `/client/suppliers/${catalogId}/items`;
                }
              }

              // initiateInventoryItems(catalogId, query ? query : undefined);
              return fetchAllItems({
                catalogId: catalogId,
                paging: true,
                limit: 10,
                offset: 0,
                searchTerm: query,
                inventoryType: inventoryType !== "all" ? inventoryType : null,
                isReset: true,
                sortDirection: (["asc", "desc"].includes(sortDirection)
                  ? sortDirection
                  : "desc") as "asc" | "desc",
                sortBy: sortBy ? sortBy : "updatedAt",
              });
            })
            .then(() => {
              ItemService.getCatalogInventoryTypes({
                catalogId,
              }).then((res) => {
                // const cats = res.map(
                //   (type: { inventoryType: string }) => type.inventoryType
                // );
                setCategories(res);
              });
            })
            .catch((error) => {
              setUnauthorized(true);
              console.error(error);
            })
            .finally(() => {
              setIsLoading(false);
            });
        } else {
          // initiateInventoryItems(catalogId, query ? query : undefined);
          fetchAllItems({
            catalogId: catalogId,
            paging: true,
            limit: 10,
            offset: 0,
            searchTerm: query,
            inventoryType: inventoryType !== "all" ? inventoryType : null,
            isReset: true,
            sortDirection: (["asc", "desc"].includes(sortDirection)
              ? sortDirection
              : "desc") as "asc" | "desc",
            sortBy: sortBy ? sortBy : "updatedAt",
          });
        }
      });
      // InventoryService.getOneCatalog({
      //   catalogId: catalogId,
      // })
      //   .then((res) => {
      //     setCatalog(res);
      //     // setIsCatalogOwner(res.business.id === session?.activeBusiness.id);
      //     const tempCartItems = getCartItems(
      //       catalogId,
      //       session?.account.id || "wildcart",
      //       session?.accessToken ? "local" : "session"
      //     );

      //     if (tempCartItems) {
      //       setCartItems(tempCartItems);
      //       setCartUpdated(false);
      //     }

      //     TrackerService.track(
      //       TRACKER_CONSTANTS.CATALOG_SUPPLIER.events.AccessCatalogDetails,
      //       {
      //         "Catalog ID": catalogId,
      //         "Catalog Name": res?.catalogName,
      //         "Catalog Owner Company Name": res?.business.companyName,
      //         "Product Count": res?.itemsCount,
      //       }
      //     );

      //     if (res.catalogType !== "PUBLIC") {
      //       SupplierService.verifyAccess({
      //         catalog: catalogId,
      //         business: getActiveBusiness()?.id ?? null,
      //         accountId: session?.account.id ?? null,
      //       })
      //         .then((res) => {
      //           if (!res) {
      //             setUnauthorized(true);

      //             if (isGuestMode) {
      //               window.location.href = `/client/suppliers/${catalogId}/items`;
      //             }
      //           }

      //           initiateInventoryItems(catalogId, query ? query : undefined);
      //         })
      //         .catch((error) => {
      //           setUnauthorized(true);
      //           console.error(error);
      //         });
      //     } else {
      //       initiateInventoryItems(catalogId, query ? query : undefined);
      //     }
      //   })
      //   .catch((err) => {
      //     console.error(err);
      //     setError(err);
      //   })
      //   .finally(() => {
      //     setIsLoading(false);
      //   });
    }
  }, [catalogId, session, query, inventoryType, sortDirection, sortBy]);

  // useEffect(() => {
  //   if (query && !searchTerm) return;
  //   const observer = new IntersectionObserver((entries) => {
  //     const target = entries[0];
  //     if (target.isIntersecting && hasMore && catalogId && items.length > 0) {
  //       fetchAllItems({
  //         catalogId: catalogId,
  //         paging: true,
  //         limit: 10,
  //         offset: pagination.limit * pagination.current,
  //         searchTerm: searchTerm ?? null,
  //         inventoryType: selectedCategory !== "all" ? selectedCategory : null,
  //         isReset: false,
  //       });
  //     }
  //   });

  //   if (loaderRef.current) {
  //     observer.observe(loaderRef.current);
  //   }

  //   return () => {
  //     if (loaderRef.current) {
  //       observer.unobserve(loaderRef.current);
  //     }
  //   };
  // }, [fetchAllItems]);

  // useEffect(() => {
  //   if (query) return;
  //   if (
  //     catalogId
  //     // (searchTerm ||
  //     //   searchTerm === "" ||
  //     //   selectedCategory ||
  //     //   (sortBy && sortDirection))
  //   ) {
  //     console.log("FILTERS", {
  //       catalogId,
  //       sortDirection,
  //       sortBy,
  //     });
  //     fetchAllItems({
  //       catalogId: catalogId,
  //       paging: true,
  //       limit: 10,
  //       offset: 0,
  //       searchTerm: query,
  //       inventoryType: inventoryType !== "all" ? inventoryType : null,
  //       isReset: true,
  //       sortDirection: (["asc", "desc"].includes(sortDirection)
  //         ? sortDirection
  //         : "desc") as "asc" | "desc",
  //       sortBy: sortBy ? sortBy : "updatedAt",
  //     });
  //   }
  // }, [catalogId, query, inventoryType, sortDirection, sortBy]);

  useEffect(() => {
    if (cartUpdated && catalogId) {
      setShowToast(true);

      setTimeout(() => {
        setShowToast(false);
      }, 3000);

      const tempCartItems = getCartItems(
        catalogId,
        session?.account.id || "wildcart",
        session?.accessToken ? "local" : "session"
      );

      if (tempCartItems) {
        setCartItems(tempCartItems);
        setCartUpdated(false);
      }
    }
  }, [cartUpdated]);

  useEffect(() => {
    if (toCart.length > 0) {
      let tmp = 0;
      // console.log("BANANA IS TOCART BEFORE?", toCart);
      toCart.forEach((c) => {
        tmp += (c.initPrice ?? 0) * c.quantity;
      });

      // console.log("BANANA IS TOCART AFTER?", tmp);
      setSubtotal(tmp);
    } else {
      setSubtotal(0);
    }
  }, [toCart]);

  // useEffect(() => {
  //   const handleCartUpdateListener = (event: any) => {
  //     setShowToast(true);

  //     setTimeout(() => {
  //       setShowToast(false);
  //     }, 3000);
  //   };

  //   window.addEventListener("cartUpdated", handleCartUpdateListener);

  //   return () => {
  //     window.removeEventListener("cartUpdated", handleCartUpdateListener);
  //   };
  // }, []);

  const debouncedSetSearchTerm = useCallback(
    debounce((value: string) => {
      if (value === "") {
        searchParams.delete("query");
      } else {
        searchParams.set("query", value);
      }
      setSearchParams(searchParams);
    }, 300),
    []
  );

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    debouncedSetSearchTerm(event.target.value);
  };

  const orderDataCallback = () => {
    // setUnsavedOrderData(localStorage.getItem(`unsavedOrderData_${orderId}`));
  };

  const CatalogInfo = ({ title, value }: { title: string; value: string }) => {
    return (
      <Grid lg={4}>
        <div className="flex justify-between items-center">
          <div className="flex justify-between items-center">
            <div className="flex justify-between items-center gap-5">
              <span
                className={`text-[12px] text-[${theme.colorSchemes.light.palette.others.secondary_text}] w-[100px]`}
              >
                {title}
              </span>

              <span
                className={`text-[12px] [${theme.colorSchemes.light.palette.others.secondary_text}]`}
              >
                {":"}
              </span>
            </div>
            <span className="text-[12px] font-semibold">{value}</span>
          </div>
        </div>
      </Grid>
    );
  };

  const CatalogTypeButton = () => {
    return (
      <button
        className={`my-4 lg:my-0 py-3 px-4 rounded-lg ${
          catalog?.catalogType === "PUBLIC"
            ? "bg-primary-200"
            : "bg-gray-700 text-white"
        } capitalize font-semibold items-center flex cursor-default w-[106px]`}
      >
        {catalog?.catalogType === "PUBLIC" ? (
          <LockOpenOutlined
            style={{
              width: 18,
              height: 18,
              marginRight: "0.5rem",
            }}
          />
        ) : (
          <LockOutlined
            style={{
              color: "white",
              width: 18,
              height: 18,
              marginRight: "0.5rem",
            }}
          />
        )}

        {catalog?.catalogType.toLowerCase()}
      </button>
    );
  };

  const CartButton = () => {
    return (
      <Link
        to={
          isGuestMode
            ? catalog?.catalogType === "PRIVATE"
              ? `/client/suppliers/${catalogId}/items`
              : `/public/cart/${catalogId}/items`
            : orderId
            ? `/client/orders/${orderId}`
            : session?.accessToken
            ? `/client/cart/${catalogId}/items`
            : `/public/cart/${catalogId}/items`
        }
      >
        <button
          className={`my-4 lg:my-0 py-3 px-4 rounded-lg  border text-primary-400 border-primary-400 capitalize font-semibold items-center flex cursor-default `}
        >
          <ShoppingCartOutlined
            style={{
              width: 18,
              height: 18,
              marginRight: "0.5rem",
            }}
          />
          {t(`catalog.${orderId ? "order" : "cart"}`)}{" "}
          {!orderId && cartItems
            ? cartItems.items.length > 0 && `(${cartItems.items.length})`
            : ""}
        </button>
      </Link>
    );
  };

  return (
    <Sheet
      sx={{
        position: "relative",
        backgroundColor: "transparent",
        overflowX: "hidden",
        px: {
          xs: isGuestMode ? "1rem" : 0,
        },
        width: isGuestMode ? "auto" : { xs: "100%" },
        display: "flex",
        flexDirection: "column",
        gap: "2rem",
      }}
    >
      <IfElse
        condition={!error}
        ifBlock={
          <IfElse
            condition={!unauthorized}
            ifBlock={
              <Stack
                direction={"column"}
                gap={{ xs: "8px", md: "24px" }}
                position={"relative"}
              >
                <If condition={isGuestMode}>
                  <Stack
                    direction={"row"}
                    sx={{
                      alignItems: "center",
                      width: "100%",
                      py: "1rem",
                    }}
                  >
                    <img
                      src={LogoImg}
                      alt="linkz-logo"
                      width={109.05}
                      height={52}
                      className={"mr-auto"}
                    />

                    <Box
                      sx={{
                        ml: "auto",
                        display: { xs: "block", lg: "hidden" },
                      }}
                      // className="ml-auto lg:hidden"
                    >
                      <LanguageSelector />
                    </Box>

                    <Stack
                      direction={"row"}
                      display={{ xs: "none", lg: "flex" }}
                      sx={{
                        gap: "0.5rem",
                        ml: "auto",
                      }}
                    >
                      {/* {t("catalog.loginToSeePrice")} */}
                      <LanguageSelector />

                      <Link
                        to={`/signup?callbackURL=/client/suppliers/${catalogId}/items`}
                        className="ml-3"
                      >
                        <Button className="min-w-[3rem]">
                          {t("landing.createAccountBtn")}
                        </Button>
                      </Link>
                      <Link
                        to={`/signin?callbackURL=/client/suppliers/${catalogId}/items`}
                        className="ml-3"
                      >
                        <Button
                          variant="primary-light"
                          className="min-w-[3rem]"
                        >
                          {t("landing.loginBtn")}
                        </Button>
                      </Link>
                    </Stack>
                  </Stack>
                </If>

                {/* catalog details */}
                <Stack
                  direction={{ xs: "column-reverse", md: "row" }}
                  gap={"24px"}
                >
                  {/* <IfElse
                    condition={isMobile}
                    ifBlock={
                      <Stack
                        direction={"row"}
                        justifyContent={"space-between"}
                        alignItems={"center"}
                      >
                        <CatalogTypeButton />

                        <If condition={!catalog?.hidePrice}>
                          <CartButton />
                        </If>
                      </Stack>
                    }
                    elseBlock={
                      <div className="block lg:hidden">
                        <CatalogTypeButton />
                      </div>
                    }
                  /> */}

                  <Box
                    sx={{
                      py: { xs: "1rem", lg: 0 },
                      position: "relative",
                      width: "100%",
                    }}
                    // className={`py-4 lg:py-0 relative w-[100%] `}
                  >
                    <Stack
                      direction={{ xs: "column", lg: "row" }}
                      alignItems={{ xs: "flex-start", lg: "center" }}
                      justifyContent="space-between"
                      gap={2}
                    >
                      <Stack
                        direction={"row"}
                        gap={"0.5rem"}
                        // className="flex gap-2"
                      >
                        {imgExistanceCheckQuery.data && (
                          <img
                            style={{
                              width: "60px",
                              height: "60px",
                              objectFit: "cover",
                            }}
                            src={imgExistanceCheckQuery.data}
                            alt=""
                          />
                        )}
                        <Box>
                          <Typography
                            fontSize={16}
                            fontWeight={500}
                            textColor={"#101828"}
                          >
                            {catalog?.business?.companyName}
                          </Typography>
                          <Typography
                            fontSize={18}
                            fontWeight={700}
                            textColor={"#101828"}
                          >
                            {catalog?.catalogName}
                          </Typography>
                        </Box>
                      </Stack>

                      <Stack
                        direction={"row"}
                        sx={{
                          justifyContent: "space-between",
                          width: { xs: "100%", lg: "auto" },
                          gap: "0.5rem",
                          alignItems: "center",
                        }}
                        // className="flex justify-between w-[100%] lg:w-auto gap-2"
                      >
                        <CatalogTypeButton />

                        {/* <div className="hidden ml-auto lg:block"> */}
                        {/* <If condition={!catalog?.hidePrice}> */}
                        <CartButton />
                        {/* </If> */}
                        {/* </div> */}
                      </Stack>
                    </Stack>

                    {catalog && (
                      <Stack
                        direction={"row"}
                        justifyContent={""}
                        spacing={1}
                        mt={4}
                        sx={{
                          background: "var(--Neutral-050, #F9FAFB)",
                          paddingX: 2,
                          paddingY: 3,
                          // borderRadius: 8,
                        }}
                      >
                        <Grid
                          container
                          lg={12}
                          columnSpacing={{ md: 1 }}
                          width={"100%"}
                        >
                          <CatalogInfo
                            title={t("catalog.totalItems")}
                            value={`${catalog.itemsCount} Items`}
                          />
                          <CatalogInfo
                            title={t("catalog.sellerEmail")}
                            value={catalog.business.companyEmail || "-"}
                          />
                          <CatalogInfo
                            title={t("catalog.sellerLocation")}
                            value={`${catalog.business.companyAddress}, ${
                              getCountryInfo(catalog.business.companyCountry)
                                ?.name
                            }`}
                          />
                          <CatalogInfo
                            title={t("catalog.catalogLastUpdated")}
                            value={formatDate(
                              new Date(catalog.updatedAt || "")
                            )}
                          />
                          <CatalogInfo
                            title={t("catalog.sellerPhone")}
                            value={`+${catalog.business.companyNumberCountryCode} ${catalog.business.companyNumber}`}
                          />
                        </Grid>
                      </Stack>
                    )}
                    {/* <Typography
                      fontSize={14}
                      sx={{
                        color: theme.colorSchemes.dark.palette.text.secondary,
                      }}
                    >
                      <span className="text-xs lg:text-sm lg:hidden">
                        {t("commons.lastUpdated")}:{" "}
                        <span className="ml-2">
                          {formatDate(new Date(catalog?.updatedAt ?? ""))}
                        </span>
                      </span>
                    </Typography> */}
                    <hr className="lg:hidden w-screen h-px absolute bottom-0 bg-[#636B744D]" />
                  </Box>
                </Stack>

                {/* search bar */}
                <Stack
                  direction={{ xs: "column", lg: "row" }}
                  justifyContent={"space-between"}
                  gap={"24px"}
                  className="w-[100%]"
                >
                  <Input
                    defaultValue={query ?? ""}
                    variant="linkz-input-search"
                    size="sm"
                    fullWidth={isGuestMode}
                    name="searchFormData.search"
                    placeholder={t("catalog.searchItemPlaceholder")}
                    startDecorator={<SearchOutlinedIcon />}
                    sx={{ flexGrow: 1, width: { xs: "100%", lg: "41%" } }}
                    onChange={handleChange}
                  />

                  <Stack
                    direction={{ xs: "column", lg: "row" }}
                    sx={{
                      gap: { xs: "1rem", lg: "0.5rem" },
                      justifyContent: "flex-end",
                      overflow: "auto",
                      width: "100%",
                      alignItems: "center",
                    }}
                    // className={`lg:flex grid grid-cols-2 gap-2 justify-end overflow-auto lg:w-full items-center hide-scrollbar`}
                    className="hide-scrollbar"
                  >
                    {/* category filter */}
                    <Select
                      slotProps={{ button: { sx: { whiteSpace: "nowrap" } } }}
                      size="sm"
                      defaultValue={""}
                      sx={{
                        paddingY: "10px",
                        paddingX: "16px",
                        fontWeight: 600,
                        gap: "8px",
                        width: { xs: "100%", lg: "auto" },
                        color:
                          theme.colorSchemes.light.palette.others.neutral_dark,
                        borderColor:
                          theme.colorSchemes.light.palette.others.neutral_dark,
                      }}
                      indicator={<ChevronDownIcon width={18} height={18} />}
                      // onChange={(
                      //   _event: React.SyntheticEvent | null,
                      //   value: string | null
                      // ) => {
                      //   setSelectedCategory(value);
                      // }}
                      onChange={(
                        _event: React.SyntheticEvent | null,
                        value: string | null
                      ) => {
                        if (!value && value !== "") {
                          return;
                        }

                        if (value.length > 1) {
                          searchParams.set("inventory_type", value);
                        } else {
                          searchParams.delete("inventory_type");
                        }
                        setSearchParams(searchParams);
                      }}
                    >
                      <Option
                        // key={cat}
                        color="primary"
                        value={""}
                        // label={t("catalog.supplier.items.allCategoryLabel")}
                        sx={{
                          color: theme.colorSchemes.dark.palette.common.black,
                        }}
                      >
                        {t("catalog.supplier.items.allCategoryLabel")}
                      </Option>
                      {categories.map((cat) => {
                        return (
                          <Option
                            key={cat}
                            color="primary"
                            value={cat}
                            label={cat.toUpperCase()}
                            sx={{
                              color:
                                theme.colorSchemes.dark.palette.common.black,
                            }}
                          >
                            {cat}
                          </Option>
                        );
                      })}
                    </Select>

                    <Stack
                      direction={"row"}
                      gap={"0.5rem"}
                      width={{ xs: "100%", lg: "auto" }}
                    >
                      {/* sort by */}
                      <Select
                        slotProps={{ button: { sx: { whiteSpace: "nowrap" } } }}
                        size="sm"
                        sx={{
                          paddingY: "10px",
                          paddingX: "16px",
                          fontWeight: 600,
                          gap: "8px",
                          width: { xs: "80%", lg: "auto" },
                          color:
                            theme.colorSchemes.light.palette.others
                              .neutral_dark,
                          borderColor:
                            theme.colorSchemes.light.palette.others
                              .neutral_dark,
                        }}
                        indicator={<ChevronDownIcon width={18} height={18} />}
                        defaultValue={
                          sortBy && sortDirection
                            ? sortBy + "_" + sortDirection
                            : ""
                        }
                        onChange={(
                          _event: React.SyntheticEvent | null,
                          value: string | null
                        ) => {
                          if (!value) {
                            return;
                          }

                          if (value.length > 1) {
                            const [field, order] = value.split("_");
                            searchParams.set("sort_by", field);
                            searchParams.set("sort_direction", order);
                          } else {
                            searchParams.delete("sort_by");
                            searchParams.delete("sort_direction");
                          }
                          setSearchParams(searchParams);
                        }}
                      >
                        {sortOptions.map((opt) => {
                          if (opt.value.includes("price")) {
                            return;
                          }

                          return (
                            <Option
                              key={opt.value}
                              value={opt.value}
                              color="primary"
                              sx={{
                                color:
                                  theme.colorSchemes.dark.palette.common.black,
                              }}
                            >
                              {t(`catalog.sortOptions.${opt.label}`)}
                            </Option>
                          );
                        })}
                      </Select>

                      {/* new view mode */}
                      <Stack
                        direction={"row"}
                        sx={{
                          borderWidth: "1px",
                          borderColor: "#9ca3af",
                          borderRadius: "0.5rem",
                          overflow: "hidden",
                          maxWidth: "78px",
                          display: "flex",
                          justifyContent: "flex-end",
                          alignItems: "center",
                        }}
                        // className="border border-gray-400 rounded-lg overflow-hidden max-w-[78px] hidden md:flex"
                      >
                        <IconButton
                          onClick={() => {
                            searchParams.set("layout", "list");
                            setSearchParams(searchParams);
                            // setLayoutType("list");
                          }}
                          variant="plain"
                          className={`h-[100%] rounded-none px-2  ${
                            layoutType == "list" ? "bg-primary-200" : ""
                          }`}
                        >
                          <ListBulletIcon className="w-6" />
                        </IconButton>
                        <span className="border-l border-gray-400 h-[100%]"></span>
                        <IconButton
                          onClick={() => {
                            searchParams.set("layout", "grid");
                            setSearchParams(searchParams);
                            // setLayoutType("grid");
                          }}
                          variant="plain"
                          className={`h-[100%]  rounded-none ${
                            layoutType == "grid" ? "bg-primary-200" : ""
                          }`}
                        >
                          <Squares2X2Icon className="w-6" />
                        </IconButton>
                      </Stack>
                    </Stack>
                  </Stack>
                </Stack>

                {/* product list */}
                {catalog && (
                  <Box
                    sx={{
                      m: 0,
                      p: 0,
                      mb: "100px",
                      height: "100%",
                      overflowY: "auto",
                    }}
                  >
                    <InfiniteScroll
                      pageStart={0}
                      // initialLoad
                      useWindow={false}
                      loadMore={(_page: number) => {
                        // console.log(
                        //   "LOADMORE TRIGGERED",
                        //   pagination,
                        //   page,
                        //   isLoading
                        // );

                        if (isLoading) {
                          return;
                        }

                        fetchAllItems({
                          catalogId: catalogId ?? "",
                          paging: true,
                          limit: 10,
                          offset: pagination.limit * pagination.current,
                          searchTerm: query,
                          inventoryType:
                            inventoryType !== "all" ? inventoryType : null,
                          isReset: false,
                          sortDirection: (["asc", "desc"].includes(
                            sortDirection
                          )
                            ? sortDirection
                            : "desc") as "asc" | "desc",
                          sortBy: sortBy ? sortBy : "updatedAt",
                        });
                      }}
                      hasMore={hasMore}
                      loader={
                        <div className="loader" key={0}>
                          Loading...
                        </div>
                      }
                    >
                      {items.length > 0 ? (
                        <Box width={{ xs: "90vw", lg: "100%" }}>
                          {layoutType === "grid" && (
                            <Grid
                              container
                              // columns={{ xs: 2, xl: 5 }}
                              sx={{
                                mt: 4,
                                mb: 2,
                                spacing: { xs: 1, lg: 0 },
                                alignItems: "stretch",
                                gap: { xs: "0.5rem", md: "1.5rem" },
                                // width: "100%",
                              }}
                              // mt={4}
                              // mb={2}
                              // spacing={{ xs: 1, lg: 0 }}
                              // alignItems={"stretch"}
                              // TODO: need help on replicating these classes in mui
                              className={"grid grid-cols-2 xl:grid-cols-5"}
                            >
                              {items.map((i) => {
                                return (
                                  <div key={i.id}>
                                    <SupplierProductCard
                                      item={i}
                                      catalog={catalog}
                                      setCartUpdated={setCartUpdated}
                                      orderDataCallback={orderDataCallback}
                                      type="supplier"
                                      displayType={"grid"}
                                      onClick={() => {
                                        setSelectedItem({
                                          show: true,
                                          item: i,
                                        });
                                      }}
                                    />
                                  </div>
                                );
                              })}
                            </Grid>
                          )}
                          {layoutType === "list" && (
                            <List>
                              {items.map((i) => {
                                return (
                                  <div key={i.id}>
                                    <SupplierProductCard
                                      item={i}
                                      catalog={catalog}
                                      setCartUpdated={setCartUpdated}
                                      orderDataCallback={orderDataCallback}
                                      type="supplier"
                                      displayType={"list"}
                                      onClick={() => {
                                        setSelectedItem({
                                          show: true,
                                          item: i,
                                        });
                                      }}
                                    />
                                  </div>
                                );
                              })}
                            </List>
                          )}
                        </Box>
                      ) : (
                        <div className="flex w-full mt-10">
                          <p className="mx-auto my-auto text-base">
                            {t("catalog.noItemInCatalog")}
                          </p>
                        </div>
                      )}
                      {/* <div
                      className={`${
                        viewMode === "image"
                          ? "grid grid-cols-2 lg:grid-cols-1 gap-2 lg:gap-0 align-stretch"
                          : viewMode === "grid"
                          ? "grid grid-cols-2 lg:grid-cols-5 gap-2 lg:gap-6"
                          : "flex flex-col"
                      } `}
                    >
                      {items.map((i) => {
                        switch (viewMode) {
                          case "image":
                            return (
                              <>
                                <div className="block lg:hidden">
                                  <SupplierProductCard
                                    item={i}
                                    catalog={catalog}
                                    setCartUpdated={setCartUpdated}
                                    resetFlag={resetFlag}
                                    type="supplier"
                                    onClick={() => {
                                      setSelectedItem({
                                        show: true,
                                        item: i,
                                      });
                                    }}
                                  />
                                </div>
                                <div className="hidden lg:block">
                                  <ProductListItem
                                    type="image"
                                    item={i}
                                    isMobile={isMobile}
                                    catalog={catalog!}
                                    setToCart={setToCart}
                                    resetFlag={resetFlag}
                                    onClick={() => {
                                      setSelectedItem({
                                        show: true,
                                        item: i,
                                      });
                                    }}
                                  />
                                </div>
                              </>
                            );
                          case "grid":
                            return (
                              <SupplierProductCard
                                type="supplier"
                                item={i}
                                catalog={catalog}
                                setCartUpdated={setCartUpdated}
                                resetFlag={resetFlag}
                                onClick={() => {
                                  setSelectedItem({
                                    show: true,
                                    item: i,
                                  });
                                }}
                              />
                            );
                          default:
                            return (
                              <ProductListItem
                                item={i}
                                isMobile={isMobile}
                                catalog={catalog!}
                                setToCart={setToCart}
                                resetFlag={resetFlag}
                                onClick={() => {
                                  setSelectedItem({
                                    show: true,
                                    item: i,
                                  });
                                }}
                              />
                            );
                        }
                      })}
                    </div> */}
                    </InfiniteScroll>
                  </Box>
                )}

                {/* add to cart toolbar */}
                {/* <If condition={!catalog?.hidePrice}>
                  <div className="bg-[#FCFCFD] fixed hidden lg:flex w-full max-w-[inherit] bottom-0 left-0">
                    <Stack
                      direction={{ xs: "column", md: "row" }}
                      justifyContent={{ md: "space-between" }}
                      alignItems={"center"}
                      width={"100%"}
                      ml={isGuestMode ? 0 : "240px"}
                      py={"24px"}
                      px={"32px"}
                      gap={{ xs: "8px", md: 0 }}
                    >
                      <hr
                        className={`w-screen h-px absolute ${isGuestMode ? "left-0" : "left-[240px]"
                          } top-0 bg-[#636B744D]`}
                      />
                      <div className="flex align-middle lg:block">
                        <Typography fontSize={18}>
                          <span className="text-sm lg:text-lg">
                            {t("catalog.total")}:{" "}
                            <span className="text-black font-[600]">
                              {`${items[0]?.currency ??
                                items[0]?.inventoryItem.currency ??
                                ""
                                } ${catalog?.hidePrice
                                  ? "-"
                                  : formatPrice(
                                    subtotal,
                                    items[0]?.currency ??
                                    items[0]?.inventoryItem.currency
                                  )
                                }`}
                            </span>
                          </span>
                        </Typography>
                      </div>

                      <div className="flex flex-col lg:flex-row gap-[8px] lg:gap-[10px]">
                        <Button
                          variant="outlined"
                          className="border-[#CDD7E1] text-[#32383E] focus:bg-[#CDD7E1] focus:border-[#CDD7E1] focus:text-[#32383E] hover:bg-[#CDD7E1] hover:border-[#CDD7E1] hover:text-[#32383E]"
                          onClick={() => {
                            setToCart([]);
                            setResetFlag(!resetFlag);
                          }}
                        >
                          {t("catalog.supplier.items.resetQtyBtn")}
                        </Button>

                        {catalog && (
                          <AddToCartButton
                            toCart={toCart}
                            catalog={catalog}
                            inventoryItems={items}
                            setCartUpdated={setCartUpdated}
                            cartDataCallback={() => {
                              setToCart([]);
                              setResetFlag(!resetFlag);
                            }}
                            orderDataCallback={orderDataCallback}
                            isDisabled={toCart.length === 0}
                            unsavedOrderData={unsavedOrderData}
                          />
                        )}
                      </div>
                    </Stack>
                  </div>
                </If> */}

                {catalog && selectedItem && (
                  <ProductDetailsModal
                    show={selectedItem.show}
                    item={selectedItem.item}
                    catalog={catalog}
                    isDisabled
                    onClose={(item: CatalogInventoryItemType) => {
                      setSelectedItem({
                        show: false,
                        item: item,
                      });
                    }}
                    withAddToCart={isMobile}
                    items={items}
                    orderDataCallback={orderDataCallback}
                    setCartUpdated={setCartUpdated}
                  />
                  // <IfElse
                  //   condition={isMobile}
                  //   ifBlock={
                  //     <ProductDetailsModal
                  //       show={selectedItem.show}
                  //       item={selectedItem.item}
                  //       catalog={catalog}
                  //       isDisabled
                  //       onClose={(item: CatalogInventoryItemType) => {
                  //         setSelectedItem({
                  //           show: false,
                  //           item: item,
                  //         });
                  //       }}
                  //       withAddToCart={isMobile}
                  //       unsavedOrderData={unsavedOrderData}
                  //       items={items}
                  //       orderDataCallback={orderDataCallback}
                  //       setCartUpdated={setCartUpdated}
                  //     />
                  //   }
                  //   elseBlock={
                  //     <EditCatalogueItemModal
                  //       catalogId={catalog?.id}
                  //       showModal={selectedItem.show}
                  //       catalogInventoryItem={selectedItem.item}
                  //       onClose={(item: CatalogInventoryItemType) => {
                  //         setSelectedItem({
                  //           show: false,
                  //           item: item,
                  //         });
                  //       }}
                  //       isBuyerView={true}
                  //     />
                  //   }
                  // />
                )}

                <Snackbar
                  variant="solid"
                  anchorOrigin={{ vertical: "top", horizontal: "center" }}
                  open={showToast}
                  key={"asjfkangdlknakjrsh"}
                  sx={{
                    width: "40%",
                    maxWidth: 800,
                  }}
                >
                  <Box width={"100%"}>
                    <Typography
                      textAlign={"center"}
                      textColor={"common.white"}
                      fontSize={14}
                    >
                      {t(
                        `catalog.${
                          orderId ? "itemAddedToOrder" : "itemAddedToCart"
                        }`
                      )}
                    </Typography>
                  </Box>
                </Snackbar>
              </Stack>
            }
            elseBlock={
              <IfElse
                condition={!!catalog?.periodEndDate?.isAfter?.(dayjs(), "day")}
                ifBlock={
                  <Stack
                    direction={"column"}
                    sx={{
                      width: "100%",
                      justifyContent: "center",
                      alignItems: "center",
                      textAlign: "center",
                    }}
                  >
                    <img
                      src={"/catalog-expired.png"}
                      alt="catalog-expired"
                      width={472}
                      height={422}
                      className={"mx-auto"}
                    />

                    <Stack direction={"column"} gap={"1rem"}>
                      <Typography fontWeight={700} fontSize={32}>
                        Catalogue Expired
                      </Typography>
                      <Typography
                        fontWeight={500}
                        fontSize={16}
                        width={"676px"}
                      >
                        This catalogue is no longer available as it has expired.
                        Please check for other available catalogues or contact
                        the seller for more details.
                      </Typography>
                      <Link to={"/client/suppliers"}>
                        <Button className="rounded-full">
                          Back to Supplier Catalogue
                        </Button>
                      </Link>
                    </Stack>
                  </Stack>
                }
                elseBlock={
                  <ListUnavailable message={t("catalog.noAccessCatalog")} />
                }
              />
            }
          />
        }
        elseBlock={
          <Box display={"flex"} width={"100%"}>
            <If condition={error?.response?.status === 404}>
              <p className="mx-auto my-8">Catalog not found</p>
            </If>
            <If condition={error?.response?.status === 500}>
              <p className="mx-auto my-8">Something went wrong</p>
            </If>
          </Box>
        }
      />
      {/* <div ref={loaderRef} className="flex justify-center w-full">
        {isLoading && "Loading..."}
      </div> */}
    </Sheet>
  );
};

export default ListSupplierItems;
