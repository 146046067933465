import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
} from "@headlessui/react";
import {
  BanknotesIcon,
  CheckIcon,
  ChevronUpDownIcon,
  PresentationChartBarIcon,
  SwatchIcon,
} from "@heroicons/react/24/outline";
import { Stack, Typography } from "@mui/joy";
import { useQuery } from "@tanstack/react-query";
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from "chart.js";
import { useEffect, useRef, useState } from "react";
import { Bar } from "react-chartjs-2";
import { useTranslation } from "react-i18next";
import OutsideClickHandler from "react-outside-click-handler";
import FlagById from "../../../components/FlagById";
import LoadingModal from "../../../components/LoadingModal/LoadingModal";
import { BusinessSessionHelper } from "../../../helpers/business-session.helper";
import { OrderService } from "../../../services/order.service";
import { formatCurrency, formatNumber } from "../../../utils/formatPrice";
import { currencies } from "../Dashboard";
import { StatsCard } from "./StatsCard";
import { StatsModal } from "./StatsModal";
import useContainer from "./useContainer";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export default function DahsboardChart() {
  const {
    handleSelectChange,
    handleChange,
    isLoading,
    state,
    data,
    xLabels,
    analyticData,
    selected,
    setSelected,
  } = useContainer();

  const { t } = useTranslation();

  const [dateRange, setDateRange] = useState({
    name: "this_week",
    value: t("order.filters.date.thisWeek"),
  });

  const haveSOPermission = BusinessSessionHelper.RoleResourcePermissions(
    "sales",
    "READ"
  );

  const havePOPermission = BusinessSessionHelper.RoleResourcePermissions(
    "purchase",
    "READ"
  );

  const { saleData, purchaseData, gmvData, catalogData } = data;
  const series = [
    ...(state["sale"] && haveSOPermission
      ? [
          {
            data: saleData,
            id: "saleId",
            label: "Sales",
          },
        ]
      : []),
    ...(state["catalog"] && haveSOPermission
      ? [
          {
            data: catalogData,
            id: "catalogId",
            label: "Catalog",
          },
        ]
      : []),
    ...(state["purchase"] && havePOPermission
      ? [
          {
            data: purchaseData,
            id: "purchaseId",
            label: "Purchase",
          },
        ]
      : []),
    ...(state["gmv"] && haveSOPermission
      ? [
          {
            data: gmvData,
            id: "gmvId",
            label: "GMV",
          },
        ]
      : []),
  ];
  const getAvailableCurrencyQuery = useQuery({
    queryKey: ["available-currency"],
    queryFn: () => OrderService.getAvailableCurrency(),
  });
  const availableCurrencies = getAvailableCurrencyQuery.data?.data ?? [];
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [coordinates, setCoordinates] = useState({ pageX: 0, pageY: 0 });
  const [selectedChartData, setSelectedChartData] = useState({
    gmv: 0,
    catalog: 0,
    sale: 0,
    purchase: 0,
  });
  const chartRef = useRef<any>(null);
  const barChartRef = useRef<any>(null);

  const handleClose = () => {
    setIsModalOpen(false);
    setCoordinates({ pageX: -100, pageY: -100 });
  };

  useEffect(() => {
    const handleResize = () => {
      const chart = barChartRef.current;
      if (chart) {
        chart.resize();
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  if (isLoading || getAvailableCurrencyQuery.isLoading) {
    return <LoadingModal isLoading={isLoading} />;
  }

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      tooltip: {
        enabled: false,
      },
      legend: {
        display: false,
        position: "top" as const,
      },
      title: {
        display: false,
        text: "Chart.js Bar Chart",
      },
    },
    scales: {
      x: {
        stacked: false,
        grid: {
          display: true,
          drawBorder: false,
        },
      },
      y: {
        beginAtZero: true,
        grid: {
          display: true,
          borderDash: [3, 3],
          drawBorder: false,
        },
        ticks: {
          callback: formatNumber,
        },
      },
    },
    onClick: function (evt: any, element: any) {
      if (element.length > 0) {
        const dataIndex = element[0]?.index;
        const datasetIndex = element[0]?.datasetIndex;
        if (!dataIndex && dataIndex !== 0) return;
        const salesData = series.filter((s) => s.id === "saleId")[0]?.data[
          dataIndex
        ];
        const purchaseData = series.filter((s) => s.id === "purchaseId")[0]
          ?.data[dataIndex];
        const gmvData = series.filter((s) => s.id === "gmvId")[0]?.data[
          dataIndex
        ];
        const catalogData = series.filter((s) => s.id === "catalogId")[0]?.data[
          dataIndex
        ];
        setSelectedChartData({
          gmv: gmvData ?? 0,
          catalog: catalogData ?? 0,
          sale: salesData ?? 0,
          purchase: purchaseData ?? 0,
        });
        setCoordinates({
          pageX: evt.x + 10 * (3 - datasetIndex),
          pageY: 0,
        });
        setIsModalOpen(true);
      }
    },
  };

  const chart_data = {
    labels: xLabels,
    datasets: [
      ...(state["sale"] && haveSOPermission
        ? [
            {
              label: "Sale",
              data: saleData,
              backgroundColor: "#8CBEA7",
            },
          ]
        : []),
      ...(state["catalog"] && haveSOPermission
        ? [
            {
              label: "Catalog",
              data: catalogData,
              backgroundColor: "#DEBBFD",
            },
          ]
        : []),
      ...(state["gmv"] && haveSOPermission
        ? [
            {
              label: "GMV",
              data: gmvData,
              backgroundColor: "#FDEDDB",
            },
          ]
        : []),
      ...(state["purchase"] && havePOPermission
        ? [
            {
              label: "Purchase",
              data: purchaseData,
              backgroundColor: "#B0EFF0",
            },
          ]
        : []),
    ],
  };
  return (
    <>
      {/* overview */}
      <div className="flex flex-col gap-4 lg:flex-row lg:items-center lg:justify-between">
        <p className="text-[#101828] text-xl font-bold">
          {t("dashboard.overview.title")}
        </p>
        <div className="flex gap-4 items-center">
          <Listbox
            value={dateRange}
            onChange={(data) => {
              setDateRange(data);
              handleSelectChange(data);
            }}
          >
            <div className="relative mt-2">
              <ListboxButton className="relative w-fit cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-[#499873] sm:text-sm/6">
                <span className="flex items-center">
                  <span className="ml-3 block truncate font-normal group-data-[selected]:font-semibold">
                    {dateRange.value}
                  </span>
                </span>
                <span className="flex absolute inset-y-0 right-0 items-center pr-2 ml-3 pointer-events-none">
                  <ChevronUpDownIcon
                    aria-hidden="true"
                    className="text-gray-400 size-5"
                  />
                </span>
              </ListboxButton>
              <ListboxOptions
                transition
                className="absolute z-10 mt-1 max-h-56 w-fit overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none data-[closed]:data-[leave]:opacity-0 data-[leave]:transition data-[leave]:duration-100 data-[leave]:ease-in sm:text-sm"
              >
                {[
                  {
                    name: "this_week",
                    value: t("order.filters.date.thisWeek"),
                  },
                  {
                    name: "this_month",
                    value: t("order.filters.date.thisMonth"),
                  },
                  {
                    name: "this_year",
                    value: t("order.filters.date.thisYear"),
                  },
                ].map((date_range) => (
                  <ListboxOption
                    key={date_range.name}
                    value={date_range}
                    className="group relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900 data-[focus]:bg-[#499873] data-[focus]:text-white"
                  >
                    <div className="flex items-center">
                      <span className="ml-3 block truncate font-normal group-data-[selected]:font-semibold">
                        {date_range.value}
                      </span>
                    </div>

                    <span className="absolute inset-y-0 right-0 flex items-center pr-4 text-[#499873] group-data-[focus]:text-white [.group:not([data-selected])_&]:hidden">
                      <CheckIcon aria-hidden="true" className="size-5" />
                    </span>
                  </ListboxOption>
                ))}
              </ListboxOptions>
            </div>
          </Listbox>
          {availableCurrencies.length > 1 && (
            <Listbox value={selected} onChange={setSelected}>
              <div className="relative mt-2">
                <ListboxButton className="relative w-fit cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-[#499873] sm:text-sm/6">
                  <span className="flex items-center">
                    <FlagById id={selected.id.toUpperCase()} />
                    <span className="block ml-3 truncate">{selected.name}</span>
                  </span>
                  <span className="flex absolute inset-y-0 right-0 items-center pr-2 ml-3 pointer-events-none">
                    <ChevronUpDownIcon
                      aria-hidden="true"
                      className="text-gray-400 size-5"
                    />
                  </span>
                </ListboxButton>

                <ListboxOptions
                  transition
                  className="absolute z-10 mt-1 max-h-56 w-fit overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none data-[closed]:data-[leave]:opacity-0 data-[leave]:transition data-[leave]:duration-100 data-[leave]:ease-in sm:text-sm"
                >
                  {currencies
                    .filter((cur) => availableCurrencies.includes(cur.name))
                    .map((currency) => (
                      <ListboxOption
                        key={currency.id}
                        value={currency}
                        className="group relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900 data-[focus]:bg-[#499873] data-[focus]:text-white"
                      >
                        <div className="flex items-center">
                          <FlagById id={currency.id.toUpperCase()} />
                          <span className="ml-3 block truncate font-normal group-data-[selected]:font-semibold">
                            {currency.name}
                          </span>
                        </div>

                        <span className="absolute inset-y-0 right-0 flex items-center pr-4 text-[#499873] group-data-[focus]:text-white [.group:not([data-selected])_&]:hidden">
                          <CheckIcon aria-hidden="true" className="size-5" />
                        </span>
                      </ListboxOption>
                    ))}
                </ListboxOptions>
              </div>
            </Listbox>
          )}
        </div>
      </div>
      {/* cards */}
      <div className="flex flex-col gap-6 items-stretch lg:items-center lg:flex-row">
        {haveSOPermission && (
          <div className="w-full lg:w-1/4">
            <StatsCard
              Icon={<BanknotesIcon className="w-8 h-8 text-[#101828]" />}
              amount={formatCurrency(
                analyticData.sales.amount,
                selected.id.toUpperCase()
              )}
              ongoing={analyticData.sales.ongoing}
              outgoing={analyticData.sales.completed}
              bgColor="bg-[#8CBEA7]"
              title={t("dashboard.totalSalesOrder")}
            />
          </div>
        )}
        {haveSOPermission && (
          <div className="w-full lg:w-1/4">
            <StatsCard
              Icon={<SwatchIcon className="w-8 h-8 text-[#101828]" />}
              amount={formatCurrency(
                analyticData.catalog.amount,
                selected.id.toUpperCase()
              )}
              ongoing={analyticData.catalog.ongoing}
              outgoing={analyticData.catalog.completed}
              bgColor="bg-[#DEBBFD]"
              title={t("dashboard.totalCatalogOrder")}
            />
          </div>
        )}
        {haveSOPermission && (
          <div className="w-full lg:w-1/4">
            <StatsCard
              Icon={
                <PresentationChartBarIcon className="w-8 h-8 text-[#101828]" />
              }
              amount={formatCurrency(
                analyticData.gmv.amount,
                selected.id.toUpperCase()
              )}
              ongoing={analyticData.gmv.ongoing}
              outgoing={analyticData.gmv.completed}
              bgColor="bg-[#FDEDDB]"
              title={t("dashboard.totalGMVOrder")}
            />
          </div>
        )}
        {havePOPermission && (
          <div className="w-full lg:w-1/4">
            <StatsCard
              Icon={<img src="/cart.svg" className="w-8 h-8 text-[#101828]" />}
              amount={formatCurrency(
                analyticData.purchases.amount,
                selected.id.toUpperCase()
              )}
              ongoing={analyticData.purchases.ongoing}
              outgoing={analyticData.purchases.completed}
              bgColor="bg-[#B0EFF0]"
              title={t("dashboard.totalPurxhaseOrder")}
            />
          </div>
        )}
      </div>
      <Stack
        gap={4}
        className="bg-[#FBFCFE] rounded-3xl p-6 shadow-lg w-full relative flex  flex-col gap-6 lg:flex-row"
      >
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
          className="lg:order-2"
        >
          <div className="grid grid-cols-2 w-full lg:flex lg:gap-4 lg:flex-col">
            {haveSOPermission && (
              <Stack
                direction={"row"}
                alignItems={"center"}
                gap={"9px"}
                className="w-full"
              >
                <input
                  onChange={(e) => handleChange("sale", e.target.checked)}
                  className="w-4 h-4"
                  style={{ accentColor: "#8CBEA7" }}
                  type="checkbox"
                  id="gross-sale"
                  checked={state["sale"]}
                />
                <label htmlFor="gross-sale">
                  <Typography fontSize={14} textColor={"neutral.500"}>
                    {t("dashboard.overview.sales")}
                  </Typography>
                </label>
              </Stack>
            )}
            {haveSOPermission && (
              <Stack
                direction={"row"}
                className="w-full"
                alignItems={"center"}
                gap={"9px"}
              >
                <input
                  onChange={(e) => handleChange("catalog", e.target.checked)}
                  className="w-4 h-4"
                  style={{ accentColor: "#DEBBFD" }}
                  type="checkbox"
                  id="gross-catalog"
                  checked={state["catalog"]}
                />
                <label htmlFor="gross-catalog">
                  <Typography fontSize={14} textColor={"neutral.500"}>
                    {t("dashboard.overview.catalog")}
                  </Typography>
                </label>
              </Stack>
            )}
            {haveSOPermission && (
              <Stack
                direction={"row"}
                className="w-full"
                alignItems={"center"}
                gap={"9px"}
              >
                <input
                  onChange={(e) => handleChange("gmv", e.target.checked)}
                  className="w-4 h-4"
                  style={{ accentColor: "#FDEDDB" }}
                  type="checkbox"
                  id="gmv"
                  checked={state["gmv"]}
                />
                <label htmlFor="gmv">
                  <Typography fontSize={14} textColor={"neutral.500"}>
                    {t("dashboard.overview.gmv")}
                  </Typography>
                </label>
              </Stack>
            )}
            {havePOPermission && (
              <Stack
                direction={"row"}
                className="w-full"
                alignItems={"center"}
                gap={"9px"}
              >
                <input
                  onChange={(e) => handleChange("purchase", e.target.checked)}
                  className="w-4 h-4"
                  style={{ accentColor: "#B0EFF0" }}
                  type="checkbox"
                  id="purchase"
                  checked={state["purchase"]}
                />
                <label htmlFor="gross-profit">
                  <Typography fontSize={14} textColor={"neutral.500"}>
                    {t("dashboard.overview.purchase")}
                  </Typography>
                </label>
              </Stack>
            )}
          </div>
        </Stack>
        <div
          ref={chartRef}
          className={`flex overflow-x-scroll flex-col flex-1 gap-6 lg:flex-row`}
        >
          <div className="flex-1 min-h-[400px] min-w-[993px] relative">
            {isModalOpen && (
              <div
                className="absolute inset-0 w-full h-fit"
                onClick={handleClose}
              >
                <OutsideClickHandler
                  onOutsideClick={() => {
                    handleClose();
                  }}
                >
                  <div
                    style={{
                      left: `${coordinates.pageX}px`,
                      transform: "translate(-50%, 0)",
                    }}
                    className="absolute z-50 top-[-80px] md:top-0"
                  >
                    <div
                      className="relative my-6 w-fit"
                      onClick={(e) => e.stopPropagation()}
                    >
                      <div className="relative flex flex-col bg-white border-0 rounded-lg outline-none focus:outline-none w-[150px]">
                        <StatsModal data={selectedChartData} />
                      </div>
                    </div>
                  </div>
                </OutsideClickHandler>
              </div>
            )}
            <Bar
              ref={barChartRef}
              options={options}
              data={chart_data}
              className="h-full w-[993px]"
            />
          </div>
        </div>
      </Stack>
    </>
  );
}
