import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import AspectRatio from "@mui/joy/AspectRatio";
import Card from "@mui/joy/Card";
import CardContent from "@mui/joy/CardContent";
import Grid from "@mui/joy/Grid";
import IconButton from "@mui/joy/IconButton";
import Stack from "@mui/joy/Stack";
import Typography from "@mui/joy/Typography";
import { Helmet } from "react-helmet";
import { Link as RouterLink } from "react-router-dom";
import Shared from "../layouts/Shared";

const catalogs = [
  {
    id: 1,
    uuid: "catalog-1",
    name: "Catalog 1",
    items: [
      {
        id: 1,
        uuid: "item-1",
        description: "Item 1",
        name: "Item 1",
        price: 100,
        images: [
          "https://images.unsplash.com/photo-1661956601349-f61ddb82d179?ixlib=rb-4.0.3&ixid=MnwxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=774&q=80",
          "https://images.unsplash.com/photo-1661956601349-f61ddb82d179?ixlib=rb-4.0.3&ixid=MnwxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=774&q=80",
        ],
      },
    ],
  },
];
const catalog = catalogs[0];

export default function Items() {
  return (
    <Shared title={catalog.name}>
      <Helmet
        link={[
          {
            href: `https://linkzasia.com/catalogs/${catalog.uuid}/items`,
            rel: "canonical",
          },
        ]}
        meta={[{ content: "Catalog items", name: "description" }]}
        title="Catalog items - Linkz"
      />
      <Grid
        borderRadius="25px 25px 0px 0"
        container
        marginTop={0.1}
        padding={2}
        spacing={2}
        sx={{ backgroundColor: "white" }}
      >
        {catalog.items?.map(({ description, images, name, price, uuid }) => (
          <Grid key={uuid} xs={6}>
            <Card
              component={RouterLink}
              sx={{
                "--Card-padding": "0px",
                textDecoration: "none",
              }}
              to={`/items/${uuid}`}
              variant="plain"
            >
              <AspectRatio ratio="1">
                <img
                  alt={name}
                  height={300}
                  src={images[0] || "https://placehold.co/150@3x.png"}
                  width={400}
                />
              </AspectRatio>
              <CardContent>
                <Stack
                  alignItems="center"
                  direction="row"
                  justifyContent="space-between"
                >
                  <Typography fontWeight="lg" noWrap>
                    {name}
                  </Typography>
                  <IconButton onClick={() => console.log("Favorited!")}>
                    <FavoriteBorderOutlinedIcon />
                  </IconButton>
                </Stack>
                <Typography noWrap textColor="text.tertiary">
                  {description}
                </Typography>
                <Typography fontWeight="bold">${price}</Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Shared>
  );
}
