import {
  Add,
  DangerousOutlined,
  DeleteOutline,
  SearchOutlined,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Chip,
  Input,
  List,
  ListItem,
  Option,
  Select,
  Sheet,
  Stack,
  Tab,
  TabList,
  TabPanel,
  Tabs,
  Typography,
  tabClasses,
} from "@mui/joy";
import React, { useCallback, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroller";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { SuccessToastConfig } from "../../components/Error";
import ConfirmDeleteModal from "../../components/Inventory/ConfirmDeleteModal";
import LoadingModal from "../../components/LoadingModal/LoadingModal";
import { theme } from "../../components/Theme";
import { InventoryService } from "../../services/inventory.service";
import { getActiveBusiness } from "../../store/session";
import { getInitials } from "../../utils/misc";
import InviteModal, { IAccountBusiness } from "./components/InviteModal";
import useContainer from "./useContainer";
import debounce from "lodash.debounce";
import { ChevronDownIcon } from "@heroicons/react/24/solid";
import { EnvelopeIcon, PhoneIcon } from "@heroicons/react/24/outline";
import dayjs from "dayjs";
import ConfirmDuplicateModal from "../../components/Inventory/ConfirmDuplicateModal";
import { CatalogType } from "../../types/catalog";
import { paymentFlowTypes } from "../../types/order";

const ListCatalogue = () => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const qstatus = searchParams.get("status");
  const query = searchParams.get("q");
  const [confirmDuplicate, setConfirmDuplicate] = useState(false);
  const [activeCatalog, setActiveCatalog] = useState<CatalogType | null>(null);

  const [selectedTab, setSelectedTab] = useState(
    searchParams.get("type") ?? "public"
  );
  const [selectedCatalog, setSelectedCatalog] = useState<string>("");
  const [searchTerm, setSearchTerm] = useState<string>();
  const navigate = useNavigate();
  const statusFilter = ["Active", "Expired"];

  const {
    fetchAllCatalogs,
    pagination,
    isLoading,
    setIsLoading,
    hasMore,
    catalogs,
    setCatalogs,
    confirmDelete,
    setConfirmDelete,
    setShowAlert,
    setAlertMessage,
  } = useContainer();

  const fetchCatalogue = () => {
    setIsLoading(true);
    fetchAllCatalogs({
      businessId: getActiveBusiness()?.id ?? "",
      limit: 10,
      offset: 0,
      catalogType: selectedTab.toUpperCase() as "PUBLIC" | "PRIVATE", // had to cast, will fix later
      searchTerm: query,
      isReset: true,
      publicCatalogPeriodType: qstatus?.toUpperCase() ?? "ALL",
    });
  };

  useEffect(() => {
    fetchCatalogue();
  }, [searchTerm, query, qstatus]);

  const handleChangeTab = (
    _event: React.SyntheticEvent | null,
    value: string | number | null
  ) => {
    if (typeof value === "string") {
      setSelectedTab(value);
      setIsLoading(true);
      setCatalogs([]);
      fetchAllCatalogs({
        businessId: getActiveBusiness()?.id ?? "",
        limit: 10,
        offset: 0,
        searchTerm: query,
        catalogType: value.toUpperCase() as "PUBLIC" | "PRIVATE", // had to cast, will fix later
        isReset: true,
        publicCatalogPeriodType: qstatus?.toUpperCase() ?? "ALL",
      });
    }
  };

  const handleRemove = () => {
    setIsLoading(true);
    InventoryService.removeCatalog({
      catalogId: selectedCatalog,
    })
      .then((res) => {
        setIsLoading(false);
        if (res) {
          setAlertMessage(t("catalog.catalogRemoved"));
          setShowAlert(true);
          fetchAllCatalogs({
            businessId: getActiveBusiness()?.id ?? "",
            limit: 10,
            offset: 0,
            catalogType: selectedTab.toUpperCase() as "PUBLIC" | "PRIVATE", // had to cast, will fix later
            isReset: true,
            publicCatalogPeriodType: qstatus?.toUpperCase() ?? "ALL",
          });
        }
      })
      .catch((err) => console.error(err));
  };

  const handleDuplicate = () => {
    setIsLoading(true);

    if (!activeCatalog) return;

    const businessId = getActiveBusiness()?.id;
    console.log(activeCatalog);

    if (activeCatalog.id && businessId) {
      InventoryService.duplicateCatalog({
        catalogId: activeCatalog.id,
      })
        .then((res) => {
          if (res.id) {
            setShowAlert(true);
            setAlertMessage(
              t("catalog.inventory.duplicateCatalog.successMessage")
            );

            setTimeout(() => {
              setShowAlert(false);
            }, 3000);
          }
        })
        .catch((err) => console.error(err))
        .finally(() => {
          setIsLoading(false);
          window.location.href = `/client/catalogues/list?type=${
            activeCatalog?.catalogType.toLowerCase() ?? "public"
          }`;
        });
    }
  };

  const debouncedSetSearchTerm = useCallback(
    debounce((value: string) => {
      if (value === "") {
        searchParams.delete("q");
      } else {
        searchParams.set("q", value);
      }
      setSearchParams(searchParams);
      setSearchTerm(value);
    }, 300),
    []
  );

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    debouncedSetSearchTerm(event.target.value);
  };

  const CatalogInfo = ({ title, value }: { title: string; value: string }) => {
    return (
      <div className=" flex justify-between items-center">
        <div className="flex items-center">
          <div className="flex  items-center gap-5">
            <span
              className={`text-xs text-[${theme.colorSchemes.light.palette.others.secondary_text}] `}
            >
              {title}
            </span>
            <span
              className={`text-xs [${theme.colorSchemes.light.palette.others.secondary_text}]`}
            >
              {":"}
            </span>
          </div>
          <span className="text-xs font-semibold">{value}</span>
        </div>
      </div>
    );
  };

  const CatalogueView = ({ tabValue }: { tabValue: string }) => {
    return (
      <>
        <Stack
          direction={{ sx: "column", md: "row" }}
          mt={"24px"}
          sx={{
            justifyContent: "space-between",
            alignItems: { md: "center" },
          }}
        >
          <Stack direction={"row"} spacing={1}>
            <Input
              defaultValue={query ?? ""}
              variant="linkz-input-search"
              size="sm"
              name="searchFormData.search"
              placeholder={t("catalog.searchCatalogPlaceholder")}
              startDecorator={<SearchOutlined />}
              sx={{ flexGrow: 1, width: { xs: "100%", lg: "41%" } }}
              onChange={handleChange}
            />

            <Select
              className="hidden md:flex"
              value={qstatus ?? ""}
              onChange={(
                _event: React.SyntheticEvent | null,
                newValue: string | null
              ) => {
                if (newValue || newValue === "") {
                  const newParams = new URLSearchParams(searchParams);

                  if (newValue === "") {
                    newParams.delete("status");
                  } else {
                    newParams.set("status", encodeURIComponent(newValue));
                  }
                  setSearchParams(newParams);
                }
              }}
              slotProps={{ button: { sx: { whiteSpace: "nowrap" } } }}
              size="sm"
              defaultValue={qstatus ?? ""}
              sx={{
                display: { sx: "none", md: "flex" },
                paddingY: "10px",
                paddingX: "16px",
                fontWeight: 600,
                gap: "8px",
                color: theme.colorSchemes.light.palette.others.neutral_dark,
                borderColor:
                  theme.colorSchemes.light.palette.others.neutral_dark,
              }}
              indicator={<ChevronDownIcon width={18} height={18} />}
            >
              <Option value={""}>{t("catalog.filter")}</Option>
              {statusFilter.map((s) => (
                <Option key={s} value={s}>
                  {s}
                </Option>
              ))}
            </Select>
          </Stack>
          <Stack direction={"row"} spacing={1} mt={2}>
            <div className="w-32">
              <Select
                className="md:hidden"
                value={qstatus ?? ""}
                onChange={(
                  _event: React.SyntheticEvent | null,
                  newValue: string | null
                ) => {
                  if (newValue || newValue === "") {
                    const newParams = new URLSearchParams(searchParams);

                    if (newValue === "") {
                      newParams.delete("status");
                    } else {
                      newParams.set("status", encodeURIComponent(newValue));
                    }
                    setSearchParams(newParams);
                    // setSelectedFilter(newValue);
                  }
                }}
                slotProps={{ button: { sx: { whiteSpace: "nowrap" } } }}
                size="sm"
                defaultValue={qstatus ?? ""}
                sx={{
                  paddingY: { sx: "8px", md: "10px" },
                  paddingX: { sx: "12px", md: "16px" },
                  fontWeight: 600,
                  gap: "8px",
                  color: theme.colorSchemes.light.palette.others.neutral_dark,
                  borderColor:
                    theme.colorSchemes.light.palette.others.neutral_dark,
                }}
                indicator={<ChevronDownIcon width={18} height={18} />}
              >
                <Option value={""}>{t("catalog.filter")}</Option>
                {statusFilter.map((s) => (
                  <Option key={s} value={s}>
                    {s}
                  </Option>
                ))}
              </Select>
            </div>
            <Button
              size="sm"
              className="rounded-md text-[12px] md:text-sm"
              startDecorator={<Add />}
              onClick={() => {
                const createCatalogPayload = {
                  business: {
                    id: getActiveBusiness()?.id ?? "",
                  },
                  catalogType: tabValue.toUpperCase(),
                  itemInfos: [],
                  items: [],
                };

                localStorage.setItem(
                  "new-catalog",
                  JSON.stringify(createCatalogPayload)
                );
                navigate(
                  "/client/catalogues/create?type=" + tabValue.toUpperCase()
                );
              }}
            >
              {t(
                tabValue.toUpperCase() == "PUBLIC"
                  ? "catalog.catalogPublicCatalogBtn"
                  : "catalog.catalogPrivateCatalogBtn"
              )}
            </Button>
          </Stack>
        </Stack>

        <List sx={{ marginTop: 3 }}>
          <InfiniteScroll
            pageStart={0}
            loadMore={(_page: number) => {
              console.log("LOADMORE TRIGGERED");
              fetchAllCatalogs({
                businessId: getActiveBusiness()?.id ?? "",
                limit: 10,
                offset: pagination.limit * pagination.current,
                catalogType: tabValue.toUpperCase() as "PUBLIC" | "PRIVATE",
                searchTerm: query,
                isReset: false,
                publicCatalogPeriodType: qstatus?.toUpperCase() ?? "ALL",
              });
            }}
            useWindow={false}
            hasMore={hasMore}
            loader={
              <div className="loader" key={0}>
                Loading ...
              </div>
            }
          >
            {catalogs.map((cat) => {
              const accounts = cat.accounts?.map((account) => ({
                ...account,
                newlySelectedBusinessId: account?.recipientBusiness,
                identifier:
                  account?.authStrategy === "PHONE_AUTH"
                    ? account.phoneNumber
                    : account?.email,
              }));
              return (
                <ListItem
                  key={cat.id}
                  sx={{
                    py: { xs: "1.5rem", md: "0.5rem", lg: "1rem" },
                    px: { xs: "1.5rem", md: "10px" },
                    my: { xs: "0.25rem", lg: "0.5rem" },
                    display: { xs: "block", lg: "flex" },
                    alignItems: "center",
                    width: "100%",
                    borderRadius: { xs: "8px", lg: 0 },
                    border: "1px solid",
                    borderTop: { lg: "none" },
                    borderLeft: { lg: "none" },
                    borderRight: { lg: "none" },
                    borderBottom: { lg: "1px solid" },
                    borderColor:
                      theme.colorSchemes.light.palette.others.disabled,
                  }}
                >
                  {/* <div
                    className={`md:py-2 py-6 px-6 md:px-2.5 lg:py-4 my-1 lg:my-2 lg:flex algin-middle w-full rounded-lg lg:rounded-none border lg:border-t-0 lg:border-l-0 lg:border-r-0 lg:border-b-1 border-[${theme.colorSchemes.light.palette.others.disabled}]`}
                  > */}
                  <Box
                    sx={{
                      mb: { xs: "0", md: "0.5rem" },
                    }}
                  >
                    <Stack
                      direction={{ xs: "row", md: "column" }}
                      sx={{
                        justifyContent: {
                          xs: "space-between",
                          md: "flex-start",
                        },
                        alignItems: { xs: "center", md: "flex-start" },
                      }}
                    >
                      <Stack
                        direction={{ xs: "column", md: "row" }}
                        sx={{
                          justifyContent: { md: "flex-start" },
                          alignItems: { md: "center" },
                          gap: { xs: "4px", md: "12px" },
                          mb: "0.5rem",
                        }}
                      >
                        {dayjs().isAfter(dayjs(cat.periodEndDate)) ? (
                          <Chip
                            color="neutral"
                            sx={{
                              borderRadius: 10,
                              paddingY: 0.3,
                              paddingX: 0.7,
                              border: 1,
                              backgroundColor: "#EAECF0",
                              borderColor: "#98A2B3",
                            }}
                          >
                            Expired
                          </Chip>
                        ) : (
                          <Chip
                            color="success"
                            sx={{
                              borderRadius: 10,
                              paddingY: 0.3,
                              paddingX: 0.7,
                              border: 1,
                              backgroundColor: "#CFE5DA",
                              borderColor: "#408565",
                            }}
                          >
                            Active
                          </Chip>
                        )}
                        <Typography fontSize={18} fontWeight={600}>
                          {cat.catalogName}
                        </Typography>
                      </Stack>
                      <Button
                        variant="outlined"
                        size="sm"
                        className="md:hidden text-sm min-w-8 p-2 border-danger-200  text-nowrap lg:text-base rounded-md"
                        onClick={() => {
                          setSelectedCatalog(cat.id);
                          setConfirmDelete(true);
                        }}
                      >
                        <DeleteOutline color="error" className="w-8 h-8" />
                      </Button>
                    </Stack>
                    <Stack
                      direction={{ xs: "column", md: "row" }}
                      gap={{ lg: "44px" }}
                    >
                      <Stack direction={"column"} alignItems={"flex-start"}>
                        <CatalogInfo
                          title={t("catalog.catalogTypeOfPayment")}
                          value={
                            paymentFlowTypes.find(
                              (flow) => flow.value === cat.paymentFlowType
                            )?.label || "-"
                          }
                        />
                        {cat?.currency && (
                          <CatalogInfo
                            title={t("catalog.catalogCurrency")}
                            value={cat.currency}
                          />
                        )}

                        <CatalogInfo
                          title={t("catalog.catalogTax")}
                          value={cat?.taxId?.taxLabel || "-"}
                        />
                      </Stack>

                      <Stack direction={"column"} alignItems={"flex-start"}>
                        <CatalogInfo
                          title={t("catalog.catalogPeriod")}
                          value={
                            cat.periodStartDate
                              ? `${dayjs(cat.periodStartDate).format(
                                  "YYYY-MM-DD"
                                )} ~ ${dayjs(cat.periodEndDate).format(
                                  "YYYY-MM-DD"
                                )}`
                              : "-"
                          }
                        />

                        <CatalogInfo
                          title={t("catalog.catalogLastUpdated")}
                          value={dayjs(cat?.updatedAt).format("YYYY-MM-DD")}
                        />
                        {cat?.itemsCount && (
                          <CatalogInfo
                            title={t("catalog.catalogTotalItems")}
                            value={`${cat.itemsCount} Items`}
                          />
                        )}
                      </Stack>
                    </Stack>
                  </Box>

                  <Stack
                    direction={"row"}
                    sx={{
                      alignItems: { md: "center" },
                      gap: { md: "12px" },
                      justifyContent: "flex-end",
                      ml: { md: "auto" },
                    }}
                    // className=" flex md:items-center justify-end md:gap-3 md:ml-auto"
                  >
                    <UserListButton
                      selectedTab={selectedTab}
                      fetchCatalogue={fetchCatalogue}
                      accounts={accounts ?? []}
                      catalogId={cat.id}
                    />

                    <Link
                      to={`/client/catalogues/inventory/${cat.id}/items`}
                      className="lg:mx-auto lg:ml-auto lg:mr-0"
                    >
                      <Button
                        variant="solid"
                        className=" ml-3 md:ml-0 mt-4 min-w-44 md:min-w-20 text-sm lg:mt-0 text-nowrap lg:text-base rounded-md"
                      >
                        {t("catalog.viewBtn")}
                      </Button>
                    </Link>
                    <Button
                      variant="outlined"
                      className="hidden md:flex mt-4 min-w-20 text-sm lg:mt-0 text-nowrap lg:text-base rounded-md"
                      onClick={() => {
                        setConfirmDuplicate(true);
                        setActiveCatalog(cat);
                      }}
                    >
                      {t("catalog.duplicateBtn")}
                    </Button>
                    <Button
                      variant="plain"
                      size="sm"
                      className="hidden md:flex mt-4 text-sm min-w-10 lg:mt-0 text-nowrap lg:text-base rounded-md"
                      onClick={() => {
                        setSelectedCatalog(cat.id);
                        setConfirmDelete(true);
                      }}
                    >
                      <DeleteOutline color="error" className="w-8 h-8" />
                    </Button>
                  </Stack>
                  {/* </div> */}
                </ListItem>
              );
            })}
          </InfiniteScroll>
        </List>
        {/* <div ref={loaderRef} className="flex justify-center w-full">
          {isLoading && "Loading..."}
        </div> */}
      </>
    );
  };

  return (
    <Sheet sx={{ backgroundColor: "transparent" }}>
      {!isLoading && (
        <Tabs
          aria-label="Basic tabs"
          value={selectedTab}
          onChange={handleChangeTab}
          sx={{
            backgroundColor: "transparent",
            marginTop: 4,
            width: { xs: "90vw", md: "75vw" },
          }}
        >
          <TabList
            sx={{
              [`&& .${tabClasses.root}`]: {
                "&:hover": {
                  bgcolor: "transparent",
                },
                [`&.${tabClasses.selected}`]: {
                  "&::after": {
                    bgcolor: "primary.500",
                    borderTopLeftRadius: 3,
                    borderTopRightRadius: 3,
                    height: 2,
                  },
                  color: "primary.plainColor",
                },
                bgcolor: "transparent",
                flex: "initial",
                paddingY: 2,
              },
            }}
          >
            <Tab
              value={"public"}
              key={"public"}
              sx={{
                fontWeight: "600",
                whiteSpace: "nowrap",
                minWidth: 100,
                flex: "none",
                scrollSnapAlign: "start",
                flexDirection: "column",
                paddingX: "3rem",
              }}
            >
              {t("catalog.catalogType.publicLabel")}
            </Tab>
            <Tab
              value={"private"}
              key={"private"}
              sx={{
                fontWeight: "600",
                whiteSpace: "nowrap",
                minWidth: 150,
                flex: "none",
                scrollSnapAlign: "start",
                flexDirection: "column",
                paddingX: "3rem",
              }}
            >
              {t("catalog.catalogType.privateLabel")}
            </Tab>
          </TabList>

          {/* default public empty tab */}
          <TabPanel
            value={"public"}
            key={"public"}
            sx={{
              px: {
                xs: 0,
                lg: "1.5rem",
              },
              py: 0,
            }}
          >
            {catalogs.length > 0 ? (
              <CatalogueView tabValue="public" />
            ) : (
              <Stack
                justifyContent={"center"}
                alignItems={"center"}
                mt={"32px"}
              >
                <DangerousOutlined
                  color="primary"
                  sx={{ width: "100px", height: "100px" }}
                />
                <br />
                <Typography fontWeight={"lg"} fontSize={"sm"}>
                  {t("catalog.emptyCatalogPrompt")}
                </Typography>
              </Stack>
            )}
          </TabPanel>

          {/* default private empty tab */}
          <TabPanel
            value={"private"}
            key={"private"}
            sx={{
              px: {
                xs: 0,
                lg: "1.5rem",
              },
              py: 0,
            }}
          >
            {catalogs.length > 0 ? (
              <CatalogueView tabValue="private" />
            ) : (
              <Stack
                justifyContent={"center"}
                alignItems={"center"}
                mt={"32px"}
              >
                <DangerousOutlined
                  color="primary"
                  sx={{ width: "100px", height: "100px" }}
                />
                <br />
                <Typography fontWeight={"lg"} fontSize={"sm"}>
                  {t("catalog.emptyCatalogPrompt")}
                </Typography>
              </Stack>
            )}
          </TabPanel>
        </Tabs>
      )}

      <ConfirmDeleteModal
        show={confirmDelete}
        onClick={handleRemove}
        onClose={() => {
          setConfirmDelete(false);
        }}
      />

      <ConfirmDuplicateModal
        show={confirmDuplicate}
        catalogType={
          t(
            `catalog.catalogType.${activeCatalog?.catalogType.toLowerCase()}`
          ) ?? "private"
        }
        onClick={handleDuplicate}
        onClose={() => {
          setConfirmDuplicate(false);
        }}
      />
      <LoadingModal isLoading={isLoading} />
    </Sheet>
  );
};

const UserListButton = ({
  catalogId,
  accounts,
  selectedTab,
  fetchCatalogue,
}: {
  fetchCatalogue: () => void;
  catalogId: string;
  selectedTab: string;
  accounts: IAccountBusiness[];
}) => {
  const { t } = useTranslation();

  const accountCount = accounts.length;
  const business = getActiveBusiness();
  const [showModal, setShowModal] = useState(false);
  console.log("ACCOUNTS", accounts);
  const showToast = () => {
    toast(t("catalog.shareCatalog.shareSuccess"), SuccessToastConfig);
  };

  return (
    <>
      <div
        onClick={() => setShowModal(true)}
        className="flex items-center justify-center cursor-pointer"
      >
        {accountCount >= 1 && (
          <div className="flex border font-bold  border-[#636B744D] items-center justify-center w-8 h-8 text-sm text-[#499873] uppercase bg-[#F1F8F4] rounded-full">
            {accounts[0].id ? (
              getInitials(accounts[0]?.profile?.fullName ?? accounts[0].email)
            ) : accounts[0].authStrategy === "PHONE_AUTH" ? (
              <PhoneIcon width={23.25} height={23.2} />
            ) : (
              <EnvelopeIcon width={23.25} height={23.2} />
            )}
          </div>
        )}
        {accountCount >= 2 && (
          <div className="flex border font-bold  border-[#636B744D] items-center justify-center w-8 h-8 -ml-3 text-sm text-[#499873] uppercase bg-[#F1F8F4] rounded-full">
            {accounts[1].id ? (
              getInitials(accounts[1]?.profile?.fullName ?? accounts[1].email)
            ) : accounts[1].authStrategy === "PHONE_AUTH" ? (
              <PhoneIcon width={23.25} height={23.2} />
            ) : (
              <EnvelopeIcon width={23.25} height={23.2} />
            )}
          </div>
        )}
        {accountCount > 2 && (
          <div className="flex items-center justify-center w-8 h-8 -ml-3 text-sm text-white bg-[#499873] rounded-full">
            +{accountCount - 2}
          </div>
        )}
      </div>
      {showModal && (
        <InviteModal
          selectedTab={selectedTab}
          catalogId={catalogId}
          businessId={business?.id ?? ""}
          accounts={accounts}
          show={showModal}
          onClose={() => {
            setShowModal(false);
            fetchCatalogue();
          }}
          showToast={showToast}
        />
      )}
    </>
  );
};

export default ListCatalogue;
