import Button from "@mui/joy/Button";
import Tabs from "@mui/joy/Tabs";
// import Shared from "../../layouts/Shared";
import { Add, Remove } from "@mui/icons-material";
import {
  Autocomplete,
  AutocompleteOption,
  Box,
  Divider,
  FormControl,
  FormLabel,
  Grid,
  Input,
  Textarea,
  Typography,
} from "@mui/joy";
import Stack from "@mui/joy/Stack";
import { useEffect, useRef, useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import {
  Link,
  useLocation,
  // useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import {
  Toggler,
  TwentyEightDaysPicker,
  formatInventoryItemResponse,
  transformUomList,
} from "../../components/utils";
import { AccountService } from "../../services/account.service";
import { ItemService } from "../../services/item.service";
import { OrderService } from "../../services/order.service";

import {
  BanknotesIcon,
  NewspaperIcon,
  TruckIcon,
} from "@heroicons/react/24/outline";
import dayjs from "dayjs";
import { getMessaging, onMessage } from "firebase/messaging";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
// import { usePDF } from "react-to-pdf";
import showErrorToast, { ErrorToastConfig } from "../../components/Error";
import LoadingModal from "../../components/LoadingModal/LoadingModal";
import GrandTotal from "../../components/Orders/GrandTotal";
import OrderSummary from "../../components/Orders/OrderSummary";
import ProductAndService from "../../components/Orders/ProductAndService";
import PurchaseOrderForm from "../../components/Orders/PurchaseOrderForm";
import SalesOrderForm from "../../components/Orders/SalesOrderForm";
import { SuccessModal } from "../../components/SuccessModal";
import PaymentInfoModal from "../../components/payments/PaymentInfoModal";
import PaymentModal from "../../components/payments/PaymentModal";
import { BusinessService } from "../../services/business.service";
import { PaymentService, PaymentType } from "../../services/payment.service";
import { TaxService } from "../../services/tax.service";
import { useAuthStore } from "../../store/session";
import {
  IOrderRevision,
  IOrderSavePayload,
  OrderStatusEnum,
  PaymentFlowTypeEnum,
  paymentFlowTypes,
} from "../../types/order";
import { BrowserRouterConstants } from "../../utils/constants";
// import AddNewBusinessModal from "../../components/Orders/AddNewBusinessModal";
import AddCompanyContactModal from "../../components/Orders/AddCompanyContactModal";
import { setDefaultCurrency } from "../../utils/misc";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { getInvoiceNumber } from "../../utils/order";
import { If } from "../../components/Condition";
import RelatedOrders from "../../components/Orders/RelatedOrders";
// import OrderDetails, { OrderDetailsProps } from "./pdf/OrderDetailsPDF";
// import { orderStatuses } from "../../types/order";

// const localeCurrencies = ["IDR", "MYR", "PHP", "THB", "VND"];
export const currencyList = [
  "IDR",
  "MYR",
  "PHP",
  "THB",
  "VND",
  "USD",
  "SGD",
  "EUR",
  "HKD",
  "JPY",
];
// currencyList.unshift(localeCurrencies[0]);

export const countryToCurrencyList: { [key: string]: string } = {
  ID: "IDR",
  MY: "MYR",
  PH: "PHP",
  TH: "THB",
  VN: "VND",
  US: "USD",
  SG: "SGD",
  HK: "HKD",
  JP: "JPY",
};

export default function PublicViewOrder() {
  const { t } = useTranslation();

  const [orderType, setOrderType] = useState<any>("sales");
  const [currentOrderStatus, setCurrentOrderStatus] = useState<any>("");
  const [invoiceNumber, setInvoiceNumber] = useState<any>("");
  const [isReadOnly, setIsReadOnly] = useState<any>(false);
  const initSuccessModal = {
    open: false,
    title: "Success",
    content: "Order Saved Successfully.",
  };
  const [successModal, setSuccessModal] = useState<any>(initSuccessModal);

  const [openPaymentModal, setOpenPaymentModal] = useState(false);
  const [searchParam] = useSearchParams();
  /**
   * states and variables required for adjustment order:
   * @isAdjustmentOrder
   * @revisions
   * @revisionId
   * @currentRevision
   */
  // const [isAdjustmentOrder, setIsAdjustmentOrder] = useState(false);
  const [revisions, setRevisions] = useState<any[]>([]);
  const [mainOrderCreatedAt, setMainOrderCreatedAt] = useState("");
  const [currentRevision, setCurrentRevision] = useState<
    IOrderRevision | undefined
  >();
  const revisionId = searchParam.get("revision");

  const enquiryPaymentStatus = async (transactionId: string) => {
    setLoading(true);
    PaymentService.enquiryPaymentStatus(transactionId,"")
      .then((_) => {
        setLoading(false);
        setSuccessModal({
          ...successModal,
          open: true,
          content: t("order.createOrder.success.paymentSuccess"),
        });
        setTimeout(() => {
          window.location.href = window.location.href.split("?")[0];
          setSuccessModal(initSuccessModal);
        }, 2000);
      })
      .catch((error) => {
        setLoading(false);
        toast(showErrorToast(error), ErrorToastConfig);
      });
  };

  // Listen to payment redirect url
  useEffect(() => {
    console.log("IFRAME", searchParam.get("transaction_id"));
    const transactionId = searchParam.get("transaction_id");
    if (transactionId && (transactionId?.length ?? 0) > 0) {
      enquiryPaymentStatus(searchParam.get("transaction_id") ?? "");
    }
  }, [searchParam]);

  onMessage(getMessaging(), (payload) => {
    console.log("FCM Message received. ", payload);
    if (`${payload.data?.topic}`.includes("payment-success")) {
      setSuccessModal({
        ...successModal,
        open: true,
        content: t("order.createOrder.success.paymentSuccess"),
      });
      setTimeout(() => {
        setSuccessModal(initSuccessModal);
      }, 2000);
    }
  });

  const [addNewBusinessModal, setAddNewBusinessModal] = useState<any>({
    open: false,
    companyName: "",
  });

  const [loading, setLoading] = useState<any>(false);
  // const [currencyList, setCurrencyList] = useState<any[]>();
  const [internalAccountList, setInternalAccountList] = useState<any[]>([]);
  const [selectedInternalAccount, setSelectedInternalAccount] = useState<any>(
    {}
  );
  const [externalBusinessList, setExternalBusinessList] = useState<any>([]);
  const [selectedExternalBusiness, setSelectedExternalBusiness] = useState<any>(
    {}
  );
  const [productSearchList, setProductSearchList] = useState<any[]>([]);

  const [nationalTaxes, setNationalTaxes] = useState<any[]>([]);

  const prevFocusedError = useRef<any>("");
  // const navigate = useNavigate();
  const params = useParams();
  const orderId = params?.orderId;

  const { session } = useAuthStore();
  // const [pdfContent, setPdfContent] = useState<OrderDetailsProps>(
  //   {} as OrderDetailsProps
  // );
  const location = useLocation();

  const methods = useForm<IOrderSavePayload>({
    mode: "onChange",
    defaultValues: {
      // sellerIds: {
      //   businessId: "",
      //   accountId: "",
      // },
      // buyerIds: {
      //   businessId: "",
      //   accountId: "",
      // },
      sellerIds: null,
      buyerIds: null,
      orderItems: [],
      grandTotalAmount: 0,
      totalTax: 0,
      additionalDiscount: 0,
      deliveryFee: 0,
      downPayment: 0,
    },
  });

  const {
    control,
    register,
    setValue,
    getValues,
    reset,
    clearErrors,
    formState: { errors },
  } = methods;

  /** Initial fetch and set Data: Internal Account List */
  useEffect(() => {
    if (session?.activeBusiness) {
      getAndSetInternalAccountList();
      getAndSetExternalBusinessList();
    }
  }, [session?.activeBusiness]);

  /** Initial fetch and set Data: Order */
  useEffect(() => {
    if (internalAccountList && orderId) {
      // getAndSetInitialOrderData(urlOrderType, orderId);
      getAndSetInitialOrderData(orderId);
    }
  }, [internalAccountList, orderId]);

  async function getAndSetInternalAccountList() {
    const internalAccountList =
      await AccountService.getAccountListByCurrentBusinessInternal(
        session?.activeBusiness.id || null
      );
    if (internalAccountList) {
      console.log("Internal Account List", internalAccountList);
      setInternalAccountList(internalAccountList);
    }
  }

  async function updateOrderDataToDB(data: IOrderSavePayload) {
    console.log("To Update Order Data:", data);
    return OrderService.update(orderId, data);
  }

  async function getAndSetExternalBusinessList() {
    const externalBusinessList = await BusinessService.getExternalBusinessList({
      businessId: session?.activeBusiness.id,
    });
    if (externalBusinessList) {
      console.log("External Business List", externalBusinessList);
      setExternalBusinessList(externalBusinessList);
    }
  }

  function getAndSetProductSearchList(
    orderT: string,
    sellerId: string,
    buyerId: string | null
  ) {
    /** If sales, only sellerId is necessary to fetch seller's products
     * because the current user is seller */
    if (orderT === "sales" && sellerId) {
      ItemService.getInventoryItemListByBusinessIdAsSeller(sellerId)
        .then((res) =>
          setProductSearchList(formatInventoryItemResponse(res.data))
        )
        .catch((err) => console.error(err));

      /** If purchase, buyerId and sellerId is necessary to fetch seller's products
       * because the current user is buyer and need to check seller's products first */
    } else if (orderT === "purchase" && buyerId && sellerId) {
      ItemService.getInventoryItemListByBusinessIdAsBuyer(buyerId, sellerId)
        .then((res) =>
          setProductSearchList(formatInventoryItemResponse(res.data))
        )
        .catch((err) => console.error(err));
    }
  }

  async function getAndSetNationalTaxes(businessId: any) {
    try {
      const res = await TaxService.getBusinessTaxes(businessId || "");
      console.log("Response Taxes::", res);
      setNationalTaxes(res.map((res: any) => res.tax));
    } catch (error) {
      console.error(error);
    }
  }

  async function getAndSetInitialOrderData(orderId: string | undefined) {
    /** defined tempCurrentRevision to be used directly */
    let tempCurrentRevision = undefined;

    const responseOrderData = await OrderService.getOrderPublic(orderId);
    if (responseOrderData) {
      console.log(
        "Response Get Order :::",
        responseOrderData,
        session?.activeBusiness.id,
        internalAccountList
      );

      /** Set Order Type based on the orderType URL param path */
      // if (location.pathname.includes("sales")) {
      //   setOrderType("sales");
      // } else if (location.pathname.includes("purchase")) {
      //   setOrderType("purchase");
      // }

      setOrderType(params?.orderType);

      console.log("====================================");
      console.log(
        "Response Data and location path :::",
        responseOrderData,
        location.pathname
      );
      console.log("====================================");

      setCurrentOrderStatus(responseOrderData.status || OrderStatusEnum.DRAFT);

      setIsReadOnly(true);
      /**
       * assigning values to states required by Adjustment Order
       */
      setInvoiceNumber(
        getInvoiceNumber(responseOrderData, responseOrderData.orderRevisions) ||
          null
      );
      setRevisions(responseOrderData.orderRevisions);
      setMainOrderCreatedAt(responseOrderData.createdAt);
      tempCurrentRevision = revisionId
        ? responseOrderData.orderRevisions.find(
            (r: IOrderRevision) => r.id === revisionId
          )
        : undefined;
      setCurrentRevision(tempCurrentRevision);

      let initialOrderData: IOrderSavePayload = {};
      initialOrderData.createdById = responseOrderData.createdBy;

      initialOrderData.invoiceNumber = responseOrderData.invoiceNumber || null;

      if (params.orderType === "sales") {
        /** Current active business is seller */

        setOrderType("sales");
        setSelectedInternalAccount(responseOrderData.sellerUser);
        // handleOnChangeInternalAccount("sales", responseOrderData.sellerUser);
        // handleOnChangeExternalBusiness("sales", responseOrderData.buyer);
        responseOrderData.buyer &&
          setSelectedExternalBusiness(responseOrderData.buyer);

        getAndSetNationalTaxes(responseOrderData.seller?.id);
        getAndSetProductSearchList("sales", responseOrderData.seller?.id, null);
      } else if (params.orderType === "purchase") {
        /** Current active business is buyer */

        setOrderType("purchase");
        setSelectedInternalAccount(responseOrderData.buyerUser);
        responseOrderData.seller &&
          setSelectedExternalBusiness(responseOrderData.seller);
        // handleOnChangeExternalBusiness("purchase", responseOrderData.seller);
        getAndSetNationalTaxes(responseOrderData.seller?.id);
        getAndSetProductSearchList(
          "purchase",
          responseOrderData.seller?.id,
          responseOrderData.buyer?.id
        );
      }

      initialOrderData.sellerIds = responseOrderData.seller?.id
        ? {
            businessId: responseOrderData.seller.id,
            accountId: responseOrderData.sellerUser.id,
          }
        : null;

      initialOrderData.buyerIds = responseOrderData.buyer?.id
        ? {
            businessId: responseOrderData.buyer?.id,
            accountId: responseOrderData.buyerUser?.id,
          }
        : null;

      initialOrderData.paymentFlowType = responseOrderData.paymentFlowType;

      initialOrderData.startMonth = responseOrderData.startMonth;
      initialOrderData.endMonth = responseOrderData.endMonth;
      initialOrderData.recurringDay = responseOrderData.recurringDay;
      initialOrderData.recurringParentOrderId =
        responseOrderData.recurringParentOrderId || null;

      initialOrderData.paymentTerm = responseOrderData.paymentTerm;
      initialOrderData.currency =
        responseOrderData.currency ||
        countryToCurrencyList[responseOrderData.seller?.companyCountry];
      initialOrderData.deliveryDate = responseOrderData.deliveryDate
        ? dayjs(responseOrderData.deliveryDate).format("YYYY-MM-DD")
        : "";
      initialOrderData.shippingMethod = responseOrderData.shippingMethod;
      initialOrderData.vehicleNumber = responseOrderData.vehicleNumber;
      initialOrderData.trackingNumber = responseOrderData.trackingNumber;
      initialOrderData.driverName = responseOrderData.driverName;
      initialOrderData.deliveryFee = responseOrderData.deliveryFee || 0;
      initialOrderData.additionalDiscount =
        responseOrderData.additionalDiscount || 0;
      initialOrderData.additionalDiscountType =
        responseOrderData.additionalDiscountType;
      initialOrderData.grandTotalAmount = responseOrderData.grandTotalAmount;
      initialOrderData.remarks = responseOrderData.remarks;

      initialOrderData.additionalTaxItem = {
        taxLabel: responseOrderData.additionalTaxLabel,
        taxAmount: responseOrderData.additionalTax,
      };
      initialOrderData.additionalTaxLabel =
        responseOrderData.additionalTaxLabel;
      initialOrderData.additionalTax = responseOrderData.additionalTax || 0;

      initialOrderData.downPayment = responseOrderData.downPayment || 0;

      // TODO: Felix: Public View Order
      /** One OrderItem, One Product.. (only if there's orderItem data) */
      console.log("Response Order Items :::", responseOrderData.orderItems);
      initialOrderData.orderItems = responseOrderData.orderItems?.map(
        (orderItem: any) => {
          orderItem.orderItemId = orderItem.id;
          orderItem.productId = orderItem.inventoryItem.id;
          orderItem.createdById = orderItem.createdBy;
          orderItem.businessId = orderItem.inventoryItem.businessId;
          orderItem.sku = orderItem.sku || orderItem.inventoryItem.sku;
          orderItem.productName =
            orderItem.productName || orderItem.inventoryItem.productName;
          orderItem.productDescription =
            orderItem.productDescription ||
            orderItem.inventoryItem.productDescription;
          orderItem.inventoryType = orderItem.inventoryItem.inventoryType;
          orderItem.uomList = transformUomList(orderItem.inventoryItem);
          orderItem.uomItem = orderItem.uomList.find(
            (option: any) => option.uom === orderItem.uom
          );
          orderItem.uom = orderItem.uom;
          orderItem.taxLabel = orderItem.taxLabel;
          orderItem.tax = orderItem.tax;
          orderItem.taxItem = {
            taxLabel: orderItem.taxLabel,
            taxAmount: orderItem.tax,
          };
          orderItem.conversion = orderItem.conversion;
          return orderItem;
        }
      );

      console.log("initial Order Data (before reset)", initialOrderData);

      reset(initialOrderData);
    }
  }

  const onSuccessAddContact = (data: any) => {
    console.log("onSuccessAddContact", data);
    if (orderType === "sales") {
      setValue("buyerIds.businessId", data?.id);
      setValue("buyerIds.accountId", data?.account?.id);
    } else if (orderType === "purchase") {
      setValue("sellerIds.businessId", data?.id);
      setValue("sellerIds.accountId", data?.account?.id);
    }
    setSelectedExternalBusiness(data);
  };

  const [paymentTransactionDetails, setPaymentTransactionDetails] =
    useState<any>(null);
  const [paymentInfo, setPaymentInfo] = useState<any>(null);
  const [showPaymentInfoModal, setShowPaymentInfoModal] = useState(false);

  const getPaymentStatus = (onSuccess?: () => void) => {
    PaymentService.enquiryPaymentStatus(
      orderId ?? "",
      currentOrderStatus === OrderStatusEnum.PENDING_FINAL_PAYMENT
        ? PaymentType.FINAL_PAYMENT
        : PaymentType.INITIAL_PAYMENT
    ).then((response) => {
      setPaymentInfo(response.data.transactionInfo);
      setPaymentTransactionDetails(response.data);
      onSuccess?.();
    });
  };

  useEffect(() => {
    if (currentOrderStatus) getPaymentStatus();
  }, [currentOrderStatus]);

  const handlePaymentContinue = async () => {
    const payload = getValues();
    payload.status = OrderStatusEnum.PROCESSING;
    console.log("To Continue", payload);
    setOpenPaymentModal(false);
    if (
      !["request-for-loan", "offline-payment"].includes(
        payload?.paymentMethod ?? ""
      )
    ) {
      const method = payload.paymentMethod?.split(":")[0];
      const option = payload.paymentMethod?.split(":")[1];
      if (method) {
        var downPayment =
          getValues("downPayment") ?? getValues("grandTotalAmount");
        if (currentOrderStatus === OrderStatusEnum.PENDING_FINAL_PAYMENT) {
          downPayment =
            (getValues("grandTotalAmount") ?? 0) -
            (getValues("downPayment") ?? 0);
        }
        console.log("Payable Amount", downPayment, getValues("status"));
        setLoading(true);
        PaymentService.requestPayment(
          PaymentService.createPaymentRequestPayload({
            method,
            option: option ?? "",
            transactionType: "order",
            transactionId: orderId,
            amount: downPayment,
            currency: getValues("currency"),
            redirectUrl: window.location.href,
            paymentType:
              currentOrderStatus === OrderStatusEnum.PENDING_PAYMENT
                ? PaymentType.INITIAL_PAYMENT
                : PaymentType.FINAL_PAYMENT,
          }),
          ""
        )
          .then((response) => {
            if (response) {
              getPaymentStatus(() => {
                setShowPaymentInfoModal(true);
              });
            }
          })
          .catch((err) => {
            toast(showErrorToast(err), ErrorToastConfig);
          })
          .finally(() => {
            setLoading(false);
          });
      }
    } else {
      setLoading(true);
      // Just update the status when pending for initial payment
      if (currentOrderStatus === OrderStatusEnum.PENDING_PAYMENT) {
        updateOrderDataToDB(payload)
          .then((_) => {
            setLoading(false);
          })
          .catch((err) => {
            setLoading(false);
            toast(showErrorToast(err), ErrorToastConfig);
          });
      } else {
        setLoading(false);
      }
    }
  };
  const handlePaymentSkip = () => {
    const payload = getValues();
    payload.paymentMethod = null;
    payload.status = OrderStatusEnum.PROCESSING;
    console.log("To Skip", payload);
    updateOrderDataToDB(payload);
    setOpenPaymentModal(false);
  };

  // const handleGeneratePdf = async () => {
  //   toPDF({});
  // };

  const handleOnChangeInternalAccount = (orderT: any, selectedAcc: any) => {
    setSelectedInternalAccount(selectedAcc);
    if (orderT === "sales") {
      setValue(
        "sellerIds",
        selectedAcc?.id
          ? {
              businessId: session?.activeBusiness.id || null,
              accountId: selectedAcc?.id,
            }
          : null
      );

      setValue(
        "currency",
        selectedAcc?.role?.[0]?.business?.bankInfo?.[0]?.currency ||
          setDefaultCurrency(selectedAcc?.role?.[0]?.business?.companyCountry)
      );
    } else if (orderT === "purchase") {
      setValue(
        "buyerIds",
        selectedAcc?.id
          ? {
              businessId: session?.activeBusiness.id || null,
              accountId: selectedAcc?.id,
            }
          : null
      );
    }
  };

  const handleOnChangeExternalBusiness = (orderT: any, selectedBiz: any) => {
    console.log("Selected External Business", selectedBiz);
    if (typeof selectedBiz === "string") {
      console.log("Selected External Business [STRING]", selectedBiz);
      /** Timeout to avoid instant validation of the dialog's form. */

      setTimeout(() => {
        setAddNewBusinessModal({
          ...addNewBusinessModal,
          open: true,
        });
      });
    } else if (selectedBiz && selectedBiz.newBusinessInput) {
      /** If the selected one is the adding new business */

      setAddNewBusinessModal({
        ...addNewBusinessModal,
        open: true,
        companyName: selectedBiz.newBusinessInput,
      });
    } else {
      /** If the selected one is the existing business */
      console.log(
        "Selected External Business [NORMAL EXISTING BUSINESS]",
        selectedBiz
      );
      setSelectedExternalBusiness(selectedBiz);
      if (orderT === "sales") {
        setValue(
          `buyerIds`,
          selectedBiz?.id
            ? {
                businessId: selectedBiz?.id,
                accountId: selectedBiz?.role?.[0].account.id,
              }
            : null
        );
        errors.buyerIds && clearErrors("buyerIds");
      } else if (orderT === "purchase") {
        setValue(
          `sellerIds`,
          selectedBiz?.id
            ? {
                businessId: selectedBiz?.id,
                accountId: selectedBiz?.role?.[0].account.id,
              }
            : null
        );
        errors.sellerIds && clearErrors("sellerIds");

        setValue(
          "currency",
          selectedBiz?.bankInfo?.[0]?.currency ||
            setDefaultCurrency(selectedBiz?.country)
        );

        getAndSetNationalTaxes(selectedBiz.id);

        getAndSetProductSearchList(
          "purchase",
          selectedBiz?.id,
          session?.activeBusiness.id || null
        );
      }
      /** Clear the new business input if existing business is selected */
      setValue("newBusiness", null);
    }
  };

  // Function to focus on the first field with an error
  useEffect(() => {
    // const firstErrorField = Object.keys(errors)[0];
    const firstErrorField = Object.keys(errors).find(
      (key) => (errors as any)[key]
    );
    console.log(
      "Use Effect Error Current Field ::",
      firstErrorField,
      prevFocusedError.current
    );
    if (
      JSON.stringify(firstErrorField) !==
      JSON.stringify(prevFocusedError.current)
    ) {
      (
        document.querySelector(
          `input[name="${firstErrorField}"]`
        ) as HTMLInputElement | null
      )?.focus();

      prevFocusedError.current = firstErrorField;
    }
  }, [Object.keys(errors)]);

  // useEffect(() => {
  //   if (orderId) {
  //     async function fetchOrder() {
  //       try {
  //         const result = await OrderService.getOrderPDFContent(orderId);
  //         console.log("PDF Content ::", result);
  //         setPdfContent(result);
  //       } catch (error) {
  //         console.log(error);
  //       }
  //     }
  //     fetchOrder();
  //   }
  // }, [orderId]);

  const OrderActionButtons = () => {
    return (
      <>
        <Button
          variant="solid"
          fullWidth
          component={Link}
          to={BrowserRouterConstants.Signup}
        >
          {t("landing.createAccountBtn")}
        </Button>

        <Button
          variant="soft"
          fullWidth
          sx={{
            color: "var(--joy-palette-primary-500)",
            "&:hover": {
              backgroundColor: "var(--joy-palette-primary-100)",
            },
          }}
          component={Link}
          to={BrowserRouterConstants.Signin}
        >
          {t("landing.loginBtn")}
        </Button>
      </>
    );
  };

  // const { toPDF, targetRef } = usePDF({
  //   filename: `order-${pdfContent?.invoiceNumber || "00000000-0000000"}.pdf`,
  // });

  return (
    <FormProvider {...methods}>
      {/* <form onSubmit={handleSubmit(onSubmit)}> */}
      <form>
        <Box
          className="SearchAndFilters-tabletUp"
          sx={{
            borderRadius: "sm",
            py: 2,
            px: 2,
            display: "grid",
            gap: 4,
            gridTemplateColumns: { xs: "1fr", md: "1fr 30%" },
            maxWidth: 1080,
            margin: "auto",
          }}
        >
          {/** Main Column */}
          <Box>
            <Tabs
              aria-label="transactions"
              sx={{
                borderTopLeftRadius: 25,
                borderTopRightRadius: 25,
                backgroundColor: "transparent",
              }}
              value={orderType}
            >
              {/** Mobile Order Summary */}
              <Stack
                display={{ xs: "block", md: "none" }}
                spacing={3}
                my={4}
                width={{ xs: "90vw", md: "100%" }}
              >
                {/* Order Summary */}
                <OrderSummary
                  orderType={orderType}
                  invoiceNumber={invoiceNumber}
                  currentOrderStatus={currentOrderStatus}
                  // handleGeneratePdf={handleGeneratePdf}
                  isReadOnly={isReadOnly}
                />
              </Stack>

              <Stack spacing={4} my={4} width={{ xs: "90vw", md: "100%" }}>
                <Toggler
                  defaultExpanded={true}
                  renderToggle={({ open, setOpen, sxClasses }) => (
                    <>
                      <Stack
                        direction={"row"}
                        justifyContent={"space-between"}
                        onClick={() => setOpen(!open)}
                        sx={sxClasses}
                      >
                        <Typography
                          startDecorator={
                            <BanknotesIcon width={24} height={24} />
                          }
                          fontWeight={600}
                        >
                          {t("order.createOrder.typeOfPaymentFlow")}
                          <span className={`text-danger-500`}>*</span>
                        </Typography>
                        {open ? (
                          <Remove color="primary" />
                        ) : (
                          <Add color="primary" />
                        )}
                      </Stack>
                    </>
                  )}
                >
                  <Stack spacing={4}>
                    <Controller
                      name="paymentFlowType"
                      control={control}
                      render={({
                        field: { onChange, onBlur, name, value, ref },
                      }) => {
                        return (
                          <FormControl ref={ref}>
                            <FormLabel>
                              {t("order.createOrder.form.paymentFlowType")}
                            </FormLabel>
                            <Autocomplete
                              name={name}
                              freeSolo={true}
                              placeholder={t(
                                "order.createOrder.form.paymentFlowType"
                              )}
                              options={paymentFlowTypes}
                              getOptionLabel={(option) =>
                                typeof option !== "string" ? option?.label : ""
                              }
                              onChange={(_event, newValue) => {
                                if (typeof newValue !== "string") {
                                  if (
                                    newValue &&
                                    newValue.value !==
                                      PaymentFlowTypeEnum.SPLIT_PAYMENT
                                  ) {
                                    setValue("downPayment", 0);
                                  }
                                  if (
                                    newValue &&
                                    newValue.value ===
                                      PaymentFlowTypeEnum.FULL_BEFORE_DELIVERY
                                  ) {
                                    setValue("paymentTerm", null);
                                  }
                                  if (
                                    newValue &&
                                    newValue.value !==
                                      PaymentFlowTypeEnum.RECURRING_PAYMENT
                                  ) {
                                    setValue("startMonth", null);
                                    setValue("endMonth", null);
                                    setValue("recurringDay", null);
                                  }

                                  onChange(
                                    newValue?.value as
                                      | keyof typeof PaymentFlowTypeEnum
                                      | null
                                  ); // set the Payment Flow Type's value only when the option changes
                                }
                              }}
                              value={
                                paymentFlowTypes.find(
                                  (pft) => pft.value === value
                                ) || null
                              }
                              onBlur={onBlur}
                              disabled={isReadOnly}
                              sx={{ width: { xs: "100%", lg: "100%" } }}
                              renderOption={(props, option) => (
                                <AutocompleteOption
                                  {...props}
                                  key={option.value}
                                  sx={{
                                    "&:hover": {
                                      backgroundColor:
                                        "var(--joy-palette-neutral-100)",
                                    },
                                  }}
                                >
                                  <Stack
                                    direction={"row"}
                                    justifyContent={"space-between"}
                                    alignItems={"center"}
                                    flex={1}
                                  >
                                    <Stack>
                                      {option.label}
                                      <Typography>
                                        {option.description}
                                      </Typography>
                                    </Stack>
                                  </Stack>
                                </AutocompleteOption>
                              )}
                            />
                          </FormControl>
                        );
                      }}
                    />

                    {getValues("paymentFlowType") ===
                      PaymentFlowTypeEnum.RECURRING_PAYMENT && (
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Stack
                          direction={"row"}
                          justifyContent={"space-between"}
                          spacing={2}
                        >
                          <FormControl sx={{ flexGrow: 1 }}>
                            <FormLabel>
                              {t("order.createOrder.form.startMonth")}
                            </FormLabel>

                            <Controller
                              name="startMonth"
                              control={control}
                              render={({
                                field: { name, value, ref, onChange },
                              }) => (
                                <DesktopDatePicker
                                  views={["year", "month"]}
                                  slots={
                                    {
                                      // calendarHeader: CustomDatePickerHeader,
                                    }
                                  }
                                  // openTo=
                                  minDate={dayjs(new Date()).utc(true)}
                                  slotProps={{
                                    popper: {
                                      sx: {
                                        "& .MuiDateCalendar-root": {
                                          height: "auto !important",
                                        },
                                      },
                                    },
                                    yearButton: {
                                      sx: {
                                        width: "100%",
                                        height: "100%",
                                        borderRadius: 0,
                                        border:
                                          "1px solid var(--joy-palette-neutral-200)",
                                        "&:hover, &:focus, &.Mui-selected": {
                                          backgroundColor:
                                            "var(--joy-palette-primary-500) !important",
                                          color:
                                            "var(--joy-palette-common-white)",
                                        },
                                        "&.Mui-disabled": {
                                          background:
                                            "var(--joy-palette-neutral-100)",
                                          color:
                                            "var(--joy-palette-neutral-400)",
                                        },
                                      },
                                    },
                                    monthButton: {
                                      sx: {
                                        width: "100%",
                                        height: "100%",
                                        borderRadius: 0,
                                        border:
                                          "1px solid var(--joy-palette-neutral-200)",
                                        "&:hover, &:focus, &.Mui-selected": {
                                          backgroundColor:
                                            "var(--joy-palette-primary-500) !important",
                                          color:
                                            "var(--joy-palette-common-white)",
                                        },
                                        "&.Mui-disabled": {
                                          background:
                                            "var(--joy-palette-neutral-100)",
                                          color:
                                            "var(--joy-palette-neutral-400)",
                                        },
                                      },
                                    },
                                  }}
                                  disabled={isReadOnly}
                                  name={name}
                                  value={dayjs(value).utc(true)}
                                  onChange={onChange}
                                  inputRef={ref}
                                />
                              )}
                            />
                          </FormControl>
                          <FormControl sx={{ flexGrow: 1 }}>
                            <FormLabel>
                              {t("order.createOrder.form.endMonth")}
                            </FormLabel>
                            <Controller
                              name="endMonth"
                              control={control}
                              render={({
                                field: { name, value, ref, onChange },
                              }) => (
                                <DesktopDatePicker
                                  views={["year", "month"]}
                                  minDate={dayjs(
                                    getValues("startMonth") || new Date()
                                  ).utc(true)}
                                  slotProps={{
                                    yearButton: {
                                      sx: {
                                        width: "100%",
                                        height: "100%",
                                        borderRadius: 0,
                                        border:
                                          "1px solid var(--joy-palette-neutral-200)",
                                        "&:hover, &:focus, &.Mui-selected": {
                                          backgroundColor:
                                            "var(--joy-palette-primary-500) !important",
                                          color:
                                            "var(--joy-palette-common-white)",
                                        },
                                        "&.Mui-disabled": {
                                          background:
                                            "var(--joy-palette-neutral-100)",
                                          color:
                                            "var(--joy-palette-neutral-400)",
                                        },
                                      },
                                    },
                                    monthButton: {
                                      sx: {
                                        width: "100%",
                                        height: "100%",
                                        borderRadius: 0,
                                        border:
                                          "1px solid var(--joy-palette-neutral-200)",
                                        "&:hover, &:focus, &.Mui-selected": {
                                          backgroundColor:
                                            "var(--joy-palette-primary-500) !important",
                                          color:
                                            "var(--joy-palette-common-white)",
                                        },
                                        "&.Mui-disabled": {
                                          background:
                                            "var(--joy-palette-neutral-100)",
                                          color:
                                            "var(--joy-palette-neutral-400)",
                                        },
                                      },
                                    },
                                  }}
                                  disabled={
                                    isReadOnly &&
                                    (orderType !== "sales" ||
                                      currentOrderStatus ===
                                        OrderStatusEnum.COMPLETE)
                                  }
                                  name={name}
                                  value={dayjs(value)}
                                  onChange={onChange}
                                  inputRef={ref}
                                />
                              )}
                            />
                          </FormControl>
                          <FormControl sx={{ flexGrow: 1 }}>
                            <FormLabel>
                              {t("order.createOrder.form.recurringDay")}
                            </FormLabel>
                            <Controller
                              name="recurringDay"
                              control={control}
                              render={({
                                field: { name, value, onChange },
                              }) => (
                                <TwentyEightDaysPicker
                                  name={name}
                                  value={value}
                                  onChange={onChange}
                                  disabled={
                                    isReadOnly &&
                                    (orderType !== "sales" ||
                                      currentOrderStatus ===
                                        OrderStatusEnum.COMPLETE)
                                  }
                                />
                              )}
                            />
                          </FormControl>
                        </Stack>
                      </LocalizationProvider>
                    )}

                    {getValues("paymentFlowType") !==
                      PaymentFlowTypeEnum.FULL_BEFORE_DELIVERY && (
                      <FormControl>
                        <FormLabel>
                          {t("order.createOrder.form.payment")}
                        </FormLabel>
                        <Input
                          placeholder={t("order.createOrder.form.payment")}
                          {...register("paymentTerm")}
                          disabled={isReadOnly}
                        />
                      </FormControl>
                    )}
                  </Stack>
                </Toggler>
              </Stack>
              
              <Divider />

              {/** //TODO: this is the condition based on current orderType */}
              <Stack spacing={4} my={4}>
              {orderType === "sales" ? (
                <SalesOrderForm
                  internalAccountList={internalAccountList}
                  selectedInternalAccount={selectedInternalAccount}
                  externalBusinessList={externalBusinessList}
                  selectedExternalBusiness={selectedExternalBusiness}
                  handleOnChangeInternalAccount={handleOnChangeInternalAccount}
                  handleOnChangeExternalBusiness={
                    handleOnChangeExternalBusiness
                  }
                  isReadOnly={isReadOnly}
                  isAdjustmentOrder={false}
                />
              ) : (
                <PurchaseOrderForm
                  internalAccountList={internalAccountList}
                  externalBusinessList={externalBusinessList}
                  selectedInternalAccount={selectedInternalAccount}
                  selectedExternalBusiness={selectedExternalBusiness}
                  handleOnChangeInternalAccount={handleOnChangeInternalAccount}
                  handleOnChangeExternalBusiness={
                    handleOnChangeExternalBusiness
                  }
                  isReadOnly={isReadOnly}
                  isAdjustmentOrder={false}
                />
              )}
              </Stack>
            </Tabs>

            {/* Section - Related Orders */}
            <If condition={revisions.length > 0}>
              <Stack spacing={4} mt={4} width={{ xs: "90vw", md: "100%" }}>
                <RelatedOrders
                  revisions={revisions}
                  orderType={orderType}
                  invoiceNumber={invoiceNumber}
                  currentOrderStatus={currentOrderStatus}
                  mainOrderCreatedAt={mainOrderCreatedAt}
                  currentRevision={currentRevision}
                />

                <Divider />
              </Stack>
            </If>

            <Stack spacing={4} mt={4} width={{ xs: "90vw", md: "100%" }}>
              {/** Section - Add Product & Service */}
              <ProductAndService
                nationalTaxes={nationalTaxes}
                productSearchList={productSearchList}
                isReadOnly={isReadOnly}
                orderType={orderType}
              />

              <Divider />
            </Stack>

            {/* Section - Delivery Info */}
            <Stack spacing={4} mt={4} width={{ xs: "90vw", md: "100%" }}>
              <Toggler
                renderToggle={({ open, setOpen, sxClasses }) => (
                  <Stack
                    direction={"row"}
                    justifyContent={"space-between"}
                    onClick={() => setOpen(!open)}
                    sx={sxClasses}
                  >
                    <Typography
                      startDecorator={<TruckIcon width={24} height={24} />}
                      fontWeight={600}
                    >
                      {t("order.createOrder.deliveryForm.deliveryInfo")}
                    </Typography>
                    {open ? (
                      <Remove color="primary" />
                    ) : (
                      <Add color="primary" />
                    )}
                  </Stack>
                )}
              >
                <Grid container spacing={2} mt={1}>
                  <Grid xs={12}>
                    <FormControl>
                      <FormLabel>
                        {t("order.createOrder.deliveryForm.driverName")}
                      </FormLabel>
                      <Input
                        placeholder={t(
                          "order.createOrder.deliveryForm.driverName"
                        )}
                        defaultValue={""}
                        {...register("driverName")}
                        disabled={isReadOnly}
                      />
                    </FormControl>
                  </Grid>

                  <Grid xs={12} lg={6}>
                    <FormControl sx={{ flex: "1" }}>
                      <FormLabel>
                        {t("order.createOrder.deliveryForm.deliveryDate")}
                      </FormLabel>
                      <Input
                        type="date"
                        aria-label="Date"
                        {...register("deliveryDate")}
                        error={!!errors.deliveryDate}
                        disabled={isReadOnly}
                      />
                    </FormControl>
                  </Grid>

                  <Grid xs={12} lg={6}>
                    <FormControl sx={{ flex: "1" }}>
                      <FormLabel>
                        {t("order.createOrder.deliveryForm.shippingMethod")}
                      </FormLabel>
                      <Input
                        placeholder={t(
                          "order.createOrder.deliveryForm.shippingMethod"
                        )}
                        defaultValue={""}
                        {...register("shippingMethod")}
                        disabled={isReadOnly}
                      />
                    </FormControl>
                  </Grid>

                  <Grid xs={12} lg={6}>
                    <FormControl sx={{ flex: "1" }}>
                      <FormLabel>
                        {t("order.createOrder.deliveryForm.vehicleNumber")}
                      </FormLabel>
                      <Input
                        placeholder={t(
                          "order.createOrder.deliveryForm.vehicleNumber"
                        )}
                        defaultValue={""}
                        {...register("vehicleNumber")}
                        disabled={isReadOnly}
                      />
                    </FormControl>
                  </Grid>

                  <Grid xs={12} lg={6}>
                    <FormControl sx={{ flex: "1" }}>
                      <FormLabel>
                        {t("order.createOrder.deliveryForm.trackingNumber")}
                      </FormLabel>
                      <Input
                        placeholder={t(
                          "order.createOrder.deliveryForm.trackingNumber"
                        )}
                        defaultValue={""}
                        {...register("trackingNumber")}
                        disabled={isReadOnly}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </Toggler>
              <Divider />

              {/** Part - Remarks */}
              <Toggler
                renderToggle={({ open, setOpen, sxClasses }) => (
                  <Stack
                    direction={"row"}
                    justifyContent={"space-between"}
                    onClick={() => setOpen(!open)}
                    sx={sxClasses}
                  >
                    <Typography
                      startDecorator={<NewspaperIcon width={24} height={24} />}
                      fontWeight={600}
                    >
                      {t("order.createOrder.summary.remarksLabel")}
                    </Typography>
                    {open ? (
                      <Remove color="primary" />
                    ) : (
                      <Add color="primary" />
                    )}
                  </Stack>
                )}
              >
                <Stack rowGap={4}>
                  <Controller
                    name="remarks"
                    control={control}
                    defaultValue={""}
                    render={({
                      field: { name, ref, onChange, onBlur, value },
                    }) => (
                      <FormControl ref={ref}>
                        <FormLabel>
                          {t("order.createOrder.summary.remarksLabel")}
                        </FormLabel>
                        <Textarea
                          placeholder={t(
                            "order.createOrder.summary.remarksPlaceholder"
                          )}
                          minRows={4}
                          name={name}
                          onChange={(e) => {
                            onChange(e.target.value);
                          }}
                          onBlur={onBlur}
                          value={value ?? ""}
                          disabled={isReadOnly}
                        />
                      </FormControl>
                    )}
                  />
                </Stack>
              </Toggler>
              <Divider />

              {/** Part - Grand Total Amount */}
              <GrandTotal
                nationalTaxes={nationalTaxes}
                isReadOnly={isReadOnly}
              />
            </Stack>

            {/** Part - Action Buttons */}
            {/* Mobile only */}
            <Stack
              direction={"column"}
              alignItems={"center"}
              gap={2}
              mt={4}
              width={{ xs: "90vw", md: "100%" }}
              display={{ xs: "flex", md: "none" }}
            >
              <OrderActionButtons />
            </Stack>
          </Box>

          {/** Right Sidebar Column - Laptop & Desktop */}
          <Box
            alignSelf={"start"}
            position={"sticky"}
            top={24}
            display={{ xs: "none", md: "grid" }}
            justifyContent={"stretch"}
            alignContent={"start"}
            gap={3}
          >
            {/** Order Summary */}
            <OrderSummary
              orderType={orderType}
              invoiceNumber={invoiceNumber}
              currentOrderStatus={currentOrderStatus}
              // handleGeneratePdf={handleGeneratePdf}
            />

            {/** Part - Sidebar Action Buttons - Laptop & Desktop */}
            <Stack direction={"column"} alignItems={"center"} gap={2}>
              <OrderActionButtons />
            </Stack>
          </Box>
        </Box>

        {/** Modals */}
        {/* <AddNewBusinessModal
          modalData={addNewBusinessModal}
          setModalData={setAddNewBusinessModal}
          handleCancel={handleCancelAddNewBusinessModal}
          handleSubmit={handleSubmitAddNewBusinessModal}
        /> */}
        <AddCompanyContactModal
          state={addNewBusinessModal}
          handleOpen={() => setAddNewBusinessModal(true)}
          handleClose={() => setAddNewBusinessModal(false)}
          onSuccessAdd={onSuccessAddContact}
        />
        <PaymentModal
          open={openPaymentModal}
          setOpenPaymentModal={setOpenPaymentModal}
          handleContinue={handlePaymentContinue}
          handleSkip={handlePaymentSkip}
          control={control}
          setValue={setValue}
          onlinePaymentOnly={false}
          paymentAmount={
            currentOrderStatus === OrderStatusEnum.PENDING_FINAL_PAYMENT
              ? (getValues("grandTotalAmount") ?? 0) -
                (getValues("downPayment") ?? 0)
              : getValues("downPayment") ?? getValues("grandTotalAmount")
          }
          paymentCurrency={getValues("currency") ?? ""}
        />
        {paymentTransactionDetails && paymentInfo && (
          <PaymentInfoModal
            open={showPaymentInfoModal}
            setOpenModal={setShowPaymentInfoModal}
            paymentInfo={paymentInfo}
            status={paymentTransactionDetails?.status}
            currency={getValues("currency") ?? ""}
          />
        )}
        <SuccessModal
          open={successModal.open}
          title={successModal.title}
          content={successModal.content}
        />
        <LoadingModal isLoading={loading} />
      </form>
    </FormProvider>
  );
}
