import React from "react";

import { Divider } from "@mui/joy";
import { OrderStatusEnum } from "../../types/order";
import SecondPartyInfo from "./SecondPartyInfo";
import FirstPartyInfo from "./FirstPartyInfo";

interface ISalesOrderForm {
  internalAccountList: any;
  externalBusinessList: any;
  selectedInternalAccount: any;
  selectedExternalBusiness: any;
  handleOnChangeInternalAccount: any;
  handleOnChangeExternalBusiness?: any;
  handleUpdateCompanyInfo?: any;
  handleEditNewBusiness?: any;
  handleRemoveNewBusiness?: any;
  isReadOnly: any;
  isCreator?: any;
  isAdjustmentOrder: boolean;
  orderStatus?: OrderStatusEnum;
  orderType?: string;
}

const SalesOrderForm: React.FC<ISalesOrderForm> = ({
  internalAccountList,
  externalBusinessList,
  selectedInternalAccount,
  selectedExternalBusiness,
  handleOnChangeInternalAccount,
  handleOnChangeExternalBusiness,
  handleUpdateCompanyInfo,
  handleEditNewBusiness,
  handleRemoveNewBusiness,
  isReadOnly,
  isCreator,
  isAdjustmentOrder,
  orderStatus,
  orderType,
}) => {
  if (selectedExternalBusiness) {
    console.log(
      "Selected External Business In Sales Order Form ::",
      selectedExternalBusiness
    );
  }
  return (
    <>
      <FirstPartyInfo
        {...{
          internalAccountList,
          selectedInternalAccount,
          isReadOnly,
          isAdjustmentOrder,
          handleOnChangeInternalAccount,
          orderType,
        }}
      />
      <Divider />

      <SecondPartyInfo
        {...{
          externalBusinessList,
          isReadOnly,
          isAdjustmentOrder,
          handleOnChangeExternalBusiness,
          handleEditNewBusiness,
          handleRemoveNewBusiness,
          handleUpdateCompanyInfo,
          isCreator,
          orderStatus,
          orderType,
        }}
      />

      <Divider />
    </>
  );
};

export default SalesOrderForm;
