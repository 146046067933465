import { useTranslation } from "react-i18next";
import { useAuthStore } from "../../../store/session";
import {
  Box,
  Button,
  List,
  Modal,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/joy";
import { useEffect, useState } from "react";
import { getCountryInfo } from "../../../utils/country";
import { PencilIcon, PlusIcon } from "@heroicons/react/24/outline";
import AddressModal from "../../Profile/Address/AddressModal";

interface Props {
  show?: boolean;
  onClose: () => void;
  onConfirm: (address: string) => void;
  addresses: IAddressResponse[];
  defaultAddress: IAddressResponse;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "#fff",
  boxShadow: 24,
  // px: 3,
  // py: 3,
  borderRadius: "32px",
  borderColor: "transparent",
  outline: "none",
  height: "90vh",
  width: { xs: "87.7dvw", md: "40rem" },
};

const AddressSelectionModal = ({
  show = false,
  onClose,
  onConfirm,
  addresses,
  defaultAddress,
}: Props) => {
  const { t } = useTranslation();
  const { session } = useAuthStore.getState();

  const [showModal, setShowModal] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState<string>(
    defaultAddress.id || ""
  );
  const [showEditAddressModal, setShowEditAddressModal] = useState(false);
  const [editedAddress, setEditedAddress] = useState<IAddressResponse | null>(
    null
  );

  useEffect(() => {
    setShowModal(show);
  }, [show]);

  const handleClose = () => {
    onClose();
  };

  const handleConfirm = () => {
    onConfirm(selectedAddress);
  };

  if (!session) {
    return (window.location.href = "/login");
  }

  return (
    <Modal
      onClose={handleClose}
      open={showModal}
      style={{
        backgroundColor: "#32383E99",
        backdropFilter: "unset",
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style} justifyContent={"center"}>
        <Stack
          direction={"column"}
          sx={{
            height: "100%",
            gap: "1rem",
            px: { xs: "1rem", lg: "2rem" },
            py: "1.5rem",
            overflow: "auto",
          }}
          className="hide-scrollbar"
        >
          <Typography
            sx={{
              textAlign: "center",
              fontWeight: 700,
              fontSize: 16,
              py: "1rem",
            }}
          >
            {t("catalog.checkout.myAddress.title")}
          </Typography>
          <List>
            <RadioGroup
              defaultValue={"9d0b35fa-eaa2-4c38-9e98-8dd01ddf3142"}
              name="radio-buttons-group"
              onChange={(_event) => {
                setSelectedAddress(_event.target.value);
              }}
              value={selectedAddress}
            >
              {addresses.map((address) => {
                return (
                  <Stack
                    direction={"row"}
                    sx={{
                      my: "12px",
                    }}
                  >
                    <Radio
                      key={address.id}
                      value={address.id}
                      sx={{
                        margin: "0.5rem",
                      }}
                    />
                    <Stack
                      direction={"column"}
                      gap={"0.5rem"}
                      ml={{ xs: "0.5rem", lg: 0 }}
                    >
                      <Typography fontWeight={500} textColor={"#101828"}>
                        {address.title}{" "}
                        {address.defaultAddress && (
                          <span className="border rounded-full text-primary-500 border-primary-500 text-xs px-2 ml-4">
                            {"Default"}
                          </span>
                        )}
                      </Typography>
                      <Stack direction={"column"} gap={"4px"}>
                        <Typography
                          fontSize={14}
                          fontWeight={700}
                          textColor={"#171A1C"}
                        >
                          {address.fullName}
                        </Typography>
                        <Typography
                          fontSize={14}
                          fontWeight={600}
                          textColor={"#171A1C"}
                        >
                          {`+${address.phoneNumberCountryCode} ${address.phoneNumber}`}
                        </Typography>
                      </Stack>
                      <Typography
                        fontSize={14}
                        fontWeight={400}
                        textColor={"#000000"}
                      >
                        {`${address.address}, ${address.province} ${
                          address.postalCode
                        }, ${
                          getCountryInfo(address.countryCode, "LOCALE")?.name
                        }`}
                      </Typography>
                    </Stack>

                    <Button
                      onClick={() => {
                        setShowEditAddressModal(true);
                        setEditedAddress(address);
                      }}
                      variant="plain"
                      className="ml-auto p-0 h-fit mt-2"
                    >
                      <PencilIcon width={18} height={18} />
                    </Button>
                  </Stack>
                );
              })}
            </RadioGroup>
          </List>

          <Button
            onClick={() => {
              setShowEditAddressModal(true);
            }}
            variant="outlined"
            className="my-auto rounded-md w-3/4 lg:w-1/3 gap-2 flex"
          >
            <PlusIcon width={16} height={16} />
            <Typography
              sx={{
                color: "#499873",
                fontSize: 14,
              }}
            >
              {t("catalog.checkout.myAddress.addNewAddressBtn")}
            </Typography>
          </Button>

          <Stack
            direction={"row"}
            sx={{
              alignItems: "center",
              justifyContent: "space-between",
              pb: "1rem",
              gap: "12px",
            }}
          >
            <Button
              onClick={handleClose}
              variant="outlined"
              className="my-auto rounded-md w-1/2"
            >
              <Typography
                sx={{
                  color: "#499873",
                  fontSize: 14,
                }}
              >
                {t("commons.cancelBtn")}
              </Typography>
            </Button>
            <Button
              onClick={handleConfirm}
              className="my-auto rounded-md w-1/2"
            >
              <Typography
                sx={{
                  color: "white",
                  fontSize: 14,
                }}
              >
                {t("commons.saveChangesBtn")}
              </Typography>
            </Button>
          </Stack>
        </Stack>

        <AddressModal
          show={showEditAddressModal}
          selectedAddress={editedAddress}
          onClose={() => {
            setShowEditAddressModal(false);
          }}
          onSuccess={() => {
            window.location.reload();
          }}
        />
      </Box>
    </Modal>
  );
};

export default AddressSelectionModal;
