import { Button, Chip, Sheet, Typography } from "@mui/joy";
import { EditOutlined } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

interface AddressItemProps {
  name: string;
  phone: string;
  address: string;
  title: string;
  isDefault?: boolean;
  onDelete: () => void;
  onEdit: () => void;
}

function ItemButtons({
  onDelete,
  onEdit,
}: {
  onDelete: () => void;
  onEdit: () => void;
}) {
  const { t } = useTranslation();
  return (
    <>
      <Button
        startDecorator={<EditOutlined />}
        onClick={onEdit}
        size="sm"
        variant="linkz-outlined-primary"
        className="h-10 w-full"
      >
        {t("profile.address.edit")}
      </Button>
      <Button
        onClick={onDelete}
        size="sm"
        variant="linkz-outlined-danger"
        className="h-10 w-full"
      >
        {t("profile.address.delete")}
      </Button>
    </>
  );
}

export function AddressItem({
  name,
  phone,
  address,
  title,
  isDefault = false,
  onDelete,
  onEdit,
}: AddressItemProps) {
  const { t } = useTranslation();
  return (
    <Sheet
      sx={{
        backgroundColor: "#FCFCFD",
        borderRadius: "8px",
        padding: "24px",
        border: "1px",
      }}
    >
      <div className="flex flex-col sm:flex-row mt-3 mb-3 sm:space-x-2 sm:space-y-0 space-y-1 sm:justify-between">
        <div className="flex flex-col sm:flex-row">
          <div>
            <Typography level="text-md-bold">{name}</Typography>
            <Typography level="text-md-semibold" className="whitespace-nowrap">
              {phone}
            </Typography>
          </div>
          <Typography className="sm:ml-2" level="text-sm-regular">
            {address}
          </Typography>
        </div>

        {/* Responsive: show when screen sm and more */}
        <div className="hidden sm:flex sm:flex-row sm:space-x-2">
          <ItemButtons onDelete={onDelete} onEdit={onEdit} />
        </div>
      </div>
      <div className="flex space-x-2 xm:mt-2">
        <Typography level="text-md-regular">{title}</Typography>
        {isDefault && (
          <Chip color="primary" variant="outlined">
            {t("profile.address.default")}
          </Chip>
        )}
      </div>

      {/* Responsive: hiden when screen sm and more */}
      <div className="flex flex-row space-x-2 mt-4 sm:hidden">
        <ItemButtons onDelete={onDelete} onEdit={onEdit} />
      </div>
    </Sheet>
  );
}
