import { FormLabel, Grid, Stack, Typography } from "@mui/joy";

export const ThreeColumnGridRow = ({
  label,
  inputElement,
  outputValue,
}: any) => {
  return (
    <Stack direction={"row"} sx={{ width: "100%" }}>
      <Grid
        flexBasis={{ xs: "60%" }}
        container
        columns={{ xs: 12 }}
        alignItems={"start"}
        rowGap={2}
      >
        <Grid xs={12} lg={4}>
          <FormLabel sx={{ mt: { xs: 0, lg: 2 } }}>{label}</FormLabel>
        </Grid>
        <Grid xs={12} lg={8}>
          {inputElement}
        </Grid>
      </Grid>

      <Stack
        flexBasis={{ xs: "40%", lg: "33.33%" }}
        textAlign={"right"}
        sx={{ mt: { xs: 0, lg: 2 }, ml: "auto" }}
      >
        <Typography level="text-sm-medium">{outputValue}</Typography>
      </Stack>
    </Stack>
  );
};
