import {
  Autocomplete,
  FormControl,
  FormLabel,
  Grid,
  Stack,
  Typography,
} from "@mui/joy";
import { PartialToggler } from "../utils";
import { AccountCircleOutlined, Search } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { Controller, useFormContext } from "react-hook-form";
import { BusinessSessionHelper } from "../../helpers/business-session.helper";
import { Fragment } from "react/jsx-runtime";
import SectionToggleBar from "./SectionToggleBar";

export default function FirstPartyInfo({
  internalAccountList,
  selectedInternalAccount,
  isReadOnly,
  isAdjustmentOrder,
  handleOnChangeInternalAccount,
  orderType,
}: any) {
  const { t } = useTranslation();
  const { control } = useFormContext();

  const currentBusinessInfo = selectedInternalAccount?.role?.find(
    (r: any) => r.business.id === (BusinessSessionHelper.GetBusinessId() as any)
  )?.business;

  let infoDetailToLoop = [
    {
      value: `+${selectedInternalAccount?.profile?.countryCode} ${
        currentBusinessInfo?.companyNumber ||
        selectedInternalAccount?.profile?.localNumber
      }`,
      label: t("order.createOrder.form.phone"),
    },
    {
      value: selectedInternalAccount?.email,
      label: t("order.createOrder.form.email"),
    },
    {
      value: currentBusinessInfo?.companyAddress,
      label: t("order.createOrder.form.companyAddress"),
    },
  ];

  let requiredMessage = "";
  let toggleBarTitle = "";
  if (orderType === "sales") {
    requiredMessage = "Seller is required";
    toggleBarTitle = t("order.createOrder.sellerInfo");
    const sellerInfoDetail = [
      {
        value: currentBusinessInfo?.bankInfo?.[0]?.bankName,
        label: t("order.createOrder.form.bankName"),
      },
      {
        value: currentBusinessInfo?.bankInfo?.[0]?.accountNo,
        label: t("order.createOrder.form.accountNo"),
      },
      {
        value: currentBusinessInfo?.bankInfo?.[0]?.accountHolderName,
        label: t("order.createOrder.form.accountHolderName"),
      },
    ];
    infoDetailToLoop = [...infoDetailToLoop, ...sellerInfoDetail];
  } else if (orderType === "purchase") {
    requiredMessage = "Buyer is required";
    toggleBarTitle = t("order.createOrder.buyerInfo");
  }

  return (
    <PartialToggler
      renderToggle={({ open, setOpen, sxClasses }) => (
        <>
          <SectionToggleBar
            {...{ open, setOpen, sxClasses }}
            icon={<AccountCircleOutlined />}
            title={toggleBarTitle}
            isRequired={true}
          />
        </>
      )}
    >
      {/* Internal Account Search */}
      {!isReadOnly && !isAdjustmentOrder && (
        <PartialToggler.TogglyShowUpper>
          <Controller
            name="sellerIds"
            control={control}
            rules={{ required: requiredMessage }}
            render={({ field: { name, ref }, fieldState: { error } }) => (
              <FormControl error={!!error} ref={ref}>
                <FormLabel>{t("order.createOrder.form.salesPerson")}</FormLabel>
                <Autocomplete
                  name={name}
                  startDecorator={<Search />}
                  variant="linkz-autocomplete-rounded"
                  options={internalAccountList || null}
                  getOptionLabel={(option) => option?.profile?.fullName ?? ""}
                  isOptionEqualToValue={(option, value) => {
                    if (Object.keys(value).length > 0)
                      return option.id === value.id;
                    else return !!option;
                  }}
                  value={selectedInternalAccount || ""}
                  onChange={(_, newValue) => {
                    handleOnChangeInternalAccount(orderType, newValue);
                  }}
                  disabled={isReadOnly || isAdjustmentOrder}
                  sx={{
                    width: { xs: "100%", lg: "50%" },
                    marginTop: "4px",
                  }}
                />
              </FormControl>
            )}
          />
        </PartialToggler.TogglyShowUpper>
      )}
      <PartialToggler.AlwaysShow>
        {/* Current Company Name and Account Info */}
        {selectedInternalAccount &&
          Object.keys(selectedInternalAccount).length > 0 && (
            <Stack mt={2} position={"relative"}>
              <Typography level="text-md-semibold">
                {currentBusinessInfo?.companyName}
              </Typography>

              <Grid
                container
                spacing={1}
                m={0}
                mt={1}
                width={{ xs: "100%", lg: "75%" }}
              >
                <Grid xs={5}>
                  <Typography level="text-sm-medium">
                    {t("order.createOrder.form.fullName")} :{" "}
                  </Typography>
                </Grid>
                <Grid xs={6}>
                  <Typography level="text-sm-medium">
                    {selectedInternalAccount?.profile?.fullName}
                  </Typography>
                </Grid>
              </Grid>
            </Stack>
          )}
      </PartialToggler.AlwaysShow>
      <PartialToggler.TogglyShowLower>
        <Stack spacing={4}>
          {selectedInternalAccount &&
            Object.keys(selectedInternalAccount).length > 0 && (
              <>
                <Grid container spacing={1} width={{ xs: "100%", lg: "75%" }}>
                  {infoDetailToLoop?.length > 0 &&
                    infoDetailToLoop.map((info, index) => (
                      <Fragment key={index}>
                        <Grid xs={5}>
                          <Typography level="text-sm-medium">
                            {info.label} :
                          </Typography>
                        </Grid>
                        <Grid xs={6}>
                          <Typography level="text-sm-medium">
                            {info.value}
                          </Typography>
                        </Grid>
                      </Fragment>
                    ))}
                </Grid>
              </>
            )}
        </Stack>
      </PartialToggler.TogglyShowLower>
      <Stack spacing={4}>
        {selectedInternalAccount &&
        Object.keys(selectedInternalAccount).length > 0 ? (
          <>
            <Stack>
              <Grid
                container
                spacing={1}
                mt={1}
                width={{ xs: "100%", lg: "70%" }}
              ></Grid>
            </Stack>
          </>
        ) : (
          ""
        )}
      </Stack>
    </PartialToggler>
  );
}
