import { Stack, Typography } from "@mui/material";
import { If } from "../../../components/Condition";

interface ITitleCount {
  titleText: string;
  subText?: string;
  count: number;
  classes?: string;
  isShowSubText?: boolean;
}

export default function TitleCount({
  count,
  titleText,
  subText,
  classes,
  isShowSubText = false,
}: ITitleCount) {
  return (
    <Stack direction={"row"} alignItems={"center"} gap={2} className={classes}>
      <div className="w-9 h-9 bg-primary-400 rounded-full text-white font-semibold justify-center items-center flex">
        {count}
      </div>
      <Stack width="80%">
        <Typography fontSize={18} fontWeight={600} sx={{ color: "#101828" }}>
          {titleText}
        </Typography>
        <If condition={subText && isShowSubText ? true : false}>
          <p className="md:text-sm text-xs text-[#667085]">{subText}</p>
        </If>
      </Stack>
    </Stack>
  );
}
