import Box from "@mui/joy/Box";
// import Chip from "@mui/joy/Chip";
import { EmblaOptionsType } from "embla-carousel";
import useEmblaCarousel from "embla-carousel-react";
import styles from "./Carousel.module.css";
import { useEffect, useState } from "react";
// import { IconButton } from "@mui/joy";
// import { ArrowsPointingOutIcon } from "@heroicons/react/24/outline";

type Params = {
  alt: string;
  options?: EmblaOptionsType;
  slides: any[];
};

export default function ProductCarousel({ alt, options, slides }: Params) {
  const [emblaRef, embla] = useEmblaCarousel(options);
  const [_currentIndex, setCurrentIndex] = useState(0);
  useEffect(() => {
    if (embla) {
      embla.on("select", () => {
        console.log(`Current index is ${embla.selectedScrollSnap()}`);
        setCurrentIndex(embla.selectedScrollSnap());
      });
    }
  }, [embla]);
  return (
    <Box position="relative">
      {/* {slides.length ? (
        <span className="absolute bottom-[20px] left-[20px] z-10 bg-[#F1F8F4] p-1 px-3 rounded text-sm">
          {currentIndex + 1}/{slides.length}
        </span>
      ) : null} */}
      <section className={styles.embla2}>
        <div className={styles.embla__viewport} ref={emblaRef}>
          <div className={styles.embla__container}>
            {slides.map(({ src }, index: any) => (
              <div className={styles.embla__slide} key={index}>
                <div className={styles.embla__slide__number}>
                  <img
                    alt={alt}
                    src={src || "/inventory-img-placeholder.png"}
                    className="w-[100%] max-w-[208px] h-[100%] max-h-[208px] object-cover"
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </Box>
  );
}
